import { SessionDefinitionsModel, PaginateModel, UseFetchOptions } from '@shared/models';
import { useFetch } from '../hooks';
import { request } from '@shared/utils';

export default {
  getSessionData: (config: UseFetchOptions) => useFetch<PaginateModel<SessionDefinitionsModel>>('/seance', { method: 'GET', ...config }),
  getSessionDataById: async (id: string) => request('/seance/' + id, 'GET'),
  create: async (sessiondefinitions: SessionDefinitionsModel) => request('/seance', 'POST', sessiondefinitions),
  update: async (model: SessionDefinitionsModel) => request('/seance', 'PUT', model),
  delete: async (id: string) => request('/seance/' + id, 'DELETE'),
};
