import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

export const StudentDashboard = () => {
  useAccessableSeachParams();
  return <ComingSoon navigationUrl="/" navigationText="Ana ekrana dön" />;
};

StudentDashboard.path = '/' + LayoutEnums.STUDENT + '/dashboard';
StudentDashboard.title = 'Kontrol Paneli';
StudentDashboard.group = 'flat';
StudentDashboard.roles = [Role.ADMIN];
StudentDashboard.layouts = [LayoutEnums.STUDENT];
StudentDashboard.icon = <DashboardOutlined size={20} />;
