import { ContentVersionStatus } from "@shared/constants";
import { useFetch } from "@shared/hooks";
import { CourseVersionDefination, CourseVersionModel, UseFetchOptions } from "@shared/models";
import { request } from "@shared/utils";

export default {
    create: async (version: CourseVersionDefination) => request('/lms/content-version', "POST", version),
    get:async (id:string)=>request("/lms/content-version/"+id,'GET'),
    update:async(version:CourseVersionDefination)=>request("/lms/content-version","PUT",version),
    fetchAll: (config: UseFetchOptions) => useFetch<CourseVersionModel[]>('/lms/content-version', { method: 'GET', ...config }),
    fetchWithStatus: (status: ContentVersionStatus, config: UseFetchOptions) => useFetch<CourseVersionModel[]>('/lms/content-version?status=' + status, { method: 'GET', ...config }),
    delete:async (versionId:string)=>request("/lms/content-version/"+versionId,'DELETE'),
    fork:async (versionId:string)=>request("/lms/content-version/"+versionId+"/fork",'GET'),
    publish:async (versionId:string)=>request("/lms/content-version/"+versionId+"/publish",'GET'),
    //Review
    startFirstPage:async (versionId:string)=>request("/lms/content-version/"+versionId+"/next-page",'GET'),
    prevPage:async(versionId:string,pageId:string)=>request("/lms/content-version/"+versionId+"/prev-page/"+pageId,'GET'),
    nextPage:async (versionId:string,pageId:string)=>request("/lms/content-version/"+versionId+"/next-page/"+pageId,'GET'),
    goToPage:async (versionId:string,pageId:string)=>request("/lms/content-version/"+versionId+"/go-to-page/"+pageId,'GET'),
}