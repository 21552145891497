import { ContainerOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { ClassDetailModel, StudentItem, CreateClassTeacherStudentEvaluation } from '@shared/models';
import { Button, Card, Divider, Form, InputNumber, Modal, notification, Popover } from 'antd';
import { useEffect, useState } from 'react';
import * as API from '@shared/api';
import TextArea from 'antd/es/input/TextArea';

type TeacherClassStudentProps = {
  classDetail: ClassDetailModel | undefined;
  isModalOpen: boolean;
  onCloseModal: () => void;
};

export const TeacherClassStudentList = ({ classDetail, isModalOpen, onCloseModal }: TeacherClassStudentProps) => {
  const [studentEvaluations, setStudentEvaluations] = useState<StudentItem[]>([]);

  useEffect(() => {
    if (isModalOpen) {
      getTeacherStudentEvaluation();
    }
  }, [isModalOpen]);

  const getTeacherStudentEvaluation = async () => {
    const response = await API.CLASS.getTeacherStudentEvaluation(classDetail?.id);

    const defaultStudentEvaluations: StudentItem[] =
      classDetail?.students?.map((student) => ({
        student: student,
        description: '',
        score: undefined,
      })) || [];

    if (response.data.status == 404) {
      setStudentEvaluations(defaultStudentEvaluations);
    } else {
      setStudentEvaluations(response.data.items);
    }
  };

  const handleScoreChange = (studentId: string, value) => {
    setStudentEvaluations((prev) => prev.map((evaluation) => (evaluation.student?.id === studentId ? { ...evaluation, score: value } : evaluation)));
  };

  const handleDescriptionChange = (studentId: string, value: string) => {
    setStudentEvaluations((prev) => prev.map((evaluation) => (evaluation.student?.id === studentId ? { ...evaluation, description: value } : evaluation)));
  };

  return (
    <>
      <Modal title={<span className="font-normal">Performans Notu Girin</span>} open={isModalOpen} footer={null} onCancel={onCloseModal} centered>
        <div className="mt-3">
          <div className="text-[#00000073]">Öğrenci Listesi</div>
          <div className="mt-5 max-h-[380px] 2xl:max-h-[480px] overflow-y-scroll">
            <Form layout="vertical">
              {studentEvaluations?.map((evaluation) => (
                <div key={evaluation.student?.id} className="mb-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-1">
                      {evaluation.student?.name} {evaluation.student?.surname}
                    </div>
                    <div className="flex-1">
                      <Form.Item name={`score-${evaluation.student?.id}`} initialValue={evaluation.score}>
                        <InputNumber min={0} max={100} style={{ width: '100%' }} onChange={(value) => handleScoreChange(evaluation.student?.id || '', value)} />
                      </Form.Item>
                    </div>
                    <div className="flex-1">
                      <Form.Item name={`description-${evaluation.student?.id}`} initialValue={evaluation.description}>
                        <TextArea
                          placeholder="Not yazınız"
                          rows={1}
                          style={{ width: '100%' }}
                          onChange={(e) => handleDescriptionChange(evaluation.student?.id || '', e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Divider className="my-1" />
                </div>
              ))}
            </Form>
            <Button
              className="mt-5"
              type="primary"
              onClick={async () => {
                const formattedEvaluation: CreateClassTeacherStudentEvaluation = {
                  items: studentEvaluations.map((evaluation) => ({
                    studentId: evaluation.student?.id,
                    description: evaluation.description,
                    score: evaluation.score,
                  })),
                };

                const response = await API.CLASS.upsertTeacherStudentEvaluation(classDetail?.id!, formattedEvaluation);
                if (response.ok) {
                  notification.success({ message: 'Notlandırma kaydedildi' });
                  onCloseModal();
                  getTeacherStudentEvaluation();
                } else {
                  notification.error({ message: 'Notlandırma başarısız' });
                }
              }}
            >
              Notlandırmayı Kaydet
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
