import { UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';
import { useCallback, useEffect, useState } from 'react';

const defaultOptions: UseFetchOptions = {
  init: false,
  method: 'GET',
  initBody: null,
  initParams: null,
};

export const useFetch = <T>(url: string, options = defaultOptions): [T | undefined, boolean, CallableFunction, any] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const requestCallback = useCallback(
    async (data: any, params: any) => {
      setLoading(true);

      const response = await request(url, options.method, data, params, options, true);

      if (response.ok) {
        setData(response.data);
      } else {
        setData(undefined);
        setError(response.error);
      }
      setLoading(false);
    },
    [url, setData, setLoading, setError]
  );

  useEffect(() => {
    if (options.init) {
      requestCallback(options.initBody, options.initParams);
    }
  }, []);

  return [data, loading, requestCallback, error];
};
