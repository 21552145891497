import { useEffect } from "react";

type TextPageProps = {
    text:string,
}
export const TextPage = ({text}:TextPageProps) => {

    useEffect(() => {
        var contentTextHtlmList = document.getElementsByClassName("content_text");
        for (let i = 0; i < contentTextHtlmList.length; i++) {
            let d = contentTextHtlmList[i];
            const lis = d.querySelectorAll('li');
            lis.forEach((li, index) => {
                const liContent = li.textContent;
                if (!liContent?.startsWith('?')) {
                    return;
                }
                const liHtml = li.innerHTML;
                li.innerHTML = `...`;
                li.style.cursor = 'pointer';
                li.addEventListener('click', () => (li.innerHTML = ` ${liHtml.replace('?', '')}`));
            });
        }

    }, [text]);
    return (    
        <div className="content_text" dangerouslySetInnerHTML={{ __html: text || '' }} />
    );
}