import { Button, Card, Divider, Drawer, Form, Input, InputNumber, Modal, notification, Popconfirm } from 'antd';
import { DatePicker } from 'antd';
import { LayoutEnums, Role } from '@shared/constants';
import TextArea from 'antd/lib/input/TextArea';
import { CheckCircleOutlined, StopOutlined, UploadOutlined } from '@ant-design/icons';

type HomeWorkSubmissionModalProps = {
  open: boolean;
  onClose: () => void;
};

export const HomeWorkSubmissionModal = ({ open, onClose }: HomeWorkSubmissionModalProps) => {
  const { RangePicker } = DatePicker;

  const handleOnClose = () => {
    //form.reset();
    onClose();
  };

  return (
    <Modal forceRender={true} open={open} onCancel={() => handleOnClose()} title={'Ödev Teslim Durumu'} footer={null}>
      <div className="flex">
        <div className="w-1/2 mr-1">
          <Card className="p-4" bodyStyle={{ padding: '0' }}>
            <CheckCircleOutlined style={{ color: 'green' }} /> <span className="ml-3">Teslim Edenler</span>
          </Card>
          <div className="mt-3" style={{ height: '60vh', overflowY: 'auto' }}>
            <div className="flex justify-between">Tugay İpek</div>
            <Divider className="m-1" orientation="left"></Divider>
            <div className="flex justify-between">Mustafa Pıçakçı</div>
            <Divider className="m-1" orientation="left"></Divider>
            <div className="flex justify-between">Mustafa Fehmi Altınbaş</div>
          </div>
        </div>
        <Divider className="h-96" type="vertical">
          asd
        </Divider>
        <div className="w-1/2 ml-1">
          <Card className="p-4" bodyStyle={{ padding: '0' }}>
            <StopOutlined style={{ color: 'red' }} /> <span className="ml-3">Teslim Etmeyenler</span>
          </Card>
          <div className="mt-3">
            <div className="flex justify-between">Esra İpek</div>
            <Divider className="m-1" orientation="left"></Divider>
            <div className="flex justify-between">Fıstık Pıçakçı</div>
            <Divider className="m-1" orientation="left"></Divider>
            <div className="flex justify-between">Merve Altınbaş</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

HomeWorkSubmissionModal.roles = [Role.ADMIN];
