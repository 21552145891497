import { Spin } from 'antd';

type SpinnerProps = {
  loading: boolean;
  message?: string;
  fullscreen?: boolean;
};

export const Spinner = ({ loading, message = 'Yükleniyor', fullscreen = true }: SpinnerProps) => {
  return <Spin tip={message} spinning={loading} fullscreen={fullscreen} />;
};
