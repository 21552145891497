import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { useState } from 'react';
import { CheckCircleOutlined, ReadOutlined, UserOutlined, ContactsOutlined, PaperClipOutlined, CalendarOutlined } from '@ant-design/icons';
import { AvailabilityWeekCalendar } from '@shared/components';
import { AvailabilityCalendar, ClassRoomModel, TeacherModel } from '@shared/models';
import { calculateAge } from '@shared/utils';

type RoomInfoPopeverProps = {
  data: ClassRoomModel;
  onOpenChange: (v: boolean) => void;
  open: boolean;
};

export const RoomInfoPopever = ({ data, onOpenChange, open }: RoomInfoPopeverProps) => {
  return (
    <Popover
      placement="left"
      open={open}
      trigger="click"
      onOpenChange={(v) => {
        onOpenChange(v);
      }}
      content={
        <div className="space-y-2 w-[200px]">
          <>
            <div>
              <label className="ml-4 text-bold text-lg">{data.name}</label>
            </div>
            <div className="flex justify-between">
              <div style={{ color: '#1677FF' }}>
                <ReadOutlined /> <label>Sınıf Bilgileri</label>
              </div>
            </div>
            <div className="grid grid-cols-2 ml-4">
              <div className="col-span-1">Kota:</div>
              <div className="col-span-1">{data.quota}</div>
              <div className="col-span-1">Max Kota:</div>
              <div className="col-span-1">{data.maxQuota}</div>
            </div>
          </>
        </div>
      }
    ></Popover>
  );
};
