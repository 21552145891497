import { useDroppable } from '@dnd-kit/core';
import { useDragDropContext } from '@shared/contexts';
import { HTMLAttributes, useMemo } from 'react';

export interface DroppableProps extends HTMLAttributes<HTMLElement> {
  id: string;
  type?: any;
  disabled?: boolean;
  setFn?: (item: any) => void;
}

export const Droppable = (props: DroppableProps) => {
  let { id, type, disabled, setFn, ...domProps } = props;
  const { dragItem } = useDragDropContext();

  const { isOver, setNodeRef } = useDroppable({
    id: id,
    data: {
      type: disabled ? 'DISABLED' : type,
      disabled: disabled,
      setFn,
    },
  });

  const style = useMemo(() => {
    if (dragItem && !disabled) {
      if (dragItem.type === type) {
        return { backgroundColor: 'rgba(211, 252, 134, 0.25)' };
      }
    }

    return {};
  }, [dragItem]);

  return <div {...domProps} style={style} ref={setNodeRef}></div>;
};
