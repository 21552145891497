import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ImageCdnModel } from '@shared/models';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { Upload, notification } from 'antd';
import { useEffect, useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type ImageUploadProps = {
  image: ImageCdnModel;
  onChange: (image: ImageCdnModel) => void;
  onChangeLoading?: (loading: boolean) => void;
  isAdminCdn: boolean;
  disabled?: boolean;
};

export const ImageUpload = ({ image, onChange, onChangeLoading, isAdminCdn,disabled }: ImageUploadProps) => {
  useEffect(() => {}, [image]);
  const [uploadImageFile, setUploadImageFile] = useState<UploadFile>();
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const beforeUploadImage = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.warning({ message: 'You can only upload JPG/PNG file!' });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      notification.warning({ message: 'Image must smaller than 5MB!' });
      return false;
    }
    setUploadImageFile(file);
    return true;
  };

  const handleUploadImage = async () => {
    const formData = new FormData();
    formData.append('file', uploadImageFile as FileType);
    if (onChangeLoading) {
      onChangeLoading(true);
    }
    setImageUploadLoading(true);
    let _url = '';
    if (isAdminCdn) {
      const {
        data: { url },
      } = await API.CDN.obtainCreatedUploadImageUrl();
      _url = url;
    } else {
      const {
        data: { url },
      } = await API.CDN.brandObtainCreatedUploadImageUrl();
      _url = url;
    }

    const request = await fetch(_url, {
      method: 'POST',
      body: formData,
    });
    if (request.ok) {
      const { result } = await request.json();
      const model = result as ImageCdnModel;
      onChange(model);
    }
    if (onChangeLoading) {
      onChangeLoading(false);
    }
    setImageUploadLoading(false);
  };

  const imageUploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {imageUploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 0 }}>Upload</div>
    </button>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      multiple={false}
      maxCount={1}
      supportServerRender={false}
      beforeUpload={beforeUploadImage}
      customRequest={handleUploadImage}
      disabled={disabled}
    >
      {image?.variants && image.variants.length > 0 ? <img src={image.variants[0]} alt="avatar" style={{ width: '100%' }} /> : imageUploadButton}
    </Upload>
  );
};
