import { Card, Timeline } from 'antd';

export const TeacherClassFlow = () => {
  const timelineItems = [
    {
      label: '2015-09-01',
      children: 'Genel İngilizce A1 Vize Sınavı',
    },
    {
      label: '2015-09-01',
      children: 'Almanca B2 dersine katılım sağlanmadı',
    },

    {
      label: '2015-09-01',
      children: 'Sinema Günü',
    },
    {
      label: '2015-09-01',
      children: 'Sinema Günü',
    },

    {
      label: '2015-09-01',
      children: 'Almanca B2 dersine katılım sağlanmadı',
    },
    {
      label: '2015-09-01',
      children:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo maiores eligendi rerum esse adipisci accusamus totam dolore laboriosam? Deserunt magni corporis ad ea similique aliquid beatae numquam nulla labore? Soluta eveniet nihil corrupti, sed suscipit necessitatibus, ea est non facere eos ex omnis nam praesentium quae et ratione cum! Incidunt!',
    },
    {
      label: '2015-09-01',
      children: 'Almanca B2 dersine katılım sağlanmadı',
    },
  ];

  return (
    <Card title={<span className="text-sm font-normal text-[#00000073]">Akış / Haberler</span>} styles={{ header: { borderBottom: 'none' } }}>
      <div className="flow_container ">
        <Timeline className="flow-timeline" mode="left" items={timelineItems} />
      </div>
    </Card>
  );
};
