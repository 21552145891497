import { ExerciseDefinitionInteractionType } from "@shared/constants";
import { ExerciseDefinitionInteractionModel } from "@shared/models"
import { Button, Form, Input } from "antd";
import { useCallback, useEffect, useState } from "react";


type InteractionPronounciationComponentProps = {
    value: ExerciseDefinitionInteractionModel,
    onChange: (interaction: ExerciseDefinitionInteractionModel) => void
}
export const InteractionPronounciationComponent = ({ value, onChange }: InteractionPronounciationComponentProps) => {
    const [text,setText] = useState<string>();
    const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>(value);

    /*const send = useCallback(() => {
        onChange(interaction);
    }, [interaction]);*/

    const send = (v:ExerciseDefinitionInteractionModel)=>{
        onChange(v);
    }


    return (
        <div>
            <div>
                <Form layout="vertical">
                    <Form.Item label={"Kullanıcının söylemesini istediğiniz cümleyi giriniz:"}>
                        <Input.Search value={text} enterButton={<Button type="primary">Kaydet</Button>} onSearch={(e) => {
                            if(e != ""){
                                send({
                                    ...interaction,
                                    textSpeech: [e],
                                    type: ExerciseDefinitionInteractionType.PRONOUNCE,
                                });
                            }
                           
                        }} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}