import { VideoPlayer } from "@shared/components/curriculum/VideoPlayer";
import { CourseTopicPageModel, MediaModel } from "@shared/models";
import { useEffect, useState } from "react";
import { TextPage } from "../text_page";

type VideoPageProps = {
    page: CourseTopicPageModel;
    isAdminCdn: boolean;
}

export const VideoPage = ({ page, isAdminCdn }: VideoPageProps) => {
    
    return (
        <div className={page.content?.text && page.content?.text!="<p><br></p>" ? "grid grid-cols-2 space-x-2 items-center" : "flex justify-center h-full w-full"}>
            <VideoPlayer video={page.content?.video ?? {}} isInit={false} onChange={() => { }} isAdminCdn={isAdminCdn} />
            {page.content?.text && page.content?.text != "<p><br></p>" && <TextPage text={page.content?.text} />}
        </div>
    );
}