import { ApartmentOutlined, BranchesOutlined, MoneyCollectOutlined, ReadOutlined, SnippetsOutlined, UserOutlined } from '@ant-design/icons';
import { BrandMenuGroupItem } from '@shared/components/layouts/BrandMenuGroupItem';
import { Link, useSearchParams } from 'react-router-dom';
import { urlCleaner } from './extensions';

export const pageToMenuItem = ([_, Page]) => {
  const [searchParams] = useSearchParams();

  const toRender = () => {
    return Page.path + '?' + urlCleaner(searchParams);
  };

  return {
    key: Page.path,
    label: <Link to={toRender()}>{Page.title}</Link>,
    icon: Page.icon,
    breadcrumbtitle: Page.title,
    roles: Page.roles,
    group: Page.group,
    title: Page.title,
    contexts: Page.contexts,
    layouts: Page.layouts ? Page.layouts : [],
    permissions: Page.permissions ? Page.permissions : [],
  };
};

export const menuGroups = [
  {
    key: 'curriculum',
    title: 'Müfredat',
    icon: <ReadOutlined size={20} />,
  },
  {
    key: 'brand_manager_branch',
    title: 'Şube',
    icon: <BranchesOutlined size={20} />,
  },
  {
    key: 'temp-record',
    title: 'Kayıt',
    icon: <SnippetsOutlined size={20} />,
  },

  {
    key: 'teacher',
    title: 'Öğretmen',
    icon: <UserOutlined size={20} />,
  },
  {
    key: 'definitions',
    title: 'Tanımlar',
    icon: <ApartmentOutlined size={20} />,
  },
  {
    key: 'selectedBrand',
    contextname: 'brand',
    title: <BrandMenuGroupItem />,
  },
  {
    key: 'branch.lms.credit',
    title: 'Lms Kredi Yönetimi',
    icon: <MoneyCollectOutlined />,
  },

  {
    key: 'brand.lms.credit',
    title: 'Lms Kredi Yönetimi',
    icon: <MoneyCollectOutlined />,
  },
];
