import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

export const BranchManagerDashboard = () => {
  useAccessableSeachParams();
  return <ComingSoon navigationText="Şube Kullanıcısı Oluştur" navigationUrl="/branch_manager/users" />;
};

BranchManagerDashboard.path = '/branch_manager/dashboard';
BranchManagerDashboard.title = 'Kontrol Paneli';
BranchManagerDashboard.group = 'flat';
BranchManagerDashboard.roles = [Role.ADMIN];
BranchManagerDashboard.layouts = [LayoutEnums.BRANCHMANAGER];
BranchManagerDashboard.icon = <DashboardOutlined size={20} />;
