import {
  AndroidOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  ReadOutlined,
  SettingOutlined,
  StockOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import * as API from '@shared/api';
import { TimeFormatter } from '@shared/components';
import { DayOfWeek, ProductPermissionValues } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { ClassCalendarModel, ClassDetailModel, PrePlacementDemandBasicInfo } from '@shared/models';
import { urlCleaner } from '@shared/utils';
import { Card, Divider, Dropdown, Menu, Modal, notification, Popconfirm, Popover } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClassCalendar } from './ClassCalendar';
import { ClassContentVersion } from './ClassContentVersion';
import { ClassSettings } from './ClassSettings';
import { ClassStudentList } from './ClassStudentList';

type ClassInfoProps = {
  classDetail: ClassDetailModel | undefined;
  getClassDetail: () => void;
  calendarData: ClassCalendarModel[] | undefined;
  isSettings?: boolean;
  availableStudentList?: PrePlacementDemandBasicInfo[] | undefined;
  onAddStudent?: (classId: string, demandId: string) => void;
  onRemoveStudent?: (classId: string, studentId: string) => void;
  isTeacherLayout?: boolean;
};

export const ClassInfo = ({
  classDetail,
  getClassDetail,
  isSettings = true,
  calendarData,
  availableStudentList,
  onAddStudent,
  onRemoveStudent,
  isTeacherLayout = false,
}: ClassInfoProps) => {
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [contentVersionModal, setContentVersionModalOpen] = useState<boolean>(false);
  const [hasBranchClassUpdatePermission, setHasBranchClassUpdatePermission] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setHasBranchClassUpdatePermission(hasBranchClassUpdatePermissionFunc());
  }, [user]);

  function hasBranchClassUpdatePermissionFunc(): boolean {
    const branchId = searchParams.get('branchId')!;
    const branch = user.features?.branch[branchId];
    if (branch) {
      if (branch.permissions?.role === 'ADMIN' || (branch.permissions.values && branch.permissions.values.includes(ProductPermissionValues.branch.LMS.Class.UPDATE))) {
        return true;
      }
    }
    return false;
  }

  const uniqueTeachers = useMemo(() => {
    const teacherList: { id: string; name: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        seance.classSeanceItems?.forEach((item) => {
          if (item.teacher) {
            teacherList.push({
              id: item?.teacher?.id!,
              name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
            });
          }
        });
      });
    }
    return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [classDetail]);

  const uniqueClassrooms = useMemo(() => {
    const classroomList: { id: string; name: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        seance?.classSeanceItems?.forEach((item) => {
          if (item.classRoom) {
            classroomList.push({ id: item?.classRoom?.id!, name: item?.classRoom?.name! });
          }
        });
      });
    }
    return classroomList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [classDetail]);

  const uniqueSeances = useMemo(() => {
    const seanceList: { id: string; name: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        if (seance) {
          seanceList.push({ id: seance.id!, name: seance.name! });
        }
      });
    }
    return seanceList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [classDetail]);

  const seanceDetailList = useMemo(() => {
    const result: { id: string; day: string; hour: JSX.Element; room: string; teacher: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        seance.classSeanceItems?.forEach((item) => {
          if (item) {
            const hour = <TimeFormatter startHour={item.beginHour!} startMinute={item.beginMinute!} endHour={item.endHour!} endMinute={item.endMinute!} />;
            const teacher = item.teacher ? `${item.teacher?.name} ${item.teacher?.surname}` : 'Lütfen ögretmen bilgilerini doldurunuz.';
            result.push({ id: item.id!, day: seance.dayOfWeeks!.map((day) => DayOfWeek[day]).join(', '), hour, room: item.classRoom?.name!, teacher });
          }
        });
      });
    }
    return result;
  }, [classDetail]);

  const popoverContent = (
    <div className="min-w-[270px] max-h-[520px] overflow-y-scroll">
      {classDetail?.classSeances?.map((seance) => (
        <div key={seance.id} className="mt-4 border border-solid border-[#f5f5f5] rounded p-2">
          <div className="px-2">Seans</div>
          <div className="mt-2 py-2 px-2 bg-[#E6F4FF] rounded-lg">{seance.name}</div>
          {seance.classSeanceItems?.map((item) => (
            <div key={item.id}>
              <div className="border border-solid border-[#91CAFF] rounded-md mt-3 px-1 text-sm">
                <TimeFormatter startHour={item.beginHour!} startMinute={item.beginMinute!} endHour={item.endHour!} endMinute={item.endMinute!} />
              </div>
              <div className="grid grid-cols-5 mt-2 gap-x-3">
                <div className="flex flex-col col-span-2">
                  <div>Derslik</div>
                  <div className="py-1 px-2 bg-[#f7f7f7] rounded-lg">{item.classRoom?.name}</div>
                </div>
                <div className="flex flex-col col-span-3">
                  <div>Öğretmen</div>
                  <div className="py-1 px-2 bg-[#f7f7f7] rounded-lg">
                    {item.teacher?.name} {item.teacher?.surname}
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="mt-2">Günler</div>
          <div className="py-1 px-2 bg-[#f7f7f7] rounded-lg">{seance.dayOfWeeks?.map((day) => DayOfWeek[day]).join(', ')}</div>
        </div>
      ))}
    </div>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <div className="w-full text-left cursor-pointer" onClick={() => setContentVersionModalOpen(true)}>
          <ReadOutlined className="mr-2" />
          Aktif içerik versiyonlarını yönet
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <div
          className="w-full text-left cursor-pointer"
          onClick={() => {
            setSettingsModalOpen(true);
          }}
        >
          <SettingOutlined className="mr-2" />
          Seans ayarlarını görüntüle
        </div>
      </Menu.Item>
      {classDetail?.status === 'STARTED' && (
        <Menu.Item key="3">
          <Popconfirm
            title="Sınıfı bitirmek istediğine emin misin?"
            onConfirm={async () => {
              const response = await API.CLASS.classComplete(classDetail?.id);
              if (response.ok) {
                notification.success({ message: 'Sınıf bitirme başarılı' });
                navigate('/lms/class?' + urlCleaner(searchParams));
              }
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <div className="w-full text-left">
              <FileDoneOutlined className="mr-2" />
              Sınıfı bitir
            </div>
          </Popconfirm>
        </Menu.Item>
      )}
      {(classDetail?.status === 'NOT_STARTED' || classDetail?.status === 'CANCELED') && (
        <Menu.Item key="4">
          <Popconfirm
            title="Sınıfı silmek istediğine emin misin?"
            onConfirm={async () => {
              const response = await API.CLASS.deleteClass(classDetail?.id);
              if (response.ok) {
                notification.success({ message: 'Sınıf silme başarılı' });
                navigate('/lms/class?' + urlCleaner(searchParams));
              }
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <div className="w-full text-left">
              <DeleteOutlined className="mr-2" />
              Sınıfı sil
            </div>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <Card
        size="small"
        title={<span className=" font-normal text-[#00000073]">Sınıf Bilgisi</span>}
        extra={
          classDetail?.status !== 'COMPLETED' &&
          hasBranchClassUpdatePermission && (
            <Dropdown overlay={menu} trigger={['click']}>
              <MoreOutlined className="cursor-pointer text-lg text-[#00000073]" />
            </Dropdown>
          )
        }
        style={{ background: 'linear-gradient(to bottom, #f8f8f8, #f3d9d5)', height: 'calc(100vh - 227px)' }}
        className="overflow-y-auto scrollbar-hide"
      >
        <Card type="inner">
          <div className="flex justify-between text-[#00000073]">
            <span>
              <TeamOutlined className="text-lg" /> <span className="text-sm">Öğretmenler</span>
            </span>
            <Popover placement="left" trigger="click" content={popoverContent} title={<span className="text-sm font-normal text-[#00000073]">Bilgi</span>}>
              <InfoCircleOutlined className="cursor-pointer" />
            </Popover>
          </div>
          {uniqueTeachers.length > 0 ? (
            uniqueTeachers.map(({ id, name }) => (
              <div key={id} className="mt-1 text-sm">
                <span>{name}</span>
              </div>
            ))
          ) : (
            <span className="mt-1 text-xs">Lütfen öğretmen ekleyiniz</span>
          )}

          <Divider className="my-2" />

          <div className="text-[#00000073] mt-3">
            <ClockCircleOutlined className="text-lg" /> <span className="text-sm">Derslik</span>
          </div>
          <div className="mt-1 text-sm">{uniqueClassrooms.map((room) => room.name).join(', ')}</div>
          <Divider className="my-2" />

          <div className="text-[#00000073] mt-3">
            <StockOutlined className="text-lg" /> <span className="text-sm">Seans</span>
          </div>
          <div className="mt-1 text-sm">{uniqueSeances.map((seance) => seance.name).join(', ')}</div>
        </Card>
        <div className="mt-2 mb-1 text-sm text-[#00000073]">Öğrenci Listesi</div>
        <ClassStudentList
          hasBranchClassUpdatePermission={hasBranchClassUpdatePermission}
          availableStudentList={availableStudentList}
          classDetail={classDetail}
          onAddStudent={onAddStudent!}
          onRemoveStudent={onRemoveStudent!}
          isTeacherLayout={isTeacherLayout}
        />
        <div className="mt-2 text-sm text-[#00000073]">Ders Programı</div>

        {seanceDetailList.map((seance) => (
          <Card className="mt-1" key={seance.id} type="inner">
            <div>
              <CalendarOutlined className="mr-1" /> {seance.day}
            </div>
            <div>
              <ClockCircleOutlined className="mr-1" /> {seance.hour}
            </div>
            <div>
              <ReadOutlined className="mr-1" /> {seance.room}
            </div>
            <div>
              <AndroidOutlined className="mr-1" /> {seance.teacher}
            </div>
          </Card>
        ))}

        <div className="mt-2 text-sm text-[#00000073]">Sınıf Takvimi</div>
        <ClassCalendar calendarData={calendarData} />
      </Card>

      <Modal title={<span className="font-normal">Ayarlar</span>} open={settingsModalOpen} footer={null} onCancel={() => setSettingsModalOpen(false)}>
        <ClassSettings classDetail={classDetail} getClassDetail={getClassDetail!} />
      </Modal>
      <Modal
        title={<span className="font-normal">Aktif içerik versiyonlarını yönet</span>}
        open={contentVersionModal}
        footer={null}
        onCancel={() => setContentVersionModalOpen(false)}
      >
        <ClassContentVersion classDetail={classDetail} getClassDetail={getClassDetail!} />
      </Modal>
    </>
  );
};
