{
  "app": {
    "welcome": "welcome",
    "monday": "Mon",
    "tuesday": "Tue",
    "wednesday": "Wed",
    "thursday": "Thu",
    "friday": "Fri",
    "saturday": "Sat",
    "sunday": "Sun",
    "morning": "M",
    "afternoon": "A",
    "night": "N",
    "midWeek": "MW",
    "weekend": "W",
    "noteType": {
      "COMPLAINT": "Complaint",
      "GENERAL": "General",
      "DEMAND": "Demand",
      "TECHNICAL_FAILURE": "Technical Failure"
    },
    "branchType": {
      "BRANCH": "Branch",
      "FRANCHISE": "Franchise"
    },
    "enrollmentStatus": {
      "INFO": "Info",
      "READY_FOR_PLANNING": "Ready For Plannning",
      "STARTED": "Started",
      "DONE": "Done",
      "CANCELED": "Canceled"
    },
    "enrollmentType": {
      "INDIVIDUAL": "Individual",
      "GROUP": "Group",
      "ENTERPRISE": "Enterprise"
    },
    "subPermissions": {
      "teacher": "Teacher",
      "observer": "Observer",
      "student": "Student"
    },
    "classStatus": {
      "NOT_STARTED": "Not Started",
      "STARTED": "Started",
      "COMPLETED": "Completed",
      "CANCELED": "Canceled"
    },
    "preplacementStatus": {
      "SUCCESS": "Success",
      "FAILED": "Failed",
      "CONDITIONAL": "Conditional"
    }
  },
  "error": {
    "unauthorized": "Bu işlem için yetkiniz yok",
    "not.found": "Erişmek istediğiniz varlık bulunamadı",
    "bad.request": "Geçersiz istek",
    "internal.server.error": "Beklenmeyen bir hata meydana geldi",
    "conflict": "Aynı özelliklere varlık zaten bulunmaktadır",
    "argument.out.of.range": "Geçersiz parametre aralığı",
    "argument.invalid": "Geçersiz parametre",
    "argument.not.provided": "Parametre sağlanmadı",
    "entity.is.using.by.another.entity": "Bu varlık başka bir varlık tarafından kullanılıyor",
    "account.is.not.active": "Hesap aktif değil",
    "account.not.found": "Hesap bulunamadı",
    "account.activation.token.expired": "Hesap aktivasyon kodunun süresi dolmuş",
    "account.activation.token.required": "Hesap aktivasyon kodu gereklidir",
    "account.reset.password.token.required": "Şifre sıfırlama kodu gereklidir",
    "account.reset.password.token.expired": "Şifre sıfırlama kodunun süresi dolmuş",
    "users.cant.delete.themselves": "Kullanıcı kendi hesabını silemez",
    "users.cant.update.permissions.themselves": "Kullanıcı kendi izinlerini güncelleyemez",
    "role.not.found": "Rol bulunamadı",
    "passwords.dont.match": "Şifreler eşleşmiyor",
    "locale.not.found": "Dil ayarı bulunamadı",
    "product.not.found": "Ürün bulunamadı",
    "unit.not.found": "Birim bulunamadı",
    "unit.name.not.found": "Birim adı bulunamadı",
    "unit.cant.update": "Birim güncellenemiyor",
    "topic.not.found": "Konu bulunamadı",
    "topic.name.not.found": "Konu adı bulunamadı",
    "topic.type.not.found": "Konu türü bulunamadı",
    "page.not.found": "Sayfa bulunamadı",
    "page.content.cant.be.empty": "Sayfa içeriği boş olamaz",
    "exercise.not.found": "Alıştırma bulunamadı",
    "exercise.content.not.found": "Alıştırma içeriği bulunamadı",
    "exercise.group.not.found": "Alıştırma grubu bulunamadı",
    "exercise.group.name.not.found": "Alıştırma grubu adı bulunamadı",
    "invalid.exercise.type": "Geçersiz alıştırma türü",
    "calendar.entry.not.found": "Takvim girdisi bulunamadı",
    "calendar.entry.name.not.found": "Takvim girdisi adı bulunamadı",
    "calendar.entry.date.not.found": "Takvim girdisi tarihi bulunamadı",
    "calendar.entry.time.range.not.found": "Takvim girdisi zaman aralığı bulunamadı",
    "education.level.not.found": "Eğitim seviyesi bulunamadı",
    "education.theme.not.found": "Eğitim teması bulunamadı",
    "education.type.not.found": "Eğitim türü bulunamadı",
    "education.field.not.found": "Eğitim alanı bulunamadı",
    "content.version.not.found": "İçerik sürümü bulunamadı",
    "content.version.cant.update": "İçerik sürümü güncellenemiyor",
    "content.version.cant.delete": "İçerik sürümü silinemiyor",
    "content.version.cant.archive": "İçerik sürümü arşivlenemiyor",
    "content.version.should.be.reviewed.before.publish": "İçerik sürümü yayınlanmadan önce incelenmelidir",
    "content.version.should.be.published.before.set.as.default": "İçerik sürümü varsayılan olarak ayarlanmadan önce yayınlanmalıdır",
    "content.version.update.package.not.found": "İçerik sürümü güncelleme paketi bulunamadı",
    "content.version.name.not.found": "İçerik sürümü adı bulunamadı",
    "country.code.not.found": "Ülke kodu bulunamadı",
    "country.name.not.found": "Ülke adı bulunamadı",
    "city.code.not.found": "Şehir kodu bulunamadı",
    "city.name.not.found": "Şehir adı bulunamadı",
    "address.not.found": "Adres bulunamadı",
    "postal.code.not.found": "Posta kodu bulunamadı",
    "phone.number.not.found": "Telefon numarası bulunamadı",
    "begin.time.not.found": "Başlangıç zamanı bulunamadı",
    "end.time.not.found": "Bitiş zamanı bulunamadı",
    "end.time.should.be.after.begin.time": "Bitiş zamanı, başlangıç zamanından sonra olmalıdır",
    "hour.is.out.of.range": "Saat aralığı dışındadır",
    "minute.is.out.of.range": "Dakika aralığı dışındadır",
    "start.date.not.found": "Başlangıç tarihi bulunamadı",
    "start.date.and.end.date.not.found": "Başlangıç ve bitiş tarihi bulunamadı",
    "end.date.not.found": "Bitiş tarihi bulunamadı",
    "start.date.should.be.before.end.date": "Başlangıç tarihi, bitiş tarihinden önce olmalıdır",
    "owner.not.found": "Sahip bulunamadı",
    "email.is.already.readed": "E-posta zaten okundu",
    "email.receiver.not.found": "E-posta alıcısı bulunamadı",
    "email.not.found": "E-posta bulunamadı",
    "invalid.teacher.score": "Geçersiz öğretmen puanı",
    "invalid.exam.score": "Geçersiz sınav puanı",
    "invalid.homework.score": "Geçersiz ödev puanı",
    "invalid.attendance.score": "Geçersiz katılım puanı",
    "invalid.attendance.score.threshold": "Geçersiz katılım puanı eşiği",
    "invalid.fail.score.threshold": "Geçersiz başarısızlık puanı eşiği",
    "invalid.success.score.threshold": "Geçersiz başarı puanı eşiği",
    "education.level.success.requirement.not.found": "Eğitim seviyesi başarı gereksinimi bulunamadı",
    "brand.product.already.exists": "Marka ürünü zaten mevcut",
    "brand.not.found": "Marka bulunamadı",
    "brand.user.not.found": "Marka kullanıcısı bulunamadı",
    "brand.name.not.found": "Marka adı bulunamadı",
    "brand.content.version.update.package.already.applied": "Marka içerik sürümü güncelleme paketi zaten uygulanmış",
    "brand.content.version.update.package.already.applying": "Marka içerik sürümü güncelleme paketi zaten uygulanıyor",
    "branch.not.found": "Şube bulunamadı",
    "branch.user.not.found": "Şube kullanıcısı bulunamadı",
    "branch.cant.change": "Şube değiştirilemiyor",
    "branch.name.not.found": "Şube adı bulunamadı",
    "enrollment.not.found": "Kayıt bulunamadı",
    "enrollment.type.not.found": "Kayıt türü bulunamadı",
    "enrollment.type.cant.change": "Kayıt türü değiştirilemiyor",
    "enrollment.cant.update": "Kayıt güncellenemiyor",
    "enrollment.cant.accept.new.student": "Kayıt yeni öğrenci kabul edemiyor",
    "enrollment.basic.info.not.found": "Kayıt temel bilgisi bulunamadı",
    "enrollment.status.cant.change": "Kayıt durumu değiştirilemiyor",
    "observer.not.found": "Gözlemci bulunamadı",
    "preplacement.already.exists": "Kayıt zaten mevcut",
    "class.planning.not.found": "Ders planlaması bulunamadı",
    "class.planning.status.cant.change": "Ders planlama durumu değiştirilemiyor",
    "seance.not.found": "Seans bulunamadı",
    "seance.name.not.found": "Seans adı bulunamadı",
    "seance.item.not.found": "Seans öğesi bulunamadı",
    "seance.item.duration.should.greater.than.zero": "Seans öğesi süresi sıfırdan büyük olmalıdır",
    "seance.item.time.range.should.be.increasing.order": "Seans öğesi zaman aralığı artan sırada olmalıdır",
    "at.least.one.student.required": "En az bir öğrenci gereklidir",
    "preplacement.not.found": "Kayıt bulunamadı",
    "class.room.not.found": "Sınıf odası bulunamadı",
    "class.room.name.not.found": "Sınıf odası adı bulunamadı",
    "class.room.quota.not.found": "Sınıf odası kotası bulunamadı",
    "class.room.max.quota.exceeded": "Derslik maksimum kota aşıldı",
    "class.room.optimal.quota.exceeded": "Derslik optimal kota aşıldı",
    "class.room.quota.cant.be.higher.than.max.quota": "Derslik kotası maksimum kotadan yüksek olamaz",
    "class.room.calendar.not.fit": "Derslik takvimi uymuyor",
    "teacher.level.not.fit": "Öğretmen seviyesi uygun değil",
    "demand.status.cant.change": "Talep durumu değiştirilemez",
    "demand.basic.info.not.found": "Talep temel bilgileri bulunamadı",
    "demand.level.not.fit": "Talep seviyesi uygun değil",
    "demand.not.found": "Talep bulunamadı",
    "demand.name.not.found": "Talep adı bulunamadı",
    "demand.calendar.not.found": "Talep takvimi bulunamadı",
    "demand.calendar.not.fit": "Talep takvimi uymuyor",
    "demand.is.already.completed": "Talep zaten tamamlandı",
    "demand.is.already.active": "Talep zaten aktif",
    "demand.is.canceled": "Talep iptal edildi",
    "demand.level.doesnt.belong.to.branch": "Talep seviyesi şubeye ait değil",
    "demand.is.not.ready.for.planning": "Talep planlama için hazır değil",
    "demand.level.not.found": "Talep seviyesi bulunamadı",
    "teacher.not.found": "Öğretmen bulunamadı",
    "teacher.availability.levels.not.found": "Öğretmen uygunluk seviyeleri bulunamadı",
    "teacher.availability.calendar.not.found": "Öğretmen uygunluk takvimi bulunamadı",
    "teacher.availability.calendar.not.fit": "Öğretmen uygunluk takvimi uymuyor",
    "invalid.day.of.week": "Geçersiz gün",
    "day.of.weeks.required": "Haftanın günleri gerekli",
    "class.not.found": "Ders bulunamadı",
    "class.is.not.active": "Ders aktif değil",
    "class.name.not.provided": "Ders adı sağlanmadı",
    "class.seance.items.overlaping": "Ders seansı öğeleri çakışıyor",
    "class.already.started": "Ders zaten başladı",
    "class.can.not.be.started": "Ders başlatılamaz",
    "class.already.completed": "Ders zaten tamamlandı",
    "class.should.be.started.before.completing": "Ders tamamlanmadan önce başlatılmalıdır",
    "class.students.should.be.evaluated.before.completing": "Ders tamamlanmadan önce öğrenciler değerlendirilmelidir",
    "class.students.already.evaluated": "Öğrenciler zaten değerlendirildi",
    "teacher.student.evaluation.items.not.found": "Öğretmen-öğrenci değerlendirme öğeleri bulunamadı",
    "teacher.student.evaluation.not.found": "Öğretmen-öğrenci değerlendirmesi bulunamadı",
    "teacher.student.evaluation.invalid.score": "Öğretmen-öğrenci değerlendirmesi geçersiz puan",
    "teacher.student.evaluation.item.not.found": "Öğretmen-öğrenci değerlendirme öğesi bulunamadı",
    "student.already.exists": "Öğrenci zaten mevcut",
    "student.not.found": "Öğrenci bulunamadı",
    "class.calendar.entry.not.found": "Ders takvimi girişi bulunamadı",
    "class.calendar.entry.name.is.required": "Ders takvimi girişi adı zorunludur",
    "class.calendar.entry.already.started": "Ders takvimi girişi zaten başladı",
    "in.class.is.not.available.now": "Ders şu anda mevcut değil",
    "class.activity.not.found": "Ders etkinliği bulunamadı",
    "class.activity.already.completed": "Ders etkinliği zaten tamamlandı",
    "attendance.not.found": "Devam durumu bulunamadı",
    "make.attendance.first": "Önce devam durumu yapın",
    "not.completed.class.activity.exist": "Tamamlanmamış ders etkinliği mevcut",
    "student.not.active": "Öğrenci aktif değil",
    "demand.is.frozen.before": "Bu kayıt daha önce dondurulduğu için tekrar dondurma işlemi yapamazmısınız."
  },
  "notification": {
    "you.are.brand.user": "Artık şube kullanıcısınız",
    "you.are.brand.user.description": "{{brandName}}'de artık şube kullanıcısısınız."
  }
}