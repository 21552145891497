import { GanntCalendarItemType } from '@shared/models';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
dayjs.extend(dayOfYear);

export const covertCalendarItem = (calendarItem: GanntCalendarItemType, rowIndex: number, startPoint: Dayjs) => {
  const dayStart = dayjs(calendarItem.startDate).diff(startPoint, 'day') + 1;
  const daySpan = dayjs(calendarItem.endDate).diff(dayjs(calendarItem.startDate), 'day') + 1;

  const overflow = Math.max(dayStart + daySpan - 397, 0);

  return {
    ...calendarItem,
    rowIndex,
    dayStart: dayStart,
    daySpan: daySpan - overflow,
  };
};

export const covertCalendarItems = (calendarItems: GanntCalendarItemType[], startPoint: Dayjs) => {
  const rows: GanntCalendarItemType[][] = [];

  calendarItems.forEach((calendarItem) => {
    if (rows.length === 0) {
      rows.push([]);
      rows[0].push(covertCalendarItem(calendarItem, 0, startPoint));
    } else {
      let index = 0;

      for (index = 0; index < rows.length; index++) {
        const overlap = rows[index].some((rowCalendarItem) => {
          return isDateOverlap(rowCalendarItem.startDate, rowCalendarItem.endDate, calendarItem.startDate, calendarItem.endDate);
        });

        if (!overlap) {
          break;
        }
      }

      if (index === rows.length) {
        rows.push([]);
      }

      rows[index].push(covertCalendarItem(calendarItem, index, startPoint));
    }
  });
  const flatted = rows.flat();

  return {
    dates: flatted,
    rowCount: rows.length,
  };
};

export const isDateOverlap = (starDateA: Date, endDateA: Date, starDateB: Date, endDateB: Date) => {
  const endTimeA = endDateA.getTime();
  const startTimeA = starDateA.getTime();

  const endTimeB = endDateB.getTime();
  const startTimeB = starDateB.getTime();

  const diffA = endTimeA - startTimeA;
  const diffB = endTimeB - startTimeB;

  let isOverlap = false;
  if (diffB > diffA) {
    isOverlap = isDateOverlapTime(startTimeB, endTimeB, startTimeA, endTimeA);
  } else {
    isOverlap = isDateOverlapTime(startTimeA, endTimeA, startTimeB, endTimeB);
  }

  return isOverlap;
};

export const isDateOverlapTime = (longStart: number, longEnd: number, shortStart: number, shortEnd: number) => {
  if (longStart && longEnd && shortStart && shortEnd) {
    if (shortStart < longStart) {
      if (shortEnd < longStart) {
        return false;
      }
      return true;
    }

    if (shortStart <= longEnd) {
      return true;
    }

    return false;
  }

  return false;
};
