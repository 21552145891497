import { PlusOutlined } from "@ant-design/icons"
import { CascaderOption } from "@shared/models"
import { Button, Divider, Input, Select, Spin } from "antd"
import { useEffect, useState } from "react";

type EducationFieldProps ={
    items: CascaderOption[];
    isLoading:boolean;
    isDisabled?:boolean;
    selected?:(string | number);
    placeholder?:string;
    onChange: (value: (string | number)) => void;
    onAdd:(name:string)=>void
}
export const SelectWithInsert = ({items,isLoading,isDisabled=false,selected,placeholder="Lütfen seçim yapınız",onChange,onAdd}:EducationFieldProps) => {
    const [inputValue,setInputValue] = useState("");
    
    return (
        <Select
            placeholder={placeholder}
            onChange={onChange}
            disabled={isDisabled}
            value={selected}
            dropdownRender={
                (menu) => (
                    <>
                        {!isLoading ? (menu) : (<div className="text-center"><Spin size="large"  ><div className="text-center text-cyan-600">Güncelleniyor...</div></Spin></div>)}
                        <Divider style={{ margin: '8px 0' }} />
                        <div className="flex gap-1">
                            <Input value={inputValue} onChange={(e)=>{setInputValue(e.target.value);}} placeholder="Eğer listede bulamazsanız buradan ekleyebilirsiniz" />
                            <Button type="text" icon={<PlusOutlined />} onClick={() => {
                                if(inputValue != ""){
                                    onAdd(inputValue);
                                    setInputValue("");
                                }
                             }}>
                                Ekle
                            </Button>
                        </div>
                    </>
                )
            }
            options={items}
        />
    )
}