import { Button, Empty, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

type ComingSoonProps = {
  imageHeight?: number;
  navigationUrl: string;
  navigationText: string;
};
export const ComingSoon = ({ imageHeight = 160, navigationUrl, navigationText }: ComingSoonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="w-full h-full flex items-center justify-center">
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: imageHeight }}
        description={
          <Typography.Text>
            <span className="text-3xl text-blue-500">Görüntülenecek kayıt yok</span>
          </Typography.Text>
        }
      >
        <Button
          type="primary"
          onClick={() => {
            navigate(navigationUrl + location.search);
          }}
        >
          {navigationText}
        </Button>
      </Empty>
    </div>
  );
};
