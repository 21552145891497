import { PlusCircleOutlined } from '@ant-design/icons';
import { LayoutEnums, Role } from '@shared/constants';
import { Button, Spin, Table, TableProps, notification, Drawer, Modal } from 'antd';
import * as API from '@shared/api';
import { useAccessableSeachParams } from '@shared/hooks';
import { Branch, BranchUserModel, CreateBranchModel, PaginationQuery } from '@shared/models';
import { ActionDropdown } from '@shared/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { BranchCreateUser } from './BranchCreateUser';
import { useEffect, useState } from 'react';
import i18n from '@shared/localization/i18n';

export const Branchs = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, loading, getAllBranch] = API.BRANCH.getAllBranch({ init: false });
  const [open, setOpen] = useState(false);
  const [branchId, setBranchId] = useState('');
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });

  useEffect(() => {
    if (paginationQuery) {
      getAllBranch(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  const onCancelModal = () => {
    setOpen(false);
  };

  const title = (
    <div className="flex justify-between">
      <div></div>
      <Button type="dashed" icon={<PlusCircleOutlined />} onClick={() => navigate('/brand_manager/create_branch' + location.search)}>
        Yeni Şube Oluştur
      </Button>
    </div>
  );

  const columns: TableProps<Branch>['columns'] = [
    {
      title: 'Şube',
      dataIndex: 'name',
      key: 'name',
      className: 'text-center',
      render: (text, _branch) => {
        return (
          <a key={_branch.id} onClick={() => {}}>
            {text}
          </a>
        );
      },
    },
    {
      title: 'Tip',
      key: 'type',
      className: 'text-center',
      render: (_, { type }) => <span>{i18n.t('app.branchType.' + type)}</span>,
    },
    {
      title: 'Telefon numarası',
      key: 'phone',
      className: 'text-center',
      render: (_, { phone }) => (
        <span>
          {phone?.code}
          {phone?.number}
        </span>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      className: 'text-center',
      render: (_, { address }) => {
        return (
          <span>
            {address?.country?.name} / {address?.city?.name}
          </span>
        );
      },
    },
    {
      title: 'Aksiyon',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <ActionDropdown
          data={record}
          isEditUser={true}
          onEditUser={({ id }) => {
            setBranchId(id);
            setOpen(true);
          }}
          onEdit={({ id }) => {
            navigate('/brand_manager/create_branch' + location.search + '&branch=' + id);
          }}
          onDelete={async ({ id }) => {
            const result = await API.BRANCH.delete(id);
            if (result.ok) {
              notification.success({ message: 'The branch was deleted' });
            }
            getAllBranch(undefined, { ...paginationQuery, page: 1 });
          }}
        />
        /*TODO bu buttonlların işlevselliği olmalı
                <div className="flex justify-center align-middle  gap-3">
                    <FormOutlined onClick={() => console.log("selam")} style={{ fontSize: 16 }} />
                    <Popconfirm
                        title="Delete the brand"
                        description="Are you sure to delete this brand?"
                        onConfirm={async () => {
                            //TODO branch delete gelmeli
                            const result = await API.BRANCH.delete(record.id!);
                            if (result.ok) {
                                notification.success({message:"The branch was deleted"});
                            } else {
                                notification.error({message:"The branch wasn't deleted"})
                            }

                            getAllBranch();
                        }}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <MinusCircleOutlined style={{ fontSize: 16 }} />
                    </Popconfirm>
                </div>*/
      ),
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={result?.data}
        rowKey={'id'}
        bordered
        title={() => title}
        pagination={{ total: result?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        onChange={(pagination) => setPaginationQuery((prev) => ({ ...prev, page: pagination.current }))}
      />

      <BranchCreateUser branchId={branchId} open={open} onCancelModal={onCancelModal} />
    </>
  );
};

Branchs.path = '/brand_manager/branchs';
Branchs.title = 'Şubeler';
Branchs.group = 'brand_manager_branch';
Branchs.roles = [Role.USER];
Branchs.layouts = [LayoutEnums.BRANDMANAGER];
Branchs.permissions=["branch.view"];
