import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";

type VisualBaseProps = {
    content: ExerciseDefinitionContentModel,
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isInClass: boolean;
}
export const VisualMultiSelect = ({ content, interaction, isPreview = false, isInClass }: VisualBaseProps) => {
    return (
        <div className="flex h-full text-center content-center justify-center items-center">
            <span className="text-red-600 text-3xl">Bu egzersiz tipi şu an için mevcut değil</span>
        </div>
    );
}