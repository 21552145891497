import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { useState } from 'react';
import { CheckCircleOutlined, ReadOutlined, UserOutlined, ContactsOutlined, PaperClipOutlined, CalendarOutlined } from '@ant-design/icons';
import { AvailabilityWeekCalendar } from '@shared/components';
import { AvailabilityCalendar, Enrollment, TeacherModel } from '@shared/models';
import { calculateAge } from '@shared/utils';
import { renderLevelsAsString } from '@shared/utils';

type StudentInfoPopeverProps = {
  data: TeacherModel;
  availabilityCalendar: AvailabilityCalendar;
  course: string;
  onOpenChange: (v: boolean) => void;
  open: boolean;
  prePlacement: Enrollment[];
};

export const StudentInfoPopever = ({ data, availabilityCalendar, course, onOpenChange, open, prePlacement = [] }: StudentInfoPopeverProps) => {
  return (
    <Popover
      placement="left"
      open={open}
      trigger="click"
      onOpenChange={(v) => {
        onOpenChange(v);
      }}
      content={
        <div className="space-y-2 w-[610px]">
          <>
            <div>
              <label className="ml-4 text-bold text-lg">
                {data.name} {data.surname}
              </label>
            </div>
            <div className="flex justify-between">
              <div className="w-1/2">
                <div style={{ color: '#1677FF' }}>
                  <ReadOutlined /> <label>Eğitim Bilgileri</label>
                </div>
                <div className="grid grid-cols-2 ml-4">
                  <div className="grid grid-cols-2">
                    <div className="col-span-1">Kur:</div>
                    <div className="col-span-1">
                      <span>{course}</span>
                    </div>

                    <div className="col-span-1">Kurlar:</div>
                    <div className="col-span-1">
                      <Popover
                        placement="bottom"
                        content={
                          <>
                            {data.prePlacementDemands?.map((item) => (
                              <div>{renderLevelsAsString(item.level)}</div>
                            ))}
                          </>
                        }
                        title="Talep Ettiği Kurlar"
                        trigger="hover"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                    <div className="col-span-1">STS:</div>
                    <div className="col-span-1">TODO</div>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div style={{ color: '#1677FF' }}>
                  <UserOutlined /> <label>Kişisel Bilgiler</label>
                </div>
                <div className="grid grid-cols-2 ml-4">
                  <div className="grid grid-cols-2">
                    <div className="col-span-1">Yaş:</div>
                    <div className="col-span-1">{calculateAge(data.birthDate)}</div>
                    <div className="col-span-1">Cinsiyet:</div>
                    <div className="col-span-1">{data.gender}</div>
                    <div className="col-span-1">Meslek:</div>
                    <div className="col-span-1">TODO</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="w-1/2">
                <div style={{ color: '#1677FF' }}>
                  <ContactsOutlined /> <label>İletişim Bilgileri</label>
                </div>
                <div className="grid grid-cols-2 ml-4 gap-8">
                  <div className="grid grid-cols-2">
                    <div className="col-span-1">E-posta:</div>
                    <div className="col-span-1">{data.email}</div>
                    <div className="col-span-1">Telefon numarası:</div>
                    <div className="col-span-1">
                      {data.phone?.code}
                      {data.phone?.number}
                    </div>
                    <div className="col-span-1">Veli Telefon numarası:</div>
                    <div className="col-span-1">
                      {prePlacement?.map((item) => (
                        <div>
                          {item.phone?.code}
                          {item.phone?.number}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div style={{ color: '#1677FF' }}>
                  <PaperClipOutlined /> <label>Notlar</label>
                </div>
                <div className="ml-4">
                  {data.note && data.note.length > 140 ? (
                    <Tooltip title={data.note}>
                      <span className="line-clamp-3">{data.note}</span>
                    </Tooltip>
                  ) : (
                    <span>{data.note}</span>
                  )}
                </div>
              </div>
            </div>
            <div style={{ color: '#1677FF' }}>
              <CalendarOutlined /> <label>Müsaitlik takvimi</label>
            </div>
          </>

          <div className="col-span-2">
            <AvailabilityWeekCalendar value={availabilityCalendar} onChange={(availability) => {}} />
          </div>
        </div>
      }
    ></Popover>
  );
};
