import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Card, Form, Input, Button } from 'antd';
import { TextInput } from '@shared/components';
import { z } from 'zod';
import { useForm } from '@shared/hooks';
import { StudentPasswordRenovation } from '@shared/models';

const StudentSettingPasswordForm = z.object({
  password: z.string({ required_error: 'Şifre Zorunludur' }).min(1, 'Şifre Zorunludur'),
  newPassword: z.string({ required_error: 'Yeni Şifre Zorunludur' }).min(1, 'Şifre Zorunludur'),
  newPasswordConfirm: z.string({ required_error: 'Yeni Şifre Zorunludur' }).min(1, 'Şifre Zorunludur'),
});

export const StudentSettingPassword = () => {
  useAccessableSeachParams();
  const form = useForm<StudentPasswordRenovation>(StudentSettingPasswordForm, {});

  return (
    <div className="mt-4">
      <div className="w-1/2">
        <Card>
          <div className="w-11/12">
            <Form layout="vertical">
              <Form.Item {...form.formItem('password')} label={'Mevcut Parola :'}>
                <Input.Password {...form.input('password')} />
              </Form.Item>
              <Form.Item {...form.formItem('newPassword')} label={'Yeni parola :'}>
                <Input.Password {...form.input('newPassword')} />
              </Form.Item>
              <Form.Item {...form.formItem('passwordConfirm')} label={'Yeni parolayı doğrula :'}>
                <Input.Password {...form.input('passwordConfirm')} />
              </Form.Item>
              <div className="w-1/2">
                <Button className="my-4" block type="primary">
                  Parolayı kaydet
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

StudentSettingPassword.roles = [Role.ADMIN];
