import { LayoutEnums } from "@shared/constants";
import { createContext, useContext, useState } from "react";

type PlatformLayoutContextType = { selectedLayout: LayoutEnums | undefined; setSelectedLayout: (layout: LayoutEnums) => void };

const PlatformLayoutContext = createContext<PlatformLayoutContextType>({selectedLayout:LayoutEnums.WELCOME_LAYOUT,setSelectedLayout:()=>{}});

export const PlatformLayoutContextProvider = ({ children }) => {
    const [selectedLayout,setSelectedLayout] = useState<LayoutEnums>(LayoutEnums.WELCOME_LAYOUT);
    return (
        <PlatformLayoutContext.Provider value={{selectedLayout,setSelectedLayout}}>
            {children}
        </PlatformLayoutContext.Provider>
    )
}

export const usePlatformLayoutContext = ()=>{
    const context = useContext(PlatformLayoutContext);
    if(context ==undefined){
        throw new Error("usePlatformLayoutContext must be used within a PlatformLayoutContextProvider");
    }
    return context;
}