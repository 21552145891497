export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum LocalStorageKeys {
  LAST = 'joedtech.last',
  THEN = 'joedtech.then',
  USER = 'joedtech',
}

export enum BrandOrBranch {
  BRAND = 'brand',
  BRANCH = 'branch',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum BranchType {
  FRANCHISE = 'FRANCHISE',
  BRANCH = 'BRANCH',
}

export enum LayoutEnums {
  LMS = 'LMS',
  HR = 'HR',
  CRM = 'CRM',
  BRANDMANAGER = 'brand_manager',
  BRANCHMANAGER = 'branch_manager',
  WELCOME_LAYOUT = 'WELCOME_LAYOUT',
  STUDENT = 'student',
  TEACHER = 'teacher',
  OBSERVER = 'observer',
}

export enum ProductsEnum {
  LMS = 'LMS',
  HR = 'HR',
  CRM = 'CRM',
}

export enum AgreementType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

export enum ExerciseLayoutType {
  VISUAL_BASE = 'VISUAL_BASE',
  VISUAL_SINGLE_SELECT = 'VISUAL_SINGLE_SELECT',
  VISUAL_MULTI_SELECT = 'VISUAL_MULTI_SELECT',
  LISTENING_BASE = 'LISTENING_BASE',
  LISTENING_SINGLE_SELECT = 'LISTENING_SINGLE_SELECT',
  LISTENING_MULTI_SELECT = 'LISTENING_MULTI_SELECT',
  DIALOG_BASE = 'DIALOG_BASE',
  DIALOG_SELECT = 'DIALOG_SELECT',
  DIALOG_SORTING = 'DIALOG_SORTING',
  GAP_FILLING_SELECT = 'GAP_FILLING_SELECT',
  GAP_FILLING_KEYBOARD = 'GAP_FILLING_KEYBOARD',
  MATCHING_BASE = 'MATCHING_BASE',
  READING_SINGLE_SELECT = 'READING_SINGLE_SELECT',
  READING_MULTI_SELECT = 'READING_MULTI_SELECT',
  READING_GAP_FILLING = 'READING_GAP_FILLING',
}

export enum ExerciseDefinitionContentType {
  INFO = 'INFO',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  CHAT = 'CHAT',
  MATCHING = 'MATCHING',
  FILL_IN_THE_BLANKS = 'FILL_IN_THE_BLANKS',
}

export enum ExerciseDefinitionInteractionType {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTI_CHOICE = 'MULTI_CHOICE',
  FILL_IN_THE_BLANKS = 'FILL_IN_THE_BLANKS',
  MATCHING = 'MATCHING',
  PRONOUNCE = 'PRONOUNCE',
  ORDER = 'ORDER',
}

export enum CourseTopicType {
  PRE_CLASS = 'PRE_CLASS',
  POST_CLASS = 'POST_CLASS',
  IN_CLASS = 'IN_CLASS',
}

export enum CourseTopicPageType {
  EXERCISE = 'EXERCISE',
  DOWNLOADABLE = 'DOWNLOADABLE',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export enum ContentVersionStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum BrandContentVersionUpdatePackageStatus {
  NONE = 'NONE',
  FAILED = 'FAILED',
  APPLIED = 'APPLIED',
  APPLYING = 'APPLYING',
  REJECTED = 'REJECTED',
}

export enum PageReviewStatus {
  NONE = 'NONE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum RESULT {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum PrePlacementDemandStatus {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  DELAYED_ACTION_REQUIRED = 'DELAYED_ACTION_REQUIRED',
  PLANNING = 'PLANNING',
  ACTIVE = 'ACTIVE',
  SUCCESS = 'SUCCESS',
  CONDITIONAL = 'CONDITIONAL',
  FROZEN = 'FROZEN',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export enum ClassStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum DayOfWeek {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}
export enum NoteType {
  COMPLAINT = 'COMPLAINT',
  GENERAL = 'GENERAL',
  DEMAND = 'DEMAND',
  TECHNICAL_FAILURE = 'TECHNICAL_FAILURE',
}

export enum ClassActivityStatus {
  NEAR_TIME = 'NEAR_TIME',
  EXPIRED = 'EXPIRED',
  DANGLED = 'DANGLED',
  WAITING_FOR_ATTENDANCE = 'WAITING_FOR_ATTENDANCE',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export enum ClassCalendarEntryType {
  IN_CLASS = 'IN_CLASS',
  EVENT = 'EVENT', // Belki o sınıf için bir etkinlik oluşturulabilir. emin değilim, kaldırılabilir.
  HOME_WORK = 'HOME_WORK', // ödev gibi durumlar da takvime işlenebilir. emin değilim, kaldırılabilir.
}

export enum ClassCalendarEntryStatus {
  STARTED = 'STARTED', // Başlanıldı
  WAITING = 'WAITING', // Henüz tarih gelmedi.
  COMPLETED = 'COMPLETED', // Başarıyla gerçekleşti
  CANCELED = 'CANCELED', // iptal edildi
  SKIPPED = 'SKIPPED', // yapılmadı, atlandı, aksiyon alınmadı
  POSTPONED = 'POSTPONED', // başka bir tarihe ertelendi //? eğer bu seçilirse belki hangi tarihe ertelendiğini de tutmak lazım bir fieldta
}

export enum StudentEvaluateStatus {
  WAITING_FOR_EVALUATION = 'WAITING_FOR_EVALUATION',
  NOT_REQUIRED_FOR_NOW = 'NOT_REQUIRED_FOR_NOW',
  EVALUATED = 'EVALUATED',
}

export enum StudentCalendarType {
  BRANCH_ITEM = 'BRANCH_ITEM',
  IN_CLASS = 'IN_CLASS',
  DEMAND_ACTION = 'DEMAND_ACTION',
}
