import { useState, createContext, useContext } from 'react';
import { BrandModel } from '@shared/models';

type BrandContextType = { brand: BrandModel | undefined; setBrand: (brand: BrandModel) => void };

const BrandContext = createContext<BrandContextType>({ brand: undefined, setBrand: () => null });


export const BrandContextProvider = ({ children }) => {
    const [brand, setBrand] = useState<BrandModel>();
    return (
        <BrandContext.Provider value={{ brand, setBrand }}>
            {children}
        </BrandContext.Provider>
    );
}

export const useBrandContext = ()=>{
    const context = useContext(BrandContext);
    if(context ==undefined){
        throw new Error("useBrandContext must be used within a BrandContextProvider");
    }
    return context;
}
