import { CourseTopicPageType, PageReviewStatus } from "@shared/constants";
import { ExerciseGroupModel, MediaModel } from "../exercise";

export type CourseTopicPageDefinetion = Partial<{
    order: number,
    type: CourseTopicPageType,
    content: any,
    exerciseId: string,
}>

export type CourseTopicPageUpdateModel = Partial<{
    id: string,
    order: number,
    type: CourseTopicPageType,
    content: any,
    exerciseId: string,
}>


export type CourseTopicPageModel = Partial<{
    id: string,
    order: number,
    reviewStatus: PageReviewStatus,
    type: CourseTopicPageType,
    content: CoursePageContentModel,
    exercise: ExerciseGroupModel,
    reviewNote: string,
}>

export type CoursePageContentModel = Partial<{
    text: string;
    image: MediaModel;
    video: MediaModel;
    sound: MediaModel;
}>