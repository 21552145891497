import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Card, Tabs, TabsProps } from 'antd';
import { StudentSettingNotification } from './StudentSettingNotification';
import { StudentSettingPassword } from './StudentSettingPassword';

export const StudentSetting = () => {
  useAccessableSeachParams();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Bildirim Tercihleri',
      children: <StudentSettingNotification />,
    },
    {
      key: '2',
      label: 'Parola',
      children: <StudentSettingPassword />,
    },
  ];

  return (
    //TODO Student Layout düzeltiğinde burasıda oraya eşitlenmeli MARGİN SAĞDAN SOLDAN
    <div style={{ margin: '30px 200px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
      <Card>
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </div>
  );
};

StudentSetting.path = '/' + LayoutEnums.STUDENT + '/setting';
StudentSetting.roles = [Role.ADMIN];
