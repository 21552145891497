import { Form, Input } from 'antd';

type NationalIdProps = {
  form: any;
  disabled?: boolean;
  label?: string;
};

export const NationalIdInput = ({ form, disabled = false, label = 'TC / Vergi No:' }: NationalIdProps) => {
  //Alfabetik karakter girmeyi engelleyen kontrol
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  //Alfabetik karakter yapıştırmayı engelleyen kontrol
  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('Text');
    if (!/^\d+$/.test(pasteData)) {
      event.preventDefault();
    }
  };

  return (
    <Form.Item {...form.formItem('nationalId')} label={label}>
      <Input maxLength={13} {...form.input('nationalId')} disabled={disabled} onKeyPress={handleKeyPress} onPaste={handlePaste} />
    </Form.Item>
  );
};
