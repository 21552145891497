import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { EducationCascader, ExerciseCreateHandler, ExerciseHandler, ExerciseTypeSelective } from '@shared/components';
import { Button, Divider, Drawer, Form, Input, Spin, Tooltip } from 'antd';
import { ExerciseListComponent } from '../../exercies/ExerciseListComponent';
import { z } from 'zod';
import { CascaderOption, ExerciseDefinitionModel, ExerciseGroupDefinitionModel, ExerciseGroupModel } from '@shared/models';
import { useForm } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { ExerciseLayoutType } from '@shared/constants';

type CreateExerciseGroupProps = {
  exerciseGroup?: ExerciseGroupModel;
  exerciseLayoutType?: ExerciseLayoutType;
  onOrder: (exercies: ExerciseDefinitionModel[]) => void;
  onRemove: (exercisId: string) => void;
  onSelect: (exercis: ExerciseDefinitionModel) => void;
  exercisIndex?: number;
  onChanceSelectedIndex: (exercisIndex: number) => void;
  abilityLevelOptions: CascaderOption[];
  onChangeEducationLevel: (value: (string | number)[]) => void;
  isLoadingEducationLevel: boolean;
  exerciseLevel?: (string | number)[];
  onCreateOrUpdateExerciseGroupMeta: (value: ExerciseGroupDefinitionModel) => void;
  onChangeType: (layout: ExerciseLayoutType) => void;
  onChangeCreateComponent: (item: ExerciseDefinitionModel) => void;
  onInsertExerciseToExerciseGroup: (exercis: ExerciseDefinitionModel) => void;
  isAdminCdn: boolean;
  loading?: boolean;
};
const CreateExerciseGroupForm = z.object({
  name: z.string({ required_error: 'Egzersiz group adı zorunlu alandır' }).min(1, 'Egzersiz group adı 1 karekterden fazla olmalı'),
  description: z.string({ required_error: 'Egzersiz group açıklaması zorunlu alandır' }).min(1, 'Egzersiz group acıklaması 1 karekterden fazla olmalı'),
  levelId: z.string({ required_error: 'Lütfen kur seçimi yapınız' }),
});
export const CreateExerciseGroup = ({
  exerciseGroup,
  exercisIndex,
  exerciseLayoutType,
  onChanceSelectedIndex,
  onOrder,
  onRemove,
  onSelect,
  abilityLevelOptions,
  isLoadingEducationLevel,
  exerciseLevel,
  onChangeEducationLevel,
  onCreateOrUpdateExerciseGroupMeta,
  onChangeType,
  onChangeCreateComponent,
  onInsertExerciseToExerciseGroup,
  isAdminCdn,
  loading
}: CreateExerciseGroupProps) => {
  const groupForm = useForm<ExerciseGroupDefinitionModel>(CreateExerciseGroupForm, {});
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isAbleToCreateExercise, setAbleToCreateExercise] = useState<boolean>(true);
  

  useEffect(() => {
    if (exerciseGroup) {
      groupForm.setValue({
        name: exerciseGroup.name,
        description: exerciseGroup.description,
        levelId: exerciseGroup.level?.id,
      });
      if (exercisIndex == undefined) {
        setAbleToCreateExercise(true);
      } else {
        if (exerciseGroup.exercises && exerciseGroup.exercises.length > 0) {
          const list = exerciseGroup.exercises.filter((e) => !e.id);
          setAbleToCreateExercise(list.length == 0);
        } else {
          setAbleToCreateExercise(true);
        }
      }
    } else {
      setAbleToCreateExercise(true);
    }
  }, [exerciseGroup, exercisIndex]);



  return (
    <div>
      <Drawer title="Egzersiz Tipleri" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <ExerciseTypeSelective
          onChange={(layout) => {
            setIsOpenDrawer(false);
            onChangeType(layout);
          }}
        />
      </Drawer>
      <Form layout="vertical" className="grid grid-cols-4 space-x-2">
        <Form.Item {...groupForm.formItem('name')} label={'Egzersiz Grubu Adı:'}>
          <Input {...groupForm.input('name')} />
        </Form.Item>
        <Form.Item {...groupForm.formItem('description')} label={'Egzersiz Grubu Açıklaması:'}>
          <Input {...groupForm.input('description')} />
        </Form.Item>
        <Form.Item {...groupForm.formItem('levelId')} label={'Kur:'}>
          <EducationCascader
            value={exerciseLevel}
            items={abilityLevelOptions}
            loading={isLoadingEducationLevel}
            onChange={(value) => {
              const levelId = value.at(-1)?.toString();
              groupForm.setValue({
                ...groupForm.value,
                levelId,
              });
              onChangeEducationLevel(value);
            }}
          />
        </Form.Item>
        <Form.Item label={' '}>
          <Button
            type="primary"
            onClick={() => {
              const formResult = groupForm.parse();
              if (formResult.success) {
                const data = formResult.data;
                onCreateOrUpdateExerciseGroupMeta(data);
              } else {
                groupForm.setAllTouched();
              }
            }}
          >
            {exerciseGroup && exerciseGroup.id ? 'Egzersizin Group Güncelle' : 'Egzersizin Group Oluştur'}
          </Button>
        </Form.Item>
      </Form>
      <div>
        <Tooltip title={isAbleToCreateExercise ? '' : 'Lütfen ilgili egzersiz tipini eklemeyi tamamlayınız'} color="red">
          <Button
            type="dashed"
            className="w-48"
            disabled={!isAbleToCreateExercise}
            onClick={() => {
              setIsOpenDrawer(true);
            }}
          >
            + Egzersiz Tipi Seç
          </Button>
        </Tooltip>
        
            <div className="flex flex-row space-x-2 overflow-auto">
              <ExerciseListComponent
                exercies={exerciseGroup?.exercises ?? []}
                selected={exerciseGroup?.exercises![exercisIndex!]}
                onOrder={onOrder}
                onRemove={onRemove}
                onSelect={onSelect}
              />
            </div>
            {exerciseGroup?.exercises && exercisIndex != undefined ? (
              <div className="grid grid-cols-3 gap-x-2">
                <div className="col-span-2">
                  <h3>{exerciseLayoutType}</h3>
                  <div className="border border-dashed border-mid-gray rounded min-h-[61vh]">
                    <ExerciseCreateHandler
                      isAdminCdn={isAdminCdn}
                      item={exerciseGroup.exercises[exercisIndex]}
                      onSave={onInsertExerciseToExerciseGroup}
                      onChange={onChangeCreateComponent}
                    />
                  </div>
                </div>
                <div>
                  <h3>Ön izleme</h3>
                  <div className="border border-dashed border-mid-gray rounded min-h-[61vh] grid grid-cols-6">
                    <div className="col-span-5 min-h-[61vh]">
                      <ExerciseHandler isAdminCdn={isAdminCdn} item={exerciseGroup.exercises[exercisIndex]} isPreview={true} isInClass={false} />
                    </div>
                    <div className="h-full">
                      <div
                        className="h-2/5 text-center content-center"
                        onClick={() => {
                          if (exercisIndex - 1 > -1) {
                            onChanceSelectedIndex(exercisIndex - 1);
                          }
                        }}
                      >
                        <UpOutlined style={{ fontSize: '200%' }} />
                      </div>
                      <div className="text-center content-center">
                        <Divider />
                        <p className="text-lg">
                          {exercisIndex + 1} / {exerciseGroup.exercises.length}
                        </p>
                        <Divider />
                      </div>
                      <div
                        className="h-2/5 text-center content-center"
                        onClick={() => {
                          if (exercisIndex + 1 < exerciseGroup.exercises?.length!) {
                            onChanceSelectedIndex(exercisIndex + 1);
                          }
                        }}
                      >
                        <DownOutlined style={{ fontSize: '200%' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          

      </div>
    </div>
  );
};
