import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  HomeOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Avatar, Button, Card, Form, notification, Image, Divider, Tabs } from 'antd';
import { ActionDropdown } from '@shared/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TabsProps } from 'antd';
import { CreateHomeWorkDrawer } from './CreateHomeWorkDrawer';
import { HomeWorkSubmissionModal } from './HomeWorkSubmissionModal';

export const TeacherClassHomeWork = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const onClose = () => {
    setDrawerOpen(false);
    setModalOpen(false);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Taslaklar',
      children: (
        <div>
          {' '}
          <Button type="dashed" className="w-full mb-3" icon={<PlusCircleOutlined />} onClick={() => setDrawerOpen(true)}>
            Yeni ödev oluştur
          </Button>
          <Card className="h-32 p-2" bodyStyle={{ padding: '0' }}>
            <div className="text-sm">
              <div className="flex justify-between font-bold">
                Kompozisyon
                <span>
                  <ActionDropdown isDelete={false} isEdit={false} data={{}} />
                </span>
              </div>
              <div className="mt-1">Genel İngilizce A1</div>
              <div className="mt-1">
                <PaperClipOutlined />
                <span className="ml-1">1</span>
              </div>
              <div className="mt-1">
                <CalendarOutlined />
                <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
              </div>
            </div>
          </Card>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Kütüphane',
      children: 'TODO olmayabilir burası',
    },
  ];

  useAccessableSeachParams();
  return (
    <div className="container mx-auto pt-1">
      <div className="grid grid-cols-4 mt-3 gap-3">
        <Card size="small" className="h-12">
          <SyncOutlined style={{ color: '#1677FF' }} />
          <span className="ml-2">Aktif</span>
        </Card>
        <Card size="small" className="h-12">
          <div>
            <FieldTimeOutlined style={{ color: '#FAAD14' }} />
            <span className="ml-2"> Henüz aktif değil</span>
          </div>
        </Card>
        <Card size="small" className="h-12">
          <div>
            <CheckCircleOutlined style={{ color: '#52C41A' }} />
            <span className="ml-2"> Tamamlandı</span>
          </div>
        </Card>
        <Card size="small" className="h-12">
          <div>
            <ClockCircleOutlined className="opacity-25" />
            <span className="ml-2">Taslaklar</span>
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-4 gap-3 mt-2">
        <Card className="h-[400px] overflow-y-auto p-3" bodyStyle={{ padding: '0' }}>
          <div className="grid grid-cols-1 gap-3">
            <Card className="h-32 p-2" bodyStyle={{ padding: '0' }}>
              <div className="text-sm">
                <div className="flex justify-between font-bold">
                  Kompozisyon
                  <span>
                    <ActionDropdown isHomeWorkSubmission={true} onHomeWorkSubmission={() => setModalOpen(true)} isDelete={false} isEdit={false} data={{}} />
                  </span>
                </div>
                <div className="mt-1">Genel İngilizce A1</div>
                <div className="mt-1">
                  <PaperClipOutlined />
                  <span className="ml-1">1</span>
                </div>
                <div className="mt-1">
                  <CalendarOutlined />
                  <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
                </div>
              </div>
            </Card>
            <Card className="h-32 p-2" bodyStyle={{ padding: '0' }}>
              <div className="text-sm">
                <div className="flex justify-between font-bold">
                  Kompozisyon
                  <span>
                    <ActionDropdown isDelete={false} isEdit={false} data={{}} />
                  </span>
                </div>
                <div className="mt-1">Genel İngilizce A1</div>
                <div className="mt-1">
                  <PaperClipOutlined />
                  <span className="ml-1">1</span>
                </div>
                <div className="mt-1">
                  <CalendarOutlined />
                  <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
                </div>
              </div>
            </Card>
            <Card className="h-32 p-2" bodyStyle={{ padding: '0' }}>
              <div className="text-sm">
                <div className="flex justify-between font-bold">
                  Kompozisyon
                  <span>
                    <ActionDropdown isDelete={false} isEdit={false} data={{}} />
                  </span>
                </div>
                <div className="mt-1">Genel İngilizce A1</div>
                <div className="mt-1">
                  <PaperClipOutlined />
                  <span className="ml-1">1</span>
                </div>
                <div className="mt-1">
                  <CalendarOutlined />
                  <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
                </div>
              </div>
            </Card>
          </div>
        </Card>
        <Card className="h-[400px] overflow-y-auto p-3" bodyStyle={{ padding: '0' }}></Card>
        <Card className="h-[400px] overflow-y-auto p-3" bodyStyle={{ padding: '0' }}></Card>
        <Card className="h-[400px] overflow-y-auto p-3" bodyStyle={{ padding: '0' }}>
          <Tabs size="small" tabBarGutter={60} centered defaultActiveKey="1" items={items} />
        </Card>
      </div>
      <CreateHomeWorkDrawer open={drawerOpen} onClose={onClose} />
      <HomeWorkSubmissionModal open={modalOpen} onClose={onClose} />
    </div>
  );
};

TeacherClassHomeWork.roles = [Role.ADMIN];
TeacherClassHomeWork.layouts = [LayoutEnums.TEACHER];
