import { LayoutEnums, NoteType, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as API from '@shared/api';
import { notification, Spin, Tabs, Tag } from 'antd';
import { TeacherInspection } from '@platform/platform_components/teacher_class/homework_components/TeacherInspection';
import { ClassHeader, ClassSuccessSummary, TeacherClassDashboard } from '@platform/platform_components';
import { TeacherClassHomeWork } from '@platform/platform_components/teacher_class/homework_components/TeacherClassHomeWork';
import { Spinner } from '@shared/components';

export const TeacherClassDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [classId, setClassId] = useState<string>();
  const [activeKey, setActiveKey] = useState<string>('dashboard');
  const [classDetail, classDetailLoading, getClassDetail] = API.CLASS.getClassDetail(classId, { init: false });
  const [calendarData, calendarLoading, getCalendarData] = API.CLASS.getClassCalendar(classId, { init: false });

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('class_id');
      if (!id) return;
      setClassId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (classId) {
      getClassDetail();
      getCalendarData();
    }
  }, [classId]);

  const createClassNote = async (note: string, noteType: NoteType) => {
    const response = await API.CLASS.createClassNote(classDetail?.id!, note, noteType);
    if (response.ok) {
      await getClassDetail();
      notification.success({ message: 'Sınıf notu oluşturuldu!' });
    }
  };

  return (
    <>
      <Spinner loading={classDetailLoading} />
      <div style={{ margin: '30px 100px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
        <ClassHeader classDetailPage={true} classDetail={classDetail} imageName="class_teacher_dashboard" titleColor="#FF3436" />
        <Tabs
          className="teacher-border"
          onChange={(key) => setActiveKey(key)}
          items={[
            {
              key: 'dashboard',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'dashboard' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
                  <span style={{ color: activeKey !== 'dashboard' ? '#f0501b' : '' }}>Dashboard</span>
                </Tag>
              ),
              children: <TeacherClassDashboard createClassNote={createClassNote} classDetail={classDetail} calendarData={calendarData} getClassDetail={() => getClassDetail()} />,
            },
            {
              key: 'Öğrenci Durumu',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'Öğrenci Durumu' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
                  <span style={{ color: activeKey !== 'Öğrenci Durumu' ? '#f0501b' : '' }}>Öğrenci Durumu</span>
                </Tag>
              ),
              children: <ClassSuccessSummary classDetail={classDetail} />,
            },
            //TODO Kullanılmayan alanların kaldırılması
            {
              key: 'homework',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'homework' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-3">
                  <span style={{ color: activeKey !== 'homework' ? '#f0501b' : '' }}>Ödev</span>
                </Tag>
              ),
              children: <TeacherClassHomeWork />,
            },
            {
              key: 'attendance',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'attendance' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
                  <span style={{ color: activeKey !== 'attendance' ? '#f0501b' : '' }}>Yoklama</span>
                </Tag>
              ),
              children: <TeacherInspection />,
            },
            //TODO Kullanılmayan alanların kaldırılması
            // {
            //   key: 'performance',
            //   label: (
            //     <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'performance' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
            //       <span style={{ color: activeKey !== 'performance' ? '#f0501b' : '' }}>Sınıf Performansı</span>
            //     </Tag>
            //   ),
            //   children: 'Soon',
            // },
            //TODO Kullanılmayan alanların kaldırılması
            // {
            //   key: 'agenda',
            //   label: (
            //     <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'agenda' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
            //       <span style={{ color: activeKey !== 'agenda' ? '#f0501b' : '' }}>Ajanda</span>
            //     </Tag>
            //   ),
            //   children: 'Soon',
            // },
          ]}
        />
      </div>
    </>
  );
};

TeacherClassDetail.path = '/' + LayoutEnums.TEACHER + '/class_detail';
TeacherClassDetail.roles = [Role.ADMIN];
TeacherClassDetail.layouts = [LayoutEnums.TEACHER];
