import { DeleteOutlined } from "@ant-design/icons";
import { AvatarFromName } from "@shared/components";
import { ExerciseChatModel, ExerciseConversationModel } from "@shared/models";
import { Button } from "antd";
type DialogMessageBoxProp = {
    conversation: ExerciseConversationModel,
    isPreview?: boolean;
    onRemove?: (chat: ExerciseChatModel,index: number) => void;
}
export const DialogMessageBox = ({ conversation, isPreview = false,onRemove }: DialogMessageBoxProp) => {
    return (
        <div className="flex flex-col h-full w-full overflow-auto gap-y-4">
            {
                conversation.chat?.map((c,index) => {
                    const member = conversation.members![c.who!];
                    return (
                        <div key={c.id} className="exercise-message-container" data-main-member={member?.isMainMember}>
                            <div className="exercise-message">
                                <AvatarFromName title={member?.name!} subtitle="" />
                                <div className={(c.interaction) ? "exercise-message-question" : "exercise-message-text"}>
                                    {c.interaction ? (
                                        c.interaction.textSpeech ? c.text :
                                        (c.interaction.orderItems ? c.interaction.orderItems[0].map((o) => "__ ") : "?")
                                        ) : c.text}
                                </div>
                                {
                                    (isPreview  && onRemove) && (<Button danger size="small" icon={<DeleteOutlined/>} onClick={()=>{onRemove(c,index)}}/>)
                                }

                            </div>
                        </div>
                    );
                }
                )
            }
        </div>
    )
}