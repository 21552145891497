import { useForm } from '@shared/hooks';
import { ClassRoomModel } from '@shared/models';
import { Button, Drawer, Form, Input, InputNumber, notification, Popconfirm } from 'antd';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

type ClassroomDrawerProps = {
  selected: ClassRoomModel | null;
  open: boolean;
  isUpdate: boolean;
  saveOrUpdateClassroom: (data: ClassRoomModel) => void;
  onCloseDrawer: () => void;
};

const CreateClassRoomForm = z.object({
  name: z.string({ required_error: 'Derslik adı zorunlu alandır' }).min(1, 'Sınıf adı 1 karekterden fazla olmalı'),
  quota: z.number({ required_error: 'Kota zorunlu alandır' }).max(999, 'Sınıf 3 basamaktan az olmalı'),
  maxQuota: z.number({ required_error: 'Max Kota zorunlu alandır' }).max(999, 'Sınıf 3 basamaktan az olmalı'),
});

export const ClassroomDrawer = ({ selected, open, isUpdate, saveOrUpdateClassroom, onCloseDrawer }: ClassroomDrawerProps) => {
  const form = useForm<ClassRoomModel>(CreateClassRoomForm, {});
  const [derslikAdi, setDerslikAdi] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selected) {
      form.setValue({ ...selected });
      setDerslikAdi(selected.name);
    }
  }, [selected]);

  const handleSaveOrUpdate = () => {
    const result = form.parse();
    if (result.success) {
      if (result.data['quota'] > result.data['maxQuota']) {
        notification.error({ message: 'Kota, maksimum kotadan büyük olamaz' });
        return;
      }
      saveOrUpdateClassroom(result.data);
      form.reset();
    } else {
      form.setAllTouched();
    }
  };

  const handleOnClose = () => {
    form.reset();
    onCloseDrawer();
  };

  return (
    <Drawer forceRender={true} onClose={handleOnClose} open={open} closable={true} title={isUpdate ? 'Dersliği Düzenle' : 'Yeni Derslik Oluştur'}>
      <Form layout="vertical" className="flex flex-col">
        <Form.Item {...form.formItem('name')} label="Derslik Adı">
          <Input {...form.input('name')} />
        </Form.Item>

        <Form.Item {...form.formItem('quota')} label="Derslik Kapasitesi">
          <InputNumber min={1} {...form.inputNumber('quota')} />
        </Form.Item>

        <Form.Item {...form.formItem('maxQuota')} label="Maksimum Kapasite">
          <InputNumber min={1} {...form.inputNumber('maxQuota')} />
        </Form.Item>

        <div className="grid grid-cols-2 gap-4">
          <Button type="primary" onClick={handleSaveOrUpdate}>
            {isUpdate ? 'Güncelle' : 'Derslik Oluştur'}
          </Button>

          {isUpdate ? (
            <></>
          ) : (
            <Button type="default" onClick={() => form.reset()}>
              Sıfırla
            </Button>
          )}
        </div>
      </Form>
    </Drawer>
  );
};
