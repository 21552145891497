import { ExerciseLayoutType } from '@shared/constants';
import { Image, Tag } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};
export const DialogBaseSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      onClick={() => {
        onChange(ExerciseLayoutType.DIALOG_BASE);
      }}
    >
      <Tag bordered={false}>Basit</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Diyalog/Dış/kelime-siralama.png')} />
      </div>
    </div>
  );
};

export const DialogSelectedSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      onClick={() => {
        onChange(ExerciseLayoutType.DIALOG_SELECT);
      }}
    >
      <Tag bordered={false}>Seçenekli</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Diyalog/Dış/secenekli.png')} />
      </div>
    </div>
  );
};

export const DialogSortingSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      onClick={() => {
        onChange(ExerciseLayoutType.DIALOG_SORTING);
      }}
    >
      <Tag bordered={false}>Kelime Sıralama</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Diyalog/Dış/kelime-siralama.png')} />
      </div>
    </div>
  );
};
