import { NoteType } from '@shared/constants';
import { ClassCalendarModel, ClassDetailModel } from '@shared/models';
import { convertDate } from '@shared/utils';
import { Card } from 'antd';
import { ClassInfo, ClassTimeline } from '../class';
import { TeacherClassFlow, TeacherClassStudentList } from './dashboard_components';
import { useAccessableSeachParams } from '@shared/hooks';

type TeacherClassDashboardProps = {
  classDetail: ClassDetailModel | undefined;
  createClassNote: (note: string, noteType: NoteType) => void;
  calendarData: ClassCalendarModel[] | undefined;
  getClassDetail: () => void;
};

export const TeacherClassDashboard = ({ classDetail, createClassNote, calendarData, getClassDetail }: TeacherClassDashboardProps) => {
  useAccessableSeachParams();

  return (
    <div className="grid grid-cols-3 gap-x-2">
      <div className="col-span-2" style={{ height: 'calc(100vh - 227px)' }}>
        <ClassTimeline classDetail={classDetail} notes={classDetail?.notes} createClassNote={createClassNote} isTeacher={true} />
      </div>
      <div className="col-span-1 h-full" style={{ height: 'calc(100vh - 227px)' }}>
        <ClassInfo classDetail={classDetail} isSettings={false} calendarData={calendarData} getClassDetail={() => getClassDetail()} isTeacherLayout={true} />
      </div>
    </div>
  );
};
