import { useFetch } from '@shared/hooks';
import { PaginateModel, TeacherAvailableCalendar, TeacherModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<PaginateModel<TeacherModel>>('/teacher', { method: 'GET', ...config }),
  getById: async (id: string) => request('/teacher/' + id, 'GET'),
  create: async (teacher: TeacherModel) => request('/teacher', 'POST', teacher),
  update: async (teacher: TeacherModel) => request('/teacher', 'PUT', teacher),
  delete: async (id: string) => request('/teacher/' + id, 'DELETE'),
  updateAvailableCalendar: async (teacherAvailableCalendar: TeacherAvailableCalendar) => request('/teacher/available-calendar', 'PUT', teacherAvailableCalendar),
  getAvailableCalendar: async (teacherId: string) => request('/teacher/' + teacherId + '/available-calendar', 'GET'),
  getUnavailableCalendar: async (teacherId: string) => request('/teacher/' + teacherId + '/unavailable-calendar', 'GET'),
};
