import { Form, Input, InputRef } from 'antd';
import React, { useImperativeHandle, useRef } from 'react';
import useGeoLocation from 'react-ipgeolocation';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type PhoneInputProps = {
  form: any;
  disabled?: boolean;
};

const MyInput = React.forwardRef((props, ref) => {
  const inputRef = useRef<InputRef>(null);

  useImperativeHandle(
    ref,
    () => {
      if (inputRef.current) {
        return inputRef.current.input;
      }
      return null;
    },
    []
  );
  return <Input {...props} ref={inputRef} />;
});

export const PhoneInput = ({ form, disabled = false }: PhoneInputProps) => {
  const location = useGeoLocation(); // Hangi ülkeden girildiğine göre ülke kodunu ekranda default gösteriyoruz.
  return (
    <Form.Item {...form.formItem('phone.number')} label="Telefon">
      <Phone
        inputComponent={MyInput}
        international
        countryCallingCodeEditable={false}
        disabled={disabled}
        defaultCountry={location.country ?? 'TR'}
        {...form.inputPhone('phone.code', 'phone.number')}
        limitMaxLength
      />
    </Form.Item>
  );
};
