import { AgreementType, ClassActivityStatus, Gender } from '@shared/constants';
import { ClassSeanceItem } from './class_seance_item';
import { EducationLevel } from './education_level';
import { AvailabilityCalendar, PrePlacementDemand } from './enrollment';
import { PhoneModel } from './information';
import { StudentModel } from './student';
import { ContentVersionNavigation } from './curriculum';



export type TeacherModel = Partial<{
  id: string;
  name: string;
  surname: string;
  phone: PhoneModel;
  email: string;
  abilityLevels: EducationLevel[];
  gender: Gender;
  nationalId: string;
  note: string;
  birthDate: string;
  agreementType: AgreementType | undefined;
  prePlacementDemands: PrePlacementDemand[];
}>;

export type TeacherAvailableCalendar = Partial<{
  id: string;
  availabilityCalendar: AvailabilityCalendar;
}>;

export type TeacherInClass = Partial<{
  id: string;
  attendances: Attendances[];
  contentProgress: ContentVersionNavigation;
  status: ClassActivityStatus;
  name: string;
  classSeanceItem: ClassSeanceItem;
}>;

export type Attendances = Partial<{
  id: string;
  branchId: string;
  attended: boolean;
  student: StudentModel;
  activityId: string;
}>;

export type AttendancesRequest = Partial<{
  attended: boolean;
  studentId: string;
}>;




export type Conversation = Partial<{
  chat: Chat[];
  members: Members;
}>;

export type Chat = Partial<{
  id: string;
  text: string;
  who: string;
}>;

export interface Members {
  [key: string]: Member;
}

export interface Member {
  name: string;
  isMainMember: boolean;
}

export interface Tree {
  approved: boolean;
  id: string;
  name: string;
  topics: Topics[];
}

export interface Topics {
  approved: boolean;
  id: string;
  name: string;
  pages: Pages[];
}

export interface Pages {
  approved: boolean;
  id: string;
  order: number;
}
