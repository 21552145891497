import { EducationLevel } from "@shared/models";
import {
    SortableContext,
    rectSortingStrategy,
    useSortable,
    sortableKeyboardCoordinates,
    arrayMove,
} from '@dnd-kit/sortable';
import {
    DndContext,
    PointerSensor,
    KeyboardSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useEffect, useState } from "react";

type EducationLevelSortingProps = {
    levels: EducationLevel[],
    onOrder: (units: EducationLevel[]) => void
}
export const EducationLevelSorting = ({ levels, onOrder }: EducationLevelSortingProps) => {

    const [items, setItems] = useState<EducationLevel[]>([]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setItems(levels);
    }, [levels]);

    const handleDragEnd = ({ active, over }) => {
        if (!over) {
            return;
        }

        if (active.id == over.id) {
            return;
        }

        const from = items.findIndex((it) => it.id == active.id);
        const to = items.findIndex((it) => it.id == over.id);

        const _item = arrayMove(
            items,
            from,
            to
        ).map((i,index)=>{
            return {
                ...i,
                order:index
            }
        });
        setItems(_item);
        onOrder(_item);
    };

    const SortableItem = (item) => {
        const { id, name, description } = item;
        const { attributes, listeners, setNodeRef, transform, transition } =
            useSortable({ id });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (

            <div ref={setNodeRef} className="cursor-ew-resize flex flex-col rounded-md content-center justify-center text-center items-center border border-solid min-w-28 m-2 p-1 bg-white hover:bg-gray-100" style={{ ...style }} {...attributes} {...listeners}>
                <div className="h-max w-max text-6xl">{name}</div>
            </div>

        );
    };


    return (

        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={items.map((u) => {
                return {
                    id: u.id!
                }
            })} strategy={rectSortingStrategy} >
                {items.map((item) => (
                    <SortableItem key={item.id} id={item.id} name={item.name} description={item} />
                ))}
            </SortableContext>
        </DndContext>
    )
}