import { ExerciseDefinitionModel, ImageCdnModel, MediaModel } from "@shared/models";
import { Button, Form, Input, Switch } from "antd"
import { useCallback, useEffect, useState } from "react";
import { ImageUpload } from "../../../ImageUpload";
import { SoundUpload } from "@shared/components/curriculum/SoundUpload";
import { ExerciseLayoutType } from "@shared/constants";
import { layoutToContentType } from "@shared/utils";




type CreateExerciseProps = {
    item: ExerciseDefinitionModel,
    onSave: (item: ExerciseDefinitionModel) => void;
    onChange: (item: ExerciseDefinitionModel) => void;
    isAdminCdn: boolean;
}

export const CreateVisualBase = ({ item, onSave, onChange, isAdminCdn }: CreateExerciseProps) => {


    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [image, setImage] = useState<ImageCdnModel>();
    const [sound, setSound] = useState<MediaModel>();
    const [isSound, setIsSound] = useState<boolean>(false);
    const [isPronunciation, setIsPronunciation] = useState<boolean>(false);
    const [isMediaLoading, setMediaLoading] = useState<boolean>(false);





    useEffect(() => {
        setTitle(item.content?.content?.title);
        setDescription(item.content?.content?.description);
        setImage(item.content?.image?.resourceUrl ? {id: item.content?.image?.resourceId, variants: [item.content?.image?.resourceUrl]} : undefined);
        setSound(item.content?.sound);
        setIsSound((item.content?.sound && Object.keys(item.content.sound).length > 0) ? true : false);
        setIsPronunciation(item.content?.isPronounEnabled ? true : false);

    }, [item.id]);

    useEffect(() => {
        if (title || description || image || sound  || isPronunciation) {
            const i = {
                ...item,
                layout: ExerciseLayoutType.VISUAL_BASE,
                content: {
                  ...item.content,
                  content: {
                    ...(item.content ? item.content.content : {}),
                    title: title,
                    description: description,
                  },
                  sound: sound,
                  image: image ? {
                    resourceId: image.id,
                    resourceUrl: image.variants![0],
                  }: undefined,
                  isPronounEnabled: isPronunciation,
                  type:layoutToContentType(ExerciseLayoutType.VISUAL_BASE),
                },
              } as ExerciseDefinitionModel;
              onChange(i);
        }
    }, [title, description, image, sound, isSound, isPronunciation]);

   

    const send = useCallback(()=>{
        const i = {
            ...item,
            layout: ExerciseLayoutType.VISUAL_BASE,
            content: {
              ...item.content,
              content: {
                ...(item.content ? item.content.content : {}),
                title: title,
                description: description,
              },
              sound: sound,
              image: image ? {
                resourceId: image.id,
                resourceUrl: image.variants![0],
              }: undefined,
              isPronounEnabled: isPronunciation,
              type:layoutToContentType(ExerciseLayoutType.VISUAL_BASE),
            },
          } as ExerciseDefinitionModel;
        onSave(i);
    },[title, description, image, sound, isSound, isPronunciation]);


    const onChangePronunciation = (e) => {
        setIsPronunciation(e);
    }

    return (
        <div className="p-5">
            <div className="flex justify-end">
                <Button type="primary" disabled={isMediaLoading} size="small" onClick={send}>{item.id ? "Güncelle" : "Ekle"}</Button>
            </div>
            <div className="flex flex-col items-center h-fit">

                <div>

                    <ImageUpload image={image ? image : {}}
                        onChange={(image) => {
                            setImage(image);
                        }}
                        onChangeLoading={setMediaLoading}
                        isAdminCdn={isAdminCdn}
                    />
                </div>
                <div className="w-full">
                    <Form layout="vertical">
                        <Form.Item label={"Kelime:"}>
                            <Input
                                value={title}
                                disabled={isMediaLoading}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }} />
                        </Form.Item>
                        <Form.Item label={"Açıklama:"}>
                            <Input
                                value={description}
                                disabled={isMediaLoading}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }} />
                        </Form.Item>
                        <div className="grid grid-cols-2 gap-x-2">
                            <div className="flex flex-row space-x-2">
                                <Form.Item label={"Ses dosyası:"}>
                                    <Switch size="small" value={isSound} disabled={isMediaLoading} onChange={(e)=>{
                                        
                                        setIsSound(e);
                                        if(e){
                                            setSound({resourceId:undefined} as MediaModel);
                                        }else{
                                            setSound(undefined);
                                        }
                                    }} />
                                </Form.Item>
                                {
                                    isSound ? (
                                        <Form.Item label={"Ses dosyası"}>
                                            <SoundUpload
                                                sound={sound}
                                                onChangeLoading={setMediaLoading}
                                                onChange={(sound) => {
                                                    setSound(sound);
                                                }}
                                                isAdminCdn={isAdminCdn} />
                                        </Form.Item>
                                    ) : (<div></div>)
                                }
                            </div>

                            <Form.Item label={isPronunciation ? "Telaffuzu pasif et" : "Telaffuzu aktif et:"}>
                                <Switch size="small" value={isPronunciation} disabled={isMediaLoading} onChange={onChangePronunciation} />
                            </Form.Item>
                        </div>

                    </Form>
                </div>
            </div>
        </div>
    )
}