import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Country, State as Sehir } from 'country-state-city';

interface Country {
  flag: string;
  name: string;
  isoCode: string;
}

interface State {
  isoCode: string;
  name: string;
  countryCode: string;
}

export const CountrySelector = ({ form }) => {
  const [countryList, setCountryList] = useState<Country[]>();
  const [stateList, setStateList] = useState<State[]>();
  const [stateListLoading, setStateListLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(undefined);

  /* API olarak kullanmak istersek bu request atılacak (API ve country-state-city kütüphanesi aynı veritabanından bilgileri alıyor)
  const getCountryList = async () => {
    const response = await fetch('https://api.countrystatecity.in/v1/countries', {
      method: 'GET',
      headers: { 'X-CSCAPI-KEY': 'QjltcHpHek5Lc2VEMkp0NnJWU3FmRjRhaTFxNFNEMEw3VWJSWXgxMw==' },
      redirect: 'follow',
    });
    const data = await response.json();
    
    setCountryList(data);
  };

  const getStateList = async (iso2: string) => {
    setStateListLoading(true);
    const response = await fetch(`https://api.countrystatecity.in/v1/countries/${iso2}/states`, {
      method: 'GET',
      headers: { 'X-CSCAPI-KEY': 'QjltcHpHek5Lc2VEMkp0NnJWU3FmRjRhaTFxNFNEMEw3VWJSWXgxMw==' },
      redirect: 'follow',
    });

    const data = await response.json();

    setStateList(data);
    setStateListLoading(false);
  };
*/

  useEffect(() => {
    setCountryList(Country.getAllCountries());
  }, []);

  const countryOptions = countryList
    ?.map((country) => ({
      value: country.name,
      label: `${country.flag} ${country.name}`,
    }))
    .sort((a, b) => (a.label.includes('Turkey') ? -1 : b.label.includes('Turkey') ? 1 : 0));

  const stateOptions = stateList
    ?.map((state) => ({
      value: state.name,
      label: state.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleCountryChange = (value: string) => {
    const country = countryList?.find((c) => c.name === value) as Country;
    setSelectedCountry(country);
    form.setField('address.city.code')();
    form.setField('address.country.code')(country.isoCode);
    form.setField('address.country.name')(country.name);
    setStateList(Sehir.getStatesOfCountry(country.isoCode));
  };

  const filterInput = (input: string, option?: { label: string; value: string }) => {
    const lowerCaseInput = input.toLowerCase();
    const lowerCaseLabel = (option?.label ?? '').toLowerCase();
    return lowerCaseLabel.includes(lowerCaseInput);
  };

  return (
    <>
      <Form.Item {...form.formItem('address.country.code')}>
        <Select
          showSearch
          filterOption={filterInput}
          optionFilterProp="children"
          {...form.inputSelected('address.country.code')}
          placeholder="Ülke Seçiniz"
          className="w-full"
          value={form.value['address.country.name']}
          options={countryOptions}
          onChange={handleCountryChange}
        />
      </Form.Item>

      <Form.Item {...form.formItem('address.city.code')}>
        <Select
          showSearch
          filterOption={filterInput}
          optionFilterProp="children"
          {...form.inputSelected('address.city.code')}
          value={form.value['address.city.code']}
          disabled={!selectedCountry || stateListLoading}
          placeholder="Şehir Seçiniz"
          className="w-full ml-2"
          options={stateOptions}
        />
      </Form.Item>
    </>
  );
};
