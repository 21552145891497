import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

export const TeacherDashboard = () => {
  useAccessableSeachParams();
  return <ComingSoon navigationUrl="/" navigationText="Ana ekrana dön" />;
};

TeacherDashboard.path = '/' + LayoutEnums.TEACHER + '/dashboard';
TeacherDashboard.title = 'Kontrol Paneli';
TeacherDashboard.group = 'flat';
TeacherDashboard.roles = [Role.ADMIN];
TeacherDashboard.layouts = [LayoutEnums.TEACHER];
TeacherDashboard.icon = <DashboardOutlined size={20} />;
