import { ExerciseDefinitionContentType, ExerciseDefinitionInteractionType, ExerciseLayoutType } from "@shared/constants";
import { EducationLevel } from "../../education_level";


export type ExerciseGroupModel = Partial<{
    id:string,
    name:string,
    description:string,
    level:EducationLevel,
    exerciseCount:number
    exercises:ExerciseDefinitionModel[]
}>

export type ExerciseGroupDefinitionModel = Partial<{
    name: string,
    description: string,
    levelId: string,
}>

export type ExerciseDefinitionModel = Partial<{
    id:string,
    layout: ExerciseLayoutType;
    content: ExerciseDefinitionContentModel;
    interaction: ExerciseDefinitionInteractionModel;
    order:number;
}>

export type ExerciseDefinitionContentModel = Partial<{
    type: ExerciseDefinitionContentType,
    isPronounEnabled: boolean,
    isKeyboardEnabled: boolean,
    sound: MediaModel;
    video: MediaModel,
    image: MediaModel,
    content: ContentMediaModel,
    conversation: ExerciseConversationModel,
}>;

export type ExerciseConversationModel = Partial<{
    members: ExerciseMembersModel,
    chat: ExerciseChatModel[],
}>

export type ExerciseChatModel = Partial<{
    id: string,
    text: string,
    who: string, //members.memberId
    interaction: ExerciseDefinitionInteractionModel,

}>

export type ExerciseMembersModel = Partial<{
    [memberId: string]: ExerciseMemberInfoModel
}>
export type ExerciseMemberInfoModel = Partial<{
    name: string; isMainMember: boolean
}>

type ContentMediaModel = Partial<{
    title: string,
    description: string
}>

export type MediaModel = Partial<{
    resourceId: string, resourceUrl: string, subtitle: string
}>

export type ExerciseDefinitionInteractionModel = Partial<{
    type: ExerciseDefinitionInteractionType,
    choices: ExerciseChoicesModel[],
    orderItems: string[][],
    textSpeech: string[], //Pronounce answers and alternatives
    instruction: {
        title: string,
        description: string,
    };
}>;

export type ExerciseChoicesModel = Partial<{
    id: string, title: string, isCorrect: boolean
}>