import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BranchManager } from '@shared/models';
import { Avatar, Card, Tag } from 'antd';
import { useEffect } from 'react';

type BranchUserCardProps = {
  user: BranchManager;
  onDelete: (branchUser: BranchManager) => void;
  onEdit: (branchUser: BranchManager) => void;
};
export const BranchUserCard = ({ user, onDelete, onEdit }: BranchUserCardProps) => {
  return (
    <Card
      title={<Card.Meta className="flex items-center" avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />} title={[user.name, user.surname].join(' ')} />}
      extra={
        <div className="flex gap-2 items-center">
          {/* TODO : Icon button olacak */}
          <EditOutlined style={{ color: '#FAAD14' }} />
          <DeleteOutlined style={{ color: '#FF4D4F' }} />
        </div>
      }
    >
      <div className="grid grid-cols-[50px_1fr] items-center">
        <label className="font-medium">E-posta:</label>
        <span className="text-dark-gray text-xs ">{user.email}</span>

        <label className="font-medium ">Telefon numarası:</label>
        <span className="text-dark-gray text-xs ">{user.phone}</span>

        <label className="font-medium">Rolu:</label>
        <span className="text-dark-gray text-xs ">
          <div className="grid grid-cols-3">
            {Object.keys(user.permissions?.products!).map((k, i) => {
              return (
                <Tag key={i} color="success" className="text-center" icon={<CheckCircleOutlined />}>
                  {k}
                </Tag>
              );
            })}
          </div>
        </span>
      </div>
    </Card>
  );
};
