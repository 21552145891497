import { useFetch } from '@shared/hooks';
import { Account, PaginateModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  getAccounts: (config: UseFetchOptions) => useFetch<PaginateModel<Account>>('/account-management', { method: 'GET', ...config }),
  enableAccount: async (id) => request(`/account-management/${id}/enable`, 'GET'),
  disableAccount: async (id) => request(`/account-management/${id}/disable`, 'GET'),
  accountResetPassword: async (id) => request(`/account-management/${id}/create-reset-password-request`, 'GET'),
};
