import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone, CloseOutlined, ShareAltOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm, Spin } from "antd";
import { ExerciseOrPageHandler } from "../exercies/ExerciseOrPageHandler";
import { ContentTree, ContentVersionNavigation } from "@shared/models";
import { useEffect, useState } from "react";

type ReviewLearningContentVersionProps = {
    loading: boolean,
    contentNavigation?: ContentVersionNavigation,
    onPrevPage: () => void,
    onNextPage: () => void,
    onRejectPage: () => void,
    onApprovePageAndNext: () => void,
    onBack?: () => void,
    onPublish: () => void,
    onGoToPage:(pageId:string)=>void,
    isAdminCdn:boolean
}

export const ReviewLearningContentVersion = ({ loading, contentNavigation, onPrevPage, onNextPage, onRejectPage, onApprovePageAndNext, onPublish,onGoToPage, onBack,isAdminCdn }: ReviewLearningContentVersionProps) => {
    const [isPublished, setIsPublished] = useState<boolean>(false);

    useEffect(() => {
        if (contentNavigation) {
            const { tree } = contentNavigation;
            let approved = true;
            tree.forEach((t) => {
                if (approved) {
                    approved = t.approved;
                }
            });
            setIsPublished(approved);
        }
    }, [contentNavigation]);


    const renderUnitCard = (unit: ContentTree) => (
        <div className="flex flex-col p-1" key={unit.id}>
            <Card size="small" className={contentNavigation?.meta.unit.id == unit.id ? "bg-[#FCF8FF]" : unit.approved ? "bg-green-100" : "bg-red-100"}>
                <Card.Meta title={
                    <div className="flex flex-row justify-between">
                        <span className={contentNavigation?.meta.unit.id == unit.id ? "text-xs font-sans text-[#722ED1]" : "text-xs font-sans"}>{unit.name}</span>
                        {unit.approved ? <CheckCircleTwoTone twoToneColor="#198754" /> : <CloseCircleTwoTone twoToneColor="#dc3545" />}
                    </div>
                } />
            </Card>
            <div className="flex flex-col ml-2 ">
                {
                    unit.topics.map((t) => (
                        <div key={t.id}>
                            <Card
                                size="small"
                                className={contentNavigation?.meta.topic.id == t.id ? "bg-[#FCF8FF] mt-1  " : t.approved ? "bg-green-100 mt-1" : "bg-red-100 mt-1"}
                            >
                                <Card.Meta title={
                                    <div className="flex flex-row justify-between">
                                        <span className={contentNavigation?.meta.topic.id == t.id ? "text-xs font-sans text-[#722ED1]" : "text-xs font-sans"}>{t.name}</span>
                                        {t.approved ? <CheckCircleTwoTone twoToneColor="#198754" /> : <CloseCircleTwoTone twoToneColor="#dc3545" />}
                                    </div>
                                } />
                            </Card>
                            <div className="flex flex-col ml-3 ">
                                {
                                    t.pages.map((p) => (
                                        <Card size="small" hoverable onClick={()=>onGoToPage(p.id)} className={contentNavigation?.meta.pageId == p.id ? "bg-[#FCF8FF] mt-1" : p.approved ? "bg-green-100 mt-1" : "bg-red-100 mt-1"} key={p.id}>
                                            <Card.Meta title={
                                                <div className="flex flex-row justify-between">
                                                    <span className={contentNavigation?.meta.pageId == p.id ? "text-xs font-sans text-[#722ED1]" : "text-xs font-sans"}>Sayfa: {p.order + 1}</span>
                                                    {p.approved ? <CheckCircleTwoTone twoToneColor="#198754" /> : <CloseCircleTwoTone twoToneColor="#dc3545" />}
                                                </div>
                                            } />
                                        </Card>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );

    return (
        <div>
            <Spin spinning={loading} size="large" fullscreen  />
            <div className="flex flex-row justify-end space-x-2">
                {isPublished && <Popconfirm title="Yayınlamak istediğinizden emin misiniz?" description="Sistemi kullanan tüm markalara bu versiyon yayını bildirilecektir?" onConfirm={onPublish} okText="Evet" cancelText="Hayır"><Button type="dashed" size="small" icon={<ShareAltOutlined />}>Yayınla</Button></Popconfirm>}
                {
                    onBack && (<Button
                        type="primary"
                        ghost
                        size="small"
                        icon={<SnippetsOutlined />}
                        onClick={onBack}
                    >
                        Çalışmaya Dön
                    </Button>)
                }

            </div>
            <div className=" grid grid-cols-12">
                <div className=" col-span-3 flex flex-col overflow-x-scroll" style={{ height: "calc(100vh - 165px)" }}>
                    {contentNavigation?.tree.map((t) => (renderUnitCard(t)))}
                </div>
                {
                    contentNavigation?.page &&
                    <div className="col-span-9 flex flex-col items-center justify-center ">
                        <div style={{ height: "calc(100vh - 165px)" }}>
                            <ExerciseOrPageHandler page={contentNavigation?.page!} isAdminCdn={isAdminCdn} isPreview={true} isInClass={false} />
                        </div>
                        <div className="flex flex-row space-x-2 mt-2">
                            <Button type="primary" ghost size="small" icon={<ArrowLeftOutlined />} onClick={onPrevPage}>Prev</Button>
                            <Button danger ghost size="small" icon={<CloseOutlined />} onClick={onRejectPage}>Reddet</Button>
                            <Button type="primary" ghost size="small" icon={<CheckCircleOutlined />} onClick={onApprovePageAndNext} >Onayla ve Next</Button>
                            <Button type="primary" ghost size="small" icon={<ArrowRightOutlined />} onClick={onNextPage} >Next</Button>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}