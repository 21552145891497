export const productColors = {
  LMS: ['bg-[#FFFBE6]', 'border-[#FAAD14]', '#FA541C'],
  CRM: ['bg-[#F0F5FF]', 'border-[#2F54EB]', '#EB2F96'],
  HR: ['bg-[#FFF0F6]', 'border-[#EB2F96]', '#722ED1'],
  ACCOUNDING: ['bg-[#F0F5FF]', 'border-[#2F54EB]', '#2F54EB'],
};

export const LayoutColors = {
  LMS: {
    backgroundColor: '',
    color: '',
  },
  HR: { backgroundColor: '', color: '' },
  CRM: { backgroundColor: '', color: '' },
  brand_manager: { backgroundColor: '#E6F4FF', color: '#1677FF' },
  branch_manager: { backgroundColor: '#FFF0F6', color: '#EB2F96' },
  WELCOME_LAYOUT: { backgroundColor: '', color: '' },
  student: { backgroundColor: 'rgb(255 227 203)', color: '#FA541C', selectedBackground: '#FFBB9666' },
  teacher: { backgroundColor: 'rgb(255 227 203)', color: '#FA541C', selectedBackground: '#FFD4C1' },
  observer: { backgroundColor: '', color: '' },
};
