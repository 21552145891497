import { BirthdayCalendar, EmailSearch, NationalIdInput, PhoneInput } from '@shared/components/';
import { Gender } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { Observer } from '@shared/models';
import { Button, DatePicker, Form, Input, Radio } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type CreateGroupInfoStepProps = {
  value?: Observer;
  onSave: (observer: Observer) => void;
  onUpdate: (observer: Observer) => void;
  isPreview?: boolean;
};

const CreateObserverStepForm = z.object({
  name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Soyadı alanı zorunlu alandır' }),
  email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('This is not a valid email'),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  gender: z.string({ required_error: 'Lütfen cinsiyet seçiniz' }),
  nationalId: z.string({ required_error: 'Lütfen TC/Vergi No seçiniz' }),
  birthDate: z.string({ required_error: 'Lüften doğum günü seçiniz' }),
});

export const CreateGroupInfoStep = ({ value, onSave, onUpdate, isPreview = false }: CreateGroupInfoStepProps) => {

  const [isEmailSearch, setIsEmailSearch] = useState(false);
  const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);

  const form = useForm<
    Partial<{
      name: string;
      surname: string;
      email: string;
      'phone.code': string;
      'phone.number': string;
      gender: string;
      nationalId: string;
      birthDate: string;
    }>,
    Observer
  >(CreateObserverStepForm, {});

  useEffect(() => {
    if (value) {
      form.setInitialValue(value);
    }
    if(value?.id){
      setIsEmailSearch(true);
      setIsEmailSearchFound(true);
    }
  }, [value]);

  const onSearchResult = (data?: Observer) => {
    if (data) {
      form.setInitialValue({
        email: data.email,
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        gender: data.gender,
        nationalId: data.nationalId,
        birthDate: data.birthDate,
      });
      setIsEmailSearchFound(true);
    }else{
      setIsEmailSearchFound(false);
      form.setInitialValue({
        ...form.value,
        name: undefined,
        surname: undefined,
        phone: {
          code: undefined,
          number: undefined,
        },
        gender: undefined,
        nationalId: undefined,
        birthDate: undefined,
      });
    }
    setIsEmailSearch(true);
  };

  return (
    <div className="grid grid-cols-2">
      <Form layout="vertical">
        <div className="col-span-2">
          <EmailSearch form={form} disabled={isPreview || isEmailSearchFound} label="Yönetici E-posta" onSearchResult={onSearchResult} 
           onClear={()=>{
            form.setInitialValue({
              ...form.value,
              email: undefined,
              name: undefined,
              surname: undefined,
              phone: {
                code: undefined,
                number: undefined,
              },
              gender: undefined,
              nationalId: undefined,
              birthDate: undefined,
            });
            setIsEmailSearchFound(false);
            setIsEmailSearch(false);
          }} 
          
           />
        </div>
        <div className="grid grid-cols-2 gap-x-2">
          <Form.Item {...form.formItem('name')} label={'Yönetici Adı'}>
            <Input {...form.input('name')} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
          </Form.Item>
          <Form.Item {...form.formItem('surname')} label={'Yönetici Soyadı'}>
            <Input {...form.input('surname')} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
          </Form.Item>
        </div>

        <div className="grid grid-cols-2 gap-x-2">
          <PhoneInput form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
          <NationalIdInput form={form} label={'TC No'} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
        </div>

        <div className="grid grid-cols-2 gap-x-2">
          <Form.Item {...form.formItem('gender')} label={'Cinsiyet'}>
            <Radio.Group {...form.input('gender')} disabled={isPreview || !isEmailSearch || isEmailSearchFound}>
              <Radio value={Gender.FEMALE}>Kadın</Radio>
              <Radio value={Gender.MALE}>Erkek</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item {...form.formItem('birthDate')} label={'Doğum Tarihi'}>
            <BirthdayCalendar form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
          </Form.Item>
        </div>
      </Form>
      <div className="col-span-2 flex justify-end">
        <Button
          type="primary"
          onClick={() => {
            const result = form.parse();
            if (result.success) {
              const { data } = result;
              if (data.id) {
                onUpdate(data);
              } else {
                onSave({ ...data });
              }
              form.reset();
            } else {
              form.setAllTouched();
            }
          }}
        >
          Sonraki adım
        </Button>
      </div>
    </div>
  );
};
