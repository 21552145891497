import { useEffect, useState } from 'react';
import { AppNavigation } from './AppNavigation';
import { useAccessableContext, usePlatformLayoutContext } from '@shared/contexts';
import { LayoutColors, LayoutEnums } from '@shared/constants';
import * as API from '@shared/api';
import { Avatar, Card, Popover } from 'antd';
import { AppstoreOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { string } from 'zod';

export const AppNavigationView = () => {
  const { selectedLayout } = usePlatformLayoutContext();
  const { brand, branch } = useAccessableContext();
  const [title, setTitle] = useState<string>('');
  const [appNavigationStyle, setAppNavigationStyle] = useState<{ backgroundColor: string; color: string }>();
  const [subtitle, setSubttile] = useState<string>('');
  const [brands, brandsLoading, getBrands] = API.BRAND.accessableBrands({ init: true });
  const [branchs, branchLoading, getBranchs] = API.BRANCH.accessableBranches({ init: true });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (brands && branchs && brand) {
      const brandName = brands.data?.find((d) => {
        return d.id == brand?.id;
      })?.name;
      const branchName = branchs.data?.find((d) => {
        return d.id == branch?.id;
      })?.name;
      let _subTitle = '';
      if (brandName) {
        _subTitle = brandName;
      }
      if (branchName) {
        _subTitle = _subTitle + '/' + branchName;
      }
      setSubttile(_subTitle);
    }
  }, [brands, branchs, brand, branch]);

  useEffect(() => {
    if (selectedLayout) {
      setAppNavigationStyle(LayoutColors[selectedLayout]);
      switch (selectedLayout) {
        case LayoutEnums.BRANDMANAGER:
          setTitle('Marka yöneticisi');
          break;
        case LayoutEnums.BRANCHMANAGER:
          setTitle('Şube yöneticisi');
          break;
        case LayoutEnums.TEACHER:
          setTitle('Şube yöneticisi');
          break;
        case LayoutEnums.STUDENT:
          setTitle('Şube yöneticisi');
          break;
        default:
          setTitle('Hoşgeldiniz');
          setSubttile('Lütfen marka/şube seçiniz');
          break;
      }
    }
  }, [selectedLayout]);

  return (
    <div className="flex flex-row p-2">
      <div>
        <Popover
          content={
            <div className="w-[36rem]">
              <div className="flex flex-row justify-between">
                <span className="text-[16px] font-bold">Kullanıcı Seçiniz</span>
                <CloseCircleOutlined
                  style={{ fontSize: 20 }}
                  className="text-gray-400"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
              <AppNavigation />
            </div>
          }
          trigger={'hover'}
          open={open}
          onOpenChange={setOpen}
          placement="bottomRight"
        >
          <Avatar
            onClick={() => {
              setOpen(true);
            }}
            shape="square"
            size={'large'}
            icon={<AppstoreOutlined />}
            style={{ ...appNavigationStyle }}
          />
        </Popover>
      </div>
      <div className=" ml-2 grid grid-flow-row-dense mt-3 gap-2">
        <div className="font-bold">{title}</div>
        <div className="opacity-45">{subtitle}</div>
      </div>
    </div>
  );
};

//<AppNavigation />
