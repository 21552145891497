type TimeFormatterProps = {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  className?: string;
};

export const TimeFormatter = ({ startHour, startMinute, endHour, endMinute, className = '' }: TimeFormatterProps) => {
  const formatNumber = (number) => {
    return number < 10 ? `0${number}` : number;
  };

  const formatStartHour = formatNumber(startHour);
  const formatStartMinute = formatNumber(startMinute);
  const formatEndHour = formatNumber(endHour);
  const formatEndMinute = formatNumber(endMinute);

  return (
    <span className={className}>
      {formatStartHour}:{formatStartMinute} - {formatEndHour}:{formatEndMinute}
    </span>
  );
};
