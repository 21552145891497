import { ExerciseConversationModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";
import { DialogMessageBox } from "./DialogMessageBox";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { ExerciseDefinitionInteractionType } from "@shared/constants";
import { shuffleToArray } from "@shared/utils";
import { PronunciationVerifier } from "@shared/components/curriculum/PronunciationVerifier";

type ExerciseProps = {
    content: ExerciseDefinitionContentModel,
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isInClass:boolean;
}
export const DialogBase = ({ content, interaction, isPreview = false,isInClass }: ExerciseProps) => {

    const [conversation, setConversation] = useState<ExerciseConversationModel>({});
    const [chatIndex,setChatIndex] = useState<number>(0);

    useEffect(() => {
        if (content.conversation) {
            setConversation(content.conversation);
            if(isPreview){
                setChatIndex(content.conversation.chat?.length! -1);
            }
            /*setInterval(() => {
                if (content.conversation?.chat && content.conversation?.chat?.length > 0) {

                    let i = 0;
                    const _chat = content.conversation?.chat![i];
                    setConversation({
                        members: content.conversation?.members,
                        chat: [...(conversation?.chat ? conversation?.chat : []), { ..._chat }]
                    });
                    if (content.conversation?.chat?.length != i + 1) {
                        i = i + 1;
                    }
                }
            }, 300);*/
        }
    }, [content]);


    return (
        <>
            {
                isInClass ? 
                (<div className="flex flex-row h-full w-full bg-white pt-10 pb-10 p-5">
                    <div className={(conversation.chat && conversation.chat[chatIndex]?.interaction) ? "w-3/6 border-dashed border-1 border-mid-gray p-1" : "w-3/6"}>
                        <DialogMessageBox conversation={conversation} />
                    </div>
                    {
                        (conversation.chat && conversation.chat[chatIndex].interaction) ? (
                            <div className="w-3/6 mt-3 text-center flex flex-col justify-center space-y-6">
                                <span style={{fontWeight:'bold',color:'#722ED1'}}>Choose the correct option</span>
                                <div className="space-x-2 space-y-2 w-full items-center justify-center content-center">
                                    
                                    {
                                        
                                        conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.SINGLE_CHOICE ? (conversation.chat[chatIndex]?.interaction?.choices?.map((c)=>(<Button key={c.id} type="default">{c.title}</Button>))):(<></>)
                                    }
                                    {
                                        conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.MULTI_CHOICE ? (conversation.chat[chatIndex]?.interaction?.choices?.map((c)=>(<Button key={c.id} type="default">{c.title}</Button>))):(<></>)
                                    }
                                    {
                                        conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS ? (shuffleToArray(conversation.chat[chatIndex].interaction?.orderItems![0]!).map((c)=>(<Button type="default">{c}</Button>))):(<></>)
                                    }
                                    {
                                        conversation.chat[chatIndex]?.interaction.type == ExerciseDefinitionInteractionType.PRONOUNCE ? (<PronunciationVerifier onVerify={(e)=>{}} texts={conversation.chat[chatIndex]?.interaction?.textSpeech!}/>):(<></>)
                                    }
                                </div>
                            </div>
                        ) : (<></>)
                    }
        
                </div>)
                :
                (<div className="flex flex-col h-full w-full bg-white pt-10 pb-10 p-5">
                    <div className={(conversation.chat && conversation.chat[chatIndex]?.interaction) ? "h-4/6 w-full" : "w-full h-full"}>
                        <DialogMessageBox conversation={conversation} />
                    </div>
                    {
                        (conversation.chat && conversation.chat.length>0 && conversation.chat[chatIndex].interaction) ? (
                            <div className="h-2/6 mt-3 text-center w-full space-y-6">
                                <span style={{fontWeight:'bold',color:'#722ED1'}}>Choose the correct option</span>
                                <div className="space-x-2 space-y-2 w-full items-center justify-center content-center">
                                    
                                    {
                                        
                                        conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.SINGLE_CHOICE ? (conversation.chat[chatIndex]?.interaction?.choices?.map((c)=>(<Button key={c.id} type="default">{c.title}</Button>))):(<></>)
                                    }
                                    {
                                        conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.MULTI_CHOICE ? (conversation.chat[chatIndex]?.interaction?.choices?.map((c)=>(<Button key={c.id} type="default">{c.title}</Button>))):(<></>)
                                    }
                                    {
                                        conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS ? (shuffleToArray(conversation.chat[chatIndex].interaction?.orderItems![0]!).map((c)=>(<Button type="default">{c}</Button>))):(<></>)
                                    }
                                    {
                                        conversation.chat[chatIndex]?.interaction.type == ExerciseDefinitionInteractionType.PRONOUNCE ? (<PronunciationVerifier onVerify={(e)=>{}} texts={conversation.chat[chatIndex]?.interaction?.textSpeech!}/>):(<></>)
                                    }
                                </div>
                            </div>
                        ) : (<></>)
                    }
        
                </div>)
            }
        </>
        
    );
}


