import { NearTimeNotification } from '@shared/components/NearTimeNotification';
import { LayoutEnums, LocalStorageKeys } from '@shared/constants';
import { useAccountActivityContext, usePlatformLayoutContext, useUserContext } from '@shared/contexts';
import { useWebSocket } from '@shared/hooks';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { BranchManagerLayout } from './BranchManagerLayout';
import { BrandManagerLayout } from './BrandManagerLayout';
import { PlatformWelcomeLayout } from './PlatformWelcomeLayout';
import { StudentLayout } from './StudentLayout';
import { TeacherLayout } from './TeacherLayout';
import { UserNotificationContextProvider } from '@shared/contexts/UserNotificationContext';

export const PlatformLayoutHandler = ({ pages }) => {

  const { selectedLayout } = usePlatformLayoutContext();
  const { user, setUser } = useUserContext();
  const { teacherActivityList, setTeacherActivityList } = useAccountActivityContext();
  const [layout, setLayout] = useState<ReactElement>(<div></div>);

  useWebSocket("account", useMemo(() => {
    return {
      "accessables.changed": ({ data }) => {
        let _user = { ...user, features: data };
        setUser(_user);
        localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(_user));
      },
      'teacher.neartime.activity.created': ({ data }) => {
        setTeacherActivityList([...teacherActivityList, data]);
      },
      'teacher.neartime.activity.deleted': ({ data }) => {
        setTeacherActivityList(teacherActivityList.filter((t) => t.id != data.id))
      },
      'teacher.neartime.activity.list': ({ data }) => {
        setTeacherActivityList(data);
      },

    }
  }, [teacherActivityList, setTeacherActivityList]));


  useEffect(() => {
    switch (selectedLayout) {
      case LayoutEnums.BRANDMANAGER:
        setLayout(<BrandManagerLayout pages={selectedLayout == LayoutEnums.BRANDMANAGER ? pages : []} />);
        break;
      case LayoutEnums.BRANCHMANAGER:
        setLayout(<BranchManagerLayout pages={selectedLayout == LayoutEnums.BRANCHMANAGER ? pages : []} />);
        break;
      case LayoutEnums.STUDENT:
        setLayout(<StudentLayout pages={selectedLayout == LayoutEnums.STUDENT ? pages : []} />);
        break;
      case LayoutEnums.TEACHER:
        setLayout(<TeacherLayout pages={selectedLayout == LayoutEnums.TEACHER ? pages : []} />);
        break;
      default:
        setLayout(<PlatformWelcomeLayout pages={selectedLayout == LayoutEnums.WELCOME_LAYOUT ? pages : []} />);
        break;
    }
  }, [selectedLayout]);

  return (
    <UserNotificationContextProvider>
      <div>
        {
          teacherActivityList && (<div className='flex flex-col'>
            {
              teacherActivityList.map(
                (t) => (
                  <NearTimeNotification key={t.id} activity={t} />
                )
              )
            }
          </div>
          )
        }
        {layout}
      </div>
    </UserNotificationContextProvider>
  );
};
