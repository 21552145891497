//TODO servisler hazır oldugunda bu modeller düzenlenmeli
export type Product = Partial<{
  id: string;
  name: string;
  active: boolean;
  price: number;
  description: string;
  type: string;
  baseProperties: ProductBaseProperties;
  createdAt: string;
}>;

export type ProductBaseProperties = Partial<{
  perBranch: number;
}>;

export type EnableProductModel = Partial<{
  brandId: string;
  products: EnableProduct[];
}>;

export type EnableProduct = Partial<{
  productInitialId: string;
  properties: object;
  basePrice: number;
  price: number;
  discount: number;
  active: boolean;
  name: string;
  createdAt: string;
  type: string;
}>;
