import { HTMLAttributes } from 'react';

export interface SeanceDetailProps extends HTMLAttributes<HTMLElement> {
  seance?: any;
}

export const SeanceDetail = (props: SeanceDetailProps) => {
  let { seance, ...domProps } = props;

  return (
    <div {...domProps}>
      <h3>{seance.name}</h3>
    </div>
  );
};
