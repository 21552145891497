import { ClearOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ActionDropdown, EducationCascader } from "@shared/components";
import { CascaderOption, EducationLevel, ExerciseGroupModel, PaginateModel, PaginationQuery } from "@shared/models"
import { Button, Cascader, Input, Table, TableProps } from "antd"
import { useEffect, useState } from "react";

type ExerciseGroupsProps = {
    isLoading: boolean,
    groups?: PaginateModel<ExerciseGroupModel>,
    levels: CascaderOption[],
    onPaginationChange: (query: PaginationQuery) => void,
    onCreateRequest: () => void,
    onFork: (id: string) => void,
    onDelete: (id: string) => void,
    onEdit: (id: string) => void,
}
export const ExerciseGroups = ({ isLoading, groups, levels, onPaginationChange, onCreateRequest, onFork, onDelete, onEdit }: ExerciseGroupsProps) => {
    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });
    const [cascaderValue, setCascaderValue] = useState<(string | number)[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [isClearFilter, setIsClearFilter] = useState<boolean>(false);

    useEffect(() => {
        onPaginationChange(paginationQuery);
    }, [paginationQuery]);

    useEffect(() => {
        if (cascaderValue.length == 0 && !searchText) {
            setIsClearFilter(false);
            onPaginationChange({ ...paginationQuery, page: 1, levelIds: [], name: "" });
        } else {
            setIsClearFilter(true);

        }
    }, [cascaderValue, searchText])

    const title = (
        <div className="flex flex-row justify-between">
            <div className="flex space-x-2 items-center">
                <span className="font-bold flex min-w-max">Egzersiz Grupları</span>
                <Input.Search
                    className="w-full"
                    placeholder="Ada göre ara"
                    value={searchText}
                    onSearch={
                        (v) => {
                            setPaginationQuery({ ...paginationQuery, page: 1, name: v })
                        }
                    }
                    onChange={(v) => {
                        setSearchText(v.target.value);
                    }} />
                <Cascader
                    loading={isLoading}
                    value={cascaderValue}
                    expandTrigger="hover"
                    options={levels}
                    onChange={(i) => {
                        setCascaderValue(i);
                        if(i.length>0){
                            setPaginationQuery({ ...paginationQuery, page: 1, levelIds: [i[i.length-1].toString()] });
                        }else{
                            setPaginationQuery({ ...paginationQuery, page: 1, levelIds: [] });
                        }
                    }}
                    placeholder="Kura göre ara"
                />
                {
                    isClearFilter && <Button type="dashed" icon={<ClearOutlined />}
                        onClick={() => {
                            setSearchText("");
                            setCascaderValue([]);
                            setPaginationQuery({ ...paginationQuery, page: 1, name: "", levelIds: [] });
                        }}>
                        Filtreyi Temizle
                    </Button>
                }

            </div>
            <Button
                icon={<PlusCircleOutlined />}
                onClick={() => {
                    onCreateRequest();
                }}
                type="dashed"
            >
                Yeni Egzersiz Group Oluştur
            </Button>
        </div>
    );

    const columns: TableProps<ExerciseGroupModel>['columns'] = [
        {
            title: 'Adı',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            align: 'center',
        },
        {
            title: 'Egzersiz Sayısı',
            dataIndex: 'exerciseCount',
            align: 'center',
        },

        {
            title: 'Aksiyon',
            key: 'action',
            className: 'text-center',
            render: (_, record) => (
                <ActionDropdown
                    data={record}
                    items={[
                        {
                            key: "clone",
                            label: 'Egzersizi kopyala ve çalımaya başla',
                            onClick: () => {
                                onFork(record.id!);
                            },
                        }
                    ]}
                    onEdit={({ id }) => {
                        onEdit(id);
                    }}
                    onDelete={({ id }) => {
                        onDelete(id);
                    }}
                />
            ),
        },
    ];
    return (
        <>
            <Table
                loading={isLoading}
                rowKey="id"
                pagination={{
                    total: groups?.count,
                    pageSize: paginationQuery.limit,
                    position: ['bottomCenter'],
                }}
                columns={columns}
                dataSource={groups?.data}
                bordered
                title={() => title}
                onChange={(pagination) => setPaginationQuery((prev) => ({ ...prev, page: pagination.current }))}
            />
        </>
    )
}