import { Form, Input } from 'antd';
import { ReactNode } from 'react';

type TextInputProps = {
  formItem: any;
  formInput: any;
  disabled?: boolean;
  label?: ReactNode;
  maxLength?: number;
  classname?: string;
};

export const TextInput = ({ formItem, formInput, disabled = false, label, maxLength = 25, classname }: TextInputProps) => {
  return (
    <Form.Item {...formItem} label={label}>
      <Input className={classname} maxLength={maxLength} {...formInput} disabled={disabled} />
    </Form.Item>
  );
};
