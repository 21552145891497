import { ExerciseChoicesModel, ExerciseDefinitionModel, ImageCdnModel } from "@shared/models";
import { Button, Form, Input, Radio, Upload } from "antd";
import { useCallback, useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { layoutToContentType, uuid } from "@shared/utils";
import { ImageUpload } from "../../../ImageUpload";
import { ExerciseDefinitionInteractionType, ExerciseLayoutType } from "@shared/constants";

type CreateExerciseProps = {
    item: ExerciseDefinitionModel,
    onSave: (item: ExerciseDefinitionModel) => void;
    onChange: (item: ExerciseDefinitionModel) => void;
    isAdminCdn:boolean
}
export const CreateVisualSingleSelect = ({ item, onSave, onChange,isAdminCdn }: CreateExerciseProps) => {

    const [choiceName, setChoiceName] = useState<string>();
    const [isMediaLoading,setIsMediaLoading] = useState<boolean>(false);

    const [title,setTitle] = useState<string>();
    const [description,setDescription] = useState<string>();
    const [image,setImage] = useState<ImageCdnModel>();
    const [choices,setChoices] = useState<ExerciseChoicesModel[]>([]);


    useEffect(() => {
        setTitle(item.content?.content?.title);
        setDescription(item.content?.content?.description);
        setImage(item.content?.image?.resourceUrl ? {id: item.content?.image?.resourceId, variants: [item.content?.image?.resourceUrl]} : undefined);
        setChoices(item.interaction?.choices ?? []);
    }, [item.id]);

    useEffect(()=>{
        if(title || description || image || choices.length > 0){
            const i = {
                ...item,
                layout: ExerciseLayoutType.VISUAL_SINGLE_SELECT,
                content: {
                    ...item.content,
                    content: {
                        ...(item.content ? item.content.content : {}),
                        title: title,
                        description: description
                    },
                    image: image ? {resourceId: image.id, resourceUrl: image.variants![0]} : undefined,
                    type: layoutToContentType(ExerciseLayoutType.VISUAL_SINGLE_SELECT)
                },
                interaction: {
                    ...item.interaction,
                    choices: choices,
                    type: ExerciseDefinitionInteractionType.SINGLE_CHOICE
                }
            }as ExerciseDefinitionModel;
            onChange(i);
        }
    },[title,description,image,choices]);

    const send = useCallback(()=>{
        const i = {
            ...item,
            layout: ExerciseLayoutType.VISUAL_SINGLE_SELECT,
            content: {
                ...item.content,
                content: {
                    ...(item.content ? item.content.content : {}),
                    title: title,
                    description: description
                },
                image: image ? {resourceId: image.id, resourceUrl: image.variants![0]} : undefined,
                type: layoutToContentType(ExerciseLayoutType.VISUAL_SINGLE_SELECT)
            },
            interaction: {
                ...item.interaction,
                choices: choices,
                type: ExerciseDefinitionInteractionType.SINGLE_CHOICE
            }
        }as ExerciseDefinitionModel;
        onSave(i);
    },[title,description,image,choices]);


    return (
        <div className="p-5">
            <div className="flex justify-end">
            <Button type="primary" disabled={isMediaLoading} size="small" onClick={send}>{item.id ? "Güncelle":"Ekle"}</Button>
            </div>
                <div className="flex flex-col">
                    <div className="items-center content-center justify-center">
                        <ImageUpload
                            image={image ?? {}}
                            onChange={(image) => {
                                setImage(image);
                            }}
                            onChangeLoading={setIsMediaLoading}
                            isAdminCdn={isAdminCdn}
                        />
                    </div>
                    <div>
                        <Form layout="vertical" className="grid grid-cols-3 gap-2">
                            <Form.Item label={"Title"}>
                                <Input
                                    value={title}
                                    disabled={isMediaLoading}
                                    onChange={(e) => {
                                       setTitle(e.target.value);
                                    }} />
                            </Form.Item>
                            <Form.Item label={"Subtitle"}>
                                <Input
                                    value={description}
                                    disabled={isMediaLoading}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <Form layout="vertical">
                        <div className="flex flex-row gap-x-2">
                            <Form.Item key={"cmkdvkmsdklvmlk"}>
                                <Input placeholder="Seçenegin adı" disabled={isMediaLoading} value={choiceName} onChange={(e) => {
                                    setChoiceName(e.target.value);
                                }} />
                            </Form.Item>
                            <Button 
                            disabled={isMediaLoading}
                            onClick={() => {

                                if (choiceName) {
                                    setChoices([...choices, { id: uuid(), title: choiceName, isCorrect: false }]);
                                    setChoiceName("");
                                }
                            }} icon={<PlusCircleOutlined />} />
                        </div>
                        <div className="grid grid-cols-3 gap-1 ">
                            {
                                choices?.map((c) => (
                                    <Input disabled={isMediaLoading} key={c.id + "ufsjdnfsdjn"} value={c.title} onChange={(e) => {
                                        const index = choices.findIndex((t) => t.id == c.id);
                                        if (index != undefined && index > -1) {
                                            const _choices = [...(choices ? choices : [])];
                                            _choices![index] = {
                                                id: c.id,
                                                isCorrect: c.isCorrect,
                                                title: e.target.value
                                            };
                                            setChoices(_choices);
                                        }
                                    }} />
                                ))
                            }

                        </div>

                        <div>
                            <p>Seçeneklerin hangisi doğru</p>
                            <div className="grid grid-cols-3 gap-1">
                                <Radio.Group
                                disabled={isMediaLoading}
                                    value={choices?.find((c) => c.isCorrect)?.id}
                                    onChange={(e) => {
                                        const _targetId = e.target.id;
                                        if (_targetId) {
                                            const index = choices?.findIndex((t) => t.id == _targetId);
                                            if (index != undefined && index > -1) {
                                                const _choices = [...(choices ? choices : [])].map((c) => ({ ...c, isCorrect: false }));
                                                const _findObj = {
                                                    ..._choices[index]
                                                }
                                                _choices![index] = {
                                                    id: _targetId,
                                                    isCorrect: e.target.checked,
                                                    title: _findObj.title
                                                };

                                                setChoices(_choices);
                                            }
                                        }
                                    }}
                                    options={
                                        choices?.map((c) => (
                                            { id: c.id, label: c.title!, value: c.id! }
                                        ))
                                    }
                                />
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
    )
}