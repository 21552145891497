import { CascaderOption } from '@shared/models';
import { Cascader } from 'antd';

type EducationCascaderProps = {
    items?: CascaderOption[];
    value?:(string | number)[];
    loading:boolean;
    disabled?:boolean;
    onChange: (value: (string | number)[]) => void;
  };
export const EducationCascader = ({items,value,loading,disabled=false,onChange}:EducationCascaderProps) => {

    return (<Cascader value={value} disabled={disabled} options={items} expandTrigger="hover" style={{width:'100%'}} loading={loading} onChange={onChange} placeholder="Please select" />);
}