import { useFetch } from "@shared/hooks";
import { CourseTopicDefinetion, CourseTopicPageDefinetion, CourseTopicPageModel, CourseTopicPageUpdateModel, PaginateModel, UseFetchOptions } from "@shared/models";
import { request } from "@shared/utils";
export default {
    create : async (versionId:string,unitId:string,topicId:string,page:CourseTopicPageDefinetion)=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page','POST',page),
    get:async(versionId:string,unitId:string,topicId:string,pageId:string)=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page/'+pageId,'GET'),
    fetchAll:(config:UseFetchOptions) => useFetch<PaginateModel<CourseTopicDefinetion>>('',{method:'GET',...config}),
    getPageByVersionIdAndUnitIdAndTopicId : async (versionId:string,unitId:string,topicId:string)=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page/list','GET'),
    order:async (versionId:string,unitId:string,topicId:string,order:CourseTopicPageModel[])=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page/order','PUT',order),
    delete:async (versionId:string,unitId:string,topicId:string,pageId:string)=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page/'+pageId,'DELETE'),
    update:async(versionId:string,unitId:string,topicId:string,data:CourseTopicPageUpdateModel)=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page','PUT',data),
    
    approve:async(versionId:string,unitId:string,topicId:string,pageId,reviewNote?:{reviewedNote:string})=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page/'+pageId+'/approve','PUT',reviewNote),
    reject:async(versionId:string,unitId:string,topicId:string,pageId,reviewNote:{reviewedNote:string})=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId+'/page/'+pageId+'/reject','PUT',reviewNote),
}