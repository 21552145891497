import { PlayCircleOutlined } from '@ant-design/icons';
import { ExerciseLayoutType } from '@shared/constants';
import { Skeleton, Tag, Image } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};

export const ListiningBaseSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.LISTENING_BASE);
      }}
    >
      <Tag bordered={false}>Basit</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Dinleme/Dış/ses-transkript.png')} />
      </div>
    </div>
  );
};

export const ListiningSingilSelectSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.LISTENING_SINGLE_SELECT);
      }}
    >
      <Tag bordered={false}>Tekli Seçim</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Dinleme/Dış/tekli-secim.png')} />
      </div>
    </div>
  );
};

export const ListiningMultiSelectSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.LISTENING_MULTI_SELECT);
      }}
    >
      <Tag bordered={false}>Çoklu Seçim</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Dinleme/Dış/coklu-secim.png')} />
      </div>
    </div>
  );
};
