import { ExerciseLayoutType } from '@shared/constants';
import { Skeleton, Image, Tag } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};

export const GapFillingSelectedSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.GAP_FILLING_SELECT);
      }}
    >
      <Tag bordered={false}>Cümle Oluşturma</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Boşluk Doldurma/Dış/bosluk_doldurma.png')} />
      </div>
    </div>
  );
};

export const GapFillingKeyboardSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.GAP_FILLING_KEYBOARD);
      }}
    >
      <Tag bordered={false}>Klavye Doldurma</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Boşluk Doldurma/Dış/typing.png')} />
      </div>
    </div>
  );
};
