import { FullscreenOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ClassCalendarEntryType } from '@shared/constants';
import { ClassCalendarModel } from '@shared/models';
import { Badge, Calendar, Modal, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

type ClassCalendarProps = {
  calendarData: ClassCalendarModel[] | undefined;
};

export const ClassCalendar = ({ calendarData }: ClassCalendarProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Dayjs>(dayjs());

  const calendarHeader = (value, onChange) => {
    const currentMonth = value.format('MMMM YYYY');
    const handlePrevMonth = () => {
      const newValue = value.clone().subtract(1, 'month');
      onChange(newValue);
      setValue(newValue);
    };

    const handleNextMonth = () => {
      const newValue = value.clone().add(1, 'month');
      onChange(newValue);
      setValue(newValue);
    };

    return (
      <div className="flex justify-between items-center p-2">
        <LeftOutlined className=" cursor-pointer" onClick={handlePrevMonth} />
        <span className=" font-bold">{currentMonth}</span>
        <div className="flex gap-x-2">
          <RightOutlined className="cursor-pointer" onClick={handleNextMonth} />
          <FullscreenOutlined className="cursor-pointer" onClick={() => setIsModalOpen(true)} />
        </div>
      </div>
    );
  };

  const cellRender = (date) => {
    const currentDate = date.toDate();
    const event = calendarData?.find((event) => dayjs(event.at).isSame(currentDate, 'day'));

    //Eğer o tarihte event varsa borderlı şekilde gösteriyoruz
    if (event && dayjs(event.at).isSame(date, 'day')) {
      return (
        <div className="flex h-full justify-center items-center border-solid rounded-[4px] border-blue-400 ml-1">
          <span className="font-bold">{date.date()}</span>{' '}
        </div>
      );
    }

    // O tarihte bir event yoksa normal gösteriyoruz.
    return <div className="flex justify-center items-center h-full">{date.date()}</div>;
  };

  //Modal'da açılan takvimde eğer eventler ile o günkü tarih eşleşiyorsa o tarihte Tag olarak eventi gösteriyoruz.
  const renderModal = (value) => {
    const currentDate = value.toDate();
    const dayEvents = calendarData?.filter((event) => dayjs(event.at).isSame(currentDate, 'day'));

    return (
      <div>
        {dayEvents?.map((event, index) => (
          <Tag className="w-11/12 whitespace-nowrap overflow-hidden text-ellipsis" color={getColor(event)} key={event.id}>
            {event.name}
          </Tag>
        ))}
      </div>
    );
  };

  const modalHeader = (value, onChange) => {
    const currentMonth = value.format('MMMM YYYY');
    const handlePrevMonth = () => {
      const newValue = value.clone().subtract(1, 'month');
      onChange(newValue);
      setValue(newValue);
    };

    const handleNextMonth = () => {
      const newValue = value.clone().add(1, 'month');
      onChange(newValue);
      setValue(newValue);
    };

    return (
      <div className="flex justify-center gap-5 items-center p-2 text-xl">
        <LeftOutlined className=" cursor-pointer" onClick={handlePrevMonth} />
        <span>{currentMonth}</span>
        <div className="flex">
          <RightOutlined className="cursor-pointer" onClick={handleNextMonth} />
        </div>
      </div>
    );
  };

  const getColor = (event: ClassCalendarModel) => {
    if (event.type === ClassCalendarEntryType.EVENT) return 'blue';
    if (event.type === ClassCalendarEntryType.IN_CLASS) return 'magenta';
    if (event.type === ClassCalendarEntryType.HOME_WORK) return 'green';

    return 'default';
  };

  return (
    <>
      <Calendar className="mt-2" fullscreen={false} headerRender={({ value, onChange }) => calendarHeader(value, onChange)} fullCellRender={cellRender} value={value} />

      <Modal title={<span className="font-bold text-xl">Sınıf Takvimi</span>} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)} width={1100}>
        <Calendar headerRender={({ value, onChange }) => modalHeader(value, onChange)} cellRender={renderModal} mode="month" value={value} />
      </Modal>
    </>
  );
};
