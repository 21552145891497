import { DeleteOutlined, SwapOutlined } from '@ant-design/icons';
import { ClassRoomModel, PrePlacementDemand, SessionDefinitionsModel, TeacherModel } from '@shared/models';
import { Button, Tooltip } from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import { HTMLAttributes, useEffect, useMemo } from 'react';
import { InfoPopever } from './InfoPopever';

const statusBadge: Record<string, PresetStatusColorType> = {
  SUCCESS: 'processing',
  ERROR: 'error',
  WARNING: 'warning',
};

export interface PlanBlockProps extends HTMLAttributes<HTMLElement> {
  variant: 'drop' | 'drag';
  status?: string;
  tooltip?: string;
  onDelete?: VoidFunction;
  onDetail?: VoidFunction;
  hideInfo?: boolean;
  disabled?: boolean;
  type?: 'STUDENT' | 'TEACHER' | 'SEANCE' | 'ROOM';
  student?: PrePlacementDemand;
  teacher?: TeacherModel;
  seance?: SessionDefinitionsModel;
  room?: ClassRoomModel;
}

export const PlanBlock = (props: PlanBlockProps) => {
  const { variant, onDelete, onDetail, hideInfo, disabled, tooltip, status = 'SUCCESS', ...domProps } = props;

  const tooltipTitle = useMemo(() => {
    if (variant === 'drop' && status !== 'SUCCESS' && tooltip) {
      return <span className="text-black">{tooltip}</span>;
    }

    return null;
  }, [variant, status, tooltip]);

  return (
    <Tooltip title={tooltipTitle} color="white">
      <div {...domProps} className={`planblock ${variant} ${disabled && 'disabled'}`} data-status={status}>
        <div>
          {variant === 'drop' && <Button disabled={disabled} size="small" type="text" onClick={onDelete} icon={<DeleteOutlined />} />}
          {variant === 'drag' && <SwapOutlined className="flex items-center justify-center w-[32px] py-[4px]" />}
        </div>

        <span className="flex-1 text-[12px] overflow-hidden text-ellipsis whitespace-nowrap select-none">{domProps.children}</span>
        <div className="ml-0.5 mr-1">
          {/* <Badge status={disabled && status === 'SUCCESS' ? 'default' : statusBadge[status]} /> */}

          {!hideInfo && (
            <InfoPopever
              key={Math.random().toString(36).substring(7) + props.teacher?.id}
              type={props.type!}
              student={props.student!}
              teacher={props.teacher!}
              room={props.room}
              seance={props.seance}
            />
          )}
        </div>
      </div>
    </Tooltip>
  );
};

/*
<Button data-no-dnd="true" disabled={disabled} type="text" onClick={onDetail} icon={<InfoCircleOutlined />} />
*/
