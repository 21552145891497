import { LearningContentDraftVersion } from "@shared/components";
import { CourseTopicPageType, LayoutEnums, Role } from "@shared/constants";
import { CourseTopicDefinetion, CourseTopicModel, CourseTopicPageModel, CourseUnitDefinetion, CourseUnitModel, CourseVersionDefination, CourseVersionModel, ExerciseGroupModel } from "@shared/models";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as API from '@shared/api';
import { notification } from "antd";
import { useAccessableSeachParams } from "@shared/hooks";

export const BrandDraftVersion = () => {
    useAccessableSeachParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRAND.useFetchField({ init: true });
    const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRAND.useFetchType({ init: true });
    const [themes, themesLoading, getThemes] = API.EDUCATION_LEVEL.BRAND.useFetchTheme({ init: true });
    const [levels, levelsLoading, getLevels] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: true });

    const [selectedUnit, setSelectedUnit] = useState<CourseUnitModel>();
    const [selectedTopic, setSelectedTopic] = useState<CourseTopicModel>();
    const [selectedPage, setSelectedPage] = useState<CourseTopicPageModel>();

    const [version, setVersion] = useState<CourseVersionModel>();
    const [units, setUnits] = useState<CourseUnitModel[]>([]);
    const [topics, setTopics] = useState<CourseTopicModel[]>([]);
    const [pages, setPages] = useState<CourseTopicPageModel[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [exerciseGroups, exerciseGroupsLoading, getExerciseGroups] = API.CURRICULUM.BRAND_EXERCISE.fetchAllExerciseGroup({ init: false });

    useEffect(() => {
        if (searchParams.size > 0) {
            const versionId = searchParams.get('versionId');
            if (versionId) {
                getVersion(versionId);
            }
        }
    }, [searchParams]);



    useEffect(() => {
        if (version) {
            getUnitByVersionId();
        }
    }, [version]);

    useEffect(() => {
        if (selectedUnit) {
            getTopicsByUnitId();
        }
    }, [selectedUnit]);

    useEffect(() => {
        if (selectedTopic) {
            getPageByTopicId();
        }
    }, [selectedTopic]);

    useEffect(() => {
        if (selectedPage && selectedPage.type == CourseTopicPageType.EXERCISE && selectedPage.exercise && selectedPage.exercise.id) {
            getExerciseGroupDetail(selectedPage.exercise.id);
        }
    }, [selectedPage]);


    const getVersion = async (id) => {
        setLoading(true);
        try {
            const { data } = await API.CURRICULUM.BRAND_COURSE_VERSION.get(id);
            setVersion(data);
        } catch (error) {
            notification.error({ message: 'Version getirilirken bir hata oluştu!' });
        } finally {
            setLoading(false);
        }
    };



    const getUnitByVersionId = async () => {
        if (version) {
            const response = await API.CURRICULUM.BRAND_COURSE_UNIT.getByVersionId(version.id!);
            setUnits(response.data);
            if (response.data.length > 0) {
                const firstElement = response.data[0];
                setSelectedUnit(firstElement);
            } else {
                setTopics([]);
                setPages([]);
                setSelectedPage(undefined);
            }
        }
    };

    const getTopicsByUnitId = async () => {
        if (version && selectedUnit) {
            const response = await API.CURRICULUM.BRAND_COURSE_TOPIC.getTopicsByVersionIdAndUnitId(version.id!, selectedUnit.id!);
            setTopics(response.data);
            if (response.data.length > 0 && !selectedTopic) {
                const firstElement = response.data[0];
                setSelectedTopic(firstElement);
            } else {
                setPages([]);
                setSelectedPage(undefined);
            }
        } else {
            notification.warning({ message: 'Lütfen version ve unite seçiniz' });
        }
    };
    const getPageByTopicId = async () => {
        if (version && selectedUnit && selectedTopic) {
            const response = await API.CURRICULUM.BRAND_COURSE_PAGE.getPageByVersionIdAndUnitIdAndTopicId(version.id!, selectedUnit.id!, selectedTopic.id!);
            setPages(response.data);
            if (response.data.length > 0 && !selectedPage) {
                getSelectedPageDetail(response.data[0].id);
            }
        } else {
            notification.warning({ message: 'Lütfen version, unite ve topic seçiniz' });
        }
    };

    const getSelectedPageDetail = async (id: string) => {
        if (version && selectedUnit && selectedTopic) {
            try {
                const responce = await API.CURRICULUM.BRAND_COURSE_PAGE.get(version.id!, selectedUnit.id!, selectedTopic.id!, id);
                setSelectedPage(responce.data);
            } catch (error) { }
        } else {
            notification.warning({ message: 'Lütfen version, unite ve topic seçiniz' });
        }
    };

    const onCreateVersionMeta = async (meta: CourseVersionDefination) => {
        try {
            const response = await API.CURRICULUM.BRAND_COURSE_VERSION.create(meta);
            setVersion(response.data);
            notification.success({ message: 'Version oluşturuldu' });
        } catch (error) {
            notification.error({ message: 'Version oluşturulurken bir hata oluştu!' });
        }
    }

    const onUpdateVersionMeta = async (meta: CourseVersionDefination) => {
        try {
            await API.CURRICULUM.BRAND_COURSE_VERSION.update({ ...meta });
            getVersion(meta.id);
            notification.success({ message: 'Version bilgileri güncellendi' });
        } catch (error) {
            notification.error({ message: 'Version bilgileri güncellenirken bir hata oluştu!' });
        }
    }

    const onOrderUnit = async (orders: CourseUnitModel[]) => {
        const data = orders.map((o) => {
            return {
                id: o.id,
                order: o.order,
            };
        });

        if (version && selectedUnit && data && data.length > 0) {
            await API.CURRICULUM.BRAND_COURSE_UNIT.order(version.id!, data);
            getUnitByVersionId();
        }
    };

    const createUnit = async (unit: CourseUnitDefinetion) => {
        if (version) {
            try {
                await API.CURRICULUM.BRAND_COURSE_UNIT.create(version.id!, {
                    ...unit,
                    order: units.length,
                } as CourseUnitDefinetion);
                notification.success({ message: 'Unit eklendi!' });
            } catch (error) {
                notification.error({ message: 'Unit eklenirken bir hata oluştu!' });
            } finally {
                getUnitByVersionId();
            }
        }
    };

    const deleteUnit = async (id: string) => {
        if (version) {
            try {
                await API.CURRICULUM.BRAND_COURSE_UNIT.delete(version.id!, id);
                notification.success({ message: 'Unit silindi!' });
                if (selectedUnit && selectedUnit.id == id) {
                    setSelectedUnit(undefined);
                }
            } catch (error) {
                notification.error({ message: 'Unit silinirken bir hata oluştu!' })
            } finally {
                getUnitByVersionId();
            }
        }
    }


    const updateUnit = async (unit: CourseUnitModel) => {
        if (version) {
            try {
                await API.CURRICULUM.BRAND_COURSE_UNIT.update(version.id!, {
                    id: unit.id,
                    name: unit.name,
                    description: unit.description,
                } as CourseUnitModel);
                notification.success({ message: 'Unit güncellendi!' });
            } catch (error) {
                notification.error({ message: 'Unit güncellenirken bir hata oluştu!' });
            } finally {
                getUnitByVersionId();
            }
        }
    }


    const onOrderTopic = async (orders: CourseTopicModel[]) => {
        const data = orders.map((o) => {
            return {
                id: o.id,
                order: o.order,
            };
        });

        if (version && selectedUnit && data && data.length > 0) {
            await API.CURRICULUM.BRAND_COURSE_TOPIC.order(version.id!, selectedUnit.id!, data);
            getTopicsByUnitId();
        }
    };


    const createTopic = async (topic: CourseTopicDefinetion) => {
        if (version && selectedUnit) {
            try {
                await API.CURRICULUM.BRAND_COURSE_TOPIC.create(version.id!, selectedUnit.id!, {
                    ...topic,
                    order: topics.length,
                } as CourseTopicDefinetion);
                notification.success({ message: 'Topic eklendi!' });
            } catch (error) {
                notification.error({ message: 'Topic eklenirken bir hata oluştu!' });
            } finally {
                getTopicsByUnitId();
            }
        } else {
            notification.warning({ message: 'Version ve unite seçiniz' });
        }
    };

    const deleteTopic = async (id: string) => {
        if (version && selectedUnit) {
            if (selectedTopic && selectedTopic.id == id) {
                setSelectedTopic(undefined);
            }
            try {
                await API.CURRICULUM.BRAND_COURSE_TOPIC.delete(version.id!, selectedUnit.id!, id);
                notification.success({ message: 'Topic silindi!' });
                if (selectedTopic && selectedTopic.id == id) {
                    setSelectedTopic(undefined);
                }
            } catch (error) {
                notification.error({ message: 'Topic silinirken bir hata oluştu!' })
            } finally {
                getTopicsByUnitId();
            }
        }
    }

    const updateTopic = async (topic: CourseTopicModel) => {
        if (version && selectedUnit) {
            try {
                await API.CURRICULUM.BRAND_COURSE_TOPIC.update(version.id!, selectedUnit.id!, {
                    id: topic.id,
                    name: topic.name,
                    description: topic.description,
                    type: topic.type,
                } as CourseTopicModel);
                notification.success({ message: 'Topic güncellendi!' });
            } catch (error) {
                notification.error({ message: "Topic güncellenirken bir hata oluştu!" });
            } finally {
                getTopicsByUnitId();
            }
        }
    };


    const onOrderPage = async (orders: CourseTopicPageModel[]) => {
        const data = orders.map((o) => {
            return {
                id: o.id,
                order: o.order,
            };
        });
        if (version && selectedUnit && selectedTopic && data && data.length > 0) {
            await API.CURRICULUM.BRAND_COURSE_PAGE.order(version.id!, selectedUnit.id!, selectedTopic.id!, data);
            getPageByTopicId();
        }
    };

    const createPageRequest = async () => {
        if (version && selectedUnit && selectedTopic) {
            try {
                setSelectedPage(undefined);
                const responce = await API.CURRICULUM.BRAND_COURSE_PAGE.create(version.id!, selectedUnit.id!, selectedTopic.id!, { content: {}, order: pages.length });
                setSelectedPage(responce.data);
            } catch (error) {
                notification.error({ message: 'Yeni Page oluşturulurken bir hata oluştu!' });
            } finally {
                getPageByTopicId();
            }

        } else {
            notification.warning({ message: 'Lütfen version, unite ve topic seçiniz' });
        }
    };

    const updatePage = async () => {
        if (version && selectedUnit && selectedTopic && pageValidation()) {
            try {
                let _page = {};
                if (selectedPage?.type == CourseTopicPageType.EXERCISE) {
                    _page = {
                        ...selectedPage,
                        exerciseId: selectedPage?.exercise?.id
                    }
                } else {
                    _page = {
                        ...selectedPage,
                    }
                }
                await API.CURRICULUM.BRAND_COURSE_PAGE.update(version.id!, selectedUnit.id!, selectedTopic.id!, _page);
                notification.success({ message: 'Page güncellendi!' });
            } catch (error) {
                notification.error({ message: 'Page güncellenirken bir hata oluştu!' });
            } finally {
                getPageByTopicId();
            }

        } else {
            notification.warning({ message: 'Version, unite ve topic seçiniz' });
        }
    };

    const pageValidation = () => {
        if (selectedPage?.type) {
            if (selectedPage.type == CourseTopicPageType.EXERCISE) {
                if (selectedPage.exercise) {
                    return true;
                } else {
                    notification.warning({ message: 'Lütfen egzersizi seçiniz' });
                    return false;
                }
            } else if (selectedPage.type == CourseTopicPageType.TEXT) {
                if (selectedPage.content?.text) {
                    return true;
                } else {
                    notification.warning({ message: 'Lütfen text içeriği tamamlayınız' });
                    return false;
                }
            } else if (selectedPage.type == CourseTopicPageType.IMAGE) {
                if (selectedPage.content?.image) {
                    return true;
                } else {
                    notification.warning({ message: 'Lütfen resmi yükleyiniz!' });
                    return false;
                }
            } else if (selectedPage.type == CourseTopicPageType.VIDEO) {
                if (selectedPage.content?.video) {
                    return true;
                } else {
                    notification.warning({ message: 'Lütfen video yükleyiniz!' });
                    return false;
                }
            } else if (selectedPage.type == CourseTopicPageType.DOWNLOADABLE) {
                notification.warning({ message: 'Şimdilik dosya paylaşım sistemi mevcut değil!' });
                return false;
            }
        } else {
            notification.warning({ message: 'Lütfen sayfa tipi seçiniz' });
            return false;
        }
    };


    const deletePage = async (id: string) => {
        if (version && selectedUnit && selectedTopic) {
            try {
                await API.CURRICULUM.BRAND_COURSE_PAGE.delete(version.id!, selectedUnit.id!, selectedTopic.id!, id);
                if (selectedPage && selectedPage.id == id) {
                    setSelectedPage(undefined);
                }
                notification.success({ message: 'Page silindi!' });
            } catch (error) {
                notification.error({ message: 'Page silinirken bir hata oluştu!' });
            } finally {
                getPageByTopicId();
            }

        }
    };

    const getExerciseGroupDetail = async (id: string) => {
        console.log("girdi ",id);
        if(id){
            let result: ExerciseGroupModel;
            try {
                const { data } = await API.CURRICULUM.BRAND_EXERCISE.getExerciseGroupById(id);
                result = data;
            } catch (error) {
                return undefined;
            }
            return result;
        }
        return undefined;
        
    };




    return <LearningContentDraftVersion
        loading={loading}
        isAdminCdn={false}

        version={version}
        onCreateVersionMeta={onCreateVersionMeta}
        onUpdateVersionMeta={onUpdateVersionMeta}

        fields={fields?.data || []}
        levels={levels?.data || []}
        types={types?.data || []}
        themes={themes?.data || []}

        units={units || []}
        selectedUnit={selectedUnit}
        onSelectUnit={setSelectedUnit}
        onOrderUnit={onOrderUnit}
        onCreateUnit={createUnit}
        onRemoveUnit={deleteUnit}
        onUpdateUnit={updateUnit}

        topics={topics || []}
        selectedTopic={selectedTopic}
        onSelectTopic={setSelectedTopic}
        onOrderTopic={onOrderTopic}
        onCreateTopic={createTopic}
        onRemoveTopic={deleteTopic}
        onUpdateTopic={updateTopic}

        pages={pages || []}
        selectedPage={selectedPage}
        onSelectPage={({ id }) => { getSelectedPageDetail(id!) }}
        onOrderPage={onOrderPage}
        onCreatePageRequest={createPageRequest}
        onChangePageType={(type) => {
            setSelectedPage({
                ...selectedPage,
                type
            })
        }}

        onChangeContentPage={(page) => {
            setSelectedPage({
                ...page,
            })
        }}

        onUpdatePage={updatePage}
        onRemovePage={deletePage}

        exerciseGroups={exerciseGroups || { data: [], count: 0 }}

        onSearchExerciseGroup={(query) => {
            if(query.levelId || query.name){
                getExerciseGroups(undefined, query)
            }
        }}
        onChangeExercisePage={async (group) => {
            const data = await getExerciseGroupDetail(group.id!);
            setSelectedPage({
                ...selectedPage,
                exercise: data,
            });
        }}

        onBack={() => {
            const layout = searchParams.get("layout");
            const brandId = searchParams.get('brandId');
            const branchId = searchParams.get('branchId');
            const params = new URLSearchParams({
                layout: layout!,
            });
            if (brandId) {
                params.append('brandId', brandId);
            }
            if (branchId) {
                params.append('branchId', branchId);
            }
            navigate('/brand-learning-content-versions/?' + params);
        }}
    />;
}


BrandDraftVersion.path = '/brand-learning-content-versions/draft-version';
BrandDraftVersion.roles = [Role.ADMIN];
BrandDraftVersion.layouts = [LayoutEnums.BRANDMANAGER, LayoutEnums.LMS];