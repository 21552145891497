import { ActionDropdown, EducationLevelsView } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import * as API from '@shared/api';
import { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select, Switch, notification, InputNumber, TableProps, Spin, Table } from 'antd';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useAccessableSeachParams } from '@shared/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Branch, ConditionCourse, PaginationQuery } from '@shared/models';
import { ConditionsCompletedCourseCreate } from './ConditionsCompletedCourseCreate';
import { renderLevelsAsString } from '@shared/utils';

export const ConditionsCompletedCourse = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, loading, getLevelRequirement] = API.EDUCATION_LEVEL.BRAND.getLevelSuccessRequirementAll({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });

  useEffect(() => {
    if (paginationQuery) {
      getLevelRequirement(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  const title = (
    <div className="flex justify-between">
      <div></div>
      <Button type="dashed" icon={<PlusCircleOutlined />} onClick={() => navigate('/brand/conditions-completed-course-create' + location.search)}>
        Yeni Kur Geçme Şartı
      </Button>
    </div>
  );

  const columns: TableProps<ConditionCourse>['columns'] = [
    {
      title: 'Kur Bilgisi',
      dataIndex: 'id',
      key: 'id',
      className: 'text-center',
      render: (_, record) => {
        return <div>{renderLevelsAsString(record.level)}</div>;
      },
    },
    {
      title: 'Aksiyon',
      key: 'action',
      width: '10%',
      className: 'text-center',
      render: (_, record) => (
        <ActionDropdown
          data={record}
          onEdit={({ id }) => {
            navigate('/brand/conditions-completed-course-create' + location.search + '&levelId=' + id);
          }}
          onDelete={async ({ id }) => {
            const result = await API.EDUCATION_LEVEL.BRAND.deletelevelSuccessRequirement(id);
            if (result.ok) {
              notification.success({ message: 'Kur geçme şartı silindi' });
            }
            getLevelRequirement(undefined, { ...paginationQuery, page: 1 });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={result?.data}
        rowKey={'id'}
        bordered
        title={() => title}
        pagination={{ total: result?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        onChange={(pagination) => setPaginationQuery({ ...paginationQuery, page: pagination.current })}
      />
    </>
  );
};

ConditionsCompletedCourse.path = 'brand/conditions-completed-course';
ConditionsCompletedCourse.title = 'Kur Geçme Şartları';
ConditionsCompletedCourse.group = 'definitions';
ConditionsCompletedCourse.roles = [Role.USER];
ConditionsCompletedCourse.layouts = [LayoutEnums.BRANDMANAGER];
ConditionsCompletedCourse.permissions=["brand.lms.definition.view"]
