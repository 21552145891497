import { useFetch } from '@shared/hooks';
import { CourseTopicPageModel, CourseTopicPageUpdateModel, EducationLevel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';
export default {
 
  studentLevels: (config: UseFetchOptions) => useFetch<EducationLevel[]>('/student/level', { method: 'GET', ...config }),
  getContentUnits: async (levelId: string) =>request("/student/content/level/"+levelId,'GET'),
  getContentTopics: async (levelId: string,unitId: string) =>request("/student/content/level/"+levelId+"/unit/"+unitId,'GET'),
  getContentPages: async (levelId: string,unitId: string,topicId: string) =>request("/student/content/level/"+levelId+"/unit/"+unitId+"/topic/"+topicId,'GET'),
  goToNextPage : async (levelId: string,unitId: string,topicId: string,pageId: string) =>request("/student/content/level/"+levelId+"/unit/"+unitId+"/topic/"+topicId+"/page/"+pageId+"/next-page",'GET'),
  goToPrevPage:async (levelId: string,unitId: string,topicId: string,pageId: string) =>request("/student/content/level/"+levelId+"/unit/"+unitId+"/topic/"+topicId+"/page/"+pageId+"/prev-page",'GET'),



  getPageByVersionIdAndUnitIdAndTopicId: async (versionId: string, unitId: string, topicId: string) =>
    request('/lms/content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/list', 'GET'),
  order: async (versionId: string, unitId: string, topicId: string, order: CourseTopicPageModel[]) =>
    request('/lms/content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/order', 'PUT', order),
  delete: async (versionId: string, unitId: string, topicId: string, pageId: string) =>
    request('/lms/content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/' + pageId, 'DELETE'),
  update: async (versionId: string, unitId: string, topicId: string, data: CourseTopicPageUpdateModel) =>
    request('/lms/content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page', 'PUT', data),

  brandApprove: async (versionId: string, unitId: string, topicId: string, pageId, reviewNote?: { reviewedNote: string }) =>
    request('/lms/brand-content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/' + pageId + '/approve', 'PUT', reviewNote),
  brandReject: async (versionId: string, unitId: string, topicId: string, pageId, reviewNote?: { reviewedNote: string }) =>
    request('/lms/brand-content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/' + pageId + '/reject', 'PUT', reviewNote),
  approve: async (versionId: string, unitId: string, topicId: string, pageId, reviewNote?: { reviewedNote: string }) =>
    request('/lms/content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/' + pageId + '/approve', 'PUT', reviewNote),
  reject: async (versionId: string, unitId: string, topicId: string, pageId, reviewNote: { reviewedNote: string }) =>
    request('/lms/content-version/' + versionId + '/unit/' + unitId + '/topic/' + topicId + '/page/' + pageId + '/reject', 'PUT', reviewNote),
};
