import { AccessableBrand, BrandModel, BrandUserModel, CreateBrandModel, CreateBrandUser, PaginateModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';
import { useFetch } from '../hooks';

//TODO Brand user burada kalmalımı
export default {
  useFetch: (config: UseFetchOptions) => useFetch<PaginateModel<BrandModel>>('/brand', { method: 'GET', ...config }),
  create: async (brand: CreateBrandModel) => request('/brand', 'POST', brand),
  update: async (brand: CreateBrandModel) => request('/brand/' + brand.id, 'PUT', brand),
  getBrandUsers: (config: UseFetchOptions) => useFetch<PaginateModel<BrandUserModel>>('/brand-user', { method: 'GET', ...config }),
  delete: async (brandId: string) => request('/brand/' + brandId, 'DELETE'),
  accessableBrands: (config: UseFetchOptions) => useFetch<PaginateModel<AccessableBrand>>('/account/accessable-brands', { method: 'GET', ...config }),
  getAllBrandUsers: (config: UseFetchOptions) => useFetch<PaginateModel<BrandUserModel>>('/brand-user', { method: 'GET', ...config }),
  createBrandUser: async (user: CreateBrandUser) => request('/brand-user', 'POST', user),
  deleteBrandUser: async (userId: string) => request('/brand-user/' + userId, 'DELETE'),
  updateBrandUser: async (model: BrandUserModel) => request('/brand-user/permissions', 'PUT', model),
};
