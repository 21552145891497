import { PrePlacementDemandStatus } from '@shared/constants';
import { PrePlacementDemandDetail } from '@shared/models';
import { Table, Tag } from 'antd';
import { TableProps } from 'antd/lib/table';
import React, { useMemo } from 'react';

type StudentCourseInfoProps = {
  className?: string;
  demandDetails: PrePlacementDemandDetail;
};

export const StudentCourseInfo = ({ className = '', demandDetails }: StudentCourseInfoProps) => {
  const uniqueTeachers = useMemo(() => {
    const teacherList: { id: string; name: string }[] = [];
    if (demandDetails) {
      demandDetails?.class?.classSeances?.forEach((seance) => {
        seance.classSeanceItems?.forEach((item) => {
          if (item.teacher) {
            teacherList.push({
              id: item?.teacher?.id!,
              name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
            });
          }
        });
      });
    }
    return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [demandDetails]);

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Öğretmen Bilgisi',
      key: 'id',
      render: () => (
        <div className="flex flex-col gap-2">
          {uniqueTeachers.map((teacher) => (
            <div key={teacher.id}>{teacher.name}</div>
          ))}
        </div>
      ),
    },
    {
      title: 'Kur Notu',
      key: 'avgScore',
      dataIndex: 'avgScore',
      align: 'center',
    },
    {
      title: 'Devamsızlık Yüzdesi',
      key: 'attendanceRate',
      dataIndex: 'attendanceRate',
      align: 'center',
    },
    {
      title: 'Durum',
      key: 'teacherEvaluationsAvg',
      render: () => (
        <>
          {demandDetails.demand.status === PrePlacementDemandStatus.SUCCESS && <Tag color="green">Geçti</Tag>}
          {demandDetails.demand.status === PrePlacementDemandStatus.FAILED && <Tag color="red">Kaldı</Tag>}
          {demandDetails.demand.status === PrePlacementDemandStatus.CONDITIONAL && <Tag color="blue">Şartlı</Tag>}
        </>
      ),
    },
  ];

  return (
    <Table size="small" bordered pagination={false} className={className} title={() => 'Kur Sonu Bilgileri'} dataSource={demandDetails.successSummary.items} columns={columns} />
  );
};
