import { Button, Card, Checkbox, Divider, Drawer, Form, Input, List, Select, Spin, Tooltip, notification } from "antd";
import { CreateCourseUnitForm } from "../CreateCourseUnitForm";
import { CreateCourseTopicForm } from "../CreateCourseTopicForm";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CourseTopicPageType } from "@shared/constants";
import { CourseUnitListComponent, CourseTopicListComponent, CoursePageListComponent, EducationCascader, CreateVideoPage } from '@shared/components';
import { useCallback, useEffect, useState } from "react";
import { CascaderOption, CourseTopicDefinetion, CourseTopicModel, CourseTopicPageModel, CourseUnitDefinetion, CourseUnitModel, CourseVersionDefination, CourseVersionModel, EducationField, EducationLevel, EducationTheme, EducationType, ExerciseGroupModel, PaginateModel } from "@shared/models";
import { z } from "zod";
import { useForm } from "@shared/hooks";
import { ExerciseOrPageHandler } from "../exercies/ExerciseOrPageHandler";
import ReactQuill from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css'
import { CreateImagePage } from "./content_page/image_page";

type LearningContentDraftVersionProps = {
    loading?: boolean;
    isAdminCdn: boolean;
    version?: CourseVersionModel;
    fields: EducationField[];
    types: EducationType[];
    themes: EducationTheme[];
    levels: EducationLevel[];

    units: CourseUnitModel[];
    selectedUnit?: CourseUnitModel;
    onSelectUnit: (unit: CourseUnitModel) => void;
    onOrderUnit: (units: CourseUnitModel[]) => void;
    onCreateUnit: (data: CourseUnitDefinetion) => void;
    onRemoveUnit: (unitId: string) => void;
    onUpdateUnit: (unit: CourseUnitModel) => void;

    topics: CourseTopicModel[];
    selectedTopic?: CourseTopicModel;
    onSelectTopic: (topic: CourseTopicModel) => void;
    onOrderTopic: (topics: CourseTopicModel[]) => void;
    onCreateTopic: (data: CourseTopicDefinetion) => void;
    onRemoveTopic: (topicId: string) => void;
    onUpdateTopic: (topic: CourseTopicModel) => void;


    pages: CourseTopicPageModel[];
    selectedPage?: CourseTopicPageModel;
    onSelectPage: (page: CourseTopicPageModel) => void;
    onOrderPage: (pages: CourseTopicPageModel[]) => void;
    onCreatePageRequest: () => void;
    onChangePageType: (type: CourseTopicPageType) => void;
    onChangeContentPage: (page: CourseTopicPageModel) => void;
    onChangeExercisePage: (group: ExerciseGroupModel) => void;
    onRemovePage: (pageId: string) => void;

    exerciseGroups: PaginateModel<ExerciseGroupModel>;
    onSearchExerciseGroup: (query: { name?: string, levelId?: string }) => void;

    onUpdateVersionMeta: (data: CourseVersionDefination) => void;
    onCreateVersionMeta: (data: CourseVersionDefination) => void;
    onUpdatePage: () => void;

    onBack?: () => void;

};

const DraftVersionForm = z.object({
    name: z.string({ required_error: 'Müfredat version adı zorunlu alandır' }).min(1, 'Müfredat version adı 1 karekterden fazla olmalı'),
    description: z.string({ required_error: 'Müfredat version açıklaması zorunlu alandır' }).min(1, 'Müfredat version acıklaması 1 karekterden fazla olmalı'),
    levelId: z.string({ required_error: 'Lütfen kur seçimi yapınız' }),
});

export const LearningContentDraftVersion = ({
    loading = false,
    isAdminCdn,
    version,
    fields,
    types,
    themes,
    levels,
    units,
    selectedUnit,
    onSelectUnit,
    onOrderUnit,
    onRemoveUnit,
    onUpdateUnit,
    topics,
    selectedTopic,
    onSelectTopic,
    onOrderTopic,
    onRemoveTopic,
    onUpdateTopic,
    pages,
    selectedPage,
    onSelectPage,
    onOrderPage,
    onCreatePageRequest,
    onChangePageType,
    onChangeExercisePage,
    onChangeContentPage,
    onUpdatePage,
    onRemovePage,
    onUpdateVersionMeta,

    exerciseGroups,
    onSearchExerciseGroup,
    onCreateVersionMeta,
    onCreateUnit,
    onCreateTopic,
    onBack
}: LearningContentDraftVersionProps) => {

    const [isUnitDrawerOpen, setIsUnitDrawerOpen] = useState<boolean>(false);
    const [isTopicDrawerOpen, setIsTopicDrawerOpen] = useState<boolean>(false);
    const [isOpenUpdateVersionMeta, setIsOpenUpdateVersionMeta] = useState<boolean>(false);

    const [versionLevel, setVersionLevel] = useState<(string | number)[]>([]);
    const [exerciseFilterLevel, setExerciseFilterLevel] = useState<(string | number)[]>([]);
    const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
    const [levelCascaderLoading, setLevelCascaderLoading] = useState<boolean>(true);

    const [updateUnitValue, setUpdateUnitValue] = useState<CourseUnitModel>();
    const [updateTopicValue, setUpdateTopicValue] = useState<CourseTopicModel>();

    const [exerciseGroupSearchQuery, setExerciseGroupSearchQuery] = useState<{ name?: string, levelId?: string }>();
    const [exerciseGroupSearchText, setExerciseGroupSearchText] = useState<string>('');

    const form = useForm<CourseVersionDefination>(DraftVersionForm, {});

    useEffect(() => {
        if (exerciseGroupSearchQuery) {
            onSearchExerciseGroup(exerciseGroupSearchQuery);
        } else {
            setExerciseFilterLevel(versionLevel);
            const levelId = versionLevel.at(-1)?.toString();
            onSearchExerciseGroup({ levelId });
        }
    }, [exerciseGroupSearchQuery]);

    useEffect(() => {
        if (versionLevel) {
            onChangeExerciseFilterLevel(versionLevel);
        }
    }, [versionLevel])


    useEffect(() => {
        if (version) {
            form.setValue({
                name: version.name,
                description: version.description,
                levelId: version.level?.id,
            });
            if (version.level) {
                const level = [version.level.theme?.type?.field?.id!, version.level.theme?.type?.id!, version.level.theme?.id!, version.level.id!];
                setVersionLevel(level);
            }
        }
    }, [version]);

    useEffect(() => {
        if (fields && types && themes && levels) {
            setLevelCascaderLoading(false);
            const _items: CascaderOption[] = [];
            fields.map((f) => {
                _items.push({
                    value: f.id!,
                    label: f.name!,
                    children: types
                        ?.filter((t) => t.field?.id == f.id)
                        .map((t) => {
                            return {
                                value: t.id!,
                                label: t.name,
                                children: themes.filter((m) => m.type?.id == t.id)
                                    .map((m) => {
                                        return {
                                            value: m.id,
                                            label: m.name,
                                            children: levels.filter((l) => l.theme?.id == m.id)
                                                .map((l) => {
                                                    return {
                                                        value: l.id,
                                                        label: l.name,
                                                    } as CascaderOption;
                                                }),
                                        };
                                    }) as CascaderOption[],
                            };
                        }) as CascaderOption[],
                });
            });
            setAbilityLevelOptions(_items);
        }
    }, [fields, types, themes, levels]);

    const onChangeLevel = (value: (string | number)[]) => {
        const levelId = value.at(-1)?.toString();
        form.setValue({
            ...form.value,
            levelId,
        });
        setVersionLevel(value);
    };

    const onChangeExerciseFilterLevel = (value: (string | number)[]) => {
        const levelId = value.at(-1)?.toString();
        setExerciseFilterLevel(value);
        setExerciseGroupSearchQuery({
            ...exerciseGroupSearchQuery,
            levelId
        })
    }

    const createOrUpdateVersion = useCallback(async () => {
        const result = form.parse();
        if (result.success) {
            if (version && version.id) {

                onUpdateVersionMeta({ ...result.data, id: version.id });
                setIsOpenUpdateVersionMeta(false);
            } else {
                onCreateVersionMeta(result.data);
            }
        } else {
            form.setAllTouched();
        }
    }, [form]);

    const openVersionMetaUpdateDrawer = () => {
        setIsOpenUpdateVersionMeta(true);
    }

    const exerciseCard = (item: ExerciseGroupModel) => {
        return (
            <Card
                key={item.id}
                size="small"
                hoverable
                className="m-1"
                onClick={() => {
                    onChangeExercisePage(item);
                }}
                title={<Card.Meta title={<label>{item.name}</label>} />}
                extra={<Checkbox checked={item.id == selectedPage?.exercise?.id} />}
            >
                <div className="space-y-1">
                    <div className="flex flex-row space-x-1 text-center content-center items-center">
                        <label className="font-bold text-xs">Açıklama:</label>
                        <span className="text-sm">{item.description}</span>
                    </div>

                    <div className="flex flex-row space-x-1 text-center content-center items-center">
                        <label className="font-bold text-xs">Level:</label>
                        <span className="text-sm">
                            {item.level?.theme?.type?.name}/{item.level?.theme?.name}/{item.level?.name}
                        </span>
                    </div>

                    <div className="flex flex-row space-x-1 text-center content-center items-center">
                        <label className="font-bold text-xs">Egzersiz:</label>
                        <span>{item.exerciseCount}</span>
                    </div>
                </div>
            </Card>
        )
    };


    return (
        <div className="w-full h-full">
            <Spin spinning={loading} size="large" tip="İşleminiz yapılıyor...">
                <Drawer
                    title="Yeni birim oluştur"
                    open={isUnitDrawerOpen}
                    onClose={() => {
                        setIsUnitDrawerOpen(false);
                    }}
                >
                    <CreateCourseUnitForm
                        onSave={(unit) => {
                            if (unit.id) {
                                onUpdateUnit(unit);
                            } else {
                                onCreateUnit(unit);
                            }
                            setIsUnitDrawerOpen(false);
                            setUpdateUnitValue({});
                        }}
                        value={updateUnitValue}
                    />
                </Drawer>
                <Drawer
                    title="Yeni topik oluştur"
                    open={isTopicDrawerOpen}
                    onClose={() => {
                        setIsTopicDrawerOpen(false);
                    }}
                >
                    <CreateCourseTopicForm
                        onSave={(topic) => {
                            if (topic.id) {
                                onUpdateTopic(topic);
                            } else {
                                onCreateTopic(topic);
                            }
                            setIsTopicDrawerOpen(false);
                            setUpdateTopicValue({});
                        }}
                        value={updateTopicValue} />
                </Drawer>
                <Drawer
                    title="Versiyon bilgileri güncelle"
                    open={isOpenUpdateVersionMeta}
                    onClose={() => {
                        setIsOpenUpdateVersionMeta(false);
                    }}
                >
                    <div>
                        <Form layout="vertical" >
                            <Form.Item {...form.formItem('name')} label={'Version Adı:'}>
                                <Input {...form.input('name')} />
                            </Form.Item>
                            <Form.Item {...form.formItem('description')} label={'Version Açıklaması:'}>
                                <Input {...form.input('description')} />
                            </Form.Item>
                            <Form.Item {...form.formItem('levelId')} label={'Version için kur seçiniz:'}>
                                <EducationCascader value={versionLevel} items={abilityLevelOptions} loading={levelCascaderLoading} onChange={onChangeLevel} />
                            </Form.Item>
                            <Form.Item label={' '}>
                                <Button type={"primary"} onClick={createOrUpdateVersion}>
                                    Versiyonu bilgilerini güncelle
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Drawer>
                <div className="flex flex-col">
                    <Form layout="vertical" className="flex flex-row justify-between">
                        <div className="flex flex-row space-x-2 w-9/12">
                            <Form.Item {...form.formItem('name')} label={'Version Adı:'}>
                                <Input {...form.input('name')} disabled={version ? true : false} />
                            </Form.Item>
                            <Form.Item {...form.formItem('description')} label={'Version Açıklaması:'}>
                                <Input {...form.input('description')} disabled={version ? true : false} />
                            </Form.Item>
                            <Form.Item {...form.formItem('levelId')} label={'Version için kur seçiniz:'}>
                                <EducationCascader value={versionLevel} items={abilityLevelOptions} loading={levelCascaderLoading} onChange={onChangeLevel} disabled={version ? true : false} />
                            </Form.Item>
                            <Form.Item label={' '}>
                                <Button type={version ? "default" : "primary"} onClick={version ? openVersionMetaUpdateDrawer : createOrUpdateVersion}>
                                    {version ? 'Bilgileri güncelle' : 'Versiyonu oluştur'}
                                </Button>
                            </Form.Item>
                        </div>
                        <div className="flex flex-row justify-end w-3/12">

                            {
                                onBack && (
                                    <Form.Item label={' '}>
                                        <Button type={"primary"} onClick={onBack}>
                                            Versiyon listesine geri dön
                                        </Button>
                                    </Form.Item>
                                )
                            }
                        </div>

                    </Form>
                    <div className="space-y-1">
                        <div>
                            <span className="text-xs font-bold">Unite Seçiniz</span>
                            <div className="grid grid-cols-12  p-1 space-x-3">
                                <div className="flex flex-row col-span-11 space-x-2 overflow-auto">
                                    <CourseUnitListComponent
                                        units={units}
                                        selected={selectedUnit}
                                        onSelect={(unit) => {
                                            onSelectUnit(unit);
                                        }}
                                        onOrder={onOrderUnit}
                                        onRemove={onRemoveUnit}
                                        onEdit={(id) => {
                                            if (units && units.length > 0) {
                                                const unit = units.find((u) => { return u.id == id });
                                                setUpdateUnitValue(unit);
                                                setIsUnitDrawerOpen(true);
                                            }
                                        }}
                                    />
                                </div>
                                <Tooltip title={version ? "Yeni birim oluştur" : 'Lütfen bir versiyon oluşturunuz.'}>
                                    <Card
                                        bordered={false}
                                        className="content-center m-2"
                                        onClick={() => {
                                            if (version) {
                                                setIsUnitDrawerOpen(true);
                                            } else {
                                                form.setAllTouched();
                                                notification.warning({ message: "Lütfen bir versiyon oluşturunuz." });
                                            }
                                        }}
                                    >
                                        <div className="flex h-full w-full justify-center">
                                            <PlusCircleOutlined style={{ fontSize: '200%' }} />
                                        </div>
                                    </Card>
                                </Tooltip>
                            </div>
                        </div>
                        <Divider orientationMargin={0} className="m-0 p-0" />
                        <div>
                            <span className="text-xs font-bold">Topik Seçiniz</span>
                            <div className="grid grid-cols-12 p-1 space-x-3">
                                <div className="flex flex-row col-span-11 space-x-2 overflow-auto">
                                    <CourseTopicListComponent
                                        topics={topics}
                                        selected={selectedTopic}
                                        onOrder={onOrderTopic}
                                        onSelect={(t) => {
                                            onSelectTopic(t);
                                        }}
                                        onRemove={onRemoveTopic}
                                        onEdit={(id) => {
                                            if (topics && topics.length > 0) {
                                                const topic = topics.find((u) => { return u.id == id });
                                                setUpdateTopicValue(topic);
                                                setIsTopicDrawerOpen(true);
                                            }
                                        }}
                                    />
                                </div>
                                <Tooltip title={version ? "Yeni topik oluştur" : 'Lütfen bir versiyon oluşturunuz.'}>
                                    <Card
                                        bordered={false}
                                        className="content-center m-2"
                                        onClick={() => {
                                            if (version) {
                                                setIsTopicDrawerOpen(true);
                                            } else {
                                                form.setAllTouched();
                                                notification.warning({ message: "Lütfen bir versiyon oluşturunuz." });
                                            }
                                        }}
                                    >
                                        <div className="flex h-full w-full justify-center">
                                            <PlusCircleOutlined style={{ fontSize: '200%' }} />
                                        </div>
                                    </Card>
                                </Tooltip>
                            </div>
                        </div>
                        <Divider orientationMargin={0} className="m-0 p-0" />
                        <div>
                            <span className="text-xs font-bold">Sayfa Seçiniz</span>
                            <div className="grid grid-cols-12  p-1 space-x-3">
                                <div className="flex flex-row col-span-11 space-x-2 overflow-auto">
                                    <CoursePageListComponent
                                        pages={pages}
                                        selected={selectedPage}
                                        onOrder={onOrderPage}
                                        onSelect={(t) => {
                                            onSelectPage(t);
                                        }}
                                        onRemove={onRemovePage}
                                    />
                                </div>
                                <Tooltip title={version ? "Yeni sayfa oluştur" : 'Lütfen bir versiyon oluşturunuz.'}>
                                    <Card bordered={false} className="content-center m-2"
                                        onClick={() => {
                                            if (version) {
                                                onCreatePageRequest();
                                            } else {
                                                form.setAllTouched();
                                                notification.warning({ message: "Lütfen bir versiyon oluşturunuz." });
                                            }
                                        }}>
                                        <div className="flex h-full w-full justify-center">
                                            <PlusCircleOutlined style={{ fontSize: '200%' }} />
                                        </div>
                                    </Card>
                                </Tooltip>
                            </div>
                        </div>
                        <Divider orientationMargin={0} className="m-0 p-0" />
                    </div>

                    {selectedPage ? (
                        <div className="p-2">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-6">
                                    <Form layout="vertical">
                                        <div className="flex flex-row space-x-2">
                                            <Form.Item label={'Sayfa tipi:'} style={{ width: '100%' }}>
                                                <Select
                                                    value={selectedPage?.type}
                                                    placeholder={'Lütfen sayfa tipi seçiniz'}
                                                    onChange={(t) => {
                                                        onChangePageType(t);
                                                    }}
                                                    options={[
                                                        {
                                                            label: CourseTopicPageType.TEXT,
                                                            value: CourseTopicPageType.TEXT,
                                                        },
                                                        {
                                                            label: CourseTopicPageType.IMAGE,
                                                            value: CourseTopicPageType.IMAGE,
                                                        },
                                                        {
                                                            label: CourseTopicPageType.VIDEO,
                                                            value: CourseTopicPageType.VIDEO,
                                                        },
                                                        {
                                                            label: CourseTopicPageType.DOWNLOADABLE,
                                                            value: CourseTopicPageType.DOWNLOADABLE,
                                                        },
                                                        {
                                                            label: CourseTopicPageType.EXERCISE,
                                                            value: CourseTopicPageType.EXERCISE,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item label={' '}>
                                                <Button type="primary" onClick={onUpdatePage}>
                                                    Degişiklikleri Yansıt
                                                </Button>
                                            </Form.Item>
                                        </div>

                                        {selectedPage.type == CourseTopicPageType.TEXT ? (
                                            <div className="h-96 bg-white">
                                                <ReactQuill
                                                    value={selectedPage.content?.text}
                                                    onChange={(t) => {
                                                        onChangeContentPage({
                                                            ...selectedPage,
                                                            content: {
                                                                text: t,
                                                            },
                                                            type: CourseTopicPageType.TEXT,
                                                        });
                                                    }}
                                                    className="h-full p-0 m-0"
                                                    theme="snow" />
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        {selectedPage.type == CourseTopicPageType.IMAGE ? (
                                            <CreateImagePage page={selectedPage} onChange={onChangeContentPage} isAdminCdn={isAdminCdn}/>
                                        ) : (
                                            <></>
                                        )}

                                        {
                                            selectedPage.type == CourseTopicPageType.VIDEO ?

                                                <CreateVideoPage
                                                    page={selectedPage}
                                                    onChange={(page) => {
                                                        onChangeContentPage({
                                                            ...selectedPage,
                                                            ...page,
                                                        })
                                                    }} isAdminCdn={isAdminCdn} />
                                                :
                                                <></>
                                        }

                                        {selectedPage.type == CourseTopicPageType.DOWNLOADABLE ? <div>indirilebilir içeriklere daha karar vemedik</div> : <></>}

                                        {selectedPage.type == CourseTopicPageType.EXERCISE ? (
                                            <div>
                                                <div className="flex flex-row space-x-2 mb-2 items-center">
                                                    <Input.Search
                                                        value={exerciseGroupSearchText}
                                                        onSearch={(v) => {
                                                            setExerciseGroupSearchQuery({
                                                                ...exerciseGroupSearchQuery,
                                                                name: v,
                                                            });
                                                        }}
                                                        onChange={(e) => {
                                                            setExerciseGroupSearchText(e.target.value);
                                                        }}

                                                    />
                                                    <EducationCascader value={exerciseFilterLevel} items={abilityLevelOptions} loading={levelCascaderLoading} onChange={onChangeExerciseFilterLevel} />
                                                    <Button
                                                        onClick={() => {
                                                            const levelId = versionLevel.at(-1)?.toString();
                                                            setExerciseGroupSearchQuery({
                                                                levelId
                                                            });
                                                            setExerciseGroupSearchText('');
                                                        }}
                                                        type="dashed"
                                                        size="small"
                                                        icon={<DeleteOutlined />}
                                                    >
                                                        Filtreyi Temizle
                                                    </Button>

                                                </div>
                                                <div className="bg-white p-2 h-96 overflow-auto">
                                                    <List
                                                        size="small"
                                                        grid={{ gutter: 1, column: 2 }}
                                                        dataSource={exerciseGroups.data}
                                                        //loading={exerciseGroupsLoading}
                                                        className=""
                                                        header={
                                                            <div className="items-center flex flex-row justify-between">
                                                                <span>Egzersiz grupları</span>
                                                                <span>{exerciseGroups?.count}</span>
                                                            </div>
                                                        }
                                                        renderItem={(item) => exerciseCard(item)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Form>
                                </div>
                                {
                                    <div className={selectedPage.type == CourseTopicPageType.EXERCISE ? "h-[44rem] col-span-3" : "h-[44rem] col-span-6"}>
                                        <ExerciseOrPageHandler isAdminCdn={isAdminCdn} page={selectedPage} isPreview={true} isInClass={false} onNext={() => { }} onPrev={() => { }} />
                                    </div>
                                }
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                </div>
            </Spin>
        </div>
    );
}