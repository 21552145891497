import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import { shuffleToArray } from '@shared/utils';
import { Button } from 'antd';

type ReadingSingilSelectProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
};

export const ReadingSingilSelect = ({ content, interaction, isPreview = false, isInClass }: ReadingSingilSelectProps) => {
  return (
    <div className="flex flex-col h-full w-full bg-white py-10 px-5 relative">
      <div className="font-bold text-base">{content.content?.title}</div>
      <div className=" mt-5 break-words">{content.content?.description}</div>

      <div className="mt-10 text-center">
        <div className="text-center  text-[#722ED1]">{interaction.instruction?.title}</div>
        <div className="mt-2 text-[#D3ADF7]">{interaction.instruction?.description}</div>
      </div>

      <div className="text-center mt-7">
        {interaction.choices ? (
          <div className="flex flex-col justify-center gap-3 px-4">
            {interaction.choices.map((item, index) => (
              <Button key={index} type="default">
                {item.title}
              </Button>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
