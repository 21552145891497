import {
  BookOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MoneyCollectOutlined,
  MoreOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useUserContext } from '@shared/contexts';
import { Dropdown, MenuProps, Popconfirm } from 'antd';
import { useEffect } from 'react';

type ActionDropdownType = {
  data: any;
  items?: MenuProps['items'];
  isEdit?: boolean;
  isDelete?: boolean;
  isCreateReminder?: boolean;
  isGoHomework?: boolean;
  isEditUser?: boolean;
  isHomeWorkSubmission?: boolean;
  isMarkAsPaid?: boolean;
  isApprove?: boolean;
  isReject?: boolean;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  onCreateReminder?: (data: any) => void;
  onGoHomework?: (data: any) => void;
  onEditUser?: (data: any) => void;
  onHomeWorkSubmission?: (data: any) => void;
  onMarkAsPaid?: (data: any) => void;
  onApprove?: (data: any) => void;
  onReject?: (data: any) => void;
  placement?: 'bottom' | 'bottomLeft' | 'bottomRight' | 'top' | 'topLeft' | 'topRight';
  disabled?: boolean;
  iconColor?: string;
};
export const ActionDropdown = ({
  data,
  items = [],
  isDelete = true,
  isEdit = true,
  isCreateReminder = false,
  isGoHomework = false,
  isEditUser = false,
  isHomeWorkSubmission = false,
  isMarkAsPaid = false,
  isApprove = false,
  isReject = false,
  onDelete,
  onEdit,
  onCreateReminder,
  onGoHomework,
  onEditUser,
  onHomeWorkSubmission,
  onMarkAsPaid,
  onApprove,
  onReject,
  placement,
  disabled = false,
  iconColor,
}: ActionDropdownType) => {
  const menu: MenuProps['items'] = [];

  const { user } = useUserContext();

  useEffect(() => {
    if (isEdit) {
      menu.push({
        key: '1',
        icon: <EditOutlined />,
        label: <a onClick={() => (onEdit ? onEdit!(data) : null)}>Düzenle</a>,
      });
    }
    if (isDelete && data.email !== user.email) {
      menu.push({
        key: '2',
        icon: <DeleteOutlined />,
        label: (
          <Popconfirm title="Delete the task" description="Are you sure to delete this task?" onConfirm={() => (onDelete ? onDelete!(data) : null)} okText="Yes" cancelText="No">
            <a>Sil</a>
          </Popconfirm>
        ),
        danger: true,
      });
    }

    if (items.length > 0) {
      items.forEach((item, i) => {
        menu.push({ ...item, key: i + 'key' });
      });
    }
    if (isCreateReminder) {
      menu.push({
        key: '3',
        icon: <CalendarOutlined />,
        label: <a onClick={() => (onCreateReminder ? onCreateReminder!(data) : null)}>Hatırlatıcı Oluştur</a>,
      });
    }
    if (isGoHomework) {
      menu.push({
        key: '4',
        icon: <BookOutlined />,
        label: <a onClick={() => (onGoHomework ? onGoHomework!(data) : null)}>Ödeve Git</a>,
      });
    }
    if (isEditUser) {
      menu.push({
        key: '4',
        icon: <EditOutlined />,
        label: <a onClick={() => (onEditUser ? onEditUser!(data) : null)}>Kullanıcı Ekle/Düzenle</a>,
      });
    }
    if (isHomeWorkSubmission) {
      menu.push({
        key: '4',
        icon: <UserOutlined />,
        label: <a onClick={() => (onHomeWorkSubmission ? onHomeWorkSubmission!(data) : null)}>Ödev Teslim Durumu</a>,
      });
    }

    if (isMarkAsPaid) {
      menu.push({
        key: 'isMarkAsPaid',
        icon: <MoneyCollectOutlined />,
        label: (
          <Popconfirm title="Emin misiniz?" description="Bu işlem Geri Alınamaz?" onConfirm={() => (onMarkAsPaid ? onMarkAsPaid!(data) : null)} okText="Yes" cancelText="No">
            <a>Ödendi Olarak İşaretle</a>
          </Popconfirm>
        ),
      });
    }

    if (isApprove) {
      menu.push({
        key: 'isApprove',
        icon: <CheckCircleOutlined />,
        label: (
          <Popconfirm title="Emin misiniz?" description="Bu işlem Geri Alınamaz?" onConfirm={() => (onApprove ? onApprove!(data) : null)} okText="Yes" cancelText="No">
            <a>Onayla</a>
          </Popconfirm>
        ),
      });
    }

    if (isReject) {
      menu.push({
        key: 'isReject',
        icon: <CloseCircleOutlined />,
        label: (
          <Popconfirm title="Emin misiniz?" description="Bu işlem Geri Alınamaz?" onConfirm={() => (onReject ? onReject!(data) : null)} okText="Yes" cancelText="No">
            <a>Reddet</a>
          </Popconfirm>
        ),
      });
    }
  }, [isDelete, isEdit, isCreateReminder, isGoHomework, isEditUser, isHomeWorkSubmission, isMarkAsPaid, isApprove, isReject, items]);

  return (
    <Dropdown menu={{ items: menu }} trigger={['click']} placement={placement} disabled={disabled}>
      <a onClick={(e) => e.preventDefault()}>
        <MoreOutlined className={iconColor ? `text-[${iconColor}]` : 'text-black'} />
      </a>
    </Dropdown>
  );
};
