import { BranchModel } from './../../../../backend/libs/jot-lib/src/branch/branch/database/branch.model';
import { AccountWeakRefProps } from './account';
import { BrandModel } from './brand';
export enum LmsCreditOrderStatus {
  ORDERED = 'ORDERED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type CreateLmsCreditOrderProps = {
  count: number;
  note: string;
};

export enum CreditBalanceType {
  CREDIT = 'CREDIT',
  SPENT = 'SPENT',
}

//Branch
export type BranchLmsCreditOrder = {
  id?: string;
  branch: BranchModel;
  no: string;
  count: number;
  note: string;
  status: LmsCreditOrderStatus;
  paid?: boolean;
  createdBy: AccountWeakRefProps;
  proceedBy?: AccountWeakRefProps;
  markedPaidBy?: AccountWeakRefProps;
  createdAt?: Date;
  updatedAt?: Date;
};

export type BranchLmsCreditBalance = {
  id?: string;
  branch: BranchModel;
  count: number;
  description: string;
  type: CreditBalanceType;
  proceedBy?: AccountWeakRefProps;
  createdAt?: Date;
};

//Brand
export type BrandLmsCreditOrder = {
  id?: string;
  brand: BrandModel;
  no: string;
  count: number;
  note: string;
  status: LmsCreditOrderStatus;
  paid?: boolean;
  price: number;
  createdBy: AccountWeakRefProps;
  proceedBy?: AccountWeakRefProps;
  markedPaidBy?: AccountWeakRefProps;
  createdAt?: Date;
  updatedAt?: Date;
};

export type BrandLmsCreditBalance = {
  id?: string;
  brand: BrandModel;
  count: number;
  note: string;
  type: CreditBalanceType;
  proceedBy?: AccountWeakRefProps;
  createdAt?: Date;
};
