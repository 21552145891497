import { FileTextOutlined } from '@ant-design/icons';
import { CourseInfo, CourseObserverInfo, CourseStudentInfo, Enrollment, EnrollmentStatus, EnrollmentTypes, Observer, PaginateModel, PrePlacement } from '@shared/models';
import { Card, Table, TableProps } from 'antd';
import { useAccessableSeachParams } from '@shared/hooks';
import { convertDate } from '@shared/utils';
import i18n from '@shared/localization/i18n';
import { useEffect, useState } from 'react';

type EnrollmentPreViewStepProps = {
  enrollment: Enrollment;
  prePlacements: PrePlacement[];
  observers: PaginateModel<Observer>;
  onPaginationChange: (number) => void;
};
export const EnrollmentPreViewStep = ({ enrollment, prePlacements, observers, onPaginationChange }: EnrollmentPreViewStepProps) => {
  useAccessableSeachParams();

  const [dataStudentInfo, setDataStudentInfo] = useState<CourseStudentInfo[]>([]);
  const [dataCourseInfo,setDataCourseInfo] = useState<CourseInfo[]>([]);
  const [dataObserversInfo,setDataObserversInfo] = useState<CourseObserverInfo[]>([]);


  useEffect(()=>{
    if(prePlacements.length > 0){
      const _dataStudentInfo = prePlacements.map((demand, index) => ({
        key: index,
        name: demand?.name,
        surname: demand?.surname,
        email: demand?.email,
        phone: demand?.phone,
        birthDate: demand?.birthDate,
        educationInfo: demand.prePlacementDemands,
      }));
      setDataStudentInfo(_dataStudentInfo);

     const _dataCourseInfo = prePlacements[0].prePlacementDemands?.map((demand, index) => ({
        key: index,
        level: demand.level.name,
        theme: demand.level.theme.name,
        type: demand.level.theme.type.name,
        field: demand.level.theme.type.field.name,
      }));
      setDataCourseInfo(_dataCourseInfo??[]);
    }

    if(observers.data && observers.data.length > 0){
      const _dataObserversInfo = observers.data?.map((item, index) => ({
        key: index,
        name: item.name,
        surname: item.surname,
        email: item.email,
        phone: item.phone,
      }));
      setDataObserversInfo(_dataObserversInfo);
    }

  },[prePlacements,observers]);

  /*const dataCourseInfo = prePlacements[0]?.prePlacementDemands?.map((demand, index) => ({
    key: index,
    level: demand.level.name,
    theme: demand.level.theme.name,
    type: demand.level.theme.type.name,
    field: demand.level.theme.type.field.name,
  }));*/

  /*const dataObserversInfo = observers.data?.map((item, index) => ({
    key: index,
    name: item.name,
    surname: item.surname,
    email: item.email,
    phone: item.phone,
  }));*/

  /*const dataStudentInfo = prePlacements.map((demand, index) => ({
    key: index,
    name: demand?.name,
    surname: demand?.surname,
    email: demand?.email,
    phone: demand?.phone,
    birthDate: demand?.birthDate,
    educationInfo: demand.prePlacementDemands,
  }));*/

  const columnsCourseInfo: TableProps<CourseInfo>['columns'] = [
    {
      title: 'Eğitim Türü',
      dataIndex: 'field',
      align: 'center',
      key: 'field',
    },
    {
      title: 'Kategori',
      dataIndex: 'type',
      align: 'center',
      key: 'type',
    },
    {
      title: 'Tema',
      dataIndex: 'theme',
      align: 'center',
      key: 'theme',
    },
    {
      title: 'Seviye',
      dataIndex: 'level',
      align: 'center',
      key: 'level',
    },
  ];

  const columnsObserversInfo: TableProps<CourseObserverInfo>['columns'] = [
    {
      title: 'Ad Soyad',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: (_, { name, surname }) => `${name} ${surname}`,
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      align: 'center',
      key: 'email',
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      render: (_, { phone }) => `${phone?.code} ${phone?.number}`,
    },
  ];

  const columnsStudentInfo: TableProps<CourseStudentInfo>['columns'] = [
    {
      title: 'Öğrenci Adı',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: (_, { name, surname }) => `${name} ${surname}`,
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      align: 'center',
      key: 'email',
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      render: (_, { phone }) => `${phone?.code} ${phone?.number}`,
    },
    {
      title: 'Eğitim Bilgisi',
      dataIndex: 'educationInfo',
      align: 'center',
      key: 'educationInfo',
      render: (_, { educationInfo }) => {
        return (
          <div>
            <div>
              <li className="list-disc">
                {educationInfo?.[0]?.level?.theme?.type?.field?.name}/{educationInfo?.[0]?.level?.theme?.type?.name}/{educationInfo?.[0]?.level?.theme?.name}/
                {educationInfo?.[0]?.level?.name}
              </li>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div key={(Math.random() + 18).toString(36).substring(7)}>
      <div className="grid grid-cols-3 gap-4 mx-10 mt-4">
        <div>
          <Card>
            <div className="grid grid-cols-1 gap-3 min-h-[90]">
              <div style={{ fontSize: '15px' }}>
                <FileTextOutlined /> Kayıt Bilgileri
              </div>

              <div className="opacity-75">
                <span className="font-medium"> Kayıt Adı:</span> <span>{enrollment.name}</span>
              </div>
              <div className="opacity-75">
                <span className="font-medium">Kayıt Tipi: </span>
                {i18n.t('app.enrollmentType.' + enrollment.type)}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <div className="grid grid-cols-1 gap-3 min-h-[90]">
              <div style={{ fontSize: '15px' }}>
                <FileTextOutlined /> İletişim Bilgileri
              </div>

              <div className="opacity-75">
                <span className="font-medium">Telefon numarası:</span> {enrollment.phone?.code} {enrollment.phone?.number}
              </div>
              <div className="opacity-75">
                <span className="font-medium">E-posta: </span> {enrollment.email}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <div className="grid grid-cols-1 gap-3 min-h-[90]">
              <div style={{ fontSize: '15px' }}>
                <FileTextOutlined /> Kişisel Bilgiler
              </div>
              {enrollment.type == 'INDIVIDUAL' ? (
                <div className="grid grid-cols-1 gap-3">
                  <div className="opacity-75">
                    <span className="font-medium"> TC:</span>
                    {enrollment.nationalId}
                  </div>
                  <div className="opacity-75">
                    <span className="font-medium"> Doğum tarihi:</span>
                    {dataStudentInfo.length>0 ?? convertDate(dataStudentInfo[0].birthDate)}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="opacity-75">VKN: {enrollment.nationalId}</div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className="mx-10 mt-4 grid grid-cols-1 gap-3">
        {enrollment.type == 'INDIVIDUAL' ? (
          <div>
            <div className="mb-3">Kur bilgileri</div>
            <Table bordered key={(Math.random() + 18).toString(36).substring(7)} rowKey="id" columns={columnsCourseInfo} dataSource={dataCourseInfo} pagination={false}></Table>
          </div>
        ) : (
          <div>
            <div className="mb-3">Öğrenci Listesi</div>
            <Table
              bordered
              key={(Math.random() + 18).toString(36).substring(7)}
              rowKey="id"
              columns={columnsStudentInfo}
              dataSource={dataStudentInfo}
              pagination={{ position: ['bottomCenter'], pageSize: 4 }}
            ></Table>
          </div>
        )}
        <div className="mt-3">Gözetmen bilgisi</div>
        <Table
          bordered
          key={(Math.random() + 18).toString(36).substring(7)}
          rowKey="id"
          columns={columnsObserversInfo}
          dataSource={dataObserversInfo}
          pagination={{ current: observers.page, total: observers?.count, pageSize: observers.limit, position: ['bottomCenter'] }}
          onChange={({ current }) => onPaginationChange(current!)}
        ></Table>
      </div>
    </div>
  );
};
