import { ClassCalendarEntryStatus, Gender, StudentCalendarType } from '@shared/constants';
import { PhoneModel } from './information';

export type StudentModel = Partial<{
  id: string;
  accountId: string;
  name: string;
  surname: string;
  gender: Gender;
  birthDate: Date;
  email: string;
  phone: PhoneModel;
  nationalId: string;
  createdAt: Date;
  demandId: string;
  prePlacementId: string;
}>;

export type StudentCalendarItem = {
  id: string;
  date: Date;
  title: string;
  description?: string;
  type: StudentCalendarType;
  isHoliday?: boolean;
  status?: ClassCalendarEntryStatus;
  className?: string;
};
