import { Gender, PrePlacementDemandStatus } from '@shared/constants';
import { AccountWeakRefProps } from './account';
import { ClassModel, StudentsSuccessSummaryItems } from './class';
import { ConditionCourse } from './conditions_course';
import { Observer } from './enrollment';
import { Account } from './account';
import { PhoneModel } from './information';

export type PrePlacementBasicInfo = {
  id: string;
  name: string;
  surname: string;
  gender: Gender;
  birthDate: Date;
  email: string;
  phone: PhoneModel;
  nationalId: string;
  preferredStartDate: Date;
};

export type PrePlacementDemandBasicInfo = {
  id: string;
  level: any;
  status: PrePlacementDemandStatus;
  prePlacement: PrePlacementBasicInfo;
  takeActionDate: Date;
  takeActionDateInitial: Date;
  waitingInDays: number;
  history: PrePlacementDemandHistory[];
};

export type PrePlacementDemandHistory = {
  status: PrePlacementDemandStatus;
  at: Date;
  description: string;
  class: ClassWeakRef;
  updatedBy: AccountWeakRefProps;
};

export type ClassWeakRef = {
  id: string;
  name: string;
};

export type PrePlacementDemandDetail = {
  id: string;
  demand: PrePlacementDemandBasicInfo;
  class: ClassModel;
  successSummary: { requirement: ConditionCourse; items: StudentsSuccessSummaryItems[] };
  observers: Observer[];
  attendances: {
    [day: PropertyKey]: { at: Date; seances: any[] };
  };
  history?: PrePlacementDemandHistoryProps[];
};

export type PrePlacementDemandStatusUpdate = {
    description: string,
  takeActionDate?: Date,

}

export type PrePlacementDemandHistoryProps = {
  status: PrePlacementDemandStatus;
  at: Date;
  description: string;
  class: ClassModel;
  updatedBy: Account;
};
