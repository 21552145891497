import { DayOfWeek } from '@shared/constants';
import { ClassSeanceItem } from './class_seance_item';

export type ClassSeance = Partial<{
  id: string;
  dayOfWeeks: DayOfWeek[];
  classSeanceItems: ClassSeanceItem[];
  order: number;
  name: string;
  title: string;
}>;

export type AddSeanceToClassModel = Partial<{
  seanceId: string;
  name: string;
  dayOfWeeks: DayOfWeek[];
  durationInMinutes: number;
  order?: number;
  classSeanceItems: [
    {
      teacherId: string;
      classRoomId: string;
      order?: number;
      beginHour: number;
      beginMinute: number;
      endHour: number;
      endMinute: number;
    }
  ];
}>;
