import { ExerciseLayoutType, LayoutEnums, Role } from '@shared/constants';
import * as API from '@shared/api';
import { CreateExerciseGroup } from '@shared/components';
import { CascaderOption, ExerciseDefinitionModel, ExerciseGroupDefinitionModel, ExerciseGroupModel } from '@shared/models';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useAccessableSeachParams } from '@shared/hooks';
import { layoutToContentType, uuid } from '@shared/utils';

export const BrandCreateExerciseGroup = () => {
  useAccessableSeachParams();

  const [searchParams] = useSearchParams();

  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRAND.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRAND.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRAND.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: true });

  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [exerciseLevel, setExerciseLevel] = useState<(string | number)[]>([]);

  const [exerciseLayoutType, setExerciseLayoutType] = useState<ExerciseLayoutType>();

  const [exerciseGroup, setExerciseGroup] = useState<ExerciseGroupModel>();
  const [exercisIndex, setExerciseIndex] = useState<number>();

  useEffect(() => {
    if (searchParams.size > 0) {
      const exerciseGroupId = searchParams.get('exerciseGroupId');
      if (exerciseGroupId) {
        getExerciseGroupDetail(exerciseGroupId);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (exerciseGroup) {
      if (exerciseGroup.level) {
        const level = [exerciseGroup.level.theme?.type?.field?.id!, exerciseGroup.level.theme?.type?.id!, exerciseGroup.level.theme?.id!, exerciseGroup.level.id!];
        setExerciseLevel(level);
      }
      if (exerciseGroup.exercises && exerciseGroup.exercises.length > 0 && exercisIndex != 0) {
        const _exerciseIndex = exerciseGroup.exercises.length - 1;
        setExerciseIndex(_exerciseIndex);
      }else{
        if(exerciseGroup.exercises && exerciseGroup.exercises.length==0){
          setExerciseIndex(undefined);
        }
      }
    }
  }, [exerciseGroup]);

  useEffect(()=>{
    if(exercisIndex!=undefined){
      setExerciseLayoutType(exerciseGroup?.exercises![exercisIndex].layout);
    }else{
      setExerciseLayoutType(undefined);
    }
  },[exercisIndex]);

  const getExerciseGroupDetail = async (id: string) => {
    try {
      const { data } = await API.CURRICULUM.BRAND_EXERCISE.getExerciseGroupById(id);
      setExerciseGroup(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (fields && types && themes && levels) {
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: themes
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return {
                      value: m.id,
                      label: m.name,
                      children: levels?.data
                        ?.filter((l) => l.theme?.id == m.id)
                        .map((l) => {
                          return {
                            value: l.id,
                            label: l.name,
                          } as CascaderOption;
                        }),
                    };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });
      setAbilityLevelOptions(_items);
    }
  }, [fields, types, themes, levels]);

  const onCreateOrUpdateExerciseGroupMeta = async (data: ExerciseGroupDefinitionModel) => {
    try {
      if (exerciseGroup && exerciseGroup.id) {
        await API.CURRICULUM.BRAND_EXERCISE.updateExerciseGroup(exerciseGroup?.id!, data);
        getExerciseGroupDetail(exerciseGroup?.id!);
        notification.success({ message: 'Egzersiz group başarıyla güncellendi' });
      } else {
        const result = await API.CURRICULUM.BRAND_EXERCISE.createExerciseGroup(data);
        setExerciseGroup(result.data);
        notification.success({ message: 'Egzersiz group başarıyla oluşturuldu' });
      }
    } catch (error) {}
  };

  const onChangeType = (layout: ExerciseLayoutType) => {
    setExerciseLayoutType(layout);
    const _exercises = [...(exerciseGroup?.exercises ? exerciseGroup.exercises : [])];
    //TODO yeni layout eklenirken id ataması olmasa daha iyi gibi ?
    _exercises.push({
      layout: layout,
      content: {
        type: layoutToContentType(layout),
      },
      order: _exercises.length,
    });
    setExerciseIndex(_exercises.length - 1);
    setExerciseGroup({
      ...exerciseGroup,
      exercises: _exercises,
    });
  };

  const onChangeCreateComponent = (item: ExerciseDefinitionModel) => {
    const _exercises = [...(exerciseGroup?.exercises ? exerciseGroup.exercises : [])];
    if (_exercises.length > 0 && exercisIndex != undefined) {
      _exercises.splice(exercisIndex, 1, item);
      setExerciseGroup({
        ...exerciseGroup,
        exercises: _exercises,
      });
    } else {
      notification.error({ message: 'Egzersiz group egzersizleri boş olamaz' });
    }
  };

  const onInsertExerciseToExerciseGroup = async (exercis: ExerciseDefinitionModel) => {
    if (exerciseGroup) {
      try {
        if (exercis.id) {
          await API.CURRICULUM.BRAND_EXERCISE.updateExercise(exerciseGroup.id!, exercis);
          getExerciseGroupDetail(exerciseGroup.id!);
          notification.success({ message: 'Egzersiz güncellendi' });
        } else {
          await API.CURRICULUM.BRAND_EXERCISE.createExercise(exerciseGroup.id!, { ...exercis, id: uuid()});
          getExerciseGroupDetail(exerciseGroup.id!);
          notification.success({ message: 'Egzersiz groubuna eklendi' });
        }
      } catch (error) {}
    } else {
      notification.warning({ message: 'Lütfen egzersiz grubu oluşturunuz' });
    }
  };
  return (
    <CreateExerciseGroup
      isAdminCdn={false}
      exerciseLayoutType={exerciseLayoutType}
      exercisIndex={exercisIndex}
      exerciseGroup={exerciseGroup}
      exerciseLevel={exerciseLevel}
      isLoadingEducationLevel={fieldLoading || typeLoading || themesLoading || levelsLoading}
      abilityLevelOptions={abilityLevelOptions}
      onChanceSelectedIndex={setExerciseIndex}
      onChangeCreateComponent={onChangeCreateComponent}
      onChangeEducationLevel={(value) => {
        setExerciseLevel(value);
      }}
      onChangeType={onChangeType}
      onCreateOrUpdateExerciseGroupMeta={onCreateOrUpdateExerciseGroupMeta}
      onInsertExerciseToExerciseGroup={onInsertExerciseToExerciseGroup}
      onOrder={async (orders) => {
        const data = orders.map((o) => {
          return {
            id: o.id,
            order: o.order,
          };
        });
        if (exerciseGroup) {
          await API.CURRICULUM.BRAND_EXERCISE.orderExercisesInGroup(exerciseGroup.id!, data);
          getExerciseGroupDetail(exerciseGroup.id!);
        }
      }}
      onRemove={async (id) => {
        if (exerciseGroup) {
          await API.CURRICULUM.BRAND_EXERCISE.deleteExerciseFromGroup(exerciseGroup.id!, id);
          getExerciseGroupDetail(exerciseGroup.id!);
        }
      }}
      onSelect={(exercise) => {
        setExerciseIndex(exerciseGroup?.exercises?.findIndex((e) => e.id == exercise.id));
      }}
    />
  );
};

BrandCreateExerciseGroup.path = 'brand-create-exercise-group';
BrandCreateExerciseGroup.title = 'Egzersiz Grup Oluştur';
BrandCreateExerciseGroup.group = 'curriculum';
BrandCreateExerciseGroup.roles = [Role.USER];
BrandCreateExerciseGroup.layouts = [LayoutEnums.BRANDMANAGER, LayoutEnums.LMS];
BrandCreateExerciseGroup.permissions = ['brand.lms.exercise.create'];
