import { CommentOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { NoteType } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { useForm } from '@shared/hooks';
import { ClassDetailModel, ClassNote } from '@shared/models';
import { Avatar, Button, Card, Empty, Form, Modal, Select, Timeline } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

type ClassTimelineProps = {
  notes: ClassNote[] | undefined;
  createClassNote?: (note: string, noteType: NoteType) => void;
  isTeacher: boolean;
  classDetail: ClassDetailModel | undefined;
};

const ClassNoteForm = z.object({
  note: z.string({ required_error: 'Not giriniz' }).min(1, 'Not giriniz'),
  type: z.string({ required_error: 'Lütfen not türü seçiniz' }),
});

export const ClassTimeline = ({ notes, createClassNote, isTeacher, classDetail }: ClassTimelineProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUserContext();
  const form = useForm<
    Partial<{
      note: string;
      type: NoteType;
    }>
  >(ClassNoteForm, {});

  const noteTypeOptions = Object.keys(NoteType).map((key) => ({
    label: t('app.noteType.' + key),
    value: NoteType[key],
  }));

  const createNote = useCallback(async () => {
    const result = form.parse();

    if (result.success) {
      setIsModalOpen(false);
      createClassNote!(result.data['note'], result.data['type']);
      form.reset();
    } else {
      form.setAllTouched();
    }
  }, [form]);

  const timelineItems = notes?.map((note) => ({
    label: (
      <div className="flex flex-col text-center">
        <div>
          <b>{note.account?.name}</b>
        </div>
        <div className="text-[#00000073] text-xs italic">{note.updated ? dayjs(note.updatedAt).fromNow() : dayjs(note.createdAt).fromNow()}</div>
      </div>
    ),
    children: <div>{note.note}</div>,
    dot: <Avatar className="bg-[#F9F0FF]" icon={<CommentOutlined style={{ color: '#D3ADF7' }} />} />,
  }));

  return (
    <>
      <Card
        title={<span className="text-sm font-normal text-[#00000073]">Sınıf Notları</span>}
        styles={{ header: { borderBottom: 'none' } }}
        extra={
          isTeacher && classDetail?.status !== 'COMPLETED' ? (
            <Button size="small" icon={<PlusCircleOutlined />} type="dashed" onClick={() => setIsModalOpen(true)}>
              Yeni Not Ekle
            </Button>
          ) : null
        }
      >
        <div className="timeline_container overflow-scroll pt-3" style={{ height: 'calc(100vh - 520px)' }}>
          {notes?.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Henüz sınıf notu oluşturulmadı" />
          ) : (
            <Timeline className="class-timeline" mode="left" items={timelineItems} reverse />
          )}
        </div>
      </Card>

      <Modal
        className="max-h-[500px] 2xl:max-h-[600px] overflow-y-scroll"
        title={<span className="text-sm font-normal">Not Ekle</span>}
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        centered
      >
        <div className="font-semibold text-lg">{`${user.name} ${user.surname}`}</div>
        <div className="italic text-[#00000073] text-base">{dayjs().format('DD.MM.YYYY')}</div>

        <Form layout="vertical" className="mt-5">
          <Form.Item {...form.formItem('type')} label="Not Türü" className="w-2/3">
            <Select {...form.inputSelected('type')} placeholder="Seçiniz" options={noteTypeOptions} />
          </Form.Item>
          <Form.Item {...form.formItem('note')} label="Not">
            <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 3, maxRows: 5 }} {...form.input('note')} />
          </Form.Item>

          <Button type="primary" onClick={createNote} className="mt-3">
            Not Ekle
          </Button>
        </Form>
      </Modal>
    </>
  );
};
