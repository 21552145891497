import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

const bc = new BroadcastChannel('auth.channel.joedtech');
export const AuthLayout = ({ pages }) => {
  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        window.location.reload();
      }
    };
  }, []);

  return (
    <Layout style={{ height: '100svh' }}>
      <Content style={{ overflow: 'auto', backgroundColor: '#F5F5F5' }}>
        <Routes>
          {pages.map(([key, Comp]) => (
            <Route
              key={key}
              path={Comp.path}
              element={<Comp />}
              handle={(a, b, c) => {
                console.log(a, b, c, Comp);
              }}
            />
          ))}
        </Routes>
      </Content>
    </Layout>
  );
};
