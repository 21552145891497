import { ExerciseDefinitionModel, ExerciseGroupDefinitionModel, ExerciseGroupModel, PaginateModel, UseFetchOptions } from '@shared/models';
import { useFetch } from '../../hooks';
import { request } from '@shared/utils';

export default {
    createExerciseGroup: async (group: ExerciseGroupDefinitionModel) => request('/lms/exercise-group', 'POST', group),
    fetchAllExerciseGroup: (config: UseFetchOptions)=> useFetch<PaginateModel<ExerciseGroupModel>>('/lms/exercise-group',{ method: 'GET', ...config }),
    fetchExerciseGroupsByLevel: async (levelId:string) => request('/lms/exercise-group','GET'),
    getExerciseGroupById: async (id: string) => request('/lms/exercise-group/' + id, 'GET'),
    updateExerciseGroup: async (id:string,group: ExerciseGroupDefinitionModel) => request("/lms/exercise-group",'PUT',{...group,id}),
    deleteExerciseGroup: async (id:string)=>request('/lms/exercise-group/'+id,'DELETE'),
    orderExercisesInGroup: async (groupId: string, orders: ExerciseDefinitionModel[]) => request('/lms/exercise-group/' + groupId + '/order', 'PUT', orders),
    cloneExerciseGroup:async (groupId:string)=>request('/lms/exercise-group/'+groupId+'/clone','GET'),

    createExercise: async (groupId: string, exercise: ExerciseDefinitionModel) => request('/lms/exercise-group/' + groupId, 'POST', exercise),
    updateExercise:async (groupId:string,exercise:ExerciseDefinitionModel)=>request("/lms/exercise-group/"+groupId,'PUT',exercise),
    deleteExerciseFromGroup:async (groupId:string,exerciseId:string)=>request("/lms/exercise-group/"+groupId+"/exercise/"+exerciseId,'DELETE'),
};