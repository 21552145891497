import { ImageView } from "@shared/components/curriculum/ImageView";
import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";
import { shuffleToArray } from "@shared/utils";
import { Button } from "antd";
import { useEffect } from "react";

type ExerciseProps = {
    content: ExerciseDefinitionContentModel,
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isAdminCdn: boolean;
    isInClass: boolean;
}

export const GapFillingSelected = ({ content, interaction, isPreview = false, isAdminCdn, isInClass }: ExerciseProps) => {

    return (
        <>
            {
                isInClass ?
                    (
                        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10">
                            <div className="border border-double border-mid-gray rounded m-2 h-full w-3/6 text-center " >
                                <ImageView image={content?.image ? content.image : {}} />
                            </div>
                            <div className="flex flex-col w-3/6 h-full">

                                {
                                    interaction.orderItems ? (
                                        <div className=" mt-3 text-center space-y-6 space-x-1">
                                            <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                                                {shuffleToArray(interaction.orderItems![0]).map((o, i) => <Button key={i} type="default">{o}</Button>)}
                                            </div>
                                        </div>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                    ) :
                    (
                        <div className="flex flex-col items-center h-full bg-white pt-10 pb-10 relative">
                            {
                                content.image && (<div className="border border-double border-mid-gray rounded m-2 h-2/6 text-center" >
                                    <ImageView image={content?.image ? content.image : {}} />
                                </div>)
                            }

                            {
                                interaction.orderItems ? (
                                    <div className={content.image ?"h-1/6 mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center":"h-3/6 mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center"}>
                                        {interaction.instruction?.title && <span>{interaction.instruction?.title}</span>}
                                        <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                                            {interaction.orderItems!.map((o, i) => <Button key={i} disabled type="default">__</Button>)}
                                        </div>
                                    </div>
                                ) : (<></>)
                            }
                            {
                                interaction.orderItems ? (
                                    <div className="h-3/6 mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center">
                                        <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                                            {shuffleToArray(interaction.orderItems!).map((o, i) => <Button key={i} type="default">{o}</Button>)}
                                        </div>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    )
            }
        </>
    )
}