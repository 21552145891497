import { notification } from 'antd';

//let notification: NotificationInstance;

export const Notification = () => {
  //const staticFunction = App.useApp();
  //notification = staticFunction.notification;
  notification.config({
    placement: 'bottomLeft',
    duration: 4,
  });

  return null;
};
