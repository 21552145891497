import { useFetch } from "@shared/hooks";
import { CourseTopicDefinetion, CourseTopicModel, PaginateModel, UseFetchOptions } from "@shared/models";
import { request } from "@shared/utils";
export default {
    create : async (versionId:string,unitId:string,topic:CourseTopicDefinetion)=>request('/lms/content-version/'+versionId+'/unit/'+unitId+'/topic','POST',topic),
    fetchAll:(config:UseFetchOptions) => useFetch<PaginateModel<CourseTopicDefinetion>>('',{method:'GET',...config}),
    getTopicsByVersionIdAndUnitId : async (versionId:string,unitId:string)=>request('/lms/content-version/'+versionId+'/unit/'+unitId+'/topic','GET'),
    order:async(versionId:string,unitId:string,order:CourseTopicModel[])=>request('/lms/content-version/'+versionId+'/unit/'+unitId+'/topic/order','PUT',order),
    delete:async (versionId:string,unitId:string,topicId:string)=>request('/lms/content-version/'+versionId+'/unit/'+unitId+'/topic/'+topicId,'DELETE'),
    update:async(versionId:string,unitId:string,topic:CourseTopicModel)=>request("/lms/content-version/"+versionId+"/unit/"+unitId+"/topic","PUT",topic),
}