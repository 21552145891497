import { CreateObserverStep, CreatePrePlacement, EnrollmentPreViewStep } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Enrollment, EnrollmentStatus, EnrollmentTypes, PaginationQuery } from '@shared/models';
import { urlCleaner } from '@shared/utils';
import { Button, Popconfirm, Steps, Tooltip, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const CreateIndividualEnrollmentRequest = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSeachParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const [enrollment, setEnrollment] = useState<Enrollment>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMod, setIsEditMod] = useState<boolean>(false);
  const [isPreviewMod, setIsPreviewMod] = useState<boolean>(false);
  const [enrollmentId, setEnrollmentId] = useState<string>();
  const [prePlacements, prePlacementsLoading, getPrePlacements] = API.ENROLLMENT.getPrePlacements(enrollment?.id!, { init: false });
  const [observers, observersLoading, getObservers] = API.ENROLLMENT.getObservers({ init: false });
  const [observersQuery, setObserversQuery] = useState<PaginationQuery>();

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('enrollment');
      if (id) {
        setIsLoading(true);
        getEnrollmentById(id);
      }
    }
  }, [searchParams]);

  const getEnrollmentById = async (id: string) => {
    try {
      const { data } = await API.ENROLLMENT.fetchById(id);
      setIsPreviewMod(data.status != EnrollmentStatus.INFO);
      setEnrollmentId(id);
      setIsEditMod(true);
      setEnrollment(data);
      setIsLoading(false);
    } catch (error) {}
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  //enrollment oluşturulunca yani sayfa 2'ye geçince öğrenci bilgilerini ve gözlemci bilgilerini çağırıyoruz queryleri set ederek
  //öğrenci listesi gelirken(preplacement) overflow scroll kullandığımız için burada pagination kullanmak tasarımı bozuyor kaldırıldı
  useEffect(() => {
    if (enrollment) {
      setEnrollmentId(enrollment.id);
      getPrePlacements();
      setObserversQuery({ page: 1, limit: 4, enrollmentId: enrollment.id });
    }
  }, [enrollment]);

  useEffect(() => {
    if (observersQuery) {
      getObservers(undefined, observersQuery);
    }
  }, [observersQuery]);

  const onApproval = async () => {
    if (enrollment?.status != EnrollmentStatus.READY_FOR_PLANNING) {
      await API.ENROLLMENT.setAsReadyForPlanning(enrollment?.id!);
    }
    navigate('/crm/enrollments?' + urlCleaner(searchParams));
  };
  const steps = [
    {
      title: 'Öğrenci Bilgisi',
      content: !isLoading ? (
        //TODO: check enrollment, if enrollment is null send just type, or send  enrollment
        <CreatePrePlacement
          enrollment={{ ...enrollment, type: EnrollmentTypes.INDIVIDUAL }}
          prePlacements={prePlacements && prePlacements?.data ? prePlacements!.data! : []}
          isPreview={isPreviewMod}
          onSave={async (v) => {
            const _enrolment = {
              name: v.name + ' ' + v.surname,
              email: v.email,
              nationalId: v.nationalId,
              type: EnrollmentTypes.INDIVIDUAL,
              phone: v.phone,
            } as Enrollment;
            const { ok: EnrollmentCreateOk, data } = await API.ENROLLMENT.create(_enrolment);
            if (EnrollmentCreateOk) {
              setEnrollment(data);
              const { ok } = await API.ENROLLMENT.createPrePlacment({ ...v, enrollmentId: data.id });
              const layout = searchParams.get('layout');
              const brandId = searchParams.get('brandId');
              const branchId = searchParams.get('branchId');
              setSeachParams({ layout: layout!, brandId: brandId!, branchId: branchId!, enrollment: data.id }, { replace: false });
              next();
              return ok;
            } else {
              notification.error({ message: 'Kayıt oluşturulamadı.' });
            }
          }}
          onDelete={async (id) => {
            const { ok } = await API.ENROLLMENT.deletePrePlacement(id);
            getPrePlacements();
            return ok;
          }}
          onUpdate={async (v) => {
            const _enrolment = {
              id: enrollmentId!,
              name: v.name + ' ' + v.surname,
              email: v.email,
              nationalId: v.nationalId,
              type: EnrollmentTypes.INDIVIDUAL,
              phone: v.phone,
            } as Enrollment;
            const { ok: EnrollmentUpdateOk } = await API.ENROLLMENT.update(_enrolment);
            if (EnrollmentUpdateOk) {
              const { ok } = await API.ENROLLMENT.updatePrePlacement({ ...v, enrollmentId: enrollmentId! });
              getPrePlacements();
              next();
              return ok;
            } else {
              notification.error({ message: 'Kayıt oluşturulamadı.' });
            }
          }}
        />
      ) : (
        <Spinner loading={true} />
      ),
      description: '',
    },
    {
      title: 'Gözetmen Ekle',
      content:
        enrollment && observers ? (
          <CreateObserverStep
            enrollment={enrollment}
            observers={observers}
            isPreview={isPreviewMod}
            onSave={async (v) => {
              await API.ENROLLMENT.createObserver({
                ...v,
                enrollmentId: enrollment.id,
              });
              getObservers(undefined, observersQuery);
            }}
            onUpdate={async (v) => {
              await API.ENROLLMENT.updateObserver({ ...v, enrollmentId: enrollment.id });
              getObservers(undefined, observersQuery);
            }}
            onDelete={async (id) => {
              await API.ENROLLMENT.deleteObserver(id);
              getObservers(undefined, { ...observersQuery, page: 1 });
            }}
            onPaginationChange={(page) => setObserversQuery((prev) => ({ ...prev, page }))}
          />
        ) : (
          <Spinner loading={true} />
        ),
      description: '',
    },
    {
      title: 'Önizleme/Onay',
      content:
        enrollment && prePlacements ? (
          <EnrollmentPreViewStep
            enrollment={enrollment}
            prePlacements={prePlacements?.data ?? []}
            observers={observers ?? { data: [] }}
            onPaginationChange={(page) => setObserversQuery((prev) => ({ ...prev, page }))}
          />
        ) : (
          <Spinner loading={true} />
        ),
      description: '',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }));
  return (
    <>
      <Steps current={current} items={items} />
      <div className="w-full pt-3 pl-3 pr-3">
        <div className="flex justify-end">
          <Button
            onClick={() => {
              navigate('/crm/enrollments?' + urlCleaner(searchParams));
            }}
          >
            Kayıt Listesine Dön
          </Button>
        </div>
        {steps[current].content}
      </div>
      {current != 0 && (current != 1 || enrollment) ? (
        <div className="flex justify-end mt-5">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Sonraki adım
            </Button>
          )}
          {current === steps.length - 1 && (
            <Tooltip title={prePlacements?.data?.length == 0 ? 'Enrollment must have at least one pre placement' : ''} color="red">
              <Popconfirm
                title="Publish enrollment"
                description="Are you sure publish this enrollment?"
                disabled={prePlacements?.data?.length == 0}
                onConfirm={onApproval}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Onayla</Button>
              </Popconfirm>
            </Tooltip>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Geri dön
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

CreateIndividualEnrollmentRequest.path = '/crm/create-individual-enrollment-request';
CreateIndividualEnrollmentRequest.title = 'Yeni bireysel kayıt talebi';
CreateIndividualEnrollmentRequest.group = 'temp-record';
CreateIndividualEnrollmentRequest.roles = [Role.ADMIN];
CreateIndividualEnrollmentRequest.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.CRM];
CreateIndividualEnrollmentRequest.permissions = ['branch.lms.enrollment.create'];
