import { DeleteOutlined } from "@ant-design/icons";
import { ImageUpload, SoundUpload } from "@shared/components";
import { ExerciseDefinitionContentType, ExerciseDefinitionInteractionType, ExerciseLayoutType } from "@shared/constants";
import { ExerciseChoicesModel, ExerciseDefinitionModel, ImageCdnModel, MediaModel } from "@shared/models";
import { uuid } from "@shared/utils";
import { Button, Form, Input, notification, Radio, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";

type CreateListeningSingleSelectProps = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  isAdminCdn: boolean;
};

export const CreateListeningSingleSelect = ({ item, onSave, onChange, isAdminCdn }: CreateListeningSingleSelectProps) => {
  const [audio, setAudio] = useState<MediaModel>();
  const [image, setImage] = useState<ImageCdnModel>();
  const [instructionTitle, setInstructionTitle] = useState<string>();
  const [instructionSubTitle, setInstructionSubTitle] = useState<string>();
  const [choice, setChoice] = useState<string>();
  const [choiceList, setChoiceList] = useState<ExerciseChoicesModel[]>([]);
  const [correctAnswerId, setCorrectAnswerId] = useState<string>();
  const [isMediaLoading, setIsMediaLoading] = useState(false);


  useEffect(() => {
    setAudio(item.content?.sound);
    setImage(item.content?.image?.resourceUrl ? { id: item.content?.image?.resourceId, variants: [item.content?.image?.resourceUrl] } : undefined);
    setInstructionTitle(item.interaction?.instruction?.title);
    setInstructionSubTitle(item.interaction?.instruction?.description);
    setChoiceList(item.interaction?.choices ?? []);
    setCorrectAnswerId(item.interaction?.choices?.find((c) => c.isCorrect)?.id);
  }, [item.id]);

  useEffect(() => {
    if (audio || image || instructionTitle || instructionSubTitle || choiceList.length > 0) {
      const i = {
        ...item,
        layout: ExerciseLayoutType.LISTENING_SINGLE_SELECT,
        content: {
          ...item.content,
          content: {
            ...(item.content ? item.content.content : {}),

          },
          image: image ? { resourceId: image?.id, resourceUrl: image?.variants![0] } : undefined,
          sound: audio,
          type: ExerciseDefinitionContentType.AUDIO,
        },
        interaction: {
          ...item.interaction,
          type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
          choices: choiceList,

          instruction: {
            title: instructionTitle,
            description: instructionSubTitle,
          },
        },
      } as ExerciseDefinitionModel;
      onChange(i);
    }
  }, [audio, image, instructionTitle, instructionSubTitle, choiceList]);

  const send = useCallback(() => {
    if (choiceList.length > 4 || choiceList.filter((c) => c.isCorrect).length == 0) {
      showErrorAlert("Lütfen bir şıkkı doğru olarak işaretleyin");
    } else {
      const i = {
        ...item,
        layout: ExerciseLayoutType.LISTENING_SINGLE_SELECT,
        content: {
          ...item.content,
          content: {
            ...(item.content ? item.content.content : {}),
          },
          type: ExerciseDefinitionContentType.AUDIO,
          image: image ? { resourceId: image?.id, resourceUrl: image?.variants![0] } : undefined,
          sound: audio,
        },
        interaction: {
          ...item.interaction,
          type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
          choices: choiceList,
          instruction: {
            title: instructionTitle,
            description: instructionSubTitle,
          },
        },
      } as ExerciseDefinitionModel;
      onSave(i);
    }

  }, [audio, image, instructionTitle, instructionSubTitle, choiceList]);


  const showErrorAlert = (message: string) => {
    notification.warning({ message: message, })
  }



  return <div className="p-5">
    <div className="flex justify-end">
      <Button type="primary" size="small" onClick={() => send()}>
        {item.id ? 'Güncelle' : 'Ekle'}
      </Button>
    </div>

    <Form layout="vertical" className="">

      <div className="grid grid-cols-2 gap-4 ">
        <div className="flex items-center">
          <Form.Item label={'Ses dosyası'}>
            <SoundUpload
              sound={audio}
              onChangeLoading={(e) => {
                setIsMediaLoading(e);
              }}
              onChange={(sound) => {
                setAudio(sound);
              }}
              isAdminCdn={isAdminCdn}
            />
          </Form.Item>
          <Typography.Text type="secondary">
            <span className="text-red-500">*</span> Desteklenen formatlar: .mp3, .wav
          </Typography.Text>
        </div>
        <div className="flex items-center">
          <Form.Item label={'Resim dosyası'}>
            <ImageUpload
              image={image ?? {}}
              onChange={(image) => {
                setImage(image);
              }}
              onChangeLoading={setIsMediaLoading}
              isAdminCdn={isAdminCdn}
            />
          </Form.Item>
          <Typography.Text type="secondary">
            (Opsiyonel) Desteklenen formatlar: .jpeg, .png
          </Typography.Text>
        </div>
      </div>


      <div className="grid grid-cols-2 space-x-2 mt-5">
        <Form.Item label={'Bilgilendirme Başlığı'}>
          <Input value={instructionTitle} onChange={(e) => setInstructionTitle(e.target.value)} disabled={isMediaLoading} />
        </Form.Item>
        <Form.Item label={'Bilgilendirme Alt Başlığı'}>
          <Input value={instructionSubTitle} onChange={(e) => setInstructionSubTitle(e.target.value)} disabled={isMediaLoading} />
        </Form.Item>
      </div>

      <Form.Item label="Seçenek Ekle">
        <div className="flex">
          <Input value={choice} onChange={(e) => setChoice(e.target.value)} disabled={isMediaLoading || choiceList.length >= 4} />
          <Button
            disabled={!choice}
            type="default"
            onClick={() => {
              const newList = [...choiceList, { id: uuid(), title: choice, isCorrect: false }];
              setChoiceList(newList);
              setChoice('');
            }}
          >
            +
          </Button>
        </div>
      </Form.Item>

      {choiceList && choiceList.length > 0 && (
        <>
          <Form.Item label={'Seçenekler'}>
            <div className="grid grid-cols-4 gap-2">
              {choiceList.map((c, index) => (
                <div className="flex flex-row  items-center">
                <Input
                  key={c.id}
                  value={c.title}
                  disabled={isMediaLoading}
                  onChange={(e) => {
                    const newList = [...choiceList];
                    newList[index].title = e.target.value;
                    setChoiceList(newList);
                  }}
                />
                <Button  icon={<DeleteOutlined  />}  size="small" onClick={() => setChoiceList(choiceList.filter((choice) => choice.id !== c.id))}/>
                </div>
              ))}
            </div>
          </Form.Item>

          <Form.Item label="Doğru seçeneği belirleyiniz.">
            <div className="flex flex-row">
              <Radio.Group
                className="flex flex-col gap-1"
                options={choiceList?.map((c) => ({ id: c.id, label: c.title!, value: c.id! }))}
                value={correctAnswerId}
                disabled={isMediaLoading}
                onChange={({ target }) => {
                  setCorrectAnswerId(target.id);
                  const newList = [...choiceList];
                  newList.map((list) => (target.id === list.id ? (list.isCorrect = true) : (list.isCorrect = false)));
                  setChoiceList(newList);
                }}
              />
            </div>

          </Form.Item>
        </>
      )}
    </Form>
  </div>;
}