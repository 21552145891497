import { EducationLevelsView } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import * as API from '@shared/api';
import { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select, Switch, notification, InputNumber } from 'antd';
import { CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useAccessableSeachParams } from '@shared/hooks';
import { EducationLevel, PaginationQuery } from '@shared/models';

export const BranchEducationLevels = () => {
  useAccessableSeachParams();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });
  const [result, loading, getLevels] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: false });
  const [theme, themeLoading, getThemes] = API.EDUCATION_LEVEL.BRANCH.useFetchTheme({ init: true });
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState<EducationLevel>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    getLevels(undefined, paginationQuery);
  }, [paginationQuery]);

  useEffect(() => {
    if (selectedLevel) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedLevel]);

  useEffect(() => {
    if (theme?.data) {
      const filter = theme.data.map((t) => {
        return { label: t.name!, value: t.id! };
      });
      setFilterOptions(filter as never[]);
    }
  }, [theme]);

  const title = (
    <div className="flex justify-between items-center">
      <div className="flex flex-row content-center items-center space-x-2">
        <span className="font-bold">Kurlar</span>
        <Input
          placeholder={'Seviye Adı'}
          value={paginationQuery ? paginationQuery['name'] : undefined}
          onChange={(e) => {
            const name = e.target.value;
            if (name) {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                name,
              });
            } else {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                name: undefined,
              });
            }
          }}
        />
        <div className="flex flex-row space-x-2">
          <Select
            placeholder={'Türe göre filtrele'}
            value={paginationQuery ? paginationQuery['theme'] : undefined}
            style={{ width: '100%' }}
            options={filterOptions}
            onChange={(e) => {
              setPaginationQuery({
                ...paginationQuery,
                page: 1,
                theme: e,
              });
            }}
          />
          {paginationQuery['name'] || paginationQuery['theme'] ? (
            <div className="flex items-center">
              <Button type="text" size="small" icon={<MinusCircleOutlined />} onClick={() => setPaginationQuery({ page: 1, limit: 6 })}>
                Filtreyi Kaldır
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );

  const onChangeActiveStatus = async (id: string, active: boolean) => {
    try {
      await API.EDUCATION_LEVEL.BRANCH.updateLevel({ id, active });
      notification.success({ message: 'İşlem başarıyla gerçekleşti' });
    } catch (error) {
    } finally {
      setPaginationQuery({
        ...paginationQuery,
      });
    }
  };

  const onUpdateSelectedLevel = async () => {
    try {
      await API.EDUCATION_LEVEL.BRANCH.updateLevel({ ...selectedLevel });
      notification.success({ message: 'İşlem başarıyla gerçekleşti' });
    } catch (error) {
    } finally {
      setPaginationQuery({
        ...paginationQuery,
      });
      setSelectedLevel(undefined);
    }
  };

  const onCloseDrawer = () => {
    setSelectedLevel(undefined);
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <Drawer
        width={450}
        title={selectedLevel?.theme?.type?.field?.name + ' / ' + selectedLevel?.theme?.type?.name + ' / ' + selectedLevel?.theme?.name + ' / ' + selectedLevel?.name}
        open={isDrawerOpen}
        onClose={onCloseDrawer}
        closable={true}
      >
        <Form layout="vertical">
          <Form.Item label={'Kur Adı:'}>
            <Input
              value={selectedLevel?.name}
              onChange={(e) => {
                setSelectedLevel({
                  ...selectedLevel,
                  name: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={'Toplam Ders Saati :'}>
            <InputNumber
              min={1}
              value={selectedLevel?.durationInHours}
              onChange={(e) => {
                setSelectedLevel({
                  ...selectedLevel,
                  durationInHours: e || ('' as any),
                });
              }}
            />
          </Form.Item>
          <Form.Item label={'Aktif/Pasif'}>
            <Switch
              value={selectedLevel?.active}
              onChange={(e) => {
                setSelectedLevel({
                  ...selectedLevel,
                  active: e,
                });
              }}
            />
          </Form.Item>
          <Button type="primary" onClick={onUpdateSelectedLevel}>
            Güncelle
          </Button>
        </Form>
      </Drawer>
      <EducationLevelsView
        levels={result ?? {}}
        pageSize={result?.count}
        isLoading={loading}
        title={title}
        isActiveView={true}
        onChangeActiveStatus={onChangeActiveStatus}
        onEdit={(e) => {
          setSelectedLevel(e);
        }}
        onChangePagination={(page) => setPaginationQuery({ ...paginationQuery, page: page })}
      />
    </div>
  );
};

BranchEducationLevels.path = 'branch/education-levels';
BranchEducationLevels.title = 'Kurlar';
BranchEducationLevels.group = 'definitions';
BranchEducationLevels.roles = [Role.ADMIN];
BranchEducationLevels.layouts = [LayoutEnums.BRANCHMANAGER];
BranchEducationLevels.permissions = ['branch.lms.definition.view'];
