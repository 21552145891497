import { TagOutlined } from '@ant-design/icons';
import { ClassDetailModel, ContentVersion } from '@shared/models';
import { Button, Divider, notification, Tag } from 'antd';
import * as API from '@shared/api';
import { useEffect, useState } from 'react';
import { convertDate, renderLevelsAsString } from '@shared/utils';

type ClassContentVersionProps = {
  classDetail: ClassDetailModel | undefined;
  getClassDetail: () => void;
};

export const ClassContentVersion = ({ classDetail, getClassDetail }: ClassContentVersionProps) => {
  const [contentVersionData, setContentVersionData] = useState<ContentVersion[]>([]);

  useEffect(() => {
    getContentVersion();
  }, []);

  const getContentVersion = async () => {
    const response = await API.CLASS.getBranchContentVersion();
    setContentVersionData(response.data);
  };

  const updateContentVersion = async (data) => {
    const response = await API.CLASS.updateContentVersion(classDetail?.id, data.id);
    if (response.ok) {
      notification.success({ message: 'Aktif içerik başarıyla değiştirildi.' });
      getContentVersion();
      getClassDetail();
    } else {
      notification.error({ message: 'Aktif içerik değiştirilemedi. Lütfen tekrar deneyin.' });
    }
  };

  return (
    <div>
      <h3>{classDetail?.name}</h3>
      <div className="mx-1 mt-3 max-h-[500px] overflow-y-auto">
        {contentVersionData
          .sort((a, b) => {
            if (a.id === classDetail?.contentVersionId) return -1;
            if (b.id === classDetail?.contentVersionId) return 1;
            if (a.isDefault) return -1;
            if (b.isDefault) return 1;
            return 0;
          })
          .map((data) => (
            <div key={data.id} className="mt-1">
              <div className="flex justify-between">
                <div className="flex justify-center item-center">
                  <TagOutlined className="text-lg mr-2" />
                  Versiyon {data.version}.{data.brandVersion}
                  <div>
                    {data.id == classDetail?.contentVersionId ? (
                      <Tag className="ml-3" color="orange">
                        Aktif
                      </Tag>
                    ) : data.isDefault ? (
                      <Tag className="ml-3" color="blue">
                        Markanın tavsiye ettiği
                      </Tag>
                    ) : null}
                  </div>
                </div>

                <div>{convertDate(data.createdAt)}</div>
              </div>
              <div className="bg-gray-100 mt-1 rounded-md">
                <div className="px-3 py-3">{renderLevelsAsString(data.level)}</div>
              </div>
              <div className="bg-gray-100 mt-1 rounded-md">
                <div className="px-3 py-3">{data.description}</div>
              </div>
              <div className=" mt-3 mb-3">
                {!(data.id == classDetail?.contentVersionId) && (
                  <Button type="primary" className="w-full" onClick={() => updateContentVersion(data)}>
                    Versiyonu sınıf müfredatına ekle
                  </Button>
                )}
              </div>
              <Divider className="m-0"></Divider>
            </div>
          ))}
      </div>
    </div>
  );
};
