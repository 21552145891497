import { Enrollment, Observer, PaginateModel, PrePlacement, PrePlacementDemand, PrePlacementDemandBasicInfo, PrePlacementDemandStatusUpdate, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';
import { useFetch } from '../hooks';

export default {
  fetchById: (id: string) => request('/enrollment/' + id, 'GET'),
  create: (data: Enrollment) => request('/enrollment', 'POST', data),
  update: (data: Enrollment) => request('/enrollment', 'PUT', data),
  delete: (id: string) => request('/enrollment/' + id, 'DELETE'),
  getAllEnrollment: (config: UseFetchOptions) => useFetch<PaginateModel<Enrollment>>('/enrollment', { method: 'GET', ...config }),
  createPrePlacment: (data: PrePlacement) => request('/enrollment/pre-placement', 'POST', data),
  getPrePlacements: (enrollmentId: string, config: UseFetchOptions) =>
    useFetch<PaginateModel<PrePlacement>>('/enrollment/pre-placement?enrollmentId=' + enrollmentId, { method: 'GET', ...config }),
  getPrePlacementById: async (id: string) => request('/enrollment/pre-placement/' + id, 'GET'),
  getPrePlacementsAll: (config: UseFetchOptions) => useFetch<PaginateModel<PrePlacement>>('/enrollment/pre-placement', { method: 'GET', ...config }),
  deletePrePlacement: (id: string) => request('/enrollment/pre-placement/' + id, 'DELETE'),
  updatePrePlacement: (data: PrePlacement) => request('/enrollment/pre-placement', 'PUT', data),
  cancelPrePlacement: (id: string) => request(`/enrollment/${id}/set-as-canceled`, 'PUT'),
  createObserver: (data: Observer) => request('/enrollment/observer', 'POST', data),
  getObservers: (config: UseFetchOptions) => useFetch<PaginateModel<Observer>>('/enrollment/observer', { method: 'GET', ...config }),
  deleteObserver: (id: string) => request('/enrollment/observer/' + id, 'DELETE'),
  updateObserver: (data: Observer) => request('/enrollment/observer', 'PUT', data),
  setAsReadyForPlanning: (enrollmentId: string) => request('/enrollment/' + enrollmentId + '/set-as-ready-for-planning', 'PUT'),
  //Demand Table Requests
  fetchDemandTable: (config: UseFetchOptions = {}) => useFetch<any>('/demand-table', { method: 'GET', ...config }),
  //Demand Requests
  getPrePlacementDemands: (config: UseFetchOptions) => useFetch<PrePlacementDemand[]>('/enrollment/pre-placement/demand', { method: 'GET', ...config }),
  getPrePlacementDemandsById: (id: string) => request(`/enrollment/pre-placement/demand?enrollmentId=${id}&demandStatus=ACTION_REQUIRED`, 'GET'),
  getPrePlacementByPrePlacement: (id: string) => request('/enrollment/observer/by-pre-placement/' + id, 'GET'),
  //Student Demand Requests for Branch Manager
  getBranchStudentDemands: (config: UseFetchOptions) => useFetch<PrePlacementDemandBasicInfo[]>('/enrollment/pre-placement/demand', { method: 'GET', ...config }),
  getBranchStudentDemandDetail: (demandId: string) => request('/enrollment/pre-placement/demand/' + demandId, 'GET'),
  getBranchStudentCalendar: (demandAccountId: string, begin: Date, end: Date) =>
    request('/enrollment/pre-placement/demand/' + demandAccountId + '/calendar', 'GET', undefined, { begin, end }),

  updateDemandStatusAsPlanning: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/update-as-planning`, 'PUT', body),
  updateDemandStatusAsCanceled: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/update-as-canceled`, 'PUT', body),
  updateDemandStatusAsFailed: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/update-as-failed`, 'PUT', body),
  updateDemandStatusAsSuccess: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/update-as-success`, 'PUT', body),
  updateDemandStatusAsDelayedActionRequired: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/update-as-delayed-action-required`, 'PUT', body),
  updateDemandStatusAsFreeze: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/update-as-freeze`, 'PUT', body),
  repeatDemandRequest: (preplacementId: string, demandId: string, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/repeat`, 'PUT', body),
  repeatDemandWith: (preplacementId: string, demandId: string,selectedLevelId, body:PrePlacementDemandStatusUpdate) =>
    request(`/enrollment/pre-placement/${preplacementId}/demand/${demandId}/repeat-with/${selectedLevelId}`, 'PUT', body),
};
