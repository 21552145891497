import { AppConfig } from "@shared/config";
import { auth, useAccessableContext } from "@shared/contexts";
import { useCallback, useEffect, useState } from "react"
import { ManagerOptions, Socket, io } from "socket.io-client"
/* useMemo */
export const useWebSocket = (namespace: string, listenerMap: { [key: string]: (data: any) => void }) => {
    const { brand, branch } = useAccessableContext();
    const [socket, setSocket] = useState<Socket>();
    const [isConnected, setIsConnected] = useState<boolean>(false);

    const options: Partial<ManagerOptions> = {
        transportOptions: {
            polling: {
                extraHeaders: {
                    branchId: branch?.id || "",
                    brandId: brand?.id || "",
                    Authorization: auth.basic || "",
                },
            },
        },
        autoConnect: true,
        reconnection: true,
        forceNew: true,
    };

    const onEmit = useCallback((url: string, data: any) => {
        socket?.emit(url, data);
    }, [socket]);



    const socketConnection = useCallback(() => {
        const newSock = io(AppConfig.wsUrl(namespace), options);
        setSocket(newSock);
    }, [namespace, listenerMap,setIsConnected]);

    useEffect(() => {
        if(socket) {
            socket.off();
            socket.on("connect", () => {
                setIsConnected(true);
            });
            socket.on("disconnect", () => {
                setIsConnected(false);
            });
            Object.keys(listenerMap).forEach((key) => {
                socket.on(key, listenerMap[key]);
            });
        }
    }, [socket, listenerMap]);

    useEffect(() => {
        if(!socket) {
            socketConnection();
        }
        return () => {
            socket?.close();
            socket?.disconnect();
        }
    }, [socket]);


    return [socket, isConnected, onEmit];
}
