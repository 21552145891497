import { CascaderOption } from "@shared/models";
import { Cascader } from "antd"
const {SHOW_PARENT,SHOW_CHILD} = Cascader;

type MultipleCascaderProps = {
    items?: CascaderOption[];
    value?:(string | number)[][];
    placeholder?:string;
    disabled?:boolean;
    loading?:boolean;
    isShowChild?:boolean;
    onChange: (value: (string | number)[][]) => void;
  };
export const MultipleCascader = ({items,value,disabled = false,loading=false,placeholder="Lütfen bir seçim yapınız",isShowChild=false,onChange}:MultipleCascaderProps)=>{
    
    return (
        <Cascader options={items} showCheckedStrategy={isShowChild ? SHOW_CHILD:SHOW_PARENT} value={value} multiple maxTagCount="responsive" disabled={disabled} expandTrigger="hover" placeholder={placeholder} loading={loading} onChange={onChange} />
    )
}