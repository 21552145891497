import { CourseTopicType } from "@shared/constants"

export type CourseTopicDefinetion = Partial<{
    name:string,
    description:string,
    order:number,
    type:CourseTopicType
}>

export type CourseTopicModel = Partial<{
    id:string,
    name:string,
    description:string,
    order:number,
    type:CourseTopicType,
    pageCount:number,
}>