type PoweredByTagType = {
  collapsed: boolean;
};
const bc = new BroadcastChannel('auth.channel.joedtech');
export const PoweredByTag = ({ collapsed }: PoweredByTagType) => {
  return collapsed ? (
    <div className="absolute bottom-10 w-full flex flex-col items-center justify-center font-bold">
      <p className="">JT</p>
    </div>
  ) : (
    <div className="absolute bottom-10 w-full flex flex-col items-center justify-center font-bold">
      <p className="">JoedTech © {new Date().getFullYear()}</p>
    </div>
  );
};
