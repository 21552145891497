import { CourseTopicModel, CourseTopicPageModel } from "@shared/models";
import { ExerciseHandler } from "./exercise_handler";
import { useEffect, useState } from "react";
import { CourseTopicPageType } from "@shared/constants";
import { LeftCircleOutlined, MoreOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Card, Progress } from "antd";
import { TextPage, VideoPage } from "../learning_content_version";
import { ImagePage } from "../learning_content_version/content_page/image_page";

type ExerciseOrPageHandlerProps = {
    page: CourseTopicPageModel,
    isAdminCdn: boolean,
    isPreview?: boolean,
    isInClass: boolean,
    onNext: () => void,
    onPrev: () => void,
    selectedTopic?: CourseTopicModel
}
export const ExerciseOrPageHandler = ({ page, isAdminCdn, isPreview = true, isInClass, onPrev, onNext, selectedTopic }: ExerciseOrPageHandlerProps) => {

    const [exercisePage, setExercisePage] = useState<number>(0);
    const [isExercisePage, setIsExercisePage] = useState<boolean>(false);

    useEffect(() => {
        setIsExercisePage(page.type == CourseTopicPageType.EXERCISE);
        setExercisePage(0);
    }, [page]);
    return (
        <div className="h-full w-full">
            <div className="h-[95%] w-full">
                {page.type === CourseTopicPageType.EXERCISE ?
                    (
                        <div className="h-full w-full flex flex-col overflow-hidden">
                            <div className="flex flex-col space-y-2 h-[20%]" >
                                <Card size="small" className="bg-[#F9F0FF] py-4 px-2 rounded-xl h-1/2">
                                    <div className="flex flex-row justify-between space-x-2 items-center content-center text-center">
                                        <div className="flex flex-row items-center content-center justify-center text-center">
                                            <span className="text-sm text-[#8d7473] font-mono text-center">{exercisePage + 1}</span>
                                            <span className="text-sm text-[#D3ADF7] font-mono text-center">/{page.exercise?.exercises?.length}</span>
                                        </div>
                                        <Progress percent={(exercisePage + 1) / (page.exercise?.exercises?.length || 1) * 100} showInfo={false} className='w-full' strokeWidth={20} strokeColor={{
                                            '0%': '#F9E681',
                                            '48%': '#F69D35',
                                            '100%': '#F60000',
                                        }} />
                                    </div>
                                </Card>
                                <Card size="small" className="bg-[#F9F0FF]  px-2 rounded-t-xl h-1/2">
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <span className="text-[#722ED1] text-sm font-semibold">Topic:{selectedTopic?.name}</span>
                                            <span className="text-[#D3ADF7] text-sm font-semibold">{page.exercise?.name}</span>
                                        </div>
                                        <MoreOutlined className="text-[#722ED1] text-xl" />
                                    </div>
                                </Card>
                            </div>
                            <div className="h-[80%]">
                                <ExerciseHandler item={page.exercise?.exercises![exercisePage]!} isPreview={isPreview} isAdminCdn={isAdminCdn} isInClass={isInClass} />
                            </div>
                        </div>
                    )

                    :
                    <div className="h-full w-full">
                        {
                            page.type == CourseTopicPageType.TEXT && (
                                <TextPage text={page.content?.text || ''}/>
                            )
                        }
                        {
                            page.type == CourseTopicPageType.IMAGE && (
                                <ImagePage page={page} isAdminCdn={isAdminCdn}/>
                            )
                        }
                        {
                            page.type == CourseTopicPageType.VIDEO && (
                                <VideoPage page={page} isAdminCdn={isAdminCdn}/>
                            )
                        }
                    </div>
                }
            </div>
            <div className="flex flex-row space-x-2 w-full h-[5%] pt-2 ">
                <div className="w-1/2 text-center bg-[#D3ADF7] rounded-2xl items-center flex justify-center space-x-2 cursor-pointer"
                    onClick={() => {
                        if (exercisePage > 0) {
                            setExercisePage(exercisePage - 1);
                        } else {
                            onPrev();
                        }
                    }}>
                    <LeftCircleOutlined
                        style={{ fontSize: 25 }}
                        className="text-white"
                        disabled={exercisePage == 0}

                    />
                    <span className="text-white text-xl">Prev</span>
                </div>
                <div className="w-1/2 text-center bg-[#D3ADF7] rounded-2xl items-center flex justify-center space-x-2 cursor-pointer"
                    onClick={() => {
                        if (exercisePage < page.exercise?.exercises?.length! - 1) {
                            setExercisePage(exercisePage + 1);
                        } else {
                            onNext();
                        }
                    }}>
                    <span className="text-white text-xl">Next</span>
                    <RightCircleOutlined
                        style={{ fontSize: 25 }}
                        className="text-white"
                        disabled={exercisePage + 1 == page.exercise?.exercises?.length}

                    />
                </div>


            </div>

        </div>
    );
}

{/*<ExerciseHandler item={page.exercise?.exercises![exercisePage]!}  isAdminCdn={isAdminCdn}/>*/ }