import { DashboardOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

//const formatter = (value) => <CountUp end={value} />;
export const BrandManagerDashboard = () => {
  useAccessableSeachParams();
  /*const navigate = useNavigate();
    const location = useLocation();
    const [result, loading, getDashboard] = API.DASHBOARD.useFetchWithBrand({ init: true })*/
  return <ComingSoon navigationText="Ana ekrana dön" navigationUrl="/brand_manager/create_branch" />;
};

{
  /*<div className="h-full items-center content-center">
            
            <div className="grid grid-cols-3 gap-2">
                <Card hoverable
                    onClick={() => { navigate('/brand_manager/branchs'+location.search); }}
                >
                    <Statistic
                        title="Branchs"
                        value={result?.branchCount}
                        formatter={formatter}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<ArrowUpOutlined />}
                    />
                </Card>
            </div>
        </div>*/
}

BrandManagerDashboard.path = '/brand_manager/dashboard';
BrandManagerDashboard.title = 'Kontrol Paneli';
BrandManagerDashboard.group = 'flat';
BrandManagerDashboard.roles = [Role.ADMIN];
BrandManagerDashboard.layouts = [LayoutEnums.BRANDMANAGER];
BrandManagerDashboard.icon = <DashboardOutlined size={20} />;
