import { createContext, useContext, useState } from 'react';
import { LoginUser } from '@shared/models/login_user';

export const TheUser: LoginUser = {};
export const auth = { basic: '' };

type BrandContextType = { user: LoginUser ; setUser: (user: LoginUser) => void };

const UserContext = createContext<BrandContextType>({user:TheUser,setUser:()=>{}});


export const UserContextProvider = ({children})=>{
    const [user, setUser] = useState<LoginUser>(TheUser);
    return(
        <UserContext.Provider  value={{user,setUser}}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserContext = ()=>{
    const context = useContext(UserContext);
    if(context ==undefined){
        throw new Error("useUserContext must be used within a UserContextProvider");
    }
    return context;
}
