import { useFetch } from "@shared/hooks";
import { CourseUnitDefinetion, CourseUnitModel, PaginateModel, UseFetchOptions } from "@shared/models";
import { request } from "@shared/utils";

export default {
    create: async (versionId:string,unit: CourseUnitDefinetion) => request('/lms/brand-content-version/'+versionId+'/unit',"POST" , unit),
    fetchAll: (config: UseFetchOptions)=> useFetch<PaginateModel<CourseUnitDefinetion>>('/lms/brand-course-unit',{ method: 'GET', ...config }),
    getByVersionId:async(versionId:string)=> request("/lms/brand-content-version/"+versionId+"/unit",'GET'),
    order:async(versionId:string,order:CourseUnitModel[])=> request("/lms/brand-content-version/"+versionId+"/unit/order",'PUT',order),
    delete: async(versionId:string,unitId:string)=>request('/lms/brand-content-version/'+versionId+'/unit/'+unitId,'DELETE'),
    update:async(versionId:string,unit:CourseUnitModel)=>request('/lms/brand-content-version/'+versionId+'/unit/','PUT',unit)
}