import { MediaModel } from "@shared/models";
import { useEffect, useRef, useState } from "react";
import * as API from '@shared/api';

type VideoPlayerProps = {
    video: MediaModel;
    isInit: boolean;
    onChange: () => void;
    iconFontSize?: string;
    isPreview?: boolean;
    isAdminCdn: boolean;
}

export const VideoPlayer = ({ video, isInit, onChange, iconFontSize, isPreview, isAdminCdn }: VideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoUrl, setVideoUrl] = useState<string>();
    const [isPlay, setIsPlay] = useState<boolean>(false);

    useEffect(() => {
        if (video.resourceUrl) {
            setVideoUrl(video.resourceUrl);
        } else if (video.resourceId) {
            getVideoUrl(video.resourceId);
        }
    }, [video]);

    useEffect(()=>{
        if(videoUrl ){
            videoRef.current!.src=videoUrl;
            if (isInit && !isPreview) {
                startToPlay();
            }
        }
    },[videoUrl]);

    const getVideoUrl = async (id) => {
        try {
            let _url = '';
            if (isAdminCdn) {
                const {
                    data: { url },
                } = await API.CDN.obtainDownloadVideoAndSoundUrl(id);
                _url = url;
            } else {
                const {
                    data: { url },
                } = await API.CDN.brandObtainDownloadVideoAndSoundUrl(id);
                _url = url;
            }
            console.log(_url , "     ---video url");
            setVideoUrl(_url);
            videoRef.current!.src = _url;
            
        } catch (error) { }
    };

    const startToPlay = () => {
        if (isPlay) {
          videoRef.current!.pause();
          setIsPlay(false);
        } else {
          videoRef.current!.load();
          videoRef.current!
            .play()
            .then(() => {
              setIsPlay(true);
            })
            .catch((e) => {
              setIsPlay(false);
            })
            .finally(() => {
              console.log('Video is playing');
            });
        }
      };

    return (
            <video height={"100%"} width={"100%"} controls ref={videoRef}  />
    );
}