import { request } from '@shared/utils';

export default {
    obtainCreatedUploadImageUrl: async (isPrivate:boolean=false) => request('/cdn/obtain-creator-upload-image-url/'+isPrivate, 'GET'),
    obtainCreateUploadVideoAndSoundUrl: async (uniqName:string)=>request("/cdn/obtain-creator-upload-r2-url/"+uniqName),
    obtainDownloadVideoAndSoundUrl:async (uniqName:string)=>request("/cdn/obtain-download-r2-url/"+uniqName),

    //BRAND
    brandObtainCreatedUploadImageUrl: async (isPrivate:boolean=false) => request('/brand/cdn/obtain-creator-upload-image-url/'+isPrivate, 'GET'),
    brandObtainCreateUploadVideoAndSoundUrl: async (uniqName:string)=>request("/brand/cdn/obtain-creator-upload-r2-url/"+uniqName),
    brandObtainDownloadVideoAndSoundUrl:async (uniqName:string)=>request("/brand/cdn/obtain-download-r2-url/"+uniqName),
};