import { CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined, FieldTimeOutlined, FileDoneOutlined, HomeOutlined, PaperClipOutlined, SyncOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Avatar, Button, Card, Form, notification, Image, Divider } from 'antd';
import { ActionDropdown } from '@shared/components';
import { ReminderDrawer } from './ReminderDrawer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@shared/contexts';

export const StudentHomeWork = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useUserContext();

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };

  useAccessableSeachParams();
  return (
    <div className=" pt-1">
      <Card
        className="mt-1"
        style={{
          backgroundImage: 'url(' + require('../../../../../statics/Ödev.png') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="text-lg	font-sans mb-2 ml-6">Merhaba, {user.name}</div>
        <div className="grid grid-cols-3 w-3/4 gap-2">
          <Card className="bg-white/40 backdrop-blur-sm">
            <div className="opacity-45 text-black">Aktif Ödevler</div>
            <div className="font-bold text-xl">3</div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-sm">
            <div className="opacity-45 text-black">Tamamlanan Ödevler</div>
            <div className="font-bold text-xl">4</div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-sm">
            <div className="opacity-45 text-black">Henüz Aktif Olmayan Ödevler</div>
            <div className="font-bold text-xl">2</div>
          </Card>
        </div>
      </Card>
      <div className="grid grid-cols-4 mt-3 gap-3">
        <Card size="small" className="h-12">
          <SyncOutlined style={{ color: '#1677FF' }} />
          <span className="ml-2">Aktif</span>
        </Card>
        <Card size="small" className="h-12">
          <div>
            <FieldTimeOutlined style={{ color: '#FAAD14' }} />
            <span className="ml-2"> Henüz aktif değil</span>
          </div>
        </Card>
        <Card size="small" className="h-12">
          <div>
            <CheckCircleOutlined style={{ color: '#52C41A' }} />
            <span className="ml-2"> Tamamlandı</span>
          </div>
        </Card>
        <Card size="small" className="h-12">
          <div>
            <ClockCircleOutlined className="opacity-25" />
            <span className="ml-2">Süresi doldu</span>
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-4 gap-3 mt-2">
        <Card className="overflow-y-auto p-3" styles={{ body: { padding: '0' } }} style={{ height: 'calc(100vh - 377px)' }}>
          <div className="grid grid-cols-1 gap-3">
            <Card className="h-32 p-2" styles={{ body: { padding: '0' } }}>
              <div className="text-sm">
                <div className="flex justify-between font-bold">
                  Kompozisyon
                  <span>
                    <ActionDropdown
                      isDelete={false}
                      isEdit={false}
                      isCreateReminder={true}
                      isGoHomework={true}
                      onCreateReminder={() => setDrawerOpen(true)}
                      onGoHomework={() => navigate('/' + LayoutEnums.STUDENT + '/homework_detail' + location.search)}
                      data={{}}
                    />
                  </span>
                </div>
                <div className="mt-1">Genel İngilizce A1</div>
                <div className="mt-1">
                  <PaperClipOutlined />
                  <span className="ml-1">1</span>
                </div>
                <div className="mt-1">
                  <CalendarOutlined />
                  <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
                </div>
              </div>
            </Card>
            <Card className="h-32 p-2" styles={{ body: { padding: '0' } }}>
              <div className="text-sm">
                <div className="flex justify-between font-bold">
                  Kompozisyon
                  <span>
                    <ActionDropdown isDelete={false} isEdit={false} data={{}} />
                  </span>
                </div>
                <div className="mt-1">Genel İngilizce A1</div>
                <div className="mt-1">
                  <PaperClipOutlined />
                  <span className="ml-1">1</span>
                </div>
                <div className="mt-1">
                  <CalendarOutlined />
                  <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
                </div>
              </div>
            </Card>
            <Card className="h-32 p-2" styles={{ body: { padding: '0' } }}>
              <div className="text-sm">
                <div className="flex justify-between font-bold">
                  Kompozisyon
                  <span>
                    <ActionDropdown isDelete={false} isEdit={false} data={{}} />
                  </span>
                </div>
                <div className="mt-1">Genel İngilizce A1</div>
                <div className="mt-1">
                  <PaperClipOutlined />
                  <span className="ml-1">1</span>
                </div>
                <div className="mt-1">
                  <CalendarOutlined />
                  <span className="ml-1"> 12.05.2024 - 24.05.2024</span>
                </div>
              </div>
            </Card>
          </div>
        </Card>
        <Card className="overflow-y-auto p-3" styles={{ body: { padding: '0' } }} style={{ height: 'calc(100vh - 377px)' }}></Card>
        <Card className="overflow-y-auto p-3" styles={{ body: { padding: '0' } }} style={{ height: 'calc(100vh - 377px)' }}></Card>
        <Card className="overflow-y-auto p-3" styles={{ body: { padding: '0' } }} style={{ height: 'calc(100vh - 377px)' }}></Card>
      </div>
      <ReminderDrawer open={drawerOpen} onCloseDrawer={onCloseDrawer} />
    </div>
  );
};

StudentHomeWork.path = '/' + LayoutEnums.STUDENT + '/homework_exam';
StudentHomeWork.title = 'Ödev ve Sınav İşlemleri';
StudentHomeWork.group = 'flat';
StudentHomeWork.roles = [Role.ADMIN];
StudentHomeWork.layouts = [LayoutEnums.STUDENT];
StudentHomeWork.icon = <FileDoneOutlined size={20} />;
