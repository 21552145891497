import { ClassHeader, ClassInfo, ClassSuccessSummary, ClassTimeline } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { ClassCalendarEntryStatus, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ClassDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [classId, setClassId] = useState<string>();
  const [classDetail, classDetailLoading, getClassDetail] = API.CLASS.getClassDetail(classId, { init: false });
  const [availableStudentList, availableListLoading, getAvailableStudentList] = API.CLASS.getAvailableStudentsToAdd(classId, { init: false });
  const [calendarData, calendarLoading, getCalendarData] = API.CLASS.getClassCalendar(classId, { init: false });

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('class_id');
      if (!id) return;
      setClassId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (classId) {
      getClassDetail();
      getCalendarData();
      getAvailableStudentList();
    }
  }, [classId]);

  const onAddStudent = async (classId: string, demandId: string) => {
    const response = await API.CLASS.addStudentToClass(classId, demandId);
    if (response.ok) {
      await getClassDetail();
      await getAvailableStudentList();
      notification.success({ message: 'Öğrenci başarıyla sınıfa eklendi' });
    }
  };

  const onRemoveStudent = async (classId: string, studentId: string) => {
    const response = await API.CLASS.removeStudentFromClass(classId, studentId);
    if (response.ok) {
      await getClassDetail();
      await getAvailableStudentList();
      notification.success({ message: 'Öğrencinin kaydı sınıftan silindi' });
    }
  };

  return (
    <>
      <Spinner loading={classDetailLoading && availableListLoading} />
      <ClassHeader classDetailPage={true} classDetail={classDetail} />
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-9">
          <ClassSuccessSummary classDetail={classDetail} />
          <div className="mt-3">
            <ClassTimeline notes={classDetail?.notes} isTeacher={false} classDetail={classDetail} />
          </div>
        </div>
        <div className="col-span-3 justify-center ml-3  ">
          <ClassInfo
            calendarData={calendarData?.filter((i) => ![ClassCalendarEntryStatus.CANCELED, ClassCalendarEntryStatus.POSTPONED].includes(i.status!))}
            classDetail={classDetail}
            getClassDetail={() => getClassDetail()}
            availableStudentList={availableStudentList}
            onAddStudent={onAddStudent}
            onRemoveStudent={onRemoveStudent}
          />
        </div>
      </div>
    </>
  );
};

ClassDetail.path = '/lms/class_detail';
ClassDetail.roles = [Role.ADMIN];
