import { HTMLAttributes } from 'react';

export interface ClassRoomDetailProps extends HTMLAttributes<HTMLElement> {
  classRoom?: any;
}

export const ClassRoomDetail = (props: ClassRoomDetailProps) => {
  let { classRoom, ...domProps } = props;

  return (
    <div {...domProps}>
      <h3>{classRoom.name}</h3>
    </div>
  );
};
