import { UserAddOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { Gantt, Spinner } from '@shared/components';
import { DemandTableFilter, DemandTableInfo } from '@platform/platform_components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { useEffect, useState } from 'react';

dayjs.extend(dayOfYear);

export const GanttPage = () => {
  useAccessableSeachParams();
  const [startPoint, setStartPoint] = useState<Dayjs>(dayjs().startOf('day'));
  const [params, setParams] = useState<any>();

  const [demandList, demandListLoading, getDemandList] = API.ENROLLMENT.fetchDemandTable({
    init: false,
  });

  //Filtreler değiştiğinde öğrenci listesini seçilmiş filtrelerle tekrar çağırıyoruz
  useEffect(() => {
    getDemandList(undefined, params);
  }, [params]);

  if (!demandList || !demandList?.items) {
    return <div></div>;
  }
  const data = demandList.items.map((item) => {
    return {
      accountId: item.accountId,
      name: `${item.name} ${item.surname} ` + (item.waitingInDays >= 0 ? `(${item.waitingInDays} Gündür)` : `(${Math.abs(item.waitingInDays)} Gün Sonra)`),
      email: item.email,
      phone: item.phone,
      gender: item.gender,
      dates: item.demands.map((i) => {
        return {
          ...i,
          text:
            (i.waitingInDays >= 0 ? `(${i.waitingInDays} Gündür)` : `(${Math.abs(i.waitingInDays)} Gün Sonra)`) +
            ` ${i.level.theme.type.field.name}/${i.level.theme.type.name}/${i.level.theme.name}/${i.level.name}`,
          startDate: new Date(i.startDate),
          endDate: new Date(i.endDate),
        };
      }),
    };
  });

  //Takvimde öğrenci ile ilgili işlem yapıldığında tabloyu tekrar çağırıyoruz güncel filtrelerle
  const callDemandList = () => {
    getDemandList(undefined, params);
  };

  return (
    <div className="mt-3">
      <Spinner loading={demandListLoading} />
      <DemandTableFilter onFilter={(filter) => setParams(filter)} setStartPoint={setStartPoint} />
      <DemandTableInfo />
      <Gantt data={data} startPoint={startPoint} callDemandList={callDemandList} />
    </div>
  );
};

GanttPage.path = '/branch_manager/gantt_page';
GanttPage.title = 'Talep Takvimi';
GanttPage.group = 'flat';
GanttPage.roles = [Role.ADMIN];
GanttPage.layouts = [LayoutEnums.BRANCHMANAGER];
GanttPage.icon = <UserAddOutlined size={20} />;
GanttPage.permissions = ['branch.lms.class.planning.view', 'branch.lms.enrollment.view', 'branch.lms.class.view'];
