export type EducationField = Partial<{
    id: string,
    name: string,
}>

export type EducationType = Partial<{
    id: string,
    name: string,
    field: EducationField,
}>

export type EducationTheme = Partial<{
    id: string,
    name: string,
    type: EducationType,
}>

export type EducationLevel = Partial<{
    id: string,
    name: string,
    theme: EducationTheme,
    order: number;
    durationInHours: number;
    active:boolean;
    systemDefinitionId: string;
}>

export type CreateEducationLevel = Partial<{
    theme: EducationTheme,
    name: string,
    order: number;
    durationInHours: number;
}>