import { DeleteOutlined, PlusCircleOutlined, SwapOutlined } from "@ant-design/icons";
import { ExerciseDefinitionInteractionType, ExerciseLayoutType } from "@shared/constants";
import {  ExerciseDefinitionModel } from "@shared/models";
import { layoutToContentType } from "@shared/utils";
import { Button, Form, Input, Popconfirm } from "antd";
import { useCallback, useEffect, useState } from "react";

type CreateMatchingBaseProps = {
    item: ExerciseDefinitionModel,
    onSave: (item: ExerciseDefinitionModel) => void;
    onChange: (item: ExerciseDefinitionModel) => void
}

export const CreateMatchingBase = ({ item, onSave, onChange }: CreateMatchingBaseProps) => {

    const [newMatch, setNewMatch] = useState<Partial<{
        main: string,
        match: string,
    }>>({});

    const [orderItems, setOrderItems] = useState<string[][]>([]);

    useEffect(() => {
        setOrderItems(item.interaction?.orderItems ?? []);
    }, [item.id]);

    useEffect(() => {
        if (orderItems.length>0) {
            const i = {
                ...item,
                layout: ExerciseLayoutType.MATCHING_BASE,
                content: {
                    ...item.content,
                    type: layoutToContentType(ExerciseLayoutType.MATCHING_BASE)
                },
                interaction: {
                    ...item.interaction,
                    orderItems: orderItems,
                    type: ExerciseDefinitionInteractionType.MATCHING
                }
            }as ExerciseDefinitionModel;
            onChange(i);
        }
    }, [orderItems]);

    const send = useCallback(()=>{
        const i = {
            ...item,
            layout: ExerciseLayoutType.MATCHING_BASE,
            content: {
                ...item.content,
                type: layoutToContentType(ExerciseLayoutType.MATCHING_BASE)
            },
            interaction: {
                ...item.interaction,
                orderItems: orderItems,
                type: ExerciseDefinitionInteractionType.MATCHING
            }
        }as ExerciseDefinitionModel;
        onSave(i);
    },[orderItems]);

    

    const addNewMatch = () => {
        const mainText = newMatch.main;
        const matchText = newMatch.match;
        const _orderItems = [...orderItems];
        _orderItems.push([mainText!, matchText!]);
        setOrderItems(_orderItems);
        
        setNewMatch({});
    }
    return (
        <div className="p-5">
            <div className="flex justify-end">
                <Button type="primary" size="small" onClick={send}>{item.id ? "Güncelle" : "Ekle"}</Button>
            </div>
            <Form layout="vertical">
                <div className="grid grid-cols-3 gap-x-2">
                    <Form.Item label={"Ana kelime:"}>
                        <Input value={newMatch.main} onChange={(e) => {
                            setNewMatch({
                                ...newMatch,
                                main: e.target.value,
                            });
                        }}
                        />
                    </Form.Item>
                    <Form.Item label={"Karşılıgı kelime:"}>
                        <Input value={newMatch.match}
                            onChange={(e) => {
                                setNewMatch({
                                    ...newMatch,
                                    match: e.target.value,
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={" "}>
                        <Button onClick={addNewMatch} disabled={newMatch.main == undefined || newMatch.match == undefined} icon={<PlusCircleOutlined />}>Listeye Ekle</Button>
                    </Form.Item>
                </div>
            </Form>

            <div className="flex flex-col">
                {
                    orderItems?.map((o, i) => (
                        <div key={i} className="space-y-2">
                            <span className="text-center content-center text-sm">{i + 1}</span>
                            <div className="grid grid-cols-12 gap-y-2">
                                <Input className="col-span-5" value={o[0]}
                                    onChange={(e) => {
                                        const _orderItems = [...orderItems ];
                                        _orderItems[i][0] = e.target.value;
                                        setOrderItems(_orderItems);
                                    }}
                                />
                                <div className="text-center content-center">
                                    <SwapOutlined />
                                </div>
                                <Input className="col-span-5" value={o[1]}
                                    onChange={(e) => {
                                        const _orderItems = [...orderItems];
                                        _orderItems[i][1] = e.target.value;
                                        setOrderItems(_orderItems);
                                    }}
                                />
                                <Popconfirm title="Bu eşleme silinecek" okText={"Sil"} onConfirm={()=>{
                                    const _orderItems = [...orderItems].filter((item,index)=>{
                                        return index != i;
                                    });
                                    setOrderItems(_orderItems);
                                }}>
                                    <Button className="ml-2" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </div>
                        </div>
                    ))
                }


            </div>

        </div>
    );
}