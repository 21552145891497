import { ImageView } from "@shared/components";
import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";
import { Button } from "antd";

type ExerciseProps = {
    content: ExerciseDefinitionContentModel,
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isInClass: boolean;
}
export const VisualSingleSelect = ({ content, interaction, isPreview = false, isInClass }: ExerciseProps) => {
    return (
        <>
            {
                isInClass ?
                    (
                        <div className="flex flex-row items-center h-full bg-white w-full pt-10 pb-10">
                            <div className="border border-double border-mid-gray rounded m-2 h-full w-3/6 text-center" >
                                <ImageView image={content?.image ? content.image : {}} />
                            </div>
                            <div className="flex flex-col w-3/6">
                            <div className="items-center flex flex-col h-1/6 m-2">
                                <p className="p-0 m-0 font-extrabold text-3xl text-center">{isPreview ? (content.content ? content.content?.title : '....') : content.content?.title}</p>
                                <p className="text-xl text-gray-400 text-center">{isPreview ? (content.content?.description ? content.content.description : '....') : content.content?.description}</p>
                            </div>
                            {
                                (interaction && interaction.choices) ? (
                                    <div className="h-2/6 mt-3 text-center space-y-6">
                                        <div className="space-x-2 space-y-2 w-full items-center justify-center content-center">

                                            {

                                                interaction.choices ? (interaction.choices.map((c) => (<Button key={c.id} type="default">{c.title}</Button>))) : (<></>)
                                            }

                                        </div>
                                    </div>
                                ) : (<></>)
                            }
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="flex flex-col items-center h-full bg-white pt-10 pb-10">
                            <div className="border border-double border-mid-gray rounded m-2 h-3/6" >
                                <ImageView image={content?.image ? content.image : {}} />
                            </div>
                            <div className="items-center flex flex-col h-1/6 m-2">
                                <p className="p-0 m-0 font-extrabold text-xl text-center">{isPreview ? (content.content ? content.content?.title : '....') : content.content?.title}</p>
                                <p className="text-xs text-gray-400 text-center">{isPreview ? (content.content?.description ? content.content.description : '....') : content.content?.description}</p>
                            </div>
                            {
                                (interaction && interaction.choices) ? (
                                    <div className="h-2/6 mt-3 text-center space-y-6">
                                        <div className="space-x-2 space-y-2 w-full items-center justify-center content-center">

                                            {

                                                interaction.choices ? (interaction.choices.map((c) => (<Button key={c.id} type="default">{c.title}</Button>))) : (<></>)
                                            }

                                        </div>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    )
            }
        </>

    );
}