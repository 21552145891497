import { Button, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

type MonthNameProps = {
  startWeek: Dayjs;
};

const MonthName = ({ startWeek }: MonthNameProps) => {
  const getWeekRange = () => {
    const start = startWeek.startOf('week');
    const end = startWeek.endOf('week');

    const startMonth = start.format('MMM');
    const endMonth = end.format('MMM');

    if (startMonth === endMonth) {
      return `${start.format('D')} ${startMonth} - ${end.format('D')} ${startMonth}`;
    }

    return `${start.format('D')} ${startMonth} - ${end.format('D')} ${endMonth}`;
  };

  return <div className="flex max-h-6 font-bold text-base">{getWeekRange()}</div>;
};

type WeeklyCalendarHeaderProps = {
  startWeek: Dayjs;
  setStartWeek: (date: Dayjs) => void;
};

export const WeeklyCalendarHeader = ({ startWeek, setStartWeek }: WeeklyCalendarHeaderProps) => {
  return (
    <div className="flex justify-start mb-5">
      <div className="flex mr-5">
        <Button onClick={() => setStartWeek(dayjs().startOf('week'))}>Bugün</Button>
        <div className="flex px-0">
          <Button className="my-0 mx-1" onClick={() => setStartWeek(startWeek.subtract(1, 'week'))}>
            <LeftOutlined />
          </Button>
          <Button className="my-0 mx-1" onClick={() => setStartWeek(startWeek.add(1, 'week'))}>
            <RightOutlined />
          </Button>
        </div>
      </div>
      <div className="self-center">
        <MonthName startWeek={startWeek} />
      </div>
    </div>
  );
};
