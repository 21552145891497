import { useFetch } from '@shared/hooks';
import { AccountNotification, PaginateModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  paginateNotifications: (config: UseFetchOptions) => useFetch<PaginateModel<AccountNotification>>('/notification', { method: 'GET', ...config }),
  toggleReadStatus: (notificationId: string) => request(`/notification/${notificationId}`, 'PUT'),
  deleteNotification: (id: string) => request('/notification/' + id, 'DELETE'),

};
