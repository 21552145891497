import { AudioOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

type PronunciationVerifierProps = {
    texts: string[],
    onVerify: (result:boolean) => void
}

export const PronunciationVerifier = ({ texts ,onVerify}: PronunciationVerifierProps) => {
    const [commands, setCommands] = useState<any[]>([]);
    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition({ commands });

    let timer;

    useEffect(()=>{
        if (listening) {
            timer = setTimeout(() => {
                SpeechRecognition.stopListening();
                onVerify(false);
            }, 5000);
        }
        return () => {
            clearTimeout(timer);
        }
    },[listening]);

    useEffect(() => {
        if (texts.length > 0) {
            setCommands([
                {
                    command: texts,
                    callback: () => {
                        SpeechRecognition.stopListening();
                        onVerify(true);
                    },
                    isFuzzyMatch: true,
                    fuzzyMatchingThreshold: 0.1,
                    bestMatchOnly: true
                },
            ])
        }
    }, [texts]);

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.log('Browser does not support speech recognition.');
        }
        if(!isMicrophoneAvailable){
            console.log('Microphone is not available.');
        }
    }, [isMicrophoneAvailable,browserSupportsSpeechRecognition]);
    return (
        <Button type="text" className="!w-max !h-max" size="large" onClick={() => {
            if (listening) {
                SpeechRecognition.stopListening();
            } else {
                SpeechRecognition.startListening({
                    continuous: true
                });
            }
        }} icon={<AudioOutlined style={{ fontSize: '3rem',color: listening ? 'blueviolet' : '' }} />} />
    );
}