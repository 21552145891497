import { CalendarOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

export const StudentAgenda = () => {
  useAccessableSeachParams();
  return <ComingSoon navigationUrl="/" navigationText="Ana ekrana dön" />;
};

StudentAgenda.path = '/' + LayoutEnums.STUDENT + '/agenda';
StudentAgenda.title = 'Ajanda';
StudentAgenda.group = '_flat';
StudentAgenda.roles = [Role.ADMIN];
StudentAgenda.layouts = [LayoutEnums.STUDENT];
StudentAgenda.icon = <CalendarOutlined size={20} />;
