import { AddressModel, PhoneModel } from './information';
import { PermissionsModel } from './permissions';
import { Gender } from '@shared/constants';

//TODO servisler hazır oldugunda bu modeller düzenlenmeli
export type BrandModel = Partial<{
  id: string;
  name: string;
  country: string;
  city: string;
  active: boolean;
  email: string;
}>;

export type CreateBrandModel = Partial<{
  id: string;
  name: string;
  email: string;
  phone: PhoneModel;
  address: AddressModel;
  active: boolean;
}>;

//TODO create brand user ve brand user aynı yapıya sahip servisler tammalanınca burayı düzenle
export type BrandUserModel = Partial<{
  id: string;
  name: string;
  surname: string;
  email: string;
  gender: Gender;
  birthDate: string;
  phone: PhoneModel;
  accessables: {
    permissions: PermissionsModel;
  };
}>;

export type CreateBrandUser = Partial<{
  name: string;
  surname: string;
  email: string;
  phone: PhoneModel;
  nationalId: string;
  gender: Gender;
  birthDate: string;
  brandId: string;
  permissions: PermissionsModel;
}>;

export type SelectedBrand = Partial<{
  id: string;
  permissions: PermissionsModel;
}>;

//TODO AccessableBrand modeli account altında olabilir
export type AccessableBrand = Partial<{
  id: string;
  name: string;
}>;
