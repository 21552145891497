import { CheckCircleOutlined } from '@ant-design/icons';
import { Role, productColors } from '@shared/constants';
import { BrandUserModel } from '@shared/models';
import { Card, Tag } from 'antd';
import { ActionDropdown, AvatarFromName } from '@shared/components';

type BrandAndBranchUserCardProps = {
  user: BrandUserModel;
  onDelete: (brandUser: BrandUserModel) => void;
  onEdit: (brandUser: BrandUserModel) => void;
};
export const BrandAndBranchUserCard = ({ user, onDelete, onEdit }: BrandAndBranchUserCardProps) => {
  return (
    <Card
      key={user.id}
      title={
        <Card.Meta
          className="flex items-center"
          avatar={
            <div className="pt-2 pb-2">
              <AvatarFromName title={user.name!} subtitle={user.email!} />
            </div>
          }
          title={[user.name, user.surname].join(' ')}
        />
      }
      extra={
        <div className="flex gap-2 items-center">
          <ActionDropdown
            data={user}
            onDelete={(data) => {
              onDelete(data);
            }}
            onEdit={(data) => {
              onEdit(data);
            }}
          />
        </div>
      }
    >
      <div className="grid grid-cols-[60px_1fr] items-center">
        <label className="font-medium">Email:</label>
        <span className="text-dark-gray text-xs ">{user.email}</span>

        <label className="font-medium ">Telefon numarası:</label>
        <span className="text-dark-gray text-xs ">
          {user.phone?.code} {user.phone?.number}
        </span>

        <label className="font-medium">Rolu:</label>
        <Tag color={user.accessables?.permissions.role == Role.ADMIN ? 'gold' : 'blue'} className={'border mr-4 w-1/2 text-center ' + productColors['CRM'].join(' ')}>
          {user.accessables?.permissions?.role}
        </Tag>

        <label className="font-medium">Ürünler:</label>
        {user.accessables?.permissions.role == Role.ADMIN ? (
          <span className="text-dark-gray text-xs ">Tüm ürünlere erişebilir</span>
        ) : (
          user.accessables?.permissions?.products?.map((p) => (
            <div key={p} className="flex flex-row flex-wrap gap-y-2 col-span-2">
              <Tag icon={<CheckCircleOutlined />} className={'border mr-4 ' + productColors[p ?? 'CRM'].join(' ')}>
                {p}
              </Tag>
            </div>
          ))
        )}
      </div>
    </Card>
  );
};
