import { HTMLAttributes } from 'react';

export interface StudentDetailProps extends HTMLAttributes<HTMLElement> {
  student?: any;
}

export const StudentDetail = (props: StudentDetailProps) => {
  let { student, ...domProps } = props;

  return (
    <div {...domProps}>
      <h3>{student.name}</h3>
    </div>
  );
};
