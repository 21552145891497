import { Gender } from '@shared/constants';
import { PhoneModel } from './information';
import { TimeRange } from './session_definitions';

//TODO type ve status enum olmalı
export type Enrollment = Partial<{
  id: string;
  name: string;
  email: string;
  nationalId: string;
  phone: PhoneModel;
  type: EnrollmentTypes;
  status: EnrollmentStatus;
  birthDate: string;
  updatedAt: Date;
  createdAt: Date;
}>;

export type PrePlacement = Partial<{
  id: string;
  enrollmentId: string;
  name: string;
  surname: string;
  email: string;
  note: string;
  nationalId: string;
  phone: PhoneModel;
  gender: Gender;
  birthDate: string;
  prePlacementDemands: PrePlacementDemand[];
  availabilityCalendar: AvailabilityCalendar;
}>;

export type PrePlacementDemand = {
  id: string;
  prePlacement: PrePlacement;
  status: string;
  waitingInDays: number;
  level: {
    id: string;
    name: string;
    theme: {
      id: string;
      name: string;
      type: {
        id: string;
        name: string;
        field: {
          id: string;
          name: string;
        };
      };
    };
  };
};

export type Observer = Partial<{
  id: string;
  enrollmentId: string;
  name: string;
  surname: string;
  email: string;
  phone: PhoneModel;
  gender: Gender;
  birthDate: string;
  nationalId: string;
}>;

export type AvailabilityCalendar = Partial<{
  [index: string]: TimeRange[];
}>;

export enum EnrollmentTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  ENTERPRISE = 'ENTERPRISE',
}

export enum EnrollmentStatus {
  INFO = 'INFO', //bilgilerin girildiği stepler
  READY_FOR_PLANNING = 'READY_FOR_PLANNING', //önizleme ya da son ok işlemi
  STARTED = 'STARTED',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
}

export type CourseInfo = Partial<{
  key: number;
  level: string;
  theme: string;
  type: string;
  field: string;
}>;

export type CourseObserverInfo = Partial<{
  name: string;
  surname: string;
  email: string;
  phone: PhoneModel;
}>;
export type CourseStudentInfo = Partial<{
  key: number;
  name: string;
  surname: string;
  email: string;
  phone: PhoneModel;
  birthDate: string;
  educationInfo: PrePlacementDemand[];
}>;
