import { PlusCircleOutlined } from '@ant-design/icons';
import { ExerciseDefinitionContentType, ExerciseDefinitionInteractionType, ExerciseLayoutType } from '@shared/constants';
import { useDebounce } from '@shared/hooks/useDebounce';
import { ExerciseChoicesModel, ExerciseDefinitionModel } from '@shared/models';
import { uuid } from '@shared/utils';
import { Button, Form, Input, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useRef, useState } from 'react';

type CreateReadingSingilSelectProps = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  isAdminCdn: boolean;
};

export const CreateReadingSingilSelect = ({ item, onSave, onChange, isAdminCdn }: CreateReadingSingilSelectProps) => {
  const [title, setTitle] = useState<string>();
  const [textAreaInput, setTextAreaInput] = useState<string>();
  const [instructionTitle, setInstructionTitle] = useState<string>();
  const [instructionSubTitle, setInstructionSubTitle] = useState<string>();
  const [choice, setChoice] = useState<string>();
  const [choiceList, setChoiceList] = useState<ExerciseChoicesModel[]>([]);
  const [correctAnswerId, setCorrectAnswerId] = useState<string>();



  useEffect(() => {
      setTitle(item.content?.content?.title);
      setTextAreaInput(item.content?.content?.description);
      setInstructionTitle(item.interaction?.instruction?.title);
      setInstructionSubTitle(item.interaction?.instruction?.description);
      setChoiceList(item.interaction?.choices ?? []);
      setCorrectAnswerId(item.interaction?.choices?.find((c) => c.isCorrect)?.id);
  }, [item.id]);


  useEffect(() => {
    if (title || textAreaInput || instructionTitle || instructionSubTitle || choiceList.length > 0) {
        const i = {
          ...item,
          layout: ExerciseLayoutType.READING_SINGLE_SELECT,
          content: {
            ...item.content,
            content: {
              ...(item.content ? item.content.content : {}),
              title: title,
              description: textAreaInput,
            },
            type: ExerciseDefinitionContentType.INFO,
          },
          interaction: {
            ...item.interaction,
            type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
            choices: choiceList,

            instruction: {
              title: instructionTitle,
              description: instructionSubTitle,
            },
          },
        } as ExerciseDefinitionModel;
       onChange(i);
    }
  }, [title, textAreaInput, instructionTitle, instructionSubTitle, choiceList]);

  const send = useCallback(() => {
    const i = {
      ...item,
      layout: ExerciseLayoutType.READING_SINGLE_SELECT,
      content: {
        ...item.content,
        content: {
          ...(item.content ? item.content.content : {}),
          title: title,
          description: textAreaInput,
        },
        type: ExerciseDefinitionContentType.INFO,
      },
      interaction: {
        ...item.interaction,
        type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
        choices: choiceList,
        instruction: {
          title: instructionTitle,
          description: instructionSubTitle,
        },
      },
    } as ExerciseDefinitionModel;
    onSave(i);
  }, [title, textAreaInput, instructionTitle, instructionSubTitle, choiceList]);

  return (
    <div className="p-5">
      <div className="flex justify-end">
        <Button type="primary" size="small" onClick={() => send()}>
          {item.id ? 'Güncelle' : 'Ekle'}
        </Button>
      </div>

      <Form layout="vertical" className="w-2/3">
        <Form.Item label={'Başlık'}>
          <Input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label="Metin">
          <TextArea rows={4} className="mt-1" value={textAreaInput} onChange={(e) => setTextAreaInput(e.target.value)} />
        </Form.Item>

        <div className="grid grid-cols-2 space-x-2 mt-5">
          <Form.Item label={'Bilgilendirme Başlığı'}>
            <Input value={instructionTitle} onChange={(e) => setInstructionTitle(e.target.value)} />
          </Form.Item>
          <Form.Item label={'Bilgilendirme Alt Başlığı'}>
            <Input value={instructionSubTitle} onChange={(e) => setInstructionSubTitle(e.target.value)} />
          </Form.Item>
        </div>

        <Form.Item label="Seçenek Ekle">
          <div className="flex">
            <Input value={choice} onChange={(e) => setChoice(e.target.value)} />
            <Button
              disabled={!choice}
              type="default"
              onClick={() => {
                const newList = [...choiceList, { id: uuid(), title: choice, isCorrect: false }];
                setChoiceList(newList);
                setChoice('');
              }}
            >
              +
            </Button>
          </div>
        </Form.Item>

        {choiceList && choiceList.length > 0 && (
          <>
            <Form.Item label={'Seçenekler'}>
              <div className="grid grid-cols-4 gap-2">
                {choiceList.map((c, index) => (
                  <Input
                    key={c.id}
                    value={c.title}
                    onChange={(e) => {
                      const newList = [...choiceList];
                      newList[index].title = e.target.value;
                      setChoiceList(newList);
                    }}
                  />
                ))}
              </div>
            </Form.Item>

            <Form.Item label="Doğru seçeneği belirleyiniz.">
              <Radio.Group
                className="flex flex-col gap-1"
                options={choiceList?.map((c) => ({ id: c.id, label: c.title!, value: c.id! }))}
                value={correctAnswerId}
                onChange={({ target }) => {
                  setCorrectAnswerId(target.id);
                  const newList = [...choiceList];
                  newList.map((list) => (target.id === list.id ? (list.isCorrect = true) : (list.isCorrect = false)));
                  setChoiceList(newList);
                }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};
