import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";


export const HelpCenter = () => {

    return (
        <div className='bg-white w-96'>
            <div className='bg-gray-200 p-10 space-y-2'>
                <div className='text-center items-center justify-end '>
                    <label className='text-xl'>Help Center</label>
                </div>
                <Input prefix={<SearchOutlined />} placeholder={"Search help articles"} style={{ width: '100%' }} />
            </div>
            <div className="h-96 overflow-y-auto">

            </div>


        </div>
    );
}