import { useState } from 'react';
import { Calendar } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { WeeklyCalendar } from './WeeklyCalendar';
import { JotCalendarHeader } from './JotCalendarHeader';
import { ClassCalendarModel } from '@shared/models';

export const JotCalendar = () => {
  const [mode, setMode] = useState<'month' | 'week'>('month');
  const [value, setValue] = useState<Dayjs>(dayjs());

  const onModeChange = (newMode: 'month' | 'week') => {
    setMode(newMode);
  };

  const onChange = (newValue: Dayjs) => {
    setValue(newValue);
  };

  return (
    <div>
      {mode === 'week' ? (
        <WeeklyCalendar value={value} mode={mode} onModeChange={onModeChange} onChange={onChange} />
      ) : (
        <Calendar
          fullscreen
          mode="month"
          value={value}
          onChange={onChange}
          headerRender={({ value, type, onChange }) => (
            <div className="p-5">
              <JotCalendarHeader value={dayjs(value)} mode={type as 'month' | 'week'} onChange={(newValue) => onChange(dayjs(newValue))} onModeChange={onModeChange} />
            </div>
          )}
        />
      )}
    </div>
  );
};
