import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassStatus } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { CascaderOption, PaginationQuery } from '@shared/models';
import { Badge, Button, Cascader, Space } from 'antd';
import { useEffect, useState } from 'react';
import i18n from '@shared/localization/i18n';

type ClassFilterProps = {
  onFilter: (key: any, value: any, reset?: boolean) => void;
  filters: PaginationQuery;
};

export const ClassFilter = ({ onFilter, filters }: ClassFilterProps) => {
  useAccessableSeachParams();
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRANCH.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRANCH.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRANCH.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: true });

  const [selectedKur, setSelectedKur] = useState();
  const [cascaderKey, setCascaderKey] = useState(Date.now());

  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);

  useEffect(() => {
    if (fields && types && themes && levels) {
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: themes
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return {
                      value: m.id,
                      label: m.name,
                      children: levels?.data
                        ?.filter((l) => l.theme?.id == m.id)
                        .map((l) => {
                          return {
                            value: l.id,
                            label: l.name,
                          } as CascaderOption;
                        }),
                    };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });

      setAbilityLevelOptions(_items);
    }
  }, [fields, types, themes, levels]);

  const statuOptions = Object.keys(ClassStatus).map((key) => ({
    label: i18n.t('app.classStatus.' + key),
    value: ClassStatus[key],
  }));

  const anyFilterActive = Object.values(filters).some((filter) => Array.isArray(filter) && filter.length > 0);

  const onKurChange = (value) => {
    //MultiCascader'dan Tüm ağacın(field,theme,type,level) id'leri geliyor, levelid 3.index olduğu için onları tutuyoruz.
    const selectedLevels = value.map((v) => v[3]);

    onFilter('levelIds', selectedLevels);
  };

  return (
    <div className="flex ml-1 mt-3 gap-4 w-1/3">
      <div className="flex mt-1 w-1/2 gap-2 justify-around">
        <Badge dot={filters.levelIds!.length > 0} className={filters.levelIds!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            key={cascaderKey}
            value={selectedKur}
            options={abilityLevelOptions}
            expandTrigger="hover"
            showCheckedStrategy={Cascader.SHOW_CHILD}
            multiple
            onChange={(value) => onKurChange(value)}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Kur
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>

        <Badge dot={filters.statuses!.length > 0} className={filters.statuses!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            value={filters.statuses}
            options={statuOptions}
            expandTrigger="hover"
            multiple
            onChange={(value) => {
              console.log(value, 'fıksfjk');
              onFilter('statuses', value);
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Statü
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>
      </div>

      {anyFilterActive && (
        <div className="ml-5">
          <Button
            onClick={() => {
              setCascaderKey(Date.now()); //Cascader'ı butonla resetlemek için key'ini değiştiriyoruz.
              onFilter(null, null, true);
            }}
            type="text"
            size="small"
            icon={<MinusCircleOutlined />}
          >
            Filtreyi Kaldır
          </Button>
        </div>
      )}
    </div>
  );
};
