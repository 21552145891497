import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import { shuffleToArray } from '@shared/utils';
import { Button } from 'antd';

type ReadingGapFillingProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
};
export const ReadingGapFilling = ({ content, interaction, isPreview = false, isInClass }: ReadingGapFillingProps) => {
  const processedText = content?.content?.description
    ? content.content?.description?.replace(/\[([^\]]+)\]/g, (match, p1) => {
        return `<span style="width:5px; padding: 0 3px; overflow:hidden; font-style: italic;">___</span>`;
      })
    : '';

  return (
    <div className="flex flex-col h-full w-full bg-white py-10 px-5 relative">
      <div className="font-bold text-base">{content.content?.title}</div>
      <div className="mt-5" dangerouslySetInnerHTML={{ __html: processedText }} />

      <div className="mt-10 text-center">
        <div className="text-center  text-[#722ED1]">{interaction.instruction?.title}</div>
        <div className="mt-2 text-[#D3ADF7]">{interaction.instruction?.description}</div>
      </div>

<div className='text-center'>
      {interaction.orderItems ? (
        <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center mt-5">
          {shuffleToArray(interaction.orderItems).map((item, index) => (
            <Button key={index} type="default">
              {item[0]}
            </Button>
          ))}
        </div>
      ) : (
        <></>
      )}
      </div>
    </div>
  );
};
