import { Avatar, Button, Card, Form, Input, InputNumber, notification, Popconfirm } from 'antd';
import { DatePicker } from 'antd';
import { LayoutEnums, Role } from '@shared/constants';
import TextArea from 'antd/lib/input/TextArea';
import { useAccessableSeachParams } from '@shared/hooks';
import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { ReminderDrawer } from './ReminderDrawer';

type StudentHomeWorkDetailProps = {};

export const StudentHomeWorkDetail = ({}: StudentHomeWorkDetailProps) => {
  useAccessableSeachParams();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };
  return (
    //TODO Student Layout düzeltiğinde burasıda oraya eşitlenmeli MARGİN SAĞDAN SOLDAN
    <div style={{ margin: '30px 200px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
      <div>
        <Card>
          <div className="flex">
            <div className="w-1/2">
              <div className="font-bold text-xl">Kompozisyon</div>
              <div className="opacity-45 text-black">12.05.2024 - 24.05.2024</div>
            </div>
            <div className="w-1/2 flex justify-end items-center">
              <Avatar>SY</Avatar> <span className="ml-2">Semih Yılmaz</span>
            </div>
          </div>
        </Card>
        <Card className="mt-4">
          <div className="grid grid-cols-1">
            <div>
              Kur süresince öğrendiğiniz zaman zarflarını kullanarak, geçtiğimiz yaz tatiliniz ile ilgili bir kompozisyon yazınız. Yeni öğrendiğiniz kelimelere yer veriniz.
              Paragraf uzunluğu en az 120-150 kelime aralığında olmalı.
            </div>
            <div className="mt-7">
              <PaperClipOutlined />
              <span className="ml-2 text-blue-500">filename.docx</span>
            </div>
          </div>
        </Card>

        <div className="w-1/4">
          <Button className="text-blue-500 mt-5" icon={<UploadOutlined />}>
            Dosya Yükle
          </Button>
        </div>
        <div className="mt-6">
          <span className="text-red-500">*</span>Desteklenen formatlar:.docx, .word, .pdf
        </div>
        <div className="w-1/2 flex gap-5 mt-5">
          <Button className="w-44">Teslim Et</Button>
          <Button onClick={() => setDrawerOpen(true)} className="w-44">
            Hatırlatıcı Ekle
          </Button>
        </div>
      </div>
      <ReminderDrawer open={drawerOpen} onCloseDrawer={onCloseDrawer} />
    </div>
  );
};

StudentHomeWorkDetail.path = '/' + LayoutEnums.STUDENT + '/homework_detail';
StudentHomeWorkDetail.roles = [Role.ADMIN];
