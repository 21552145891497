import { Button, Form, Input } from "antd"
import * as API from '@shared/api';
import { useCallback, useEffect } from "react";
import { CourseUnitDefinetion, CourseUnitModel } from "@shared/models";
import { z } from "zod";
import { useForm } from "@shared/hooks";


const CreateCourseUnitFormValidation = z.object({
    name: z.string({ required_error: 'Kurs unite adı zorunlu alandır' }).min(1, 'Kurs unite adı 1 karekterden fazla olmalı'),
    description: z.string({ required_error: "Lütfen kur seçimi yapınız" }),
});

type CreateCourseUnitProp = {
    value?: CourseUnitModel,
    onSave: (value: CourseUnitModel) => void
}
export const CreateCourseUnitForm = ({ value, onSave }: CreateCourseUnitProp) => {
    
    const form = useForm<CourseUnitModel>(CreateCourseUnitFormValidation, {});

    useEffect(() => {
        if (value && Object.keys(value).length > 0) {
            form.setInitialValue(value);
        } else {
            form.setValue({});
            form.reset();
        }
    }, [value]);

    const send = useCallback(() => {
        const result = form.parse();
        if (result.success) {
            onSave(result.data);
        } else {
            form.setAllTouched();
        }
    }, [form]);

    return (
        <>
            <Form layout="vertical" >
                <Form.Item {...form.formItem("name")} label="Unite Adı:">
                    <Input {...form.input("name")} />
                </Form.Item>
                <Form.Item {...form.formItem("description")} label="Unite Açıklaması:">
                    <Input {...form.input("description")} />
                </Form.Item>
            </Form>
            <div className="flex flex-row justify-end">
                <Button type="primary" onClick={send}>Kaydet</Button>
            </div>
        </>
    )
}