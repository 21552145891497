import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import * as API from '@shared/api';
import { Avatar, Button, Table, notification, TableColumnType, Input, InputRef } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib/table';
import { PaginationQuery, TeacherModel } from '@shared/models';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionDropdown, AvatarFromName } from '@shared/components';
import { useEffect, useRef, useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { sortFields } from '@shared/utils';

export const Teachers = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [teachers, loading, getTeachers] = API.TEACHER.useFetch({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6, withDeleted: false });
  const [selectedTeacher, setSelectedTeacher] = useState<TeacherModel>();
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    getTeachers(undefined, paginationQuery);
  }, [paginationQuery]);

  const title = (
    <div className="flex justify-between">
      <div className="font-bold"></div>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={() => {
          navigate('/lms/create-teacher' + location.search);
        }}
        type="dashed"
      >
        Yeni Öğretmen Oluştur
      </Button>
    </div>
  );

  const getColumnSearchProps = (): TableColumnType<TeacherModel> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Filtrele"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className="block mb-2"
        />
        <div className="flex justify-around gap-3">
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small">
            Filtrele
          </Button>

          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            Kapat
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, fontSize: '120%' }} />,
  });

  const columns: TableProps<TeacherModel>['columns'] = [
    {
      title: 'Adı Soyadı',
      dataIndex: 'name',
      align: 'center',
      sorter: true,
      render: (text, record) => `${record.name} ${record.surname}`,
      ...getColumnSearchProps(),
    },
    {
      title: 'Branş',
      dataIndex: 'abilityLevels',
      align: 'center',
      render: (value, _record) => {
        const levels = {};
        value.forEach((v) => {
          const name = v.theme.type.name;
          if (name in levels) {
            levels[name].push(v.name);
          } else {
            levels[name] = [v.name];
          }
        });
        return (
          <Avatar.Group maxCount={4}>
            {Object.keys(levels).map((l, i) => (
              <AvatarFromName title={l} subtitle={levels[l].join(',')} sorting={false} key={i.toString()} />
            ))}
          </Avatar.Group>
        );
      },
    },
    {
      title: 'İletişim',
      key: 'phone',
      align: 'center',
      render: ({ phone }) => `${phone.code} ${phone.number}`,
    },

    {
      title: 'Aksiyon',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <ActionDropdown
          data={record}
          onEdit={({ id }) => {
            navigate('/lms/create-teacher' + location.search + '&teacher=' + id);
          }}
          onDelete={async ({ id }) => {
            const result = await API.TEACHER.delete(id);
            if (result.ok) {
              notification.success({ message: 'The teacher was deleted' });
            }
            setPaginationQuery((prev) => ({ ...prev, page: 1 }));
          }}
        />
      ),
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        pagination={{ current: paginationQuery.page, total: teachers?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        columns={columns}
        dataSource={teachers?.data}
        bordered
        title={() => title}
        onChange={(pagination, filters, sorter) => {
          const { current } = pagination;
          let newQuery = {
            ...paginationQuery,
            page: current,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });

          setPaginationQuery(newQuery);
        }}
      />
    </>
  );
};

Teachers.path = '/lms/teachers';
Teachers.title = 'Öğretmen listesi';
Teachers.group = 'teacher';
Teachers.roles = [Role.ADMIN];
Teachers.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
Teachers.permissions = ['branch.lms.teacher.view'];
