import { BookOutlined, CaretRightOutlined, CheckCircleTwoTone, CheckOutlined } from '@ant-design/icons';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ContentTopicTree, ContentUnitTree, ContentVersionNavigation, CourseUnitModel } from '@shared/models';
import { Card, Divider, Dropdown, Progress, Select, Tag } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import * as API from '@shared/api';
import { ExerciseOrPageHandler } from '@shared/components';

export const StudentCourseContent = () => {
  useAccessableSeachParams();
  const [levels, levelsLoading, getStudentLevels] = API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentLevels({ init: true });
  const [levelOptions, setLevelOptions] = useState<DefaultOptionType[]>([]);
  const [selectedLevelId, setSelectedLevelId] = useState<string>();
  const [units, setUnits] = useState<ContentUnitTree[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<ContentUnitTree>();
  const [topics, setTopics] = useState<ContentTopicTree[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<ContentTopicTree>();
  const [selectedPage, setSelectedPage] = useState<ContentVersionNavigation>();

  useEffect(() => {
    if (levels) {
      setLevelOptions(levels.map((l) => (
        {
          label: l.theme?.type?.field?.name + "/" + l.theme?.type?.name + "/" + l.theme?.name + "/" + l.name
          , value: l.id
        }
      )))
    }
  }, [levels]);

  useEffect(() => {
    if (selectedLevelId) {
      getContentUnits();
    }
  }, [selectedLevelId]);

  useEffect(() => {
    if (selectedUnit) {
      getContentTopics();
    }
  }, [selectedUnit]);


  useEffect(() => {
    if (selectedTopic) {
      getContentPages();
    }
  }, [selectedTopic]);

  const getContentUnits = async () => {

    if (selectedLevelId) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentUnits(selectedLevelId);
      setUnits(data);
      if (!selectedUnit) {
        setSelectedUnit(data[0]);
      }
    }
  }

  const getContentTopics = async () => {
    if (selectedUnit && selectedLevelId) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentTopics(selectedLevelId, selectedUnit.id);
      setTopics(data);
    }
  }

  const getContentPages = async () => {
    if (selectedLevelId && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentPages(selectedLevelId, selectedUnit.id, selectedTopic.id);
      setSelectedPage(data);
    }
  }

  const goToNextPage = async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToNextPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      setSelectedPage(data);
    }
  }

  const goToPrevPage = async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToPrevPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      setSelectedPage(data);
    }
  }

  const progressCircle = (percent: number) => {
    return (
      <Progress
        status='success'
        type="circle"
        size={25}
        percent={percent}
        strokeWidth={5}
        showInfo={true}
        format={
          (v, s) => {
            if (v == 100) {
              return (
                <CheckOutlined className='text-[#722ED1] text-lg font-extrabold' />
              )
            } else {
              return (
                <CaretRightOutlined className='text-[#722ED1] text-lg font-extrabold' />
              )
            }

          }
        }
        trailColor='#D3ADF7'
        strokeColor={'#722ED1'}
      />)
  }


  return (
    <>
      <div className='grid grid-cols-12'>
        <div className='flex flex-col items-start col-span-3'>
          <Select
            size='large'
            value={selectedLevelId}
            placeholder={"Lütfen bir seçim yapınız"}
            loading={levelsLoading}
            style={{ width: '15vw' }}
            onChange={(value) => {
              setSelectedLevelId(value);
            }}
            options={levelOptions}
          />
          <span className='text-lg font-sans p-2'>Üniteler</span>
          <div className='w-full p-1 overflow-y-auto scrollbar-hide ' style={{ height: 'calc(100vh - 185px)' }}>

            {
              units.map((unit) => {
                return (
                  <Card size='small' key={unit.id} className={selectedUnit?.id == unit.id ? 'bg-[#FCF8FF] mb-2 shadow-md rounded-lg' : 'bg-[#FFFEFE] mb-2 shadow-md rounded-lg'} hoverable onClick={() => setSelectedUnit(unit)}>
                    <div className='flex flex-row justify-between  '>
                      <span className='text-[#3F0A70]'>{unit.name}</span>
                      {progressCircle(unit.percent)}
                    </div>
                  </Card>
                )
              })
            }
          </div>
        </div>


        <div className='w-full flex col-span-9 content-center justify-center items-center scrollbar-hide ' style={{ height: 'calc(100vh - 185px)' }}>
          <div className={selectedPage ? 'h-full w-[30rem]' : 'w-[30rem] h-full'}>

            {
              selectedPage ? (
                <div className='w-full' style={{ height: 'calc(100vh - 110px)' }}>
                  <ExerciseOrPageHandler
                    page={selectedPage.page}
                    isAdminCdn={false}
                    isInClass={false}
                    selectedTopic={selectedTopic}
                    onNext={() => {
                      if (selectedPage && selectedPage.meta.hasNext) {
                        goToNextPage();
                      } else {
                        setSelectedPage(undefined);
                      }
                    }}
                    onPrev={() => {
                      if (selectedPage && selectedPage.meta.hasPrev) {
                        goToPrevPage();
                      } else {
                        setSelectedPage(undefined);
                      }
                    }}
                  />
                </div>
              ) : (
                topics.map((topic) => {
                  return (
                    <Card
                      key={topic.id}
                      className='mb-2 bg-[#F9F0FF] p-0 border-[#F0DBFF] hover:border-[#bb76ec]'
                      hoverable
                      size="small"
                      onClick={() => setSelectedTopic(topic)}
                      title={(
                        <div className='flex flex-row justify-between'>
                          <span className='text-[#722ED1] text-sm font-normal'>{topic.name}</span>
                          {progressCircle(topic.percent)}
                        </div>
                      )}
                    >

                      <div className='bg-[#F4E5FF] p-2 rounded-md'>
                        <Tag color='#F9F0FF'><span className='text-[#722ED1] text-xs'>{topic.type}</span></Tag>
                        <div className='flex flex-row justify-between content-center items-center space-x-2 mt-1'>
                          <Progress percent={topic.percent} showInfo={false} className='w-full' strokeColor={{
                            '0%': '#F9E681',
                            '48%': '#F69D35',
                            '100%': '#F60000',
                          }} />
                          <span className='text-sm text-[#722ED1]'>{"%" + topic.percent}</span>
                        </div>
                      </div>
                    </Card>
                  )
                })
              )

            }
          </div>

        </div>
      </div>
    </>
  );
};

StudentCourseContent.path = '/' + LayoutEnums.STUDENT + '/course_content';
StudentCourseContent.title = 'Ders İçerikleri';
StudentCourseContent.group = 'flat';
StudentCourseContent.roles = [Role.ADMIN];
StudentCourseContent.layouts = [LayoutEnums.STUDENT];
StudentCourseContent.icon = <BookOutlined size={20} />;
