import { AuthLayout, PlatformLayoutHandler } from '@shared/components';
import { useUserContext } from '@shared/contexts';
import { BrowserRouter } from 'react-router-dom';
import * as _pages from './pages';
import * as _authPages from '@shared/authenticator';
const pages = Object.entries(_pages);
const authPages = Object.entries(_authPages);

export const AppContent = () => {
  const { user } = useUserContext();

  return <BrowserRouter>{user.jwt ? <PlatformLayoutHandler pages={pages} /> : <AuthLayout pages={authPages} />}</BrowserRouter>;
};
