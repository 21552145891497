import { ActionDropdown, AvatarFromName } from '@shared/components';
import { Observer, PrePlacement } from '@shared/models';
import { Card, Tag } from 'antd';

type EnrollmentUserCardProp = {
  user: Observer | PrePlacement;
  isDelete?: boolean;
  isEdit?: boolean;
  onDelete: (user: Observer | PrePlacement) => void;
  onEdit: (user: Observer | PrePlacement) => void;
  disabled?: boolean;
  mainUserText?: string;
};
export const EnrollmentUserCard = ({ user, isDelete = true, isEdit = true, onDelete, onEdit, disabled = false, mainUserText }: EnrollmentUserCardProp) => {
  return (
    <Card
      key={user.id}
      title={
        <Card.Meta
          className="flex items-center"
          avatar={
            <div className="pt-2 pb-2">
              <AvatarFromName title={user.name!} subtitle={user.email!} bgColor="#E6F4FF" txColor="#1677FF" />
            </div>
          }
          title={[user.name, user.surname].join(' ')}
        />
      }
      extra={
        <div className="flex gap-2 items-center">
          {(isDelete || isEdit) && <ActionDropdown
            disabled={disabled}
            data={user}
            isEdit={isEdit}
            isDelete={isDelete}
            onDelete={(data) => {
              onDelete(data);
            }}
            onEdit={(data) => {
              onEdit(data);
            }}
          />}
        </div>
      }
    >
      <div className='flex flex-col space-y-2'>
        <div className="grid grid-cols-[60px_1fr] items-center">
          <label className="font-medium">Email:</label>
          <span className="text-dark-gray text-xs ">{user.email}</span>

          <label className="font-medium ">Telefon:</label>
          <span className="text-dark-gray text-xs ">
            {user.phone?.code}
            {user.phone?.number}
          </span>
        </div>
        {
          mainUserText && <Tag className="w-full" color="cyan">{mainUserText}</Tag>
        }
        
      </div>
    </Card>
  );
};
