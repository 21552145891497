import { Button, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

type JotCalendarHeaderProps = {
  value: Dayjs;
  mode: 'week' | 'month';
  onModeChange: (type: 'week' | 'month') => void;
  onChange?: (value: Dayjs) => void;
};

export const JotCalendarHeader = ({ value, mode, onChange, onModeChange }: JotCalendarHeaderProps) => {
  const year = value.year();
  const month = value.month();
  const months = dayjs.monthsShort();

  const monthOptions = months.map((month, index) => ({
    value: index,
    label: month,
  }));

  const yearOptions = Array.from({ length: 20 }, (_, i) => year - 10 + i).map((year) => ({
    value: year,
    label: year.toString(),
  }));

  return (
    <div className="flex justify-end gap-2">
      {mode !== 'week' && (
        <>
          <Select
            options={monthOptions}
            value={month}
            onChange={(newMonth) => {
              const now = value.clone().month(newMonth);
              onChange!(now);
            }}
          />
          <Select
            options={yearOptions}
            value={year}
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange!(now);
            }}
          />
        </>
      )}

      <Button.Group>
        <Button type={mode === 'week' ? 'primary' : 'default'} onClick={() => onModeChange('week')}>
          Haftalık
        </Button>
        <Button type={mode === 'month' ? 'primary' : 'default'} onClick={() => onModeChange('month')}>
          Aylık
        </Button>
      </Button.Group>
    </div>
  );
};
