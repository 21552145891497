import { useUserContext } from '@shared/contexts';
import { ClassDetailModel, ClassModel, PaginateModel } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Card } from 'antd';
import dayjs from 'dayjs';

type ClassHeaderProps = {
  classList?: PaginateModel<ClassModel>;
  classListPage?: boolean;
  classDetail?: ClassDetailModel;
  classDetailPage?: boolean;
  imageName?: 'class_dashboard' | 'class_teacher_dashboard';
  titleColor?: string;
  startedClass?: PaginateModel<ClassModel>;
  plannedClass?: PaginateModel<ClassModel>;
};

export const ClassHeader = ({
  classList,
  classDetail,
  classListPage = false,
  classDetailPage = false,
  imageName = 'class_dashboard',
  titleColor = '#117600',
  startedClass,
  plannedClass,
}: ClassHeaderProps) => {
  const { user } = useUserContext();
  var backgroundImage = '';
  if (imageName === 'class_dashboard') {
    backgroundImage = 'url(' + require('../../../../statics/class_dashboard.png') + ')';
  } else if (imageName === 'class_teacher_dashboard') {
    backgroundImage = 'url(' + require('../../../../statics/class_teacher_dashboard.png') + ')';
  }

  const HeaderItems = () => {
    if (classListPage) {
      return (
        <div className="flex gap-2 w-full">
          <Card className="bg-white/40 backdrop-blur-sm w-1/3">
            <div className="opacity-45 text-black">Aktif Sınıf Sayısı</div>
            <div className="text-xl">{startedClass?.count ?? 0}</div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-sm w-1/3">
            <div className="opacity-45 text-black">Planlanmış Sınıf Sayısı</div>
            <div className="text-xl">{plannedClass?.count ?? 0}</div>
          </Card>
        </div>
      );
    }

    if (classDetailPage) {
      return (
        <div className="flex gap-2 w-full">
          <Card className="bg-white/40 backdrop-blur-sm w-1/4">
            <div className="opacity-45 text-black">Kur Bilgisi</div>
            <div className="text-lg flex flex-col">
              <div className="truncate">{classDetail?.name}</div>
              <div className="truncate text-md">{`${renderLevelsAsString(classDetail?.level)}`}</div>
            </div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-sm w-1/4">
            <div className="opacity-45 text-black">Kur Bitiş Tarihi</div>
            <div className="text-xl py-2">{dayjs(classDetail?.calculatedEndDate).format('DD.MM.YYYY')}</div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-sm w-1/4">
            <div className="opacity-45 text-black">Öğrenci Sayısı</div>
            <div className="text-xl py-2">{classDetail?.students?.length}</div>
          </Card>
        </div>
      );
    }

    return <></>;
  };

  return (
    <Card
      className="rounded-xl"
      style={{
        backgroundImage: backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="text-lg mb-2 ml-6 font-light" style={{ color: titleColor }}>
        Merhaba, <span className="font-normal">{user.name}</span>
      </div>
      <HeaderItems />
    </Card>
  );
};
