export enum AccountNotificationType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
export type AccountNotification = {
  id: string;
  title: { label: string; params?: Record<string, any> };
  description?: { label: string; params?: Record<string, any> };
  type: AccountNotificationType;
  isReaded: boolean;
  createdAt: Date;
  readedAt?: Date;
};
