import { Card } from 'antd';
import { ReactElement, useState } from 'react';
import { NotificationList } from './NotificationList';

export const UserInformationComponent = () => {
  const [selectedSegmend, setSelectedSegmend] = useState<ReactElement>(<NotificationList />);
  // const onChangeSegmented = (key) => {
  //     if (key == "Bildirimlerim") {
  //         return setSelectedSegmend(<NotificationList />);
  //     } else if (key == "Taleplerim") {
  //         return setSelectedSegmend(<RequestList />);
  //     } else {
  //         return setSelectedSegmend(<NewsList />);
  //     }
  // }
  return (
    <Card title="Bildirimler">
      {/* <Segmented options={['Bildirimlerim', 'Taleplerim', 'Haberler']} block onChange={(v) => onChangeSegmented(v)} /> */}
      {selectedSegmend}
    </Card>
  );
};
