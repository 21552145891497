import { useFetch } from '@shared/hooks';
import { ClassRoomModel, PaginateModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  save: async (model: ClassRoomModel) =>request('/class-room', 'POST', model),

  update: async (model: ClassRoomModel) =>request('/class-room', 'PUT', model),

  delete: async (id: string) => request('/class-room/' + id, 'DELETE', null, { id }),
  getClassroomList: (config: UseFetchOptions) => useFetch<PaginateModel<ClassRoomModel>>('/class-room', { method: 'GET', ...config }),
};
