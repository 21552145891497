import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { WeeklyCalendarHeader } from './WeeklyCalendarHeader';
import { JotCalendarHeader } from './JotCalendarHeader';
import { ClassCalendarModel } from '@shared/models';

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

const days = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
const times = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'];

type Event = {
  id: string;
  startTime: Dayjs;
  endTime: Dayjs;
  content: string;
  type: 'success' | 'error';
};

type GroupedEvents = {
  [key: string]: Event[];
};

//TODO Backendden data yapısı oluşturulduğunda revize edilecek
const events: Event[] = [
  { id: '1', startTime: dayjs(new Date(2024, 7, 7, 9, 15, 0)), endTime: dayjs(new Date(2024, 7, 7, 21, 55, 0)), content: 'Almanca B1', type: 'error' },
  { id: '2', startTime: dayjs(new Date(2024, 7, 7, 9, 55, 0)), endTime: dayjs(new Date(2024, 7, 7, 10, 45, 0)), content: 'Almanca B2', type: 'success' },
  { id: '5', startTime: dayjs(new Date(2024, 7, 2, 9, 0, 0)), endTime: dayjs(new Date(2024, 7, 2, 10, 0, 0)), content: 'İngilizce A1', type: 'success' },
  { id: '6', startTime: dayjs(new Date(2024, 7, 2, 11, 0, 0)), endTime: dayjs(new Date(2024, 7, 2, 13, 0, 0)), content: 'İngilizce A2', type: 'success' },
  { id: '7', startTime: dayjs(new Date(2024, 7, 2, 11, 0, 0)), endTime: dayjs(new Date(2024, 7, 2, 12, 0, 0)), content: 'İngilizce A3', type: 'success' },
  { id: '8', startTime: dayjs(new Date(2024, 6, 25, 15, 0, 0)), endTime: dayjs(new Date(2024, 6, 25, 16, 0, 0)), content: 'İngilizce A4', type: 'success' },
  { id: '9', startTime: dayjs(new Date(2024, 6, 25, 16, 0, 0)), endTime: dayjs(new Date(2024, 6, 25, 17, 0, 0)), content: 'İngilizce A5', type: 'success' },
  { id: '10', startTime: dayjs(new Date(2024, 6, 25, 16, 30, 0)), endTime: dayjs(new Date(2024, 6, 25, 18, 0, 0)), content: 'İngilizce A6', type: 'success' },
];

const getMinutesFromMidnight = (date: Dayjs) => {
  const hours = date.hour();
  const minutes = date.minute();
  return hours * 60 + minutes;
};

const groupEventsByDay = (events: Event[]): GroupedEvents => {
  return events.reduce((acc, event) => {
    const startDay = event.startTime.format('YYYY-MM-DD');
    if (!acc[startDay]) {
      acc[startDay] = [];
    }
    acc[startDay].push(event);
    return acc;
  }, {} as GroupedEvents);
};

const groupedEvents = groupEventsByDay(events);

const getCollidingEvents = (date: string): Event[][] => {
  const eventsForDay = groupedEvents[date] || [];
  const collidingEvents: Event[][] = [];
  const processedEvents = new Set<number>();

  for (let i = 0; i < eventsForDay.length; i++) {
    if (processedEvents.has(i)) continue;

    const eventA = eventsForDay[i];
    const eventAStart = getMinutesFromMidnight(eventA.startTime);
    const eventAEnd = getMinutesFromMidnight(eventA.endTime);
    const collisionGroup: Event[] = [eventA];

    for (let j = i + 1; j < eventsForDay.length; j++) {
      const eventB = eventsForDay[j];
      const eventBStart = getMinutesFromMidnight(eventB.startTime);
      const eventBEnd = getMinutesFromMidnight(eventB.endTime);

      if ((eventAStart < eventBEnd && eventAEnd > eventBStart) || (eventBStart < eventAEnd && eventBEnd > eventAStart)) {
        collisionGroup.push(eventB);
        processedEvents.add(j);
      }
    }

    collidingEvents.push(collisionGroup);
    processedEvents.add(i);
  }

  return collidingEvents;
};

type WeeklyCalendarProps = {
  value?: Dayjs;
  mode: 'month' | 'week';
  onModeChange: (type: 'week' | 'month') => void;
  onChange: (value: Dayjs) => void;
};

export const WeeklyCalendar = ({ value, mode, onModeChange, onChange }: WeeklyCalendarProps) => {
  const [startWeek, setStartWeek] = useState<Dayjs>(value ? value.startOf('week') : dayjs().startOf('week'));
  const endWeek = startWeek.endOf('week');

  useEffect(() => {
    const newStartWeek = value ? value.startOf('week') : dayjs().startOf('week');
    if (value && !newStartWeek.isSame(startWeek, 'day')) {
      setStartWeek(newStartWeek);
    }
  }, [value]);

  const handleStartWeekChange = (newStartWeek: Dayjs) => {
    setStartWeek(newStartWeek);
    onChange(newStartWeek);
  };

  const renderedEvents = new Set();

  const renderEvent = (day: string, time: string) => {
    const date = dayjs(startWeek).add(days.indexOf(day), 'day').format('YYYY-MM-DD');
    const eventsForDay = groupedEvents[date] || [];
    const collisionGroups = getCollidingEvents(date);

    return collisionGroups.flatMap((group, groupIndex) => {
      const margin = 1;
      const adjustedWidth = (100 - margin * (group.length - 1)) / group.length;

      return group.map((event, eventIndex) => {
        const eventStartMinutes = getMinutesFromMidnight(event.startTime);
        const eventEndMinutes = getMinutesFromMidnight(event.endTime);
        const [hours, minutes] = time.split(':').map(Number);
        const timeSlotStartMinutes = hours * 60 + minutes;
        const timeSlotEndMinutes = timeSlotStartMinutes + 60;

        // Eventi zaten render ettiysek, bir daha render etme
        if (renderedEvents.has(event.id)) {
          return null;
        }

        // Eventi render et ve Set'e ekle
        if (eventStartMinutes >= timeSlotStartMinutes && eventStartMinutes < timeSlotEndMinutes) {
          renderedEvents.add(event.id);
          const top = ((eventStartMinutes - timeSlotStartMinutes) / 60) * 100;
          const height = ((eventEndMinutes - eventStartMinutes) / 60) * 100;
          const left = (adjustedWidth + margin) * eventIndex;

          return (
            <div
              key={`${event.id}-${groupIndex}-${eventIndex}`}
              className={`event ${event.type}`}
              style={{
                top: `${top}%`,
                height: `${height}%`,
                width: `${adjustedWidth}%`,
                left: `${left}%`,
                position: 'absolute',
              }}
            >
              {event.content}
            </div>
          );
        }

        return null;
      });
    });
  };

  return (
    <Card>
      <div className="flex justify-between">
        <WeeklyCalendarHeader startWeek={startWeek} setStartWeek={handleStartWeekChange} />
        <JotCalendarHeader value={startWeek} mode={mode} onModeChange={onModeChange} onChange={handleStartWeekChange} />
      </div>

      <div className="weekly">
        <div className="weekly-calendar">
          <div className="header"></div>
          {days.map((day) => (
            <div key={day} className="header">
              {day}
            </div>
          ))}
          {times.map((time) => (
            <React.Fragment key={time}>
              <div className="time-cell">{time}</div>
              {days.map((day) => (
                <div key={`${day}-${time}`} className="time-slot">
                  {renderEvent(day, time)}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Card>
  );
};
