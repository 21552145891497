
import { ClassActivity } from "@shared/models";
import { createContext, useContext, useState } from "react";

type AccountActivityContextType = {
    teacherActivityList: ClassActivity[];
    setTeacherActivityList: (list: ClassActivity[]) => void
};

const AccountActivityContext = createContext<AccountActivityContextType>({
    teacherActivityList: [],
    setTeacherActivityList: (list:ClassActivity[]) => {},
});

export const AccountActivityContextProvider = ({ children }) => {
    const [teacherActivityList, setTeacherActivityList] = useState<ClassActivity[]>([]);
    return (
        <AccountActivityContext.Provider value={{ teacherActivityList, setTeacherActivityList }}>
            {children}
        </AccountActivityContext.Provider>
    )
}

export const useAccountActivityContext = () => {
    const context = useContext(AccountActivityContext);
    if (context == undefined) {
        throw new Error("useAccountActivityContext must be used within a AccountActivityContextProvider");
    }
    return context;
}