import { AudioOutlined } from "@ant-design/icons";
import { ImageView } from "@shared/components/curriculum/ImageView";
import { PronunciationVerifier } from "@shared/components/curriculum/PronunciationVerifier";
import { SoundPlayer } from "@shared/components/curriculum/SoundPlayer";
import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";
import { Button } from "antd";

type ExerciseProps = {
    content: ExerciseDefinitionContentModel,
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isAdminCdn: boolean;
    isInClass: boolean;
}
export const VisualBase = ({ content, interaction, isPreview = false, isAdminCdn, isInClass }: ExerciseProps) => {
    return (
        <>
            {
                isInClass ?
                    (
                        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10">
                            <div className="border border-double border-mid-gray rounded m-2 h-full w-3/6 text-center " >
                                <ImageView image={content?.image ? content.image : {}} />
                            </div>
                            <div className="flex flex-col w-3/6">
                                <div className="items-center w-full flex flex-col h-1/6 m-2">
                                    <p className="p-0 m-0 font-extrabold text-3xl text-center">{isPreview ? (content.content ? content.content?.title : '....') : content.content?.title}</p>
                                    <p className="text-lg text-gray-400 text-center">{isPreview ? (content.content?.description ? content.content.description : '....') : content.content?.description}</p>
                                </div>
                                <div className="grid grid-cols-2 h-1/6  w-full justify-center content-center items-center text-center">
                                    {content.sound && (<div className={content.isPronounEnabled ? "!p-2" : "col-span-2"}><SoundPlayer sound={content.sound} onChange={() => { }} isInit={true} isPreview={isPreview} isAdminCdn={isAdminCdn} iconFontSize="4rem" /></div>)}
                                    {content?.isPronounEnabled && (<div className={content.sound ? "!p-2" : "col-span-2"}><Button type="text" className="!w-max !h-max" size="large" icon={<AudioOutlined style={{ fontSize: '4rem' }} />} /></div>)}
                                </div>
                            </div>
                        </div>
                    ) :
                    (
                        <div className="flex flex-col items-center h-full bg-white pt-10 pb-10 relative">
                            <div className="border border-double border-mid-gray rounded m-2 h-4/6 text-center" >
                                <ImageView image={content?.image ? content.image : {}} />
                            </div>
                            <div className="items-center w-full flex flex-col h-1/6 m-2">
                                <p className="p-0 m-0 font-extrabold text-3xl text-center">{isPreview ? (content.content ? content.content?.title : '....') : content.content?.title}</p>
                                <p className="text-lg text-gray-400 text-center">{isPreview ? (content.content?.description ? content.content.description : '....') : content.content?.description}</p>
                            </div>
                            <div className="grid grid-cols-2 h-1/6  w-full justify-center content-center items-center text-center">
                                {content.sound && (<div className={content.isPronounEnabled ? "!p-4" : "col-span-2"}><SoundPlayer sound={content.sound} onChange={() => { }} isInit={true} isPreview={isPreview} isAdminCdn={isAdminCdn} iconFontSize="3rem" /></div>)}
                                {content?.isPronounEnabled && (<div className={content.sound ? "!p-4" : "col-span-2"}><PronunciationVerifier texts={[content.content?.title!]} onVerify={(e)=>{console.log(e);}}/></div>)}
                            </div>
                            {/*<div className="absolute bottom-0 left-0 right-0 top-0">LottiFile</div>*/}
                        </div>
                    )
            }
        </>

    );
}