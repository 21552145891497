import { MediaModel } from '@shared/models';
import { useEffect, useRef, useState } from 'react';
import * as API from '@shared/api';
import { Button } from 'antd';
import { PauseOutlined, PlayCircleOutlined, SoundOutlined } from '@ant-design/icons';

type SoundPlayerProps = {
  sound: MediaModel;
  isInit: boolean;
  onChange: () => void;
  iconFontSize?: string;
  isPreview?: boolean;
  isAdminCdn: boolean;
  inline?: boolean;
  onCurrentTimeChange?: (currentTime: number) => void;
};
export const SoundPlayer = ({ sound, isInit = true, isPreview = false, iconFontSize = '3rem', isAdminCdn, inline = false, onCurrentTimeChange }: SoundPlayerProps) => {
  const audioRef = useRef(new Audio());
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(0);

  

  useEffect(() => {
    if (sound.resourceUrl) {
      audioRef.current.src = sound.resourceUrl;
    } else if (sound.resourceId) {
      getSoundUrl(sound.resourceId);
    }
  }, [sound]);

  useEffect(() => {
    if (onCurrentTimeChange) {
      onCurrentTimeChange(currentTime);
    }
  }, [currentTime, onCurrentTimeChange]);

  const getSoundUrl = async (id) => {
    try {
      let _url = '';
      if (isAdminCdn) {
        const {
          data: { url },
        } = await API.CDN.obtainDownloadVideoAndSoundUrl(id);
        _url = url;
      } else {
        const {
          data: { url },
        } = await API.CDN.brandObtainDownloadVideoAndSoundUrl(id);
        _url = url;
      }
      audioRef.current.src = _url;
      if (isInit && !isPreview) {
        startToPlay();
      }
    } catch (error) {}
  };

  const startToPlay = () => {
    if (isPlay) {
      audioRef.current.pause();
      setIsPlay(false);
    } else {
      audioRef.current.load();
      audioRef.current
        .play()
        .then(() => {
          setIsPlay(true);
        })
        .catch((e) => {
          setIsPlay(false);
        });
        
    }
  };

  if (inline) {
    const togglePlayPause = () => {
      if (isPlay) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlay(!isPlay);
    };

    return (
      <div className="flex items-center bg-white p-4 rounded-full  border-solid border-[#f0f0f0]">
        <button onClick={togglePlayPause} className="bg-transparent border-none cursor-pointer outline-none mr-3">
          {isPlay ? <PauseOutlined className="text-black text-xl" /> : <PlayCircleOutlined className="text-xl" />}
        </button>

        <div className="text-sm text-gray-500">{currentTime == 0 ? '00:00' : <span>{new Date(currentTime * 1000).toISOString().substr(14, 5)}</span>}</div>

        <div className="flex-1 mx-3">
          <div className=" w-full bg-[#F9F0FF] h-1.5 rounded-full">
            <div
              className=" top-0 left-0 bg-orange-400 h-1.5 rounded-full"
              style={{ width: `${(currentTime / audioRef.current?.duration) * 100}%` }} //Bar dolum oranı hesaplama
            ></div>
          </div>
        </div>
        <div className="flex text-sm text-gray-500">
          <span> {audioRef.current.duration && !isNaN(audioRef.current.duration) ? new Date(audioRef.current.duration * 1000).toISOString().substr(14, 5) : ''}</span>
        </div>
        <audio
          ref={audioRef}
          onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
          onEnded={() => {
            setIsPlay(false);
            setCurrentTime(0);
          }}
        ></audio>
      </div>
    );
  }

  return (
    <Button
      type="text"
      className={'!w-max !h-max'}
      size="large"
      onClick={() => {
        startToPlay();
      }}
      icon={<SoundOutlined style={{ fontSize: iconFontSize, color: isPlay ? 'blueviolet' : '' }} />}
    >
      <audio
          ref={audioRef}
          onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
          onEnded={() => {
            setIsPlay(false);
            setCurrentTime(0);
          }}
        ></audio>
    </Button>
  );
};
