import { EducationLevel, PaginateModel } from '@shared/models';
import { Switch, Table, TableColumnsType, Tag } from 'antd';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { ActionDropdown } from '../ActionDropdown';

type EducationLevelsViewProps = {
  levels: PaginateModel<EducationLevel>;
  isLoading?: boolean;
  title?: ReactElement;
  isActiveView?: boolean;
  onChangeActiveStatus?: (id: string, active: boolean) => void;
  onEdit: (level: EducationLevel) => void;
  onChangePagination: (page: number) => void;
  pageSize?: number;
};

export const EducationLevelsView = ({
  levels,
  isLoading = false,
  title,
  isActiveView = false,
  onChangeActiveStatus,
  onEdit,
  onChangePagination,
  pageSize = 6,
}: EducationLevelsViewProps) => {
  const columns: TableColumnsType<EducationLevel> = [
    {
      title: 'Aktif/Pasif',
      key: 'active-pasif',
      align: 'center',
      render: ({ id, active }) => (
        <Switch
          size="small"
          value={active}
          onChange={(e) => {
            if (onChangeActiveStatus) {
              onChangeActiveStatus(id, e);
            }
          }}
        />
      ),
      hidden: !isActiveView,
    },
    {
      title: 'Eğitim Türü',
      dataIndex: 'theme.type.field.name',
      key: 'field',
      align: 'center',
      render: (_, { theme }) => <>{theme?.type?.field?.name}</>,
    },
    {
      title: 'Kategori',
      dataIndex: 'theme.type.name',
      key: 'type',
      align: 'center',
      render: (_, { theme }) => <>{theme?.type?.name}</>,
    },
    {
      title: 'Tür',
      dataIndex: 'theme.name',
      key: 'theme',
      align: 'center',
      render: (_, { theme }) => <>{<Tag color="magenta">{theme?.name}</Tag>}</>,
    },
    {
      title: 'Kur Adı',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name!.length - b.name!.length,
    },
    {
      title: 'Toplam ders saati',
      key: 'durationInHours',
      align: 'center',
      render: (_, { durationInHours }) => <a>{durationInHours} saat</a>,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (record) => <ActionDropdown data={record} onEdit={onEdit} isDelete={false} />,
    },
  ];

  return (
    <div>
      <Table
        pagination={{ total: levels?.count, pageSize: pageSize, position: ['bottomCenter'] }}
        size="small"
        bordered
        columns={columns}
        dataSource={levels.data}
        loading={isLoading}
        rowKey={'id'}
        title={() => title}
        onChange={(pagination) => onChangePagination(pagination.current!)}
      />
    </div>
  );
};
