import { HTMLAttributes } from 'react';

export interface TeacherDetailProps extends HTMLAttributes<HTMLElement> {
  teacher?: any;
}

export const TeacherDetail = (props: TeacherDetailProps) => {
  let { teacher, ...domProps } = props;

  return (
    <div {...domProps}>
      <h3>{teacher.name}</h3>
    </div>
  );
};
