import { CalendarOutlined, LeftOutlined, MailOutlined, PhoneOutlined, RightOutlined } from '@ant-design/icons';
import { LayoutEnums, StudentCalendarType } from '@shared/constants';
import { getMonthRange } from '@shared/utils';
import { Calendar, Card, Modal, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import * as API from '@shared/api';
import { StudentCalendarItem } from '@shared/models';

type StudetHeaderProps = {
  layout: LayoutEnums;
  name: string;
  surname: string;
  phone: string;
  email: string;
  accountId?: string;
};

export const StudentHeader = ({ layout, name, surname, phone, email, accountId }: StudetHeaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>();
  const [calendarItems, setCalendarItems] = useState<StudentCalendarItem[]>();

  //Hangi layouttan geldiysek (Öğrenci, Veli, Şube Yöneticisi), Calendar verilerini o layouta göre alıyoruz.
  const fetchCalendar = async ({ startOfMonth, endOfMonth }) => {
    let response: any;
    switch (layout) {
      case LayoutEnums.STUDENT:
        response = await API.STUDENT.getStudentCalendar(startOfMonth, endOfMonth);
        break;
      case LayoutEnums.OBSERVER:
        response = await API.OBSERVER.getObserverStudentCalendar('todo', startOfMonth, endOfMonth);
        break;
      case LayoutEnums.BRANCHMANAGER:
        if (accountId) {
          response = await API.ENROLLMENT.getBranchStudentCalendar(accountId, startOfMonth, endOfMonth);
        }
        break;
    }
    if (response && response.ok) {
      setCalendarItems(response.data);
    }
  };

  useEffect(() => {
    if (currentMonth) {
      const { startOfMonth, endOfMonth } = getMonthRange(currentMonth);
      fetchCalendar({ startOfMonth, endOfMonth });
    }
  }, [currentMonth]);

  // Ok tuşları İle yeni seçilen ayı state'e set ediyoruz
  const modalHeader = (value, onChange) => {
    const handlePrevMonth = () => {
      const newMonth = value.subtract(1, 'month');
      setCurrentMonth(newMonth);
      onChange(newMonth);
    };

    const handleNextMonth = () => {
      const newMonth = value.add(1, 'month');
      setCurrentMonth(newMonth);
      onChange(newMonth);
    };

    return (
      <div className="flex justify-center gap-5 items-center p-2 text-xl">
        <LeftOutlined className="cursor-pointer" onClick={handlePrevMonth} />
        <span>{value.format('MMMM YYYY')}</span>
        <RightOutlined className="cursor-pointer" onClick={handleNextMonth} />
      </div>
    );
  };

  const getColor = (event: StudentCalendarItem) => {
    if (event.type === StudentCalendarType.BRANCH_ITEM) return 'blue';
    if (event.type === StudentCalendarType.IN_CLASS) return 'magenta';
    if (event.type === StudentCalendarType.DEMAND_ACTION) return 'green';

    return 'default';
  };

  const renderModal = (value) => {
    const currentDate = value.toDate();
    const dayEvents = calendarItems?.filter((event) => dayjs(event.date).isSame(currentDate, 'day'));

    return (
      <div>
        {dayEvents?.map((event) => (
          <Tag className="w-11/12 whitespace-nowrap overflow-hidden text-ellipsis" color={getColor(event)} key={event.id}>
            {event.title}
          </Tag>
        ))}
      </div>
    );
  };

  return (
    <>
      <Card
        className="rounded-xl"
        style={{
          backgroundImage: 'url(' + require('../../../../statics/Hello.jpeg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="flex gap-5 w-full">
          <Card className="bg-white/40 backdrop-blur-md w-1/2">
            <div className="text-xl text-black">
              {name} {surname}
            </div>
            <div
              className="text-[#1677FF] mt-2 space-x-1 cursor-pointer"
              onClick={() => {
                setCurrentMonth(dayjs());
                setIsModalOpen(true);
              }}
            >
              <CalendarOutlined /> <span>Öğrenci takvimini görüntüle</span>
            </div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-md w-1/2">
            <div className="text-black">İletişim Bilgileri</div>
            <div className="opacity-45 text-black mt-2 space-x-1">
              <MailOutlined /> <span>{email}</span>
            </div>
            <div className="opacity-45 text-black mt-2 space-x-1">
              <PhoneOutlined /> <span>{phone}</span>
            </div>
          </Card>
        </div>
      </Card>

      <Modal open={isModalOpen} title="Öğrenci Takvimi" footer={null} onCancel={() => setIsModalOpen(false)} width={1100}>
        <Calendar mode="month" value={currentMonth} headerRender={({ value, onChange }) => modalHeader(value, onChange)} cellRender={renderModal} />
      </Modal>
    </>
  );
};
