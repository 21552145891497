export interface Config {
  apiUrl(): string;
  wsUrl(namespace: string): string;
}

class AppConfigInstance implements Config {
  apiUrl(): string {
    return process.env.API_URL || '/api';
  }
  wsUrl(namespace: string): string {
    const url = location.origin;
    return url.replace(':4000', ':3000').replace(':4001', ':3001') + '/' + namespace;
  }
}

export const AppConfig = new AppConfigInstance();
