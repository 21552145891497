import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassRoomModel, PaginationQuery } from '@shared/models';
import { LayoutEnums, Role } from '@shared/constants';
import { Button, Input, InputRef, notification, Table, TableColumnType } from 'antd';
import { TableProps } from 'antd/lib/table';
import { ClassroomDrawer } from '@platform/platform_components';
import { useEffect, useRef, useState } from 'react';
import { useAccessableSeachParams } from '@shared/hooks';
import { ActionDropdown } from '@shared/components';
import { sortFields } from '@shared/utils';
import { SorterResult } from 'antd/lib/table/interface';

export const ClassRoom = () => {
  useAccessableSeachParams();

  const [open, setOpen] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [selected, setSelected] = useState<ClassRoomModel | null>(null);
  const [classroomList, loading, getClassroomList] = API.CLASS_ROOM.getClassroomList({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });

  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    getClassroomList(undefined, paginationQuery);
  }, [paginationQuery]);

  const title = (
    <div className="flex justify-between">
      <div className="font-bold"></div>
      <Button icon={<PlusCircleOutlined />} onClick={() => onOpenDrawer(true, null)} type="dashed">
        Yeni Derslik Oluştur
      </Button>
    </div>
  );

  const getColumnSearchProps = (): TableColumnType<ClassRoomModel> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Filtrele"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className="block mb-2"
        />
        <div className="flex justify-around gap-3">
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small">
            Filtrele
          </Button>

          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            Kapat
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, fontSize: '120%' }} />,
  });

  const columns: TableProps<ClassRoomModel>['columns'] = [
    {
      title: 'Derslik',
      dataIndex: 'name',
      align: 'center',
      ...getColumnSearchProps(),
    },
    {
      title: 'Kapasite',
      dataIndex: 'quota',
      align: 'center',
      sorter: { multiple: 1 },
    },
    {
      title: 'Maksimum Kapasite',
      dataIndex: 'maxQuota',
      align: 'center',
      sorter: { multiple: 2 },
    },
    {
      key: 'id',
      align: 'center',
      render: (_, record) => (
        <ActionDropdown
          data={record}
          onEdit={(record) => {
            onOpenDrawer(false, record);
          }}
          onDelete={({ id }) => {
            deleteClassroom(id);
          }}
        />
      ),
    },
  ];

  const onOpenDrawer = (yeniKayitMi: boolean, record: ClassRoomModel | null) => {
    if (yeniKayitMi) {
      setIsUpdate(false);
    } else {
      setSelected(record);
      setIsUpdate(true);
    }
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
    setSelected(null);
  };

  const saveOrUpdateClassroom = async (data: ClassRoomModel) => {
    if (!isUpdate) {
      await API.CLASS_ROOM.save(data);
    } else {
      await API.CLASS_ROOM.update(data);
    }
    await getClassroomList(undefined, paginationQuery);
    onCloseDrawer();
  };

  const deleteClassroom = async (id: string) => {
    await API.CLASS_ROOM.delete(id);
    notification.success({ message: 'Derslik başarıyla silinmiştir!' });
    setPaginationQuery((prev) => ({ ...prev, page: 1 }));

    onCloseDrawer();
  };

  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        pagination={{ current: paginationQuery.page, total: classroomList?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        columns={columns}
        dataSource={classroomList?.data}
        bordered
        title={() => title}
        onChange={(pagination, filters, sorter) => {
          const { current } = pagination;
          let newQuery = {
            ...paginationQuery,
            page: current,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });

          setPaginationQuery(newQuery);
        }}
      />
      <ClassroomDrawer open={open} isUpdate={isUpdate} selected={selected} saveOrUpdateClassroom={saveOrUpdateClassroom} onCloseDrawer={onCloseDrawer} />
    </>
  );
};

ClassRoom.path = '/lms/class-room';
ClassRoom.title = 'Derslik Tanımla';
ClassRoom.group = 'definitions';
ClassRoom.roles = [Role.ADMIN];
ClassRoom.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
ClassRoom.permissions = ['branch.lms.class.room.view'];
