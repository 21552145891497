import { useFetch } from '@shared/hooks';
import { CreateEducationLevel, EducationField, EducationLevel, EducationTheme, EducationType, PaginateModel, UseFetchOptions } from '@shared/models';
import { querytoUrlString, request } from '@shared/utils';

export default {
  useFetchField: (config: UseFetchOptions) => useFetch<PaginateModel<EducationField>>('/lms/education-field', { method: 'GET', ...config }),
  createField: async (name: string) => request('/lms/education-field', 'POST', { name }),

  useFetchType: (config: UseFetchOptions) => useFetch<PaginateModel<EducationType>>('/lms/education-type', { method: 'GET', ...config }),
  createType: async (name: string, fieldId: string) =>
    request('/lms/education-type', 'POST', {
      field: {
        id: fieldId,
      },
      name,
    }),

  useFetchTheme: (config: UseFetchOptions) => useFetch<PaginateModel<EducationTheme>>('/lms/education-theme', { method: 'GET', ...config }),
  createTheme: async (name: string, typeId: string) =>
    request('/lms/education-theme', 'POST', {
      type: {
        id: typeId,
      },
      name,
    }),

  useFetchLevel: (config: UseFetchOptions) => useFetch<PaginateModel<EducationLevel>>('/lms/education-level', { method: 'GET', ...config }),
  updateLevel: async (level: EducationLevel) => request('/lms/education-level', 'PUT', { ...level }),
  createLevel: async (level: CreateEducationLevel) => request('/lms/education-level', 'POST', level),
  orderLevel: async (orderList: EducationLevel[]) => request('/lms/education-level/order', 'PUT', orderList),
};
