import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";
import { shuffleToArray, uuid } from "@shared/utils";
import { Button } from "antd";
import { useEffect } from "react";

type ExerciseProps = {
    content: ExerciseDefinitionContentModel,
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isInClass: boolean
}
export const MatchBase = ({ content, interaction, isPreview = false, isInClass }: ExerciseProps) => {
    return (
        <>
            {
                isInClass ?
                    (
                        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10">
                            <div className="m-2 h-4/6 w-3/6 pl-2 pr-2 space-y-2">
                                {
                                    interaction.orderItems?.map((o, i) => (
                                        <div key={uuid() + i} className="grid grid-cols-2 gap-2 ">
                                            <div className="text-center content-center border border-dashed border-mid-gray rounded min-h-10">
                                                {o[0]}
                                            </div>
                                            <div className="text-center content-center border border-dashed border-mid-gray rounded w-full">
                                                {isPreview ? "" : o[1]}
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            {
                                interaction.orderItems ? (<div className="h-2/6 w-3/6 mt-3 text-center space-y-6 space-x-1">
                                    <span style={{ fontWeight: 'bold', color: '#722ED1' }}>Match</span>
                                    <div className="flex flex-row space-x-2  items-center justify-center content-center">
                                        {shuffleToArray(interaction.orderItems!).map((o, i) => (<Button key={i} type="default">{o[1]}</Button>))}
                                    </div>
                                </div>) : (<></>)
                            }

                        </div>
                    )
                    :

                    (
                        <div className="flex flex-col items-center h-full bg-white pt-10 pb-10">
                            <div className="m-2 h-4/6 w-full pl-2 pr-2 space-y-2">
                                {
                                    interaction.orderItems?.map((o, i) => (
                                        <div key={uuid() + i} className="grid grid-cols-2 gap-2 ">
                                            <div className="text-center content-center border border-dashed border-mid-gray rounded min-h-10">
                                                {o[0]}
                                            </div>
                                            <div className="text-center content-center border border-dashed border-mid-gray rounded w-full">
                                                {isPreview ? "" : o[1]}
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            {
                                interaction.orderItems ? (
                                    <div className="h-2/6 mt-3 text-center space-y-6 space-x-1">
                                        <span style={{ fontWeight: 'bold', color: '#722ED1' }}>Match</span>
                                        <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                                            {shuffleToArray(interaction.orderItems!).map((o, i) => (<Button key={i} type="default">{o[1]}</Button>))}
                                        </div>
                                    </div>
                                ) : (<></>)
                            }

                        </div>
                    )
            }
        </>

    )
}