import { SoundPlayer } from '@shared/components/curriculum/SoundPlayer';
import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import { useEffect, useState } from 'react';

type ListiningBaseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
  isAdminCdn:boolean;
};

export const ListiningBase = ({ content, interaction, isPreview = false, isInClass,isAdminCdn }: ListiningBaseProps) => {
  const [subtitleLines, setSubtitleLines] = useState<{ start: number; end: number; text: string }[]>([]);
  const [currentTime, setCurrentTime] = useState<number>(0);

  useEffect(() => {
    if (content.content?.description) {
      const fileContent = content.content.description.trim();
      const parsedSubtitles = parseSubtitles(fileContent);
      setSubtitleLines(parsedSubtitles);
    }else{
      setSubtitleLines([]);
      setCurrentTime(0);
    }
  }, [content]);

  // Helper function to parse the .srt file content and clean up unnecessary parts
  const parseSubtitles = (content: string) => {
    return content
      .split(/\n\s*\n/) // Split by double newlines (block separation)
      .map((block) => {
        const lines = block.split('\n');
        if (lines.length >= 2) {
          const timeLine = lines[1];
          if (timeLine.includes('-->')) {
            const [start, end] = timeLine.split(' --> ');
            const text = lines
              .slice(2)
              .join(' ')
              .replace(/<\/?[^>]+(>|$)/g, ''); // Clean HTML tags
            return {
              start: parseTime(start),
              end: parseTime(end),
              text: text.trim(), // Clean and return the final text
            };
          }
        }
        return null;
      })
      .filter(Boolean) as { start: number; end: number; text: string }[];
  };

  // Helper function to convert subtitle time format (e.g., 00:00:06,041) to seconds
  const parseTime = (time: string): number => {
    const [hours, minutes, seconds] = time.split(':');
    const [sec, ms] = seconds.split(',');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(sec, 10) + parseInt(ms, 10) / 1000;
  };

  return (
    <div className="flex flex-col h-[calc(100vh-190px)] overflow-scroll w-full bg-white py-10 px-5 relative">
      {content.sound && (
        <SoundPlayer
          sound={content.sound}
          onChange={() => {}}
          isInit={true}
          isPreview={isPreview}
          isAdminCdn={isAdminCdn}
          iconFontSize="3rem"
          inline={true}
          onCurrentTimeChange={(currentTime) => {
            setCurrentTime(currentTime);
          }}
        />
      )}

      <div className="mt-5 whitespace-pre-line">
        {subtitleLines.map((line, index) => (
          <span key={index} className={currentTime >= line.start && currentTime <= line.end ? 'text-orange-400' : 'text-black'}>
            {line.text + ' '}
          </span>
        ))}
      </div>
    </div>
  );
};
