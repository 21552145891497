import { ImageUpload } from "@shared/components/curriculum/ImageUpload";
import { ExerciseDefinitionInteractionType, ExerciseLayoutType } from "@shared/constants";
import { ExerciseDefinitionModel, ImageCdnModel } from "@shared/models";
import { layoutToContentType, shuffleToArray } from "@shared/utils";
import { Button, Form, Input, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";

type CreateGapFillingSelectedProps = {
    item: ExerciseDefinitionModel;
    onSave: (item: ExerciseDefinitionModel) => void;
    onChange: (item: ExerciseDefinitionModel) => void;
    isAdminCdn: boolean;
}

export const CreateGapFillingSelected = ({ item, onSave, onChange, isAdminCdn }: CreateGapFillingSelectedProps) => {
    const [isMediaLoading, setIsMediaLoading] = useState(false);
    const [image, setImage] = useState<ImageCdnModel>();
    const [sentence, setSentence] = useState<string>();
    const [title, setTitle] = useState<string>();
    //const [subTitle, setSubTitle] = useState<string>();

    useEffect(() => {
        setImage(item.content?.image?.resourceUrl ? { id: item.content?.image?.resourceId, variants: [item.content?.image?.resourceUrl] } : undefined);
        setSentence(item.content?.content?.title);
        setTitle(item.interaction?.instruction?.title);
        //setSubTitle(item.interaction?.instruction?.description);
    }, [item.id]);

    useEffect(() => {
        // || subTitle
        if (image || sentence || title) {
            const i = {
                ...item,
                layout: ExerciseLayoutType.GAP_FILLING_SELECT,
                content: {
                    ...item.content,
                    image:image ? {resourceId: image?.id, resourceUrl: image?.variants![0] }: undefined,
                    content: {
                        ...item.content?.content,
                        title: sentence,
                    },
                    type: layoutToContentType(ExerciseLayoutType.GAP_FILLING_SELECT),
                },
                interaction: {
                    ...item.interaction,
                    //orderItems: sentence ? [sentence.trim().split(" ")] : undefined,
                    orderItems: buildOrderItems(),
                    type: ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS,
                    instruction: {
                        ...item.interaction?.instruction,
                        title: title,
                        //description: subTitle
                    }
                },
            } as ExerciseDefinitionModel;
            onChange(i);
        }
        //subTitle
    }, [image, sentence, title]);

    const send = useCallback(() => {
        const i = {
            ...item,
            layout: ExerciseLayoutType.GAP_FILLING_SELECT,
            content: {
                ...item.content,
                image:image ? {resourceId: image?.id, resourceUrl: image?.variants![0] }: undefined,
                content: {
                    ...item.content?.content,
                    title: sentence,
                },
                type: layoutToContentType(ExerciseLayoutType.GAP_FILLING_SELECT),
            },
            interaction: {
                ...item.interaction,
                //orderItems: sentence ? [sentence.trim().split(" ")] : undefined,
                orderItems: buildOrderItems(),
                type: ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS,
                instruction: {
                    ...item.interaction?.instruction,
                    title: title,
                    //description: subTitle
                }
            },
        } as ExerciseDefinitionModel;
        onSave(i);
        //subTitle
    }, [image, sentence, title]);

    const buildOrderItems = () => {
        const values: string[][] = [];
        sentence?.trim().split(" ").forEach((word) => {
            values.push([word]);
        });
        return values;
      };

    return (
        <div className="p-5">
            <div className="flex justify-end">
                <Button type="primary" disabled={isMediaLoading} size="small" onClick={send}>{item.id ? "Güncelle" : "Ekle"}</Button>
            </div>
            <div className="flex flex-col">
                <div className="flex flex-col items-start content-start justify-start">
                    <Typography.Text type="secondary">
                        (Opsiyonel) Desteklenen formatlar: .jpeg, .png
                    </Typography.Text>
                    <ImageUpload
                        image={image ?? {}}
                        onChange={(image) => {
                            setImage(image);
                        }}
                        onChangeLoading={setIsMediaLoading}
                        isAdminCdn={isAdminCdn}
                    />
                </div>
                <div className="grid grid-cols-2 space-x-2">
                    <Form layout="vertical">
                        <Form.Item label={"Cümleyi yazınız"}>
                            <Input value={sentence} onChange={(e) => setSentence(e.target.value)} disabled={isMediaLoading}/>
                        </Form.Item>

                    </Form>
                    <Form layout="vertical">
                        <Form.Item label={"Bilgilendirme başlıgı"}>
                            <Input value={title} onChange={(e) => setTitle(e.target.value)} disabled={isMediaLoading}/>
                        </Form.Item>
                    </Form>
                    <div className="flex flex-col">
                        <span>Cümlenin karışık kelime listesi:</span>

                        <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                            {shuffleToArray(sentence ? sentence.trim().split(" ") : []).map((o, i) => (<Button key={i} type="default">{o}</Button>))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}