import { useFetch } from '@shared/hooks';
import {
  BranchLmsCreditBalance,
  BranchLmsCreditOrder,
  BrandLmsCreditBalance,
  BrandLmsCreditOrder,
  CreateLmsCreditOrderProps,
  PaginateModel,
  UseFetchOptions,
} from '@shared/models';
import { request } from '@shared/utils';

export default {
  //Branch Credit
  paginateBranchLmsCreditOrder: (config: UseFetchOptions) => useFetch<PaginateModel<BranchLmsCreditOrder>>('/branch-lms-credit-order', { method: 'GET', ...config }),
  createBranchLmsCreditOrder: async (body: CreateLmsCreditOrderProps) => request(`/branch-lms-credit-order`, 'POST', body),
  deleteBranchLmsCreditOrder: async (id: string) => request(`/branch-lms-credit-order/${id}/`, 'DELETE'),
  updateBranchLmsCreditOrder: async (id: string, body: CreateLmsCreditOrderProps) => request(`/branch-lms-credit-order/${id}`, 'PUT', body),
  //Branch Balance
  paginateBranchLmsCreditBalance: (config: UseFetchOptions) => useFetch<PaginateModel<BranchLmsCreditBalance>>('/branch-lms-credit-balance', { method: 'GET', ...config }),
  retriveCurrentBranchLmsCreditBalance: (config: UseFetchOptions) => useFetch<number>('/branch-lms-credit-balance/current', { method: 'GET', ...config }),

  //Brand Credit

  paginateBrandLmsCreditOrder: (config: UseFetchOptions) => useFetch<PaginateModel<BrandLmsCreditOrder>>('/brand-lms-credit-order', { method: 'GET', ...config }),
  createBrandLmsCreditOrder: async (body: CreateLmsCreditOrderProps) => request(`/brand-lms-credit-order`, 'POST', body),
  deleteBrandLmsCreditOrder: async (id: string) => request(`/brand-lms-credit-order/${id}/`, 'DELETE'),
  updateBrandLmsCreditOrder: async (id: string, body: CreateLmsCreditOrderProps) => request(`/brand-lms-credit-order/${id}`, 'PUT', body),
  //Brand Balance
  paginateBrandLmsCreditBalance: (config: UseFetchOptions) => useFetch<PaginateModel<BrandLmsCreditBalance>>('/brand-lms-credit-balance', { method: 'GET', ...config }),
  retriveCurrentBrandLmsCreditBalance: (config: UseFetchOptions) => useFetch<number>('/brand-lms-credit-balance/current', { method: 'GET', ...config }),

  //Brand Branch

  paginateBrandBranchLmsCreditOrder: (config: UseFetchOptions) => useFetch<PaginateModel<BranchLmsCreditOrder>>('/brand-branch-lms-credit-order', { method: 'GET', ...config }),
  markAsPaidBranchLmsCreditOrder: async (id: string) => request(`/brand-branch-lms-credit-order/${id}/mark-as-paid`, 'PUT'),
  approveBranchLmsCreditOrder: async (id: string) => request(`/brand-branch-lms-credit-order/${id}/approve`, 'PUT'),
  rejectBranchLmsCreditOrder: async (id: string) => request(`/brand-branch-lms-credit-order/${id}/reject`, 'PUT'),

  //Admin Brand
  markAsPaidBrandLmsCreditOrder: async (id: string) => request(`/brand-lms-credit-order/${id}/mark-as-paid`, 'PUT'),
  approveBrandLmsCreditOrder: async (id: string) => request(`/brand-lms-credit-order/${id}/approve`, 'PUT'),
  rejectBrandLmsCreditOrder: async (id: string) => request(`/brand-lms-credit-order/${id}/reject`, 'PUT'),
};
