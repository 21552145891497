import { ClassRoomModel } from "./class_room";
import { TeacherModel } from "./teacher";

export type ClassSeanceItem = Partial<{
    id:string;
    teacher: TeacherModel;
    beginHour: number;
    beginMinute: number;
    endHour: number;
    endMinute: number;
    durationInMinutes: number;
    classRoom: ClassRoomModel;
    order:number;
    title:string;
}>