import { ExerciseLayoutType } from '@shared/constants';
import { ExerciseDefinitionModel } from '@shared/models';
import { ReactElement, useEffect, useState } from 'react';
import {
  DialogBase,
  MatchBase,
  GapFillingKeyboard,
  GapFillingSelected,
  ListiningBase,
  ListiningMultiSelect,
  ListeningSingleSelect,
  ReadingMultiSelect,
  ReadingSingilSelect,
  VisualBase,
  VisualMultiSelect,
  VisualSingleSelect,
  ReadingGapFilling,
} from '../exercise_types';

type ExerciseHandlerProp = {
  item: ExerciseDefinitionModel;
  isPreview?: boolean;
  isAdminCdn: boolean;
  isInClass: boolean;
};
export const ExerciseHandler = ({ item, isPreview = false, isAdminCdn, isInClass }: ExerciseHandlerProp) => {
  const [exercise, setExercise] = useState<ReactElement>();

  useEffect(() => {
    if (item) {
      switch (item.layout) {
        case ExerciseLayoutType.VISUAL_BASE:
          setExercise(<VisualBase content={item.content ? item.content : {}} interaction={{}} isPreview={isPreview} isAdminCdn={isAdminCdn} isInClass={isInClass} />);
          break;
        case ExerciseLayoutType.VISUAL_SINGLE_SELECT:
          setExercise(
            <VisualSingleSelect content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} />
          );
          break;
        case ExerciseLayoutType.VISUAL_MULTI_SELECT:
          setExercise(<VisualMultiSelect content={item.content ? item.content : {}} interaction={{}} isPreview={isPreview} isInClass={isInClass} />);
          break;
        case ExerciseLayoutType.LISTENING_BASE:
          setExercise(<ListiningBase content={item.content ? item.content : {}} interaction={{}} isPreview={isPreview} isInClass={isInClass} isAdminCdn={isAdminCdn}/>);
          break;
        case ExerciseLayoutType.LISTENING_SINGLE_SELECT:
          setExercise(<ListeningSingleSelect content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} isAdminCdn={isAdminCdn}/>);
          break;
        case ExerciseLayoutType.LISTENING_MULTI_SELECT:
          setExercise(<ListiningMultiSelect />);
          break;
        case ExerciseLayoutType.READING_SINGLE_SELECT:
          setExercise(
            <ReadingSingilSelect content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} />
          );
          break;
        case ExerciseLayoutType.READING_MULTI_SELECT:
          setExercise(
            <ReadingMultiSelect content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} />
          );
          break;
        case ExerciseLayoutType.READING_GAP_FILLING:
          setExercise(
            <ReadingGapFilling content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} />
          );
          break;
        case ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING:
          setExercise(<DialogBase content={item.content ? item.content : {}} interaction={{}} isPreview={isPreview} isInClass={isInClass} />);
          break;
        case ExerciseLayoutType.MATCHING_BASE:
          setExercise(<MatchBase content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} />);
          break;
        case ExerciseLayoutType.GAP_FILLING_SELECT:
          setExercise(<GapFillingSelected  content={item.content ? item.content : {}} interaction={item.interaction ? item.interaction : {}} isPreview={isPreview} isInClass={isInClass} isAdminCdn={isAdminCdn}/>);
          break;
        case ExerciseLayoutType.GAP_FILLING_KEYBOARD:
          setExercise(<GapFillingKeyboard />);
          break;
        default:
          setExercise(<div>Tanımsız layout</div>);
          break;
      }
    }
  }, [item]);

  return exercise!;
};
