export type SessionDefinitionsCreateModel = Partial<{
  id: string;
  name: string;
  times: BeginEndTimeRange[];
}>;

export type SessionDefinitionsModel = Partial<{
  id: string;
  name: string;
  seanceItems: BeginEndTimeRange[];
  seanceItemsStr: [];
  durationInMinutes: number;
}>;

export type BeginEndTimeRange = Partial<{
  begin: TimeRange;
  end: TimeRange;
}>;

export type TimeRange = Partial<{
  hour: number;
  minute: number;
  hourStr: string;
  minuteStr: string;
  str: string;
}>;
