import { ImageView, SoundPlayer } from "@shared/components";
import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from "@shared/models";
import { Button } from "antd";

type ListeningSingleSelectProps = {
    content: ExerciseDefinitionContentModel;
    interaction: ExerciseDefinitionInteractionModel;
    isPreview?: boolean;
    isInClass: boolean;
    isAdminCdn: boolean;
};

export const ListeningSingleSelect = ({ content, interaction, isPreview = false, isInClass, isAdminCdn }: ListeningSingleSelectProps) => {
    return (
        <div className="flex flex-col h-[calc(100vh-190px)] overflow-scroll w-full bg-white py-10 px-5 relative">
            {content.sound && (
                <SoundPlayer
                    sound={content.sound}
                    onChange={() => { }}
                    isInit={true}
                    isPreview={isPreview}
                    isAdminCdn={isAdminCdn}
                    iconFontSize="3rem"
                    inline={true}
                    onCurrentTimeChange={(currentTime) => {
                    }}
                />
            )}

            {
                content.image && (
                    <div className="rounded m-2 h-2/6 text-center" >
                        <ImageView image={content?.image ? content.image : {}} />
                    </div>
                )
            }
            <div className="h-full flex flex-col justify-center">


            <div className="mt-10 text-center">
                <div className="text-center  text-[#722ED1]">{interaction.instruction?.title}</div>
                <div className="mt-2 text-[#D3ADF7]">{interaction.instruction?.description}</div>
            </div>

            <div className="text-center mt-7">
                {interaction.choices ? (
                    <div className="flex flex-col justify-center gap-3 px-4">
                        {interaction.choices.map((item, index) => (
                            <Button key={index} type="default">
                                {item.title}
                            </Button>
                        ))}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            </div>

            


        </div>
    )
}