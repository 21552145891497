import { BranchType, Gender } from '@shared/constants';
import { AddressModel, PhoneModel } from './information';
import { PermissionsModel } from './permissions';

export type Branch = Partial<{
  id: string;
  brandId: string;
  name: string;
  address: AddressModel;
  phone: PhoneModel;
  email: string;
  type: BranchType;
}>;

export type CreateBranchModel = Partial<{
  id: string;
  brandId: string;
  name: string;
  email: string;
  phone: PhoneModel;
  address: AddressModel;
  type: BranchType;
  active: boolean;
}>;

export type CreateBranchUser = Partial<{
  name: string;
  surname: string;
  email: string;
  phone: PhoneModel;
  nationalId: string;
  gender: Gender;
  birthDate: string;
  branchId: string;
  permissions: PermissionsModel;
}>;

export type BranchUserModel = Partial<{
  id: string;
  name: string;
  surname: string;
  email: string;
  phone: PhoneModel;
  nationalId: string;
  gender: Gender;
  birthDate: string;
  branchId: string;
  accessables: {
    permissions: PermissionsModel
  }
}>;

//TODO AccessableBranch modeli account altında olabilir
export type AccessableBranch = Partial<{
  id: string;
  name: string;
  city: string;
  brandId: string;
  active: boolean;
}>;

//TODO selectedBrand ile aynı yapıda
export type SelectedBranch = Partial<{
  id: string;
  permissions: PermissionsModel;
}>;
