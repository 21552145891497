import { AuditOutlined, CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, PlusCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ActionDropdown, TextInput } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { BranchLmsCreditOrder, CreateLmsCreditOrderProps, LmsCreditOrderStatus, PaginationQuery } from '@shared/models';
import { convertDate, sortFields } from '@shared/utils';
import { Button, Drawer, Form, InputNumber, notification, Table, Tag, Tooltip } from 'antd';
import { TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { z } from 'zod';

export const BranchCredit = () => {
  useAccessableSeachParams();
  const [open, setOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedBranchLmsCreditOrder, setSelectedBranchLmsCreditOrder] = useState<BranchLmsCreditOrder | undefined>();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, withDeleted: false, orders: ['-createdAt'] });
  const [currentBalance, isCurrentBalanceLoading, getCurrentBalance] = API.LMS_CREDIT.retriveCurrentBranchLmsCreditBalance({ init: true });

  const [branchCreditOrders, loading, getBranchCreditOrders] = API.LMS_CREDIT.paginateBranchLmsCreditOrder({ init: true });

  const showDrawer = () => {
    setOpen(true);
    setIsUpdateMode(false);
  };
  const closeDrawer = () => {
    setOpen(false);
    setSelectedBranchLmsCreditOrder(undefined);
    setIsUpdateMode(false);
  };

  useEffect(() => {
    getBranchCreditOrders(undefined, paginationQuery);
  }, [paginationQuery]);

  const CreateLmsBranchCreditOrderForm = z.object({
    count: z.number({ required_error: 'Kredi sayısı zorunlu alandır' }).min(1, 'Marka adı 1 karekterden fazla olmalı'),
  });

  const form = useForm<Partial<BranchLmsCreditOrder>, CreateLmsCreditOrderProps>(CreateLmsBranchCreditOrderForm, { ...selectedBranchLmsCreditOrder });

  const saveBranchLmsCreditOrder = async () => {
    if (isUpdateMode) {
      const response = await API.LMS_CREDIT.updateBranchLmsCreditOrder(selectedBranchLmsCreditOrder?.id!, form.value as any);
      if (response.ok) {
        notification.success({ message: 'Kredi Talebi Güncellendi' });
        getBranchCreditOrders();
        closeDrawer();
      }
    } else {
      const response = await API.LMS_CREDIT.createBranchLmsCreditOrder(form.value as any);
      if (response.ok) {
        notification.success({ message: 'Kredi Talebi Oluşturuldu' });
        getBranchCreditOrders();
        closeDrawer();
      }
    }
  };

  const title = (
    <div className="flex justify-between">
      <div className="font-bold">
        <h3>Mevcut Bakiyeniz: {currentBalance}</h3>
      </div>
      <Button icon={<PlusCircleOutlined />} onClick={() => showDrawer()} type="dashed">
        Yeni Lms Kredi Talebi Oluştur
      </Button>
    </div>
  );

  const statusMap = {
    [LmsCreditOrderStatus.APPROVED]: <Tag color="green">Onaylandı</Tag>,
    [LmsCreditOrderStatus.REJECTED]: <Tag color="red">Reddedildi</Tag>,
    [LmsCreditOrderStatus.ORDERED]: <Tag color="orange">Siparişte</Tag>,
  };

  const columns: TableProps<BranchLmsCreditOrder>['columns'] = [
    {
      title: 'Durum',
      dataIndex: 'status',
      align: 'center',
      sorter: true,
      render: (_, { status }) => statusMap[status],
    },

    {
      title: 'Ödendi mi?',
      key: 'paid',
      align: 'center',
      sorter: true,
      render: (_, record) =>
        record.paid ? (
          <Tag color="green">
            <CheckCircleOutlined className="mr-1" />
            Ödendi
          </Tag>
        ) : (
          <Tag color="red">
            <CloseCircleOutlined className="mr-1" /> Ödenmedi
          </Tag>
        ),
    },
    {
      title: 'No',
      dataIndex: 'no',
      align: 'center',
      sorter: true,
      render: (_, { no }) => (
        <Tooltip title="Kopyala">
          <Tag
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(no);
              notification.info({ message: 'Talep Numarası Kopyalandı' });
            }}
          >
            <CopyOutlined className="mr-1" />
            {no}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Kredi Sayısı',
      dataIndex: 'count',
      align: 'center',
      sorter: true,
    },

    {
      title: 'Talep Tarihi',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      render: (_, { createdAt }) => convertDate(createdAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'Talep Oluşturan Kişi',
      key: 'createdBy',
      align: 'center',
      render: (_, { createdBy }) => `${createdBy.name}`,
    },

    {
      title: 'Not',
      key: 'note',
      align: 'center',
      render: (_, record) => record.note,
    },

    {
      title: 'Ödendi İşaretleyen Kişi',
      key: 'markedPaidBy',
      align: 'center',
      render: (_, { markedPaidBy }) => (markedPaidBy?.name ? `${markedPaidBy.name} ` : ''),
    },

    {
      title: 'Güncellenme Tarihi',
      key: 'updatedAt',
      align: 'center',
      sorter: true,
      render: (_, { updatedAt }) => convertDate(updatedAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'Onaylama İşlemi Yapan Kişi',
      key: 'proceedBy',
      align: 'center',
      render: (_, { proceedBy }) => (proceedBy?.name ? `${proceedBy.name}` : ''),
    },

    {
      title: 'Aksiyon',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <div>
          {record.status === LmsCreditOrderStatus.ORDERED && (
            <ActionDropdown
              data={record}
              onEdit={({ id }) => {
                setOpen(true);
                setIsUpdateMode(true);
                setSelectedBranchLmsCreditOrder(record);
                form.setValue(record);
              }}
              onDelete={async ({ id }) => {
                const result = await API.LMS_CREDIT.deleteBranchLmsCreditOrder(id);
                if (result.ok) {
                  notification.success({ message: 'Kredi Talebiniz Başarıyla Kaldırıldı' });
                }
                setPaginationQuery((prev) => ({ ...prev, page: 1 }));
              }}
            />
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Drawer title={isUpdateMode ? 'LMS Kredi Talebini Güncelle' : 'LMS Kredi Talebi Oluştur'} onClose={closeDrawer} open={open}>
        <Form layout="vertical">
          <div className="grid">
            <div className="flex flex-col gap-0">
              <Form.Item {...form.formItem('count')} label={'Kredi Talebi:'}>
                <InputNumber min={1} {...form.inputNumber('count')} />
              </Form.Item>

              <TextInput formItem={form.formItem('note')} formInput={form.input('note')} label={'Not:'} />
            </div>
            <div>
              <Button onClick={saveBranchLmsCreditOrder} type="primary">
                {isUpdateMode ? 'Güncelle' : 'Oluştur'}
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <Table
        loading={loading}
        rowKey="id"
        pagination={{ current: paginationQuery.page, total: branchCreditOrders?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        columns={columns}
        dataSource={branchCreditOrders?.data}
        bordered
        title={() => title}
        onChange={(pagination, filters, sorter) => {
          const { current } = pagination;
          let newQuery = {
            ...paginationQuery,
            page: current,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });

          setPaginationQuery(newQuery);
        }}
      />
    </div>
  );
};

BranchCredit.path = '/lms/branch-credit-order';
BranchCredit.title = 'Kredi Talepleri';
BranchCredit.icon = <AuditOutlined size={20} />;
BranchCredit.group = 'branch.lms.credit';
BranchCredit.roles = [Role.ADMIN];
BranchCredit.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
BranchCredit.permissions = ['branch.lms.credit.view', 'branch.lms.credit.manage'];
