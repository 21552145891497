
import { AccountNotification, ClassActivity, PaginateModel, PaginationQuery } from "@shared/models";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import * as API from '@shared/api';
import { useWebSocket } from "@shared/hooks";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

type UserNotificationContextType = {
    notificationList?: PaginateModel<AccountNotification>;
    unreadCount: number,
    paginationQuery:PaginationQuery,
    onChangePagination: (_pagination:PaginationQuery) => void,
    deleteNotification: (id: string) => void,
    toggleStatus: (id: string) => void
};

const UserNotificationContext = createContext<UserNotificationContextType>({
    notificationList: undefined,
    unreadCount: 0,
    paginationQuery:{ page: 1, limit: 6 },
    onChangePagination: (_pagination) => { },
    deleteNotification: (id: string) => { },
    toggleStatus: (id: string) => { }

});

export const UserNotificationContextProvider = ({ children }) => {
    const [result, loading, getAllNotifications] = API.NOTIFICATION.paginateNotifications({ init: false });
    const [notificationList, setNotificationList] = useState<PaginateModel<AccountNotification>>();
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });
    const { t } = useTranslation();
    const nt = (title: string, params: any) => t(`notification.${title}`, params).toString();

    useEffect(() => {
        getAllNotifications(null, paginationQuery);
    }, [paginationQuery]);

    useEffect(() => {
        if (result) {
            setNotificationList(result);
            const isReaded = result.data?.filter((d) => {
                return !d.isReaded
            });
            setUnreadCount(isReaded?.length ? isReaded?.length : 0);
            
        }
    }, [result]);

    useWebSocket(
        'account', useMemo(() => {
            return {
                'account.notification.created': (message: { data: AccountNotification }) => {
                    notification.info({
                        message: nt(message.data.title.label, message.data.title.params),
                        description: message.data.description ? nt(message.data.description?.label, message.data.description?.params) : undefined,
                        placement: 'topRight',
                        duration: 10
                    });
                    getAllNotifications(null, paginationQuery);
                },
            };
        }, [notificationList])
    );
    const onChangePagination = (_pagination) => {
        setPaginationQuery({ ...paginationQuery, ..._pagination });
    }

    const toggleStatus = async (id: string) => {
        await API.NOTIFICATION.toggleReadStatus(id);
        getAllNotifications(null, paginationQuery);
    };

    const deleteNotification = async (id: string) => {
        await API.NOTIFICATION.deleteNotification(id);
        getAllNotifications(null, paginationQuery);
    };

    return (
        <UserNotificationContext.Provider value={{ notificationList, unreadCount,paginationQuery, onChangePagination, deleteNotification, toggleStatus }}>
            {children}
        </UserNotificationContext.Provider>
    )
}

export const useUserNotificationContext = () => {
    const context = useContext(UserNotificationContext);
    if (context == undefined) {
        throw new Error("useUserNotificationContext must be used within a UserNotificationContextProvider");
    }
    return context;
}