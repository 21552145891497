import { InfoCircleTwoTone, MoonOutlined } from '@ant-design/icons';
import { Avatar, Badge, Popover, Tooltip } from 'antd';

export const DemandTableInfo = () => {
  const BadgeWithAvatarSize = ({ status }) => {
    return (
      <div className="w-5 h-5 flex items-center justify-center rounded border border-solid border-[#f5f5f5]">
        <Badge status={status} />
      </div>
    );
  };

  const legendContent = (
    <div className="flex flex-col gap-3">
      <div className="flex items-center">
        <BadgeWithAvatarSize status="success" />
        <span className="ml-2">Başarılı bitirdi</span>
      </div>

      <div className="flex items-center">
        <BadgeWithAvatarSize status="error" />
        <span className="ml-2">Başarısız bitirdi</span>
      </div>
      <div className="flex items-center">
        <div className="w-5 h-5 flex items-center justify-center rounded border border-solid border-[#f5f5f5]">
          <Badge color={'purple'} />
        </div>
        <span className="ml-2">Şartlı</span>
      </div>

      <div>
        <Avatar size="small" shape="square" className="border-dashed border-[#faad14] rounded bg-[#fffbe6]" />

        <span className="ml-2">Planlamada</span>
      </div>

      <div>
        <Avatar size="small" shape="square" className="border-solid border-[#722ed1] rounded bg-[#f8f0ff]" />
        <span className="ml-2">Aksiyon Bekleniyor</span>
      </div>

      <div>
        <Avatar size="small" shape="square" className="border-solid border-[#52c41a] rounded bg-[#f6ffed]" />
        <span className="ml-2">Aktif</span>
      </div>

      <div>
        <Avatar size="small" shape="square" className="border-solid border-[#69b1ff] rounded bg-[#e6f4ff]" />
        <span className="ml-2">Tamamlandı</span>
      </div>
      <div>
        <MoonOutlined />
        <span className="ml-2">Donduruldu</span>
      </div>
    </div>
  );

  return (
    <div className="flex mb-1 justify-end">
      <Popover content={legendContent} placement="left" trigger="click">
        <InfoCircleTwoTone className="text-2xl" />
      </Popover>
    </div>
  );
};
