import { AndroidOutlined, CalendarOutlined, ExclamationCircleTwoTone, ExportOutlined, FileDoneOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons';
import { ClassFilter, ClassHeader } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { ClassStatus, LayoutEnums, Role } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassModel, InClassInfo, PaginationQuery } from '@shared/models';
import { Alert, Badge, Button, Card, Empty, Pagination, Space } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const TeacherClass = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [classList, classListLoading, getClassList] = API.CLASS.getClassList({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6, levelIds: [], statuses: [[ClassStatus.STARTED], [ClassStatus.NOT_STARTED]] });
  const [startSeance, setStartSeance] = useState<boolean>(false);
  const [classId, setClassId] = useState<string>();
  const [inClassInfo, setInClassInfo] = useState<InClassInfo>();
  const { user } = useUserContext();

  //Header Aktif ve Planlanmış Sınıf Sayısı için
  const [plannedClass] = API.CLASS.getClassList({ init: true, initParams: { statuses: [ClassStatus.NOT_STARTED], page: 1, limit: 1 } });
  const [startedClass] = API.CLASS.getClassList({ init: true, initParams: { statuses: [ClassStatus.STARTED], page: 1, limit: 1 } });

  useEffect(() => {
    getClassList(undefined, paginationQuery);
  }, [paginationQuery]);

  useEffect(() => {
    getInClassInfo();
  }, [classId]);

  const getInClassInfo = async () => {
    if (classId) {
      const response = await API.CLASS.getInClassInfo(classId);
      setInClassInfo(response.data);
    }
  };

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 6, levelIds: [], statuses: [] });
      return;
    }
    setPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const uniqueTeachers = useCallback(
    (c: ClassModel) => {
      const teacherList: { id: string; name: string }[] = [];
      if (c) {
        c!.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.teacher) {
              teacherList.push({
                id: item?.teacher?.id!,
                name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
              });
            }
          });
        });
      }
      return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [classList]
  );

  const uniqueClassrooms = useCallback(
    (c: ClassModel) => {
      const classroomList: { id: string; name: string }[] = [];
      if (c) {
        c!.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.classRoom) {
              classroomList.push({
                id: item?.classRoom?.id!,
                name: item?.classRoom?.name!,
              });
            }
          });
        });
      }
      return classroomList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [classList]
  );

  const statusStyles = (status): React.CSSProperties => {
    switch (status) {
      case ClassStatus.CANCELED:
        return { backgroundColor: '#F5F5F5', color: '#00000040' };
      case ClassStatus.COMPLETED:
        return { backgroundColor: '#E6F4FF', color: '#1677FF' };
      case ClassStatus.STARTED:
        return { backgroundColor: '#F6FFED', color: '#52C41A', borderBottomColor: '#B7EB8F' };
      case ClassStatus.NOT_STARTED:
        return { backgroundColor: '#FFFBE6', color: '#FAAD14', borderBottomColor: '#FFE58F' };
      default:
        return {};
    }
  };

  return (
    <div style={{ margin: '30px 100px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
      {!startSeance ? (
        <>
          <Spinner loading={classListLoading} />
          <ClassHeader
            titleColor="#FF3436"
            imageName="class_teacher_dashboard"
            classList={classList}
            classListPage={true}
            startedClass={startedClass!}
            plannedClass={plannedClass!}
          />
          <ClassFilter onFilter={onFilter} filters={paginationQuery} />

          {classList?.data?.length === 0 ? (
            <Empty className="mt-10" description="Sınıf mevcut değil" />
          ) : (
            <>
              <div className="grid grid-cols-3 gap-3 mt-5">
                {classList?.data?.map((c) => {
                  const teacherNames = uniqueTeachers(c).map((teacher) => teacher.name);
                  const isDifferentTeacher = !teacherNames.includes(`${user?.name} ${user?.surname}`);

                  return (
                    <Space key={c.id} direction="vertical" size="middle" style={{ width: '100%' }}>
                      {isDifferentTeacher && (
                        <Badge.Ribbon className="mt-10" text={'Size ait bir sınıf değil'}>
                          <Card
                            title={`${c.level?.theme?.type?.field?.name} ${c.level?.theme?.type?.name} ${c.level?.theme?.name} | ${c?.name} `}
                            extra={
                              <>
                                {(c.status === 'STARTED' || c.status === 'NOT_STARTED') && (
                                  <ReadOutlined
                                    onClick={() => {
                                      setStartSeance(true);
                                      setClassId(c.id);
                                    }}
                                    style={{ color: statusStyles(c.status).color }}
                                    className="text-base"
                                  />
                                )}
                                <ExportOutlined
                                  className="cursor-pointer ml-1"
                                  style={{ color: statusStyles(c.status).color }}
                                  onClick={() => navigate('/' + LayoutEnums.TEACHER + '/class_detail' + location.search + '&class_id=' + c.id)}
                                />
                              </>
                            }
                            styles={{ header: { ...statusStyles(c.status), fontWeight: 'normal' } }}
                            size="small"
                            className="rounded-xl"
                          >
                            <div className="flex flex-col gap-y-2">
                              <div>
                                <CalendarOutlined className="text-gray-500" />{' '}
                                {dayjs(c.calculatedStartDate).format('DD.MM.YYYY') + ' - ' + dayjs(c.calculatedEndDate).format('DD.MM.YYYY')}
                              </div>
                              <div>
                                <TeamOutlined className="text-gray-500" /> <span>{c.studentCount} </span>
                              </div>
                              <div>
                                <ReadOutlined className="text-gray-500" />{' '}
                                {uniqueClassrooms(c)
                                  .map((classroom) => classroom.name)
                                  .join(', ')}
                              </div>
                              <div>
                                <AndroidOutlined className="text-gray-500" />{' '}
                                {uniqueTeachers(c)
                                  .map((teacher) => teacher.name)
                                  .join(', ')}
                              </div>
                            </div>
                          </Card>
                        </Badge.Ribbon>
                      )}
                      {!isDifferentTeacher && (
                        <Card
                          title={`${c.level?.theme?.type?.field?.name} ${c.level?.theme?.type?.name} ${c.level?.theme?.name} | ${c?.name} `}
                          extra={
                            <>
                              {(c.status === 'STARTED' || c.status === 'NOT_STARTED') && (
                                <ReadOutlined
                                  onClick={() => {
                                    setStartSeance(true);
                                    setClassId(c.id);
                                  }}
                                  style={{ color: statusStyles(c.status).color }}
                                  className="text-base"
                                />
                              )}
                              <ExportOutlined
                                className="cursor-pointer ml-1"
                                style={{ color: statusStyles(c.status).color }}
                                onClick={() => navigate('/' + LayoutEnums.TEACHER + '/class_detail' + location.search + '&class_id=' + c.id)}
                              />
                            </>
                          }
                          styles={{ header: { ...statusStyles(c.status), fontWeight: 'normal' } }}
                          size="small"
                          className="rounded-xl"
                        >
                          <div className="flex flex-col gap-y-2">
                            <div>
                              <CalendarOutlined className="text-gray-500" />{' '}
                              {dayjs(c.calculatedStartDate).format('DD.MM.YYYY') + ' - ' + dayjs(c.calculatedEndDate).format('DD.MM.YYYY')}
                            </div>
                            <div>
                              <TeamOutlined className="text-gray-500" /> <span>{c.studentCount} </span>
                            </div>
                            <div>
                              <ReadOutlined className="text-gray-500" />{' '}
                              {uniqueClassrooms(c)
                                .map((classroom) => classroom.name)
                                .join(', ')}
                            </div>
                            <div>
                              <AndroidOutlined className="text-gray-500" />{' '}
                              {uniqueTeachers(c)
                                .map((teacher) => teacher.name)
                                .join(', ')}
                            </div>
                          </div>
                        </Card>
                      )}
                    </Space>
                  );
                })}
              </div>
              <Pagination
                current={paginationQuery.page}
                pageSize={paginationQuery.limit}
                total={classList?.count ?? 0}
                className="mt-5 text-center"
                onChange={(page) => setPaginationQuery((prev) => ({ ...prev, page }))}
              />
            </>
          )}
        </>
      ) : (
        <div>
          <div className="flex items-center justify-center text-center" style={{ height: '55vh' }}>
            <div className="space-y-4">
              <div className="text-2xl">
                <ExclamationCircleTwoTone twoToneColor="#FAAD14" style={{ fontSize: '150%' }} />
              </div>
              <div className="text-lg italic" style={{ color: '#FAAD14' }}>
                Seansı başlat
              </div>
              <div className="text-base">
                {inClassInfo?.className} sınıfına ait {inClassInfo?.classSeanceItem?.title} seansını başlatmak istiyor musunuz?
              </div>

              {inClassInfo?.classSeanceItem &&
                (inClassInfo?.classSeanceItem?.teacher?.email === user.email ? (
                  <></>
                ) : (
                  <Alert
                    type="warning"
                    showIcon
                    message={
                      <div>
                        Bu seans bir başka öğretmene ait (<b>{inClassInfo?.classSeanceItem?.teacher?.name + ' ' + inClassInfo?.classSeanceItem?.teacher?.surname}</b>). Vekil olarak
                        bu seansa girmek için <b>Seansı Başlat</b> butonuna tıklayınız
                      </div>
                    }
                  />
                ))}
              <div className="space-x-3">
                <Button type="primary" onClick={() => navigate('/' + LayoutEnums.TEACHER + '/lesson-content' + location.search + '&class_id=' + classId)}>
                  Seansı başlat
                </Button>
                <Button onClick={() => setStartSeance(false)}>Vazgeç</Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TeacherClass.path = '/' + LayoutEnums.TEACHER + '/class';
TeacherClass.title = 'Sınıflarım';
TeacherClass.group = 'flat';
TeacherClass.roles = [Role.ADMIN];
TeacherClass.layouts = [LayoutEnums.TEACHER];
TeacherClass.icon = <FileDoneOutlined size={20} />;
