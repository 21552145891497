export type CascaderOption = Partial<{
  value: string | number;
  label: string;
  active: boolean;
  children?: CascaderOption[];
}>;

export type LazzyCascaderoption = Partial<{
  value: string | number;
  label: string;
  children?: CascaderOption[];
  isLeaf: boolean;
}>;
