import * as API from '@shared/api';
import { ExerciseGroups } from '@shared/components';
import { CascaderOption, PaginationQuery } from '@shared/models';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';

export const BrandExerciseGroups = () => {
  useAccessableSeachParams();

  const [groups, loading, getAllExerciseGroup] = API.CURRICULUM.BRAND_EXERCISE.fetchAllExerciseGroup({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6 });
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRAND.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRAND.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRAND.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: true });
  const [levelCascaderOptions, setLevelCascaderOptions] = useState<CascaderOption[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getAllExerciseGroup(undefined, paginationQuery);
  }, [paginationQuery]);

  useEffect(()=>{
    if(levels && fields && types && themes){
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: themes
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return {
                      value: m.id,
                      label: m.name,
                      children: levels?.data
                        ?.filter((l) => l.theme?.id == m.id)
                        .map((l) => {
                          return {
                            value: l.id,
                            label: l.name,
                          } as CascaderOption;
                        }),
                    };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });
      setLevelCascaderOptions(_items);
    }
  },[levels,fields,types,themes]);

  return (
    <ExerciseGroups
      isLoading={loading}
      groups={groups}
      levels={levelCascaderOptions}
      onCreateRequest={() => {
        navigate('/brand-create-exercise-group' + location.search );
      }}
      onPaginationChange={(query) => {
        setPaginationQuery(query);
      }}
      onDelete={async (id) => {
        try {
          await API.CURRICULUM.BRAND_EXERCISE.deleteExerciseGroup(id);
          getAllExerciseGroup(undefined, { ...paginationQuery, page: 1 });
        } catch (error) {}
      }}
      onEdit={(id) => {
        navigate('/brand-create-exercise-group' + location.search + '&exerciseGroupId=' + id);
      }}
      onFork={async (exerciseId) => {
        const {
          data: { id },
        } = await API.CURRICULUM.BRAND_EXERCISE.cloneExerciseGroup(exerciseId);
        navigate('/brand-create-exercise-group' + location.search + '&exerciseGroupId=' + id);
      }}
    />
  );
};

BrandExerciseGroups.path = 'brand-exercise-groups';
BrandExerciseGroups.title = 'Egzersiz Grupları';
BrandExerciseGroups.group = 'curriculum';
BrandExerciseGroups.roles = [Role.ADMIN];
BrandExerciseGroups.layouts = [LayoutEnums.BRANDMANAGER, LayoutEnums.LMS];
BrandExerciseGroups.permissions = ['brand.lms.exercise.view'];
