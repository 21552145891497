import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Card, Switch } from 'antd';

const NotificationItem = ({ label, defaultChecked }) => (
  <div className="flex">
    <div className="w-10/12">{label}</div>
    <div className="flex justify-end items-center w-2/12">
      <Switch defaultChecked={defaultChecked} />
    </div>
  </div>
);

export const StudentSettingNotification = () => {
  useAccessableSeachParams();

  const notifications = [
    { label: 'E-posta bildirimlerine izin ver', defaultChecked: true },
    { label: 'Yeni ödev bildirimi', defaultChecked: true },
    { label: 'Yeni duyuru bildirimi', defaultChecked: false },
    { label: 'Ödev ve sınav sonuç bildirimleri', defaultChecked: true },
    { label: 'Etkinlik bildirimleri', defaultChecked: false },
  ];

  return (
    <div className="grid grid-cols-1 gap-4 mt-4">
      <Card>
        <NotificationItem label="E-posta bildirimlerine izin ver" defaultChecked={true} />
      </Card>
      <Card>
        <div className="grid grid-cols-1 gap-7">
          {notifications.slice(1).map((notification, index) => (
            <NotificationItem key={index} label={notification.label} defaultChecked={notification.defaultChecked} />
          ))}
        </div>
      </Card>
    </div>
  );
};

StudentSettingNotification.roles = [Role.ADMIN];
