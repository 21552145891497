import { EnrollmentUserCard } from '@platform/platform_components/EnrollmentUserCard';
import { BirthdayCalendar, EmailSearch, NationalIdInput, PhoneInput } from '@shared/components';
import { Gender } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { Enrollment, Observer, PaginateModel } from '@shared/models';
import { Button, Form, Input, Pagination, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type CreateObserverStepProps = {
  enrollment: Enrollment;
  observers: PaginateModel<Observer>;
  value?: Observer;
  onSave: (observer: Observer) => void;
  onDelete: (id: string) => void;
  onUpdate: (observer: Observer) => void;
  isPreview?: boolean;
  onPaginationChange: (page: number) => void;
};

const CreateObserverStepForm = z.object({
  name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Soyadı alanı zorunlu alandır' }),
  email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('This is not a valid email'),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  gender: z.string({ required_error: 'Lütfen cinsiyet seçiniz' }),
  nationalId: z.string({ required_error: 'Lütfen TC/Vergi No seçiniz' }),
  birthDate: z.string({ required_error: 'Lüften doğum günü seçiniz' }),
});

export const CreateObserverStep = ({ enrollment, observers, value, onSave, onUpdate, onDelete, isPreview = false, onPaginationChange }: CreateObserverStepProps) => {
  const [isEmailSearch, setIsEmailSearch] = useState(false);
  const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);
  const [isEditMod, setIsEditMod] = useState<boolean>(false);

  const form = useForm<
    Partial<{
      id: string;
      name: string;
      surname: string;
      email: string;
      'phone.number': string;
      'phone.code': string;
      gender: string;
      nationalId: string;
      birthDate: string;
    }>,
    Observer
  >(CreateObserverStepForm, {});

  const onSearchResult = (data?: Observer) => {
    if (data) {
      form.setInitialValue({
        email: data.email,
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        gender: data.gender,
        nationalId: data.nationalId,
        birthDate: data.birthDate,
      });
      setIsEmailSearchFound(true);
    } else {
      setIsEmailSearchFound(false);
      form.setInitialValue({
        ...form.value,
        name: undefined,
        surname: undefined,
        phone: {
          code: undefined,
          number: undefined,
        },
        gender: undefined,
        nationalId: undefined,
        birthDate: undefined,
      });
    }
    setIsEmailSearch(true);
  };

  useEffect(() => {
    if (form.value.id) {
      setIsEmailSearch(true);
      setIsEmailSearchFound(true);
    }
  }, [form.value.id]);

  return (
    <div>
      <div className="grid grid-cols-3 gap-x-4">
        <Form layout="vertical">
          <EmailSearch
            form={form}
            disabled={isPreview || isEmailSearchFound}
            label="Gözlemci E-posta"
            onSearchResult={onSearchResult}
            onClear={() => {
              form.setInitialValue({
                ...form.value,
                name: undefined,
                email: undefined,
                surname: undefined,
                phone: {
                  code: undefined,
                  number: undefined,
                },
                gender: undefined,
                nationalId: undefined,
                birthDate: undefined,
              });
              setIsEmailSearchFound(false);
              setIsEmailSearch(false);
            }}
          />

          <div className="grid grid-cols-2 gap-x-2">
            <Form.Item {...form.formItem('name')} label={<span className="truncate">Gözlemci Adı</span>}>
              <Input {...form.input('name')} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
            </Form.Item>
            <Form.Item {...form.formItem('surname')} label={<span className="truncate">Gözlemci Soyadı</span>}>
              <Input {...form.input('surname')} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
            </Form.Item>
          </div>

          <PhoneInput form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />

          <div className="grid grid-cols-2 gap-x-2">
            <Form.Item {...form.formItem('gender')} label={'Cinsiyet:'}>
              <Radio.Group {...form.input('gender')} disabled={isPreview || !isEmailSearch || isEmailSearchFound}>
                <Radio value={Gender.FEMALE}>Kadın</Radio>
                <Radio value={Gender.MALE}>Erkek</Radio>
              </Radio.Group>
            </Form.Item>
            <NationalIdInput form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
          </div>
          <Form.Item {...form.formItem('birthDate')} label={'Doğum Tarihi:'}>
            <BirthdayCalendar form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
          </Form.Item>
          <div className="grid grid-cols-2 space-x-4">
            <Button
              type="primary"
              className="w-48"
              disabled={isPreview}
              onClick={() => {
                const result = form.parse();
                if (result.success) {
                  if (isEditMod) {
                    onUpdate(result.data);
                    form.reset();
                    setIsEditMod(false);
                  } else {
                    onSave({ ...result.data });
                    form.reset();
                    setIsEditMod(false);
                  }
                  setIsEmailSearch(false);
                  setIsEmailSearchFound(false);
                } else {
                  form.setAllTouched();
                }
              }}
            >
              {isEditMod ? 'Gözlemci Güncelle' : '+ Gözlemci Ekle'}
            </Button>
            {isEditMod && (
              <Button
                type="dashed"
                onClick={() => {
                  setIsEditMod(false);
                  setIsEmailSearch(false);
                  setIsEmailSearchFound(false);
                  form.reset();
                }}
              >
                Formu Sıfırla
              </Button>
            )}
          </div>
        </Form>
        <div className="col-span-2">
          <label className="text-black/45 font-normal">Eklenen Gözlemciler</label>

          {observers.data?.length == 0 ? (
            <div className="border pt-9 border-dashed border-mid-gray rounded p-5 text-center">Lütfen en az bir gözlemci ekleyiniz</div>
          ) : (
            <div className="border border-dashed border-mid-gray rounded p-5 grid grid-cols-2 gap-x-4 gap-y-8">
              {observers.data?.map((observer) => (
                <EnrollmentUserCard
                  disabled={isPreview}
                  key={observer.id}
                  user={observer}
                  isDelete={observer.email != enrollment.email}
                  isEdit={observer.email != enrollment.email}
                  onDelete={({ id }) => {
                    onDelete(id!);
                  }}
                  onEdit={(v) => {
                    form.setInitialValue({ ...v });
                    setIsEditMod(true);
                  }}
                  mainUserText={observer.email == enrollment.email ? 'Ana Gözlemci' : undefined}
                />
              ))}
              <Pagination
                className="col-span-2 text-center"
                current={observers.page}
                pageSize={observers?.limit}
                total={observers?.count}
                onChange={(page) => onPaginationChange(page)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
