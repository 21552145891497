import { FileTextOutlined, PlusCircleOutlined, PlusCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { ActionDropdown } from '@shared/components';
import { useForm } from '@shared/hooks';
import { ClassDetailModel, PrePlacementDemandBasicInfo, StudentModel } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button, Card, DatePicker, Divider, Empty, Form, MenuProps, Modal, Popconfirm, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

type ClassStudentListProps = {
  classDetail: ClassDetailModel | undefined;
  availableStudentList: PrePlacementDemandBasicInfo[] | undefined;
  onAddStudent: (classId: string, demandId: string) => void;
  onRemoveStudent: (classId: string, studentId: string) => void;
  hasBranchClassUpdatePermission?: boolean;
  isTeacherLayout?: boolean;
};

const RemoveStudentForm = z.object({
  type: z.string({ required_error: 'Lütfen işlem türü seçiniz' }),
  note: z.string({ required_error: 'Not giriniz' }).min(1, 'Not giriniz'),
});

const PendingStudentForm = z.object({
  type: z.string({ required_error: 'Lütfen işlem türü seçiniz' }),
  note: z.string({ required_error: 'Not giriniz' }).min(1, 'Not giriniz'),
  pendingTillDate: z.string({ required_error: 'Askıya alma bitiş tarihi giriniz' }),
});

export const ClassStudentList = ({
  classDetail,
  availableStudentList,
  onAddStudent,
  onRemoveStudent,
  hasBranchClassUpdatePermission,
  isTeacherLayout = false,
}: ClassStudentListProps) => {
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);
  const [isRemoveStudentModalOpen, setIsRemoveStudentModalOpen] = useState(false);
  const [isPendingStudentModalOpen, setIsPendingStudentModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<StudentModel>();
  const navigate = useNavigate();

  const removeForm = useForm<
    Partial<{
      note: string;
      type: string;
    }>
  >(RemoveStudentForm, {});

  const pendingForm = useForm<
    Partial<{
      note: string;
      type: string;
      pendingTillDate: string;
    }>
  >(PendingStudentForm, {});

  //Kurs henüz başlamadıysa veya Kurs başlamış fakat üstünden henüz 7 gün geçmediyse buton gözükebilir
  // const studentAddButton = () => {
  //   return classDetail?.status == ClassStatus.NOT_STARTED || (classDetail?.status == ClassStatus.STARTED && dayjs().diff(dayjs(classDetail.calculatedStartDate), 'day') < 7);
  // };

  //TODO Şimdilik Kaydı Dondur ve Kaydı İptal Et için Modal componenti çağrılıyor fakat iptal nedeni ve iptal notu backende gönderilmiyor.
  //İbrahim abi kaydı iptal etme nedeni gibi bilgileri nasıl handle edeceğine karar verince Figma'ya uygun hale getirilecek.
  const createDropdownItems = (student: StudentModel): MenuProps['items'] => {
    return [
      {
        key: 'profil',
        label: 'Öğrenci Profili',
        icon: <UserOutlined />,
      },

      {
        key: 'kayit',
        label: 'Kayıt İşlemleri',
        icon: <FileTextOutlined />,
        children: [
          {
            key: 'dondur',
            label: 'Kaydı Dondur',
            onClick: () => {
              setSelectedStudent(student);
              setIsPendingStudentModalOpen(true);
            },
          },
          {
            key: 'iptal',
            label: 'Öğrenciyi Sınıftan Çıkar',
            onClick: () => {
              setSelectedStudent(student);
              setIsRemoveStudentModalOpen(true);
            },
          },
        ],
      },
    ];
  };

  const removeStudentOptions = [
    { label: 'Ödeme yapılmadı', value: 'payment' },
    { label: 'Para iadesi alındı', value: 'refund' },
    { label: 'Eğitimden vazgeçildi', value: 'cancel' },
    { label: 'Disiplin cezası', value: 'discipline' },
    { label: 'Vefat', value: 'death' },
  ];

  const pendingStudentOptions = [
    { label: 'Transfer', value: 'transfer' },
    { label: 'Geçici süreliğine dondurma', value: 'temporary' },
    { label: 'Geciken ödeme', value: 'latePayment' },
    { label: 'Şube kaynaklı', value: 'branch' },
  ];

  const removeStudentFromClass = useCallback(async () => {
    const result = removeForm.parse();

    if (result.success) {
      setIsRemoveStudentModalOpen(false);
      onRemoveStudent(classDetail?.id!, selectedStudent?.id!);
      removeForm.reset();
      setSelectedStudent(undefined);
    } else {
      removeForm.setAllTouched();
    }
  }, [removeForm]);

  const pendingStudentFromClass = useCallback(async () => {
    const result = pendingForm.parse();

    if (result.success) {
      setIsPendingStudentModalOpen(false);
      //TODO askıya alma servisi eklenecek
      pendingForm.reset();
      setSelectedStudent(undefined);
    } else {
      pendingForm.setAllTouched();
    }
  }, [pendingForm]);

  const navigateStudentProfile = (id: string) => {
    if (isTeacherLayout) {
      navigate('/teacher/student_profile' + location.search + '&student_id=' + id);
    } else {
      navigate('/lms/student_profile' + location.search + '&student_id=' + id);
    }
  };

  return (
    <>
      <Card
        size="small"
        title={<span className=" font-normal text-[#00000073]"></span>}
        extra={
          /*studentAddButton() &&*/ classDetail?.status !== 'COMPLETED' &&
          hasBranchClassUpdatePermission && <PlusCircleOutlined className="cursor-pointer text-xl text-[#00000073]" onClick={() => setIsAddStudentModalOpen(true)} />
        }
        className="overflow-scroll"
      >
        {classDetail?.students?.map((student) => (
          <div key={student.id}>
            <div className="flex justify-between border-b border-[#0000000f]">
              <div className="text-[#1677ff] cursor-pointer" onClick={() => navigateStudentProfile(student.accountId!)}>
                {student.name} {student.surname}
              </div>
              <div className="pr-1">
                {classDetail.status !== 'COMPLETED' && hasBranchClassUpdatePermission && (
                  <ActionDropdown placement="bottomRight" data={student} isDelete={false} isEdit={false} items={createDropdownItems(student)} />
                )}
              </div>
            </div>
            <Divider className="m-2" />
          </div>
        ))}
      </Card>
      <Modal
        className="max-h-[500px] 2xl:max-h-[600px] overflow-y-scroll"
        title={<span className="text-sm font-normal">Sınıfa Öğrenci Ekle</span>}
        open={isAddStudentModalOpen}
        footer={null}
        onCancel={() => setIsAddStudentModalOpen(false)}
        centered
      >
        <div className="font-semibold text-lg">{`${classDetail?.name}`}</div>
        <div className="font-semibold text-md">{`${renderLevelsAsString(classDetail?.level)}`}</div>
        <div className="italic text-[#00000073] text-base">
          {dayjs(classDetail?.calculatedStartDate).format('DD.MM.YYYY')} - {dayjs(classDetail?.calculatedEndDate).format('DD.MM.YYYY')}
        </div>
        <div className="mt-3">
          {availableStudentList?.length! > 0 ? (
            availableStudentList?.map((ogrenci) => (
              <div key={ogrenci.id}>
                <div className="flex justify-between px-2  border-[#00000026]">
                  <div>
                    {ogrenci.prePlacement.name} {ogrenci.prePlacement.surname}
                  </div>
                  <Popconfirm
                    placement="bottom"
                    title={`${ogrenci.prePlacement.name} ${ogrenci.prePlacement.surname} isimli öğrenci ${classDetail?.level?.theme?.type?.field?.name} ${classDetail?.level?.theme?.type?.name} ${classDetail?.level?.theme?.name} sınıfına eklensin mi?`}
                    okText={'Evet'}
                    cancelText={'Hayır'}
                    onConfirm={() => onAddStudent(classDetail?.id!, ogrenci.id)}
                  >
                    <PlusCircleTwoTone className="cursor-pointer text-base text-[#00000073]" />
                  </Popconfirm>
                </div>
                <Divider className="m-3" />
              </div>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Müsait öğrenci bulunamadı" />
          )}
        </div>
      </Modal>

      <Modal
        className="max-h-[500px] 2xl:max-h-[600px]"
        title={<span className="text-sm font-normal">Kaydı iptal et</span>}
        open={isRemoveStudentModalOpen}
        footer={null}
        onCancel={() => {
          removeForm.reset();
          setSelectedStudent(undefined);
          setIsRemoveStudentModalOpen(false);
        }}
        centered
      >
        <div className="font-semibold text-lg">{`${selectedStudent?.name} ${selectedStudent?.surname}`}</div>
        <div className="italic text-[#00000073] text-base">{dayjs().format('DD.MM.YYYY')}</div>

        <Form layout="vertical" className="mt-5">
          <Form.Item {...removeForm.formItem('type')} label="Kayıt iptal nedeni" className="w-2/3">
            <Select {...removeForm.inputSelected('type')} placeholder="Seçiniz" options={removeStudentOptions} />
          </Form.Item>
          <Form.Item {...removeForm.formItem('note')} label="Not">
            <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 3, maxRows: 5 }} {...removeForm.input('note')} />
          </Form.Item>

          <Button type="primary" onClick={removeStudentFromClass} className="mt-3">
            Kaydı iptal et
          </Button>
        </Form>
      </Modal>

      <Modal
        className="max-h-[500px] 2xl:max-h-[600px]"
        title={<span className="text-sm font-normal">Kaydı askıya al</span>}
        open={isPendingStudentModalOpen}
        footer={null}
        onCancel={() => {
          pendingForm.reset();
          setSelectedStudent(undefined);
          setIsPendingStudentModalOpen(false);
        }}
        centered
      >
        <div className="font-semibold text-lg">{`${selectedStudent?.name} ${selectedStudent?.surname}`}</div>
        <div className="italic text-[#00000073] text-base">{dayjs().format('DD.MM.YYYY')}</div>

        <Form layout="vertical" className="mt-5">
          <div className="flex justify-between gap-4">
            <Form.Item {...pendingForm.formItem('type')} label="Kayıt askıya alma nedeni" className="w-3/5">
              <Select {...pendingForm.inputSelected('type')} placeholder="Seçiniz" options={pendingStudentOptions} />
            </Form.Item>
            <Form.Item {...pendingForm.formItem('pendingTillDate')} label="Kayıt askıya alma süresi" className="w-2/5">
              <DatePicker placeholder="Seçiniz" {...pendingForm.inputDate('pendingTillDate')} style={{ width: '100%' }} minDate={dayjs()} />
            </Form.Item>
          </div>
          <Form.Item {...pendingForm.formItem('note')} label="Not">
            <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 3, maxRows: 5 }} {...pendingForm.input('note')} />
          </Form.Item>

          <Button type="primary" onClick={pendingStudentFromClass} className="mt-3">
            Kaydı askıya al
          </Button>
        </Form>
      </Modal>
    </>
  );
};
