import { StudentHeader, StudentProfileTabs } from '@platform/platform_components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import * as API from '@shared/api';
import { useUserContext } from '@shared/contexts';

export const StudentProfile = () => {
  useAccessableSeachParams();
  const [demandList, demandListLoading, getDemandList] = API.STUDENT.getStudentDemands({ init: true }); //Öğrencinin aldığı kursların listesi
  const { user } = useUserContext();

  return (
    <div className="py-2 space-y-5">
      <StudentHeader layout={LayoutEnums.STUDENT} name={user.name!} surname={user.surname!} email={user.email!} phone={'UseContext phone gelmiyor'} />
      <StudentProfileTabs demandList={demandList} layout={LayoutEnums.STUDENT} />
    </div>
  );
};

StudentProfile.path = '/' + LayoutEnums.STUDENT + '/profile';
StudentProfile.roles = [Role.ADMIN];
StudentProfile.layouts = [LayoutEnums.STUDENT];
