"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const locale = {
  placeholder: 'Zaman seç',
  rangePlaceholder: ['Başlangıç zamanı', 'Bitiş zamanı']
};
var _default = exports.default = locale;