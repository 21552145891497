import { useDraggable } from '@dnd-kit/core';
import { HTMLAttributes } from 'react';
import { CSS } from '@dnd-kit/utilities';

export interface DraggableProps extends HTMLAttributes<HTMLElement> {
  id?: string;
  type?: any;
  value?: any;
  disabled?: boolean;
}

export const Draggable = (props: DraggableProps) => {
  let { id, type, value, disabled = false, ...domProps } = props;

  const {
    attributes,
    listeners,
    setNodeRef: setRef,
    transform,
  } = useDraggable({
    id: id || 'draggable',
    disabled: disabled,
    data: {
      type,
      value,
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return <div {...domProps} ref={setRef} style={style} {...listeners} {...attributes}></div>;
};
