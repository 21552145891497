export type PhoneModel = Partial<{
    code:string,
    number:string,
}>

export type AddressModel = Partial<{
    country:PlaceModel,
    city:PlaceModel,
    postalCode:string,
    address:string,
}>

export type PlaceModel = Partial<{
    code:string,
    name:string,
    coordinate:CoordinateModel,
}>

export type CoordinateModel = Partial<{
    lat:number,
    lng:number,
}>