import { ExerciseLayoutType } from '@shared/constants';
import { Skeleton, Image, Tag } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};

export const VisualBaseSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.VISUAL_BASE);
      }}
    >
      <Tag bordered={false}>Basit</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Görsel/Dış/baslik-tanim.png')} />
      </div>
    </div>
  );
};

export const VisualSingilSelectSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.VISUAL_SINGLE_SELECT);
      }}
    >
      <Tag bordered={false}>Tekli Seçim</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-2 gap-y-2">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Görsel/Dış/tekli-secim.png')} />
      </div>
    </div>
  );
};

export const VisualMultiSelectSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.VISUAL_MULTI_SELECT);
      }}
    >
      <Tag bordered={false}>Çoklu Seçim</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-2 gap-y-2">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Görsel/Dış/coklu-secim.png')} />
      </div>
    </div>
  );
};
