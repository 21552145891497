import { CourseVersionModel } from "./course_version_model"

export type CourseUnitDefinetion = Partial<{
    name:string,
    description:string,
    order:number,
}>

export type CourseUnitModel = Partial<{
    id:string,
    name:string,
    description:string,
    order:number,
    contentVersion:CourseVersionModel,
    topicCount:number
}>