import { Button, Drawer, Form, notification } from 'antd';
import * as API from '@shared/api';
import { useEffect, useState } from 'react';
import { CascaderOption } from '@shared/models';
import { SelectWithInsert } from '../SelectWithInsert';

type EducationPropsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const EducationPropsDrawer = ({ isOpen, onClose }: EducationPropsDrawerProps) => {
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.SYSTEM.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.SYSTEM.useFetchType({ init: true });
  const [theme, themeLoading, getThemes] = API.EDUCATION_LEVEL.SYSTEM.useFetchTheme({ init: true });

  const [filterFields, setFilterFields] = useState<CascaderOption[]>([]);
  const [filterTypes, setFilterTypes] = useState<CascaderOption[]>([]);
  const [filterTheme, setFilterTheme] = useState<CascaderOption[]>([]);

  const [selectedField, setSelectedField] = useState<string>();
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedTheme, setSelectedTheme] = useState<string>();

  useEffect(() => {
    if (fields && types && theme) {
      setFilterFields(fields.data?.map((f) => ({ value: f.id, label: f.name })) as CascaderOption[]);
      if (selectedField) {
        setFilterTypes(types?.data?.filter((t) => t.field?.id == selectedField).map((t) => ({ value: t.id, label: t.name })) as CascaderOption[]);
      }
      if (selectedType) {
        setFilterTheme(theme?.data?.filter((th) => th.type?.id == selectedType).map((th) => ({ value: th.id, label: th.name })) as CascaderOption[]);
      }
    }
  }, [fields, types, theme]);

  const reloadEducationState = async () => {
    getFields();
    getTypes();
    getThemes();
  };

  const createNewField = async (name: string) => {
    const result = await API.EDUCATION_LEVEL.SYSTEM.createField(name);
    if (result.ok) {
      reloadEducationState();
      notification.success({ message: 'Alan oluşturuldu' });
    } else {
      reloadEducationState();
    }
  };

  const createNewType = async (name: string) => {
    if (name && name != '' && selectedField && selectedField != '') {
      const result = await API.EDUCATION_LEVEL.SYSTEM.createType(name, selectedField);
      if (result.ok) {
        reloadEducationState();
        notification.success({ message: 'Tür oluşturuldu' });
      } else {
        reloadEducationState();
      }
    } else {
      notification.warning({ message: 'Lütfen alan bilgisini seçiniz' });
    }
  };

  const createNewTheme = async (name: string) => {
    if (name && name != '' && selectedType && selectedType != '') {
      const result = await API.EDUCATION_LEVEL.SYSTEM.createTheme(name, selectedType);
      if (result.ok) {
        reloadEducationState();
        notification.success({ message: 'Tema oluşturuldu' });
      } else {
        reloadEducationState();
      }
    } else {
      notification.warning({ message: 'Lütfen tür bilgisini seçiniz' });
    }
  };

  const onChangeField = (f) => {
    setFilterTypes(types?.data?.filter((t) => t.field?.id == f).map((t) => ({ value: t.id, label: t.name })) as CascaderOption[]);
    setSelectedField(f);
    setSelectedType(undefined);
    setSelectedTheme(undefined);
  };
  const onChangeType = (t) => {
    setFilterTheme(theme?.data?.filter((th) => th.type?.id == t).map((th) => ({ value: th.id, label: th.name })) as CascaderOption[]);
    setSelectedType(t);
    setSelectedTheme(undefined);
  };
  const onChangeTheme = (t) => {
    setSelectedTheme(t);
  };

  const onCloseDrawer = () => {
    setSelectedField(undefined);
    setSelectedType(undefined);
    setSelectedTheme(undefined);
    onClose();
  };

  return (
    <Drawer title="Kur bileşenleri oluştur" width={520} closable={false} onClose={onCloseDrawer} open={isOpen}>
      <Form layout="vertical">
        <Form.Item label={'Alan'}>
          <SelectWithInsert items={filterFields} selected={selectedField} isLoading={fieldLoading} onChange={onChangeField} onAdd={createNewField} />
        </Form.Item>
        <Form.Item label={'Tür'}>
          <SelectWithInsert
            items={filterTypes}
            isLoading={typeLoading}
            selected={selectedType}
            isDisabled={selectedField && selectedField != '' ? false : true}
            onChange={onChangeType}
            onAdd={createNewType}
          />
        </Form.Item>
        <Form.Item label={'Tema'}>
          <SelectWithInsert
            items={filterTheme}
            isLoading={themeLoading}
            selected={selectedTheme}
            isDisabled={selectedType && selectedType != '' ? false : true}
            onChange={onChangeTheme}
            onAdd={createNewTheme}
          />
        </Form.Item>
        <div className="flex gap-x-2">
          <Button type="primary" onClick={onCloseDrawer}>
            Kaydet
          </Button>
          <Button type="default" onClick={onCloseDrawer}>
            Vazgeç
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
