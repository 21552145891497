import * as API from '@shared/api';
import { Button, Card, Form, Input, notification, Result, Spin, Image, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { CheckCircleTwoTone, CloseCircleFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

export const Activate = () => {
  const { token } = useParams();
  const [resultDescription, setResultDescription] = useState('');
  const [loading, setLoading] = useState(true);

  const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.00)',
    borderRadius: 50,
  };
  const content = <div style={contentStyle} />;

  const turnBack = () => {
    location.replace('/');
  };

  useEffect(() => {
    if (token?.length) {
      setTimeout(async () => {
        try {
          const result = await API.AUTH.activate(token);
          if (result.status === 200) {
            setTimeout(() => {
              location.replace('/');
            }, 5000);
          } else if (result.status === 404) {
            setResultDescription('Token daha önce kullanıldı.');
          } else if (result.status === 422) {
            setResultDescription('Token süresi dolmuştur.');
          }
        } catch (error) {
          setResultDescription('Bir hata oluştu, lütfen daha sonra tekrar deneyin.');
        } finally {
          setLoading(false);
        }
      }, 3000);
    }
  }, [token]);

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300">
      <Card className="flex justify-center items-center h-screen" style={{ height: '650px', width: '600px' }}>
        <div className="flex justify-center items-center">
          <Image preview={false} src={require('../../../statics/americanlife.png')} />
        </div>
        <div className="flex flex-col gap-0.5 mt-8 mb-8">
          <div>
            {loading ? (
              <div className="h-64">
                <Spin tip="Bekleyiniz.." size="large">
                  {content}
                </Spin>
              </div>
            ) : resultDescription ? (
              <div className="h-64">
                <Result
                  className="p-0"
                  icon={<CloseCircleFilled style={{ fontSize: '35px' }} />}
                  status="error"
                  subTitle={<span className="italic text-red-500">{resultDescription}</span>}
                  extra={[<Button type="default">Yeni aktivasyon linki gönder</Button>]}
                ></Result>
                <Divider className="w-96">
                  <span className="italic font-light">ya da</span>
                </Divider>
                <div className="flex justify-center items-center mb-5">
                  <Button type="link" onClick={turnBack}>
                    Giriş yap
                  </Button>
                </div>
              </div>
            ) : (
              <div className="h-64">
                <Result
                  className="p-0"
                  icon={<CheckCircleTwoTone twoToneColor="#1677FF" style={{ fontSize: '44px', color: '#1677FF' }} />}
                  status="success"
                  subTitle={<span className="italic text-blue-500">Aktivasyon işleminiz tamamlandı. Giriş sayfasına yönlendiriliyorsunuz..</span>}
                ></Result>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

Activate.path = 'account/activate/:token';
Activate.title = 'Activate';
