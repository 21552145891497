import { LayoutEnums } from "@shared/constants";
import { useAccessableContext, usePlatformLayoutContext, useUserContext } from "@shared/contexts"
import { defaultOptions } from "@shared/utils";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useAccessableSeachParams = () => {
    const { selectedLayout, setSelectedLayout } = usePlatformLayoutContext();
    const { brand, setBrand, branch, setBranch } = useAccessableContext();
    const { user } = useUserContext();
    const [searchParams] = useSearchParams();
    const navigation = useNavigate();

    useEffect(() => {
        const layout = searchParams.get("layout");
        const brandId = searchParams.get('brandId');
        const branchId = searchParams.get('branchId');
        if (user.jwt) {
            
            if (!brand) {
                if (brandId) {
                    const { features } = user;
                    if (features?.brand[brandId]) {
                        const header = defaultOptions.headers;
                        defaultOptions.headers = {
                            ...header,
                            "x-brand-id": brandId,
                        };
                        setBrand({
                            id: brandId,
                            permissions: features?.brand[brandId].permissions
                        })
                    } else {
                        navigation('/');
                    }

                }
            }
            if (!branch) {
                if (branchId) {
                    const { features } = user;
                    if (features?.branch[branchId]) {
                        const header = defaultOptions.headers;
                        defaultOptions.headers = {
                            ...header,
                            "x-branch-id": branchId,
                        };
                        setBranch({
                            id: branchId,
                            permissions: features?.branch[branchId].permissions
                        });
                    } else {
                        navigation('/');
                    }

                }
            }
            if (layout) {
                setSelectedLayout(layout as LayoutEnums);
            } else {
                setSelectedLayout(LayoutEnums.WELCOME_LAYOUT);
            }
        }

    }, [user, searchParams]);

    return { selectedLayout };
}