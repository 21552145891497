import { ExerciseLayoutType } from '@shared/constants';
import {  Tag, Image } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};
export const ReadingSingilSelectSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.READING_SINGLE_SELECT);
      }}
    >
      <Tag bordered={false}>Tekli Seçim</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Okuma/Dış/tekli-secim.png')} />
      </div>
    </div>
  );
};

export const ReadingMultiSelectSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.READING_MULTI_SELECT);
      }}
    >
      <Tag bordered={false}>Çoklu Seçim</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Okuma/Dış/coklu-secim.png')} />
      </div>
    </div>
  );
};


export const ReadingGapFillingSkeleton = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.READING_GAP_FILLING);
      }}
    >
      <Tag bordered={false}>Boşluk Doldurma</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Okuma/Dış/coklu-secim.png')} />
      </div>
    </div>
  );
};
