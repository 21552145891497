import { Button, Drawer, Form, Input, InputNumber, notification, Popconfirm } from 'antd';
import { DatePicker } from 'antd';
import { LayoutEnums, Role } from '@shared/constants';
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';

type CreateHomeWorkDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateHomeWorkDrawer = ({ open, onClose }: CreateHomeWorkDrawerProps) => {
  const { RangePicker } = DatePicker;

  return (
    <Drawer forceRender={true} open={open} onClose={onClose} closable={true} title={'Ödev Oluştur'}>
      <div>
        <div>Ödev Başlığı</div>
        <TextArea showCount maxLength={300} style={{ resize: 'none' }} className="mt-3" autoSize={{ minRows: 2, maxRows: 5 }} />
      </div>
      <div className="mt-4">
        <div className="mb-3">Ödev Tarihi</div>

        <RangePicker className="mb-3" placeholder={['Başlangıç tarihi', 'Bitiş tarihi']} />
        <DatePicker picker="time" placeholder="Bitiş saati" />
      </div>
      <div className="mt-3">
        <div>Dosya Seçiniz</div>
        <div>
          <Button className="text-blue-500 mt-3" icon={<UploadOutlined />}>
            Dosya Yükle
          </Button>
          <div className="italic mt-3">
            <span className="text-red-500">*</span> Desteklenen formatlar: .docx, .word, .pdf, .mp4, .zip
          </div>
          <div className="grid grid-cols-2 gap-3 mt-8">
            <Button type="primary">Ödev oluştur</Button>
            <Button>Taslak olarak kaydet</Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

CreateHomeWorkDrawer.roles = [Role.ADMIN];
