import { EducationField, EducationLevel, EducationTheme, EducationType, PaginateModel, UseFetchOptions } from '@shared/models';
import { querytoUrlString, request } from '@shared/utils';
import { useFetch } from '@shared/hooks';

export default {
  useFetchField: (config: UseFetchOptions) => useFetch<PaginateModel<EducationField>>('/lms/branch-education-field', { method: 'GET', ...config }),
  createField: async (name: string) => request('/lms/branch-education-field', 'POST', { name }),

  useFetchType: (config: UseFetchOptions) => useFetch<PaginateModel<EducationType>>('/lms/branch-education-type', { method: 'GET', ...config }),
  createType: async (name: string, fieldId: string) =>
    request('/lms/branch-education-type', 'POST', {
      field: {
        id: fieldId,
      },
      name,
    }),

  useFetchTheme: (config: UseFetchOptions) => useFetch<PaginateModel<EducationTheme>>('/lms/branch-education-theme', { method: 'GET', ...config }),
  createTheme: async (name: string, typeId: string) =>
    request('/lms/branch-education-theme', 'POST', {
      type: {
        id: typeId,
      },
      name,
    }),

  useFetchLevel: (config: UseFetchOptions) => useFetch<PaginateModel<EducationLevel>>('/lms/branch-education-level', { method: 'GET', ...config }),
  createLevel: async (name: string, themeId: string) =>
    request('/lms/branch-education-level', 'POST', {
      theme: {
        id: themeId,
      },
      name,
    }),

  updateLevel: async (level: EducationLevel) => request('/lms/branch-education-level', 'PUT', { ...level }),
};
