export const isObjectEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const isObjectDiff = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 !== obj2;
  }

  const keys1 = Object.keys(obj1).filter((o) => o !== 'deletedAt' && o !== 'createdAt' && o !== 'updatedAt');
  const keys2 = Object.keys(obj2).filter((o) => o !== 'deletedAt' && o !== 'createdAt' && o !== 'updatedAt');

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return true;
    }

    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      if (isObjectDiff(obj1[key], obj2[key])) {
        return true;
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
  }

  return false;
};
