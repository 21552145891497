import { SelectedBrand,SelectedBranch } from "@shared/models";
import { createContext, useContext, useState } from "react";


type AccessableContextType = {
    brand:SelectedBrand |undefined;
    setBrand:(brand:SelectedBrand)=>void;
    branch:SelectedBranch|undefined;
    setBranch :(branch:SelectedBranch)=>void;
};

const AccessableContext = createContext<AccessableContextType>({brand:undefined,setBrand:()=>null,branch:undefined,setBranch:()=>null});

export const AccessableContextProvider = ({children})=>{
    const [brand,setBrand] = useState<SelectedBrand>();
    const [branch,setBranch] = useState<SelectedBranch>();
    return(
        <AccessableContext.Provider value={{brand,setBrand,branch,setBranch}}>
            {children}
        </AccessableContext.Provider>
    )
}

export const useAccessableContext = ()=>{
    const context = useContext(AccessableContext);

    if(context ==undefined){
        throw new Error("useAccessableContext must be used within a AccessableContextProvider");
    }
    return context;
}



