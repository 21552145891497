import * as API from '@shared/api';
import { ClassActivityStatus, LayoutEnums } from '@shared/constants';
import { ClassActivity } from '@shared/models';
import { Button, notification } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TimeFormatter } from './TimeFormatter';

type NearTimeNotificationProps = {
  activity: ClassActivity;
};

export const NearTimeNotification = ({ activity }: NearTimeNotificationProps) => {
  const navigate = useNavigate();
  const targetDate = new Date();
  const [title, setTitle] = useState<ReactElement>(<></>);
  const location = useLocation();

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {
      minutes: 0,
      seconds: 0,
      str: '00:00',
    };

    if (difference > 0) {
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      timeLeft = {
        minutes,
        seconds,
        str: minutes + ':' + seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<{ minutes: number; seconds: number; str: string }>(calculateTimeLeft());

  useEffect(() => {
    targetDate.setHours(activity.classSeanceItem?.beginHour ?? 0, activity.classSeanceItem?.beginMinute ?? 0);
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [activity]);

  useEffect(() => {
    if (activity.status == ClassActivityStatus.NEAR_TIME && !(timeLeft.minutes == 0 && timeLeft.seconds == 0)) {
      setTitle(
        <span className="text-white text-sm">
          {
            <TimeFormatter
              startHour={activity.classSeanceItem?.beginHour!}
              startMinute={activity.classSeanceItem?.beginMinute!}
              endHour={activity.classSeanceItem?.endHour!}
              endMinute={activity.classSeanceItem?.endMinute!}
            />
          }{' '}
          {activity.classCalendarEntry?.class?.name} sınıfınız {activity.classSeanceItem?.classRoom?.name} dersliğinde{' '}
          <span className="text-[#FFFF00]">
            {timeLeft.minutes} dakika {timeLeft.seconds} saniye içinde başlıyor.
          </span>
        </span>
      );
    }
    if (activity.status == ClassActivityStatus.NEAR_TIME && timeLeft.minutes == 0 && timeLeft.seconds == 0) {
      setTitle(
        <span className="text-white text-sm">
          {
            <TimeFormatter
              startHour={activity.classSeanceItem?.beginHour!}
              startMinute={activity.classSeanceItem?.beginMinute!}
              endHour={activity.classSeanceItem?.endHour!}
              endMinute={activity.classSeanceItem?.endMinute!}
            />
          }{' '}
          {activity.classCalendarEntry?.class?.name} sınıfınız {activity.classSeanceItem?.classRoom?.name} dersliğinde ders saatiniz geldi.
        </span>
      );
    }
    if (activity.status == ClassActivityStatus.DANGLED) {
      setTitle(
        <span className="text-white text-sm">
          {
            <TimeFormatter
              startHour={activity.classSeanceItem?.beginHour!}
              startMinute={activity.classSeanceItem?.beginMinute!}
              endHour={activity.classSeanceItem?.endHour!}
              endMinute={activity.classSeanceItem?.endMinute!}
            />
          }{' '}
          {activity.classCalendarEntry?.class?.name} sınıfınız {activity.classSeanceItem?.classRoom?.name} dersliğindeki ders sonlandırılmadı
        </span>
      );
    }
    if (activity.status == ClassActivityStatus.WAITING_FOR_ATTENDANCE || activity.status == ClassActivityStatus.STARTED) {
      setTitle(
        <span className="text-white text-sm">
          {
            <TimeFormatter
              startHour={activity.classSeanceItem?.beginHour!}
              startMinute={activity.classSeanceItem?.beginMinute!}
              endHour={activity.classSeanceItem?.endHour!}
              endMinute={activity.classSeanceItem?.endMinute!}
            />
          }{' '}
          {activity.classCalendarEntry?.class?.name} sınıfınız {activity.classSeanceItem?.classRoom?.name} dersliğindeki dersiniz devam etmektedir
        </span>
      );
    }
  }, [timeLeft, activity]);

  const onCloseClass = async () => {
    try {
      await API.CLASS.compeletedInClass(activity.classCalendarEntry?.class?.id!, activity.id!);
      if (location.pathname == '/teacher/lesson-content') {
        navigate('/teacher/class' + location.search);
      }
    } catch (error) {
      notification.error({
        message: 'Hata',
        description: 'Dersi sonlandırırken bir hata oluştu',
      });
    }
  };

  const goToClass = () => {
    const params = new URLSearchParams({
      layout: LayoutEnums.TEACHER,
      brandId: activity.brandId!,
      branchId: activity.branchId!,
    });
    navigate('/' + LayoutEnums.TEACHER + '/lesson-content?' + params + '&class_id=' + activity.classCalendarEntry?.class?.id);
  };

  return (
    <div key={activity.id} className="bg-black p-2 flex flex-row justify-center space-x-4">
      <div className="text-center">{title}</div>
      {activity.status == ClassActivityStatus.NEAR_TIME && timeLeft.minutes == 0 && timeLeft.seconds == 0 && (
        <Button type="primary" size="small" onClick={goToClass}>
          Derse Katıl
        </Button>
      )}
      {activity.status == ClassActivityStatus.DANGLED && (
        <Button type="primary" size="small" danger onClick={onCloseClass}>
          Dersi sonlandır
        </Button>
      )}
      {(activity.status == ClassActivityStatus.WAITING_FOR_ATTENDANCE || activity.status == ClassActivityStatus.STARTED) && (
        <div className=" space-x-2">
          <Button type="primary" className="bg-green-500" size="small" onClick={goToClass}>
            Derse Devam Et
          </Button>
          <Button type="primary" size="small" danger onClick={onCloseClass}>
            Dersi Sonlandır
          </Button>
        </div>
      )}
    </div>
  );
};
