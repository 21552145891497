import { LoadingOutlined, SoundOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { MediaModel } from '@shared/models';
import { uuid } from '@shared/utils';
import { Upload, notification } from 'antd';
import { GetProp } from 'antd/lib/_util/type';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type SoundUploadProps = {
  sound?: MediaModel;
  onChange: (sound: MediaModel) => void;
  onChangeLoading?: (loading: boolean) => void;
  isAdminCdn: boolean;
  disabled?: boolean;
};
export const SoundUpload = ({ sound, onChange, onChangeLoading, isAdminCdn,disabled }: SoundUploadProps) => {
  const [uploadSoundFile, setUploadSoundFile] = useState<UploadFile>();
  const [soundUploadLoading, setSoundUploadLoading] = useState(false);
  const [contentType, setContentType] = useState<string>();
  const [media, setMedia] = useState<MediaModel>();

  useEffect(() => {
    if (sound) {
      setMedia(media);
    }
  }, [sound]);

  const beforeUploadSound = (file: FileType) => {
    const type = file.type;
    const isTypeCorrect = type === 'audio/mpeg' || type === 'audio/wav';
    if (!isTypeCorrect) {
      notification.warning({ message: 'You can only upload mp3/wav file!' });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.warning({ message: 'Image must smaller than 10MB!' });
      return false;
    }
    setContentType(type);
    setUploadSoundFile(file);
    return true;
  };

  const handleUploadSound = async () => {
    /*const formData = new FormData();
    formData.append('file', uploadSoundFile as FileType);*/
    if (onChangeLoading) {
      onChangeLoading(true);
    }
    setSoundUploadLoading(true);
    const uuId = uuid();
    let _url = '';
    if (isAdminCdn) {
      const {
        data: { url },
      } = await API.CDN.obtainCreateUploadVideoAndSoundUrl(uuId);
      _url = url;
    } else {
      const {
        data: { url },
      } = await API.CDN.brandObtainCreateUploadVideoAndSoundUrl(uuId);
      _url = url;
    }
    const request = await fetch(_url, {
      method: 'POST',
      body: uploadSoundFile as FileType,
      headers: { 'Content-Type': contentType! },
    });
    if (request.ok) {
      await request.body;
      const model = { resourceId: uuId } as MediaModel;
      setMedia(model);
      onChange(model);
    }
    if (onChangeLoading) {
      onChangeLoading(false);
    }
    setSoundUploadLoading(false);
  };

  const soundUploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {soundUploadLoading ? <LoadingOutlined /> : <SoundOutlined />}
      <div style={{ marginTop: 0 }}>{media ? 'Media yüklendi' : 'Upload'}</div>
    </button>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      multiple={false}
      maxCount={1}
      supportServerRender={false}
      beforeUpload={beforeUploadSound}
      customRequest={handleUploadSound}
      disabled={disabled}
    >
      {soundUploadButton}
    </Upload>
  );
};
