import { menuGroups } from '@shared/utils';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  items: {
    key: string;
    icon: ReactNode;
    breadcrumbtitle: string;
    group: string;
  }[];
};

export const RouteBreadcrumb = ({ items = [] }: Props) => {
  const { pathname } = useLocation();

  const page = items.find((item) => item.key.replace('/', '') === pathname.replace('/', ''));
  const navigate = useNavigate();

  const _items = useMemo(() => {
    if (page) {
      const items: BreadcrumbItemType[] = [];
      if (page.group != 'flat' && page.group) {
        const d = menuGroups.find((m) => {
          return m.key == page.group;
        });
        if (d) {
          items.push({
            onClick: () => {},
            title: (
              <>
                {d.icon}
                <span>{d.title}</span>
              </>
            ),
          });
        }
      }
      const item: BreadcrumbItemType = {
        onClick: () => navigate(0),
        title: (
          <>
            {page.icon}
            <span>{page.breadcrumbtitle}</span>
          </>
        ),
      };
      items.push(item);

      return items;
    }

    return [] as BreadcrumbItemType[];
  }, [page, navigate]);

  return (
    <div className="ml-12 sm:ml-4  mt-3">
      <Breadcrumb items={_items}>
        {page && (
          <Breadcrumb.Item onClick={() => navigate(0)}>
            {page.icon}
            <span>{page.breadcrumbtitle}</span>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    </div>
  );
};
