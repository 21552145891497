import { BrandContentVersionUpdatePackageStatus, ContentVersionStatus } from "@shared/constants"
import { BrandModel } from "@shared/models/brand"
import { EducationLevel } from "@shared/models/education_level"

export type CourseVersionModel = Partial<{
    id:string,
    name:string,
    description:string,
    level:EducationLevel,
    version:number,
    status:ContentVersionStatus,
    isDefault:boolean,
    brandVersion:number,
}>

export type CourseVersionDefination = Partial<{
    id:string,
    name:string,
    description:string,
    levelId:string,
}>

export type BrandContentVersionUpdatePackage = Partial<{
    id:string,
    status:BrandContentVersionUpdatePackageStatus,
    brand:BrandModel,
    //TODO buradaki model farklı olabilir
    systemContentVersion:CourseVersionModel
}>