import { AvailabilityCalendar, TimeRange } from '@shared/models';
import { useEffect, useState } from 'react';

type AvailabilityWeekCalendarTypes = {
  value: AvailabilityCalendar;
  onChange: (value: AvailabilityCalendar) => void;
  disabled?: boolean;
};

export const AvailabilityWeekCalendar = ({ value, onChange, disabled }: AvailabilityWeekCalendarTypes) => {
  const [hoveredCordinat, setHoveredCordinat] = useState<number[]>([]);
  const [availabilityCalendar, setAvailabilityCalendar] = useState<AvailabilityCalendar>(value);
  const timePeriot = [
    '07:00 - 08:00',
    '08:00 - 09:00',
    '09:00 - 10:00',
    '10:00 - 11:00',
    '11:00 - 12:00',
    '12:00 - 13:00',
    '13:00 - 14:00',
    '14:00 - 15:00',
    '15:00 - 16:00',
    '16:00 - 17:00',
    '17:00 - 18:00',
    '18:00 - 19:00',
    '19:00 - 20:00',
    '20:00 - 21:00',
    '21:00 - 22:00',
    '22:00 - 23:00',
  ];

  useEffect(() => {
    if (value) {
      setAvailabilityCalendar(value);
    }
  }, [value]);

  const sendData = (value: AvailabilityCalendar) => {
    if (!disabled) {
      onChange(value);
    }
  };

  const onAllWeek = () => {
    const days = [0, 1, 2, 3, 4, 5, 6];
    let isAvalibleAllDays = false;
    if (Object.keys(availabilityCalendar).length == 7) {
      isAvalibleAllDays = true;
      days.forEach((dayIndex) => {
        const dayPeriots = availabilityCalendar[dayIndex];
        if (dayPeriots?.length != timePeriot.length) {
          isAvalibleAllDays = false;
        }
      });
      if (isAvalibleAllDays) {
        sendData({});
      } else {
        const allTimes = timePeriot.map((time) => {
          const hour = parseInt(time.split(':')[0]);
          return {
            hour,
            minute: 59,
          };
        });
        const result = {};
        days.forEach((day) => {
          result[day] = allTimes;
        });
        sendData(result);
      }
    } else {
      const allTimes = timePeriot.map((time) => {
        const hour = parseInt(time.split(':')[0]);
        return {
          hour,
          minute: 59,
        };
      });
      const result = {};
      days.forEach((day) => {
        result[day] = allTimes;
      });
      sendData(result);
    }
  };

  const onSelectPeriot = (periotIndex: number) => {
    const result: AvailabilityCalendar = {};
    const hour = parseInt(timePeriot[periotIndex].split(':')[0]);
    const days = [0, 1, 2, 3, 4, 5, 6];
    let isAvalibleAllDays = false;
    if (Object.keys(availabilityCalendar).length == 7) {
      isAvalibleAllDays = true;
      days.forEach((dayIndex) => {
        const dayPeriots = availabilityCalendar[dayIndex];
        if (dayPeriots && dayPeriots?.length > 0) {
          const find = dayPeriots.find((d) => d.hour == hour);
          if (!find) {
            isAvalibleAllDays = false;
          }
        }
      });
      if (isAvalibleAllDays) {
        days.forEach((dayIndex) => {
          const newPeriot = availabilityCalendar[dayIndex.toString()]?.filter((p) => p.hour != hour);
          result[dayIndex.toString()] = newPeriot;
        });
      } else {
        days.forEach((dayIndex) => {
          const newPeriot = availabilityCalendar[dayIndex.toString()];
          if (newPeriot) {
            newPeriot.push({ hour, minute: 59 });
            result[dayIndex.toString()] = newPeriot;
          } else {
            result[dayIndex.toString()] = [{ hour, minute: 59 }];
          }
        });
      }
    } else {
      days.forEach((dayIndex) => {
        const day = dayIndex.toString();
        if (day in availabilityCalendar) {
          const dayPeriots = availabilityCalendar[day];
          const isAvalible = dayPeriots?.find((o) => o.hour == hour);
          if (!isAvalible) {
            result[day] = [...dayPeriots!, { hour, minute: 59 }];
          }
        } else {
          result[day] = [{ hour, minute: 59 }];
        }
      });
    }
    sendData({ ...availabilityCalendar, ...result });
  };

  const onSelectDay = (dayIndex: number) => {
    const day = dayIndex.toString();
    if (day in availabilityCalendar) {
      const oldPeriots = availabilityCalendar[day];
      if (oldPeriots?.length == timePeriot.length) {
        sendData({ ...availabilityCalendar, [day]: [] });
      } else {
        const times: TimeRange[] = [];
        timePeriot.forEach((t) => {
          const hour = parseInt(t.split(':')[0]);
          times.push({
            hour,
            minute: 59,
          });
        });
        sendData({ ...availabilityCalendar, [day]: times });
      }
    } else {
      const times: TimeRange[] = [];
      timePeriot.forEach((t) => {
        const hour = parseInt(t.split(':')[0]);
        times.push({
          hour,
          minute: 59,
        });
      });
      sendData({ ...availabilityCalendar, [day]: times });
    }
  };

  const onSelectDate = (dayIndex: number, periotIndex: number) => {
    const day = dayIndex.toString();
    const hour = parseInt(timePeriot[periotIndex].split(':')[0]);

    if (day in availabilityCalendar) {
      const periots = availabilityCalendar[day];
      const find = periots?.find((p) => p.hour == hour);
      if (find) {
        const newPeriots = periots?.filter((p) => p.hour != hour);
        sendData({ ...availabilityCalendar, [day]: [...newPeriots!] });
      } else {
        periots?.push({ hour, minute: 59 });
        sendData({ ...availabilityCalendar, [day]: [...periots!] });
      }
    } else {
      sendData({ ...availabilityCalendar, [day]: [{ hour, minute: 59 }] });
    }
  };

  return (
    <div className="cursor-pointer">
      <div className="grid grid-cols-8 h-6 mb-1">
        <div
          className="border-1 border-black border-current text-center text-wrap line-clamp-1 rounded bg-gray-300 ml-1"
          onClick={() => {
            onAllWeek();
          }}
        >
          Tüm Hafta
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 0 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(0);
          }}
        >
          Pazartesi
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 1 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(1);
          }}
        >
          Salı
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 2 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(2);
          }}
        >
          Çarşamba
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 3 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(3);
          }}
        >
          Perşembe
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 4 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(4);
          }}
        >
          Cuma
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 5 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(5);
          }}
        >
          Cumartesi
        </div>
        <div
          className={'border-1 border-black border-current text-center text-wrap line-clamp-1 rounded ml-1 ' + (hoveredCordinat[0] == 6 ? 'bg-green-300' : 'bg-blue-200')}
          onClick={() => {
            onSelectDay(6);
          }}
        >
          Pazar
        </div>
      </div>
      {timePeriot.map((time, i) => (
        <div className="grid grid-cols-8 h-6 mb-1 truncate" key={i}>
          <div
            className={'border-1 border-black border-current flex flex-wrap items-center justify-center text-center rounded ml-1 ' + (hoveredCordinat[1] == i ? 'bg-green-300' : 'bg-blue-100')}
            onClick={() => {
              onSelectPeriot(i);
            }}
          >
            
            <span className="text-xs text-balance md:line-clamp-2">{time}</span>
          </div>
          {[0, 1, 2, 3, 4, 5, 6].map((t) => (
            <div
              key={(Math.random() + t).toString(36).substring(7)}
              className={
                'border-1 border-black border-current text-center rounded ml-1 ' +
                (Object.keys(availabilityCalendar).length > 0 && availabilityCalendar[t.toString()]?.find((p) => p.hour == parseInt(time.split(':')[0]))
                  ? 'bg-green-400'
                  : 'bg-gray-200')
              }
              onMouseEnter={() => {
                if (hoveredCordinat[0] != t || hoveredCordinat[1] != i) {
                  setHoveredCordinat([t, i]);
                }
              }}
              onClick={() => {
                onSelectDate(t, i);
              }}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
};
