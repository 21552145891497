import { BrandContentVersionUpdatePackageStatus, ContentVersionStatus } from "@shared/constants";
import { useFetch } from "@shared/hooks";
import { BrandContentVersionUpdatePackage, CourseVersionDefination, CourseVersionModel, UseFetchOptions } from "@shared/models";
import { request } from "@shared/utils";

export default {
    create: async (version: CourseVersionDefination) => request('/lms/brand-content-version', "POST", version),
    update:async(version:CourseVersionDefination)=>request("/lms/brand-content-version","PUT",version),
    fetchWithStatus: (status: ContentVersionStatus, config: UseFetchOptions) => useFetch<CourseVersionModel[]>('/lms/brand-content-version?status=' + status, { method: 'GET', ...config }),
    fetchVersionPackage: (config: UseFetchOptions) => useFetch<BrandContentVersionUpdatePackage[]>('/lms/brand-content-version-update-package', { method: 'GET', ...config }),
    fetchVersionPackageWithStatus: (status:BrandContentVersionUpdatePackageStatus,config: UseFetchOptions) => useFetch<BrandContentVersionUpdatePackage[]>('/lms/brand-content-version-update-package?status='+status, { method: 'GET', ...config }),
    acceptUpdateVersion: async(id:string)=>request("/lms/brand-content-version-update-package/"+id+"/accept","GET"),
    rejectUpdateVersion:async (id:string)=>request("/lms/brand-content-version-update-package/"+id+"/reject","GET"),
    get:async (versionId:string)=>request("/lms/brand-content-version/"+versionId,"GET"),

    delete:async (versionId:string)=>request("/lms/content-version/"+versionId,'DELETE'),
    fork:async (versionId:string)=>request("/lms/brand-content-version/"+versionId+"/fork",'GET'),
    publish:async (versionId:string)=>request("/lms/brand-content-version/"+versionId+"/publish",'GET'),
    setAsDefault:async (versionId:string)=>request("/lms/brand-content-version/"+versionId+"/set-as-default",'PUT'),

    //Review
    startFirstPage:async (versionId:string)=>request("/lms/brand-content-version/"+versionId+"/next-page",'GET'),
    prevPage:async(versionId:string,pageId:string)=>request("/lms/brand-content-version/"+versionId+"/prev-page/"+pageId,'GET'),
    nextPage:async (versionId:string,pageId:string)=>request("/lms/brand-content-version/"+versionId+"/next-page/"+pageId,'GET'),
    goToPage:async (versionId:string,pageId:string)=>request("/lms/brand-content-version/"+versionId+"/go-to-page/"+pageId,'GET'),
}