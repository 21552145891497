import { ExerciseLayoutType } from '@shared/constants';
import { Tag, Image } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};
export const MatchSkeletons = ({ onChange }: ExerciseProps) => {
  return (
    <div
      className="text-start"
      onClick={() => {
        onChange(ExerciseLayoutType.MATCHING_BASE);
      }}
    >
      <Tag bordered={false}>Basit</Tag>
      <div className="flex flex-col justify-center items-center content-center mt-1">
        <Image preview={false} src={require('../../../../../../../statics/exercises_skeleton/Eşleştirme/Dış/eslestirme.png')} />
      </div>
    </div>
  );
};
