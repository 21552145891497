import { Button, Drawer, Form, Input, InputNumber, notification, Popconfirm } from 'antd';
import { DatePicker } from 'antd';
import { LayoutEnums, Role } from '@shared/constants';
import TextArea from 'antd/lib/input/TextArea';

type ReminderDrawerProps = {
  open: boolean;
  onCloseDrawer: () => void;
};

export const ReminderDrawer = ({ open, onCloseDrawer }: ReminderDrawerProps) => {
  const handleOnClose = () => {
    //form.reset();
    onCloseDrawer();
  };
  return (
    <Drawer forceRender={true} open={open} onClose={onCloseDrawer} closable={true} title={'Hatırlatıcı Oluştur'}>
      <div className="font-bold text-xl">Kompozisyon</div>
      <div className="opacity-45 mt-2">12.05.2024 - 24.05.2024</div>
      <div className="opacity-45 mt-4">
        Kur süresince öğrendiğiniz zaman zarflarını kullanarak, geçtiğimiz yaz tatiliniz ile ilgili bir kompozisyon yazınız. Yeni öğrendiğiniz kelimelere yer veriniz. Paragraf
        uzunluğu en az 120-150 kelime aralığında olmalı.
      </div>
      <div className="grid grid-cols-2 gap-3 mt-7">
        <DatePicker placeholder="Seçiniz" />
        <DatePicker picker="time" placeholder="Seçiniz" />
      </div>
      <div>
        <TextArea placeholder="Not Ekleyiniz" showCount maxLength={300} style={{ resize: 'none' }} className="mt-3" autoSize={{ minRows: 2, maxRows: 5 }} />
      </div>
      <div className="grid grid-cols-2 gap-3 mt-8">
        <Button type="primary">Hatırlatıcı ekle</Button>
        <Button onClick={handleOnClose}> Vazgeç</Button>
      </div>
    </Drawer>
  );
};

ReminderDrawer.roles = [Role.ADMIN];
