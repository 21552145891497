import { ImageView, SoundPlayer, TextPage } from "@shared/components";
import { CourseTopicPageModel } from "@shared/models";

type ImagePageProps = {
    page: CourseTopicPageModel,
    isAdminCdn: boolean
}

export const ImagePage = ({ page,isAdminCdn }: ImagePageProps) => {
    return (
        <div className={page.content?.sound || (page.content?.text && page.content?.text != "<p><br></p>" ) ? "grid grid-cols-2 space-x-2 " : "h-full text-center"}>
            <ImageView image={page.content?.image ? page.content?.image : {}} />
            <div className="flex flex-col pt-3">
                {page.content?.sound && <SoundPlayer sound={page.content?.sound} onChange={() => { }} isInit={false} inline={true} isAdminCdn={isAdminCdn} />}
                {page.content?.text && page.content?.text != "<p><br></p>" && <TextPage text={page.content?.text} />}
            </div>
        </div>
    );
}