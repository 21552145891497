import { HTMLAttributes, ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import { ClockCircleOutlined, CommentOutlined, SyncOutlined } from '@ant-design/icons';

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const statusTitleIcons = {
  PLANNING: {
    title: 'Planlama Başladı',
    icon: <SyncOutlined />,
  },
  IN_REVIEW: {
    title: 'İnceleme',
    icon: <ClockCircleOutlined />,
  },
  REJECTED: {
    title: 'Reddedildi',
    icon: <ClockCircleOutlined />,
  },
  COMMENT: {
    title: 'Yorum',
    icon: <CommentOutlined />,
  },
};

export interface CommentTimelineItemProps extends HTMLAttributes<HTMLElement> {
  status?: string;
  at?: string | Date;
  name?: string;
  comment?: string;
  last?: boolean;
}

export const CommentTimelineItem = (props: CommentTimelineItemProps) => {
  let { status = 'COMMENT', at, name, comment, last, ...domProps } = props;

  const fromTo = dayjs().to(dayjs(at));

  const titleIcon = useMemo(() => {
    return statusTitleIcons[status];
  }, [status]);

  return (
    <div {...domProps} data-status={status} className="comment-timeline-item relative flex  gap-[20px] z-10">
      <div className="comment-timeline-item-icon min-w-[32px] w-[32px] h-[32px] flex items-center justify-center  border border-solid  rounded-full outline outline-white outline-8">
        {titleIcon.icon}
      </div>
      {last && <div className="absolute w-1 bg-white bottom-0 left-[16px]" style={{ height: 'calc(100% - 32px)' }}></div>}
      <div className="w-full flex flex-col gap-1 pt-[5px]">
        <div className="w-full flex justify-between items-center">
          <span className="text-[12px]">{titleIcon.title}</span> <span className="text-[9px] text-black/45">{fromTo}</span>
        </div>
        <div className="text-[10px] text-black">{name}</div>
        <div className="text-[10px] text-black/45">{comment}</div>
      </div>
    </div>
  );
};

export interface CommentTimelineProps extends HTMLAttributes<HTMLElement> {
  status?: string;
}

export const CommentTimeline = (props: CommentTimelineProps) => {
  let { children, ...domProps } = props;

  return (
    <div {...domProps} className="comment-timeline relative  flex flex-col mt-[24px]">
      <div className="absolute h-full w-[1px] left-[16px] bg-black/10"></div>
      {children}
    </div>
  );
};
