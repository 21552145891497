import { Gender } from '@shared/constants';
import { PhoneModel } from './information';

export type Account = Partial<{
  id: string;
  name: string;
  surname: string;
  nationalId: string;
  email: string;
  phone: PhoneModel;
  active: boolean;
  birthDate: Date;
  gender: Gender;
}>;

export type AccountWeakRefProps = {
  id: string;
  name: string;
  email: string;
};
