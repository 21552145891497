import { BranchesOutlined } from '@ant-design/icons';
import { AppNavigation, UserInformationComponent } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Card } from 'antd';

export const Welcome = () => {
  useAccessableSeachParams();

  return (
    <div className="grid grid-cols-2 gap-x-2">
      <div>
        <Card>
          <span className="text-[16px] font-bold">Kullanıcı Rolu Seçiniz</span>
          <AppNavigation />
        </Card>
      </div>
      <div>
        <UserInformationComponent />
      </div>
    </div>
  );
};

Welcome.path = '/';
Welcome.title = 'Kontrol Paneline Hoşgeldiniz';
Welcome.group = 'flat';
Welcome.roles = [Role.ADMIN];
Welcome.layouts = [LayoutEnums.WELCOME_LAYOUT];
Welcome.icon = <BranchesOutlined size={20} />;
